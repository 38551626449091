import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ModalController } from "@ionic/angular";
import { RemarkModalComponent } from "../../modals/remark-modal/remark-modal.component";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";
import { DisplaySettingService } from "../../services/core/display-setting.service";
import { UserNamePipe } from "../../pipes/user-name.pipe";
import { Remark, TimeSlot, User } from "../../interfaces";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RemarkService } from "src/app/services/api/remark.service";
import { AuthService } from "src/app/services/api/auth.service";

@Component({
  selector: "app-time-slot-remark",
  templateUrl: "./time-slot-remark.component.html",
  styleUrls: ["./time-slot-remark.component.scss"],
})
export class TimeSlotRemarkComponent implements OnInit {
  @Input() set Id(ItemId: string) {
    this.itemId = ItemId;
  }
  @Input() Component: string;
  @Input() Headline: string;

  remarks: Remark[];
  count: number;
  itemId: string;
  previewText: string;
  

  constructor(
    private modalService: NgbModal,
    private remarkService: RemarkService,
    private authService: AuthService,
    private modalController: ModalController,
  ) {}

  async ngOnInit() {
    await this.getRemarks();
  }

  async getRemarks() {
    if(this.itemId) {
      this.count = await this.remarkService.getCountByItemId(this.itemId);
      this.remarks = await this.remarkService.getAllByItemId(
        this.itemId
      );
      this.previewText = this.remarks && this.remarks.length > 0 ? this.remarks[0].Text : "";
    }
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: RemarkModalComponent,
      componentProps: {
        ItemId: this.itemId,
        Component: this.Component,
        Headline: this.Headline,
        ShowDates: this.Component === "User" || this.Component === "Inventory",
      },
      cssClass: "modal-80",
      backdropDismiss: false,
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response && response.data && response.data.count) {
      this.count = response.data.count;
    }
  }
}
