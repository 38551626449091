import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Qualification, Team } from "../../../interfaces";

@Component({
  selector: "app-schedule-filter",
  templateUrl: "./schedule-filter.component.html",
  styleUrls: ["./schedule-filter.component.scss"],
})
export class ScheduleFilterComponent implements OnInit {
  @Input() Teams: Team[];
  @Input() Qualifications: Qualification[];
  @Input() showScheduleFilterForUserWithoutHours: boolean = false;
  @Input() hideUserWithoutHours: boolean = false;
  @Output() TeamUpdate = new EventEmitter<string[]>();
  @Output() QualificationUpdate = new EventEmitter<string[]>();
  @Output() HideUsersWithoutHours = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  teamUpdate(ids: string[]) {
    this.TeamUpdate.emit(ids);
  }
  toggleHideUser() {
    this.hideUserWithoutHours = !this.hideUserWithoutHours;
    this.HideUsersWithoutHours.emit(this.hideUserWithoutHours);
  }

  qualificationUpdate(ids: string[]) {
    this.QualificationUpdate.emit(ids);
  }
}
