import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SocketService } from "src/app/services/socket/socket.service";
import { NavigationService } from "src/app/services/core/navigation.service";
import { Subscription } from "rxjs";
import { PlatformStorageService } from "../../../services/storage/platform-storage.service";

@Component({
  selector: "app-crud-tool-bar",
  templateUrl: "./crud-tool-bar.component.html",
  styleUrls: ["./crud-tool-bar.component.scss"],
})
export class CrudToolBarComponent implements OnInit, OnDestroy {
  @Output() Save: EventEmitter<any> = new EventEmitter(true);
  @Output() Delete: EventEmitter<any> = new EventEmitter(true);
  @Output() Close: EventEmitter<any> = new EventEmitter(true);
  @Input() DeleteMessage: string;
  @Input() Name: string;
  @Input() disableSave: boolean;
  @Input() disableDelete: boolean;
  @Input() showSave: boolean;
  @Input() showDelete: boolean;
  @Input() showBack: boolean = true;
  @Input() closeSplitView: boolean = false;

  saveActive = false;
  disableSaveSocket = false;
  showExport = false;

  blockSaveSubscription: Subscription;
  platformSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private socketService: SocketService,
    private navigation: NavigationService,
    private platformService: PlatformStorageService,
  ) {
    this.disableSave =
      typeof this.disableSave === "undefined" ? false : this.disableSave;
    this.disableDelete =
      typeof this.disableDelete === "undefined" ? false : this.disableDelete;
    this.showSave = typeof this.showSave === "undefined" ? true : this.showSave;
    this.showDelete =
      typeof this.showDelete === "undefined" ? true : this.showDelete;
  }

  ngOnInit(): void {
    this.showExport = !this.platformService.getIsTabled();
    this.disableSaveSocket = this.socketService.getBlocked();
    this.blockSaveSubscription = this.socketService.BlockSave.subscribe(
      (isBlocked) => (this.disableSaveSocket = isBlocked),
    );
    this.platformSubscription = this.platformService.Update.subscribe();
  }

  ngOnDestroy(): void {
    if (this.blockSaveSubscription) {
      this.blockSaveSubscription.unsubscribe();
    }
    if (this.platformSubscription) {
      this.platformSubscription.unsubscribe();
    }
  }

  openDeleteModal(Modal) {
    this.modalService.open(Modal, {
      windowClass: "modal-animated",
      centered: true,
    });
  }

  async save(goBack: boolean = false) {
    if (!this.saveActive) {
      this.saveActive = true;
      await this.Save.emit(goBack);
      this.saveActive = false;
    }
  }

  async closeModal(modal, acceptDelete: boolean) {
    if (acceptDelete) {
      await this.Delete.emit(true);
    }
    modal.close();
  }

  async closeSplit() {
    await this.Close.emit(true);
  }

  back() {
    this.navigation.back();
  }
}
