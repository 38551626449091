import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { ModalController } from "@ionic/angular";
import { Qualification } from "../../interfaces";

@Component({
  selector: "app-select-timeslot-modal",
  templateUrl: "./select-timeslot-modal.component.html",
  styleUrls: ["./select-timeslot-modal.component.scss"],
})
export class SelectTimeslotModalComponent implements OnInit {
  @Input() Timeslots: { QualificationId?: string }[];
  AllQualifications: Qualification[] = this.api.Cache("qualifications", []);
  constructor(
    private api: ApiService,
    private modalController: ModalController,
  ) {}

  async ngOnInit() {
    this.AllQualifications = await this.api.get("qualifications");
  }

  getQualificationName(timeslot: { QualificationId?: string }): string {
    const defaultName = "Ohne Qualifikation";
    if (!timeslot.QualificationId) {
      return defaultName;
    }
    const Qualification = this.AllQualifications.find(
      (q) => q.Id === timeslot.QualificationId,
    );
    return Qualification ? Qualification.Name : defaultName;
  }

  async closeModal(SelectedIndex?: number) {
    await this.modalController.dismiss(SelectedIndex);
  }
}
