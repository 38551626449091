import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { TimeslotElement } from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-app-settings",
  templateUrl: "./app-settings.component.html",
  styleUrls: ["./app-settings.component.scss"],
})
export class AppSettingsComponent implements OnInit {
  @Input() set Item(item: TimeslotElement) {
    if (item) {
      if (item.Id != this.itemId) {
        this.itemId = item.Id;
        this.item = item;
      }
      this.updateSettingsByItem();
    } else {
      this.itemId = null;
      this.item = null;
      this.specialFields = null;
      this.appSettings = null;
      this.hasAppSettings = false;
    }
  }

  @Input() Component: string;
  @Output() Update = new EventEmitter<any>();

  itemId: string;
  item: TimeslotElement;
  specialFields: any;
  appSettings: any;
  hasAppSettings: boolean;
  specialFieldKeys: string[];

  constructor(private api: ApiService,private translate:TranslateService) {}

  async ngOnInit() {
    this.specialFieldKeys = await this.api.get(
      "special-fields/component/keys/" + this.Component,
    );
    this.updateSettingsByItem();
  }

  updateSettingsByItem() {
    const appSettings = {
      SpecialFields: {
        Value: true,
        Translation: this.translate.instant('spezialfelder'),
        Children: {},
      },
    };
    for (const key of this.specialFieldKeys || []) {
      appSettings.SpecialFields.Children[key] = { Value: true };
    }
    for (const key in this.item?.AppSettings || {}) {
      if (appSettings[key]) {
        appSettings[key].Value = this.item.AppSettings[key].Value;
      }
      for (const child in this.item.AppSettings[key].Children || {}) {
        appSettings[key].Children = appSettings[key].Children || {};
        if (appSettings[key].Children[child]) {
          appSettings[key].Children[child].Value =
            this.item.AppSettings[key].Children[child].Value;
        }
      }
    }
    this.appSettings = appSettings;
    this.updateHasAppSettings();
  }

  updateHasAppSettings() {
    const specialFields = this.appSettings
      ? this.appSettings.SpecialFields
      : {};
    this.hasAppSettings =
      Object.keys(this.appSettings || {}).length > 0 &&
      Object.keys(specialFields.Children).length > 0;
  }

  hasChildren(item: any): boolean {
    return item.Children && Object.keys(item.Children).length > 0;
  }

  toggleValue(item: any) {
    item.Value = !item.Value;
    this.emitChanges();
  }

  emitChanges() {
    this.Update.emit(this.appSettings);
  }
}
