import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-birthday",
  templateUrl: "./birthday.component.html",
  styleUrls: ["./birthday.component.scss"],
})
export class BirthdayComponent implements OnInit {
  private privateDay: number;
  private privateMonth: number;
  private privateYear: number;
  isValid = false;

  @Output() Valid = new EventEmitter<boolean>();
  @Output() DateChange = new EventEmitter<Date>();
  @Input()
  set Date(date: Date) {
    if (date) {
      const birthday = moment(date);
      this.privateDay = birthday.date();
      this.privateMonth = birthday.month() + 1;
      this.privateYear = birthday.year();
      this.updateValid();
    }
  }

  @Input()
  set DefaultDate(date: Date) {
    if (!this.day && !this.month && !this.year) {
      this.Date = date;
    }
  }

  constructor() {}

  ngOnInit() {}

  set day(day: number) {
    this.privateDay = day;
    this.updateValid();
    this.emitDate();
  }

  get day(): number {
    return this.privateDay;
  }

  set month(month: number) {
    this.privateMonth = month;
    this.updateValid();
    this.emitDate();
  }

  get month(): number {
    return this.privateMonth;
  }

  set year(year: number) {
    this.privateYear = year;
    this.updateValid();
    this.emitDate();
  }

  get year(): number {
    return this.privateYear;
  }

  updateValid() {
    try {
      if (
        this.year &&
        this.day &&
        this.month &&
        this.year > 999 &&
        this.month > 0 &&
        this.month < 13 &&
        this.day > 0 &&
        this.day < 32
      ) {
        const date = moment(
          this.year + "-" + this.month + "-" + this.day,
          "YYYY-M-D",
        );
        this.isValid = date.isValid();
      } else {
        this.isValid = false;
      }
    } catch (err) {
      this.isValid = false;
    }
    this.Valid.emit(this.isValid);
  }

  emitDate() {
    if (this.isValid) {
      const date = moment(
        this.year + "-" + this.month + "-" + this.day,
        "YYYY-M-D",
      )
        .hour(0)
        .minute(0)
        .second(0);
      this.DateChange.emit(date.toDate());
    } else {
      this.DateChange.emit(null);
    }
  }
}
