import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TypeGroupType } from "../../enums";
import { TypeGroupService } from "../../services/api/type-group.service";
import { Router } from "@angular/router";
import {
  ShiftType,
  ShiftTypeGroup,
  WorkOrderType,
  WorkOrderTypeGroup,
} from "../../interfaces";

@Component({
  selector: "app-type-group-detail-content",
  templateUrl: "./type-group-detail-content.component.html",
  styleUrls: ["./type-group-detail-content.component.scss"],
})
export class TypeGroupDetailContentComponent implements OnInit {
  @Input() set Id(id: string) {
    const previousId = this.id;
    this.id = id;
    if (this.isInitialized && previousId != id) {
      this.load();
    }
  }

  @Input() GroupType: TypeGroupType;
  @Input() SplitViewMode: boolean = false;
  @Output() Reload = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() CloseView = new EventEmitter<void>();

  id: string;
  group: WorkOrderTypeGroup | ShiftTypeGroup;
  types: (WorkOrderType | ShiftType)[];
  GroupTypes = TypeGroupType;
  loading: boolean;
  isInitialized = false;
  typeSearchFct = this.groupService.getTypeSearchFunction();

  constructor(
    private groupService: TypeGroupService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.isInitialized = false;
    this.loading = true;
    this.types = await this.groupService.getAllTypes(this.GroupType);
    await this.load();
    this.loading = false;
    this.isInitialized = true;
  }

  async load() {
    if (this.GroupType && this.id && this.id !== "create") {
      this.group = await this.groupService.get(this.GroupType, this.id);
    } else {
      this.group = this.groupService.create();
    }
    this.types = await this.groupService.getAllTypes(this.GroupType);
  }

  async delete() {
    await this.groupService.delete(this.GroupType, this.group);
    this.Remove.emit();
    return this.goBack();
  }

  getRoute(): string {
    return "/type-groups/" + this.GroupType;
  }

  async goBack() {
    if (this.SplitViewMode) {
      return;
    }
    return this.router.navigate([this.getRoute()]);
  }

  async closeSplitView() {
    await this.CloseView.emit();
  }

  async save(goBack: boolean = false) {
    const newGroup = await this.groupService.save(this.GroupType, this.group);
    this.Reload.emit();
    if (goBack && !this.SplitViewMode) {
      return this.goBack();
    }
    if (this.id != newGroup.Id) {
      await this.router.navigate([this.getRoute(), newGroup.Id]);
    } else {
      await this.load();
    }
  }
}
