import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import * as moment from "moment/moment";
import { Qualification, ShiftType } from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ShiftTypeService {
  constructor(private api: ApiService, private translateService: TranslateService) {}

  async getAll(
    filter: any = {},
    useCache: boolean = false,
  ): Promise<ShiftType[]> {
    if (useCache) {
      return this.api.Cache("shift-types", []);
    }
    const params: any = {};
    if (filter) {
      params.Filter = JSON.stringify(filter);
    }
    return this.api.get("shift-types", params);
  }

  async get(id: string): Promise<ShiftType> {
    const shiftType: ShiftType =
      (await this.api.get("shift-types/" + id)) || {};
    if (!(shiftType.Street && shiftType.City && shiftType.Zip)) {
      shiftType.Street = null;
      shiftType.City = null;
      shiftType.Zip = null;
    }
    shiftType.ShiftStart = new Date(shiftType.ShiftStart);
    shiftType.ShiftEnd = new Date(shiftType.ShiftEnd);
    shiftType.Tasks = shiftType.Tasks || [];
    shiftType.EstimatedDuration =
      (shiftType.ShiftEnd.getTime() - shiftType.ShiftStart.getTime()) /
      1000 /
      60;
    shiftType.Triggers = shiftType.Triggers || [];
    shiftType.Inventories = shiftType.Inventories || [];
    shiftType.Qualifications = shiftType.Qualifications || [];
    return shiftType;
  }

  create(): ShiftType {
    return {
      Inventories: [],
      Qualifications: [],
      ShiftStart: moment().seconds(0).milliseconds(0).toDate(),
      Triggers: [],
      Tasks: [],
      EstimatedDuration: 0,
      QualificationIds: [],
    };
  }

  async copy(source: ShiftType): Promise<ShiftType> {
    const newShiftType: ShiftType = JSON.parse(JSON.stringify(source));
    newShiftType.CreatedAt = null;
    newShiftType.DeletedAt = null;
    newShiftType.UpdatedAt = null;
    newShiftType.Id = null;
    newShiftType.Name = source.Name + " - Kopie";
    newShiftType.Triggers = newShiftType.Triggers.map((trigger) => {
      delete trigger.Id;
      return trigger;
    });
    const successMessage = this.translateService.instant('services.api.shiftType.copySuccessMsg');
    return this.api.post("shift-types", newShiftType, successMessage);
  }

  async destroy(shiftType: ShiftType): Promise<ShiftType> {
    if (!shiftType.Id) {
      return shiftType;
    }
    const successMessage = this.translateService.instant('services.api.shiftType.deleteSuccessMsg');
    return this.api.delete("shift-types/" + shiftType.Id, successMessage);
  }

  async save(
    shiftType: ShiftType,
    qualifications: Qualification[],
    positions: any[],
  ): Promise<ShiftType> {
    const element: any = shiftType;
    let sumPositions = 0;
    let positionsWithoutQualification = 0;
    const qualificationIds: string[] = [];
    for (const position of positions) {
      if (position.Qualification) {
        const ids = Array.from({ length: position.NumWorkers }).map(
          (i) => position.Qualification,
        );
        sumPositions += ids.length;
        qualificationIds.push(...ids);
      } else {
        positionsWithoutQualification += position.NumWorkers;
      }
    }
    element.NumWorker = sumPositions + positionsWithoutQualification;
    element.QualificationIds = JSON.stringify(qualificationIds);
    element.Qualifications = qualifications;
    const successMessage = this.translateService.instant('services.api.shiftType.saveSuccessMsg');
    return this.api.post(
      `shift-types${element.Id ? "/" + element.Id : ""}`,
      element,
      successMessage,
    );
  }
}
