import { SpecialField } from './../../interfaces';
import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Qualification, Shift, WorkOrder } from "src/app/interfaces";
import { ApiService } from "src/app/services/api/api.service";
import { AuthService } from "src/app/services/api/auth.service";
import { ShiftService } from "src/app/services/api/shift.service";
import { StorageService } from "src/app/services/storage/storage.service";

@Component({
  selector: "app-masterlist-detail-changes",
  templateUrl: "./masterlist-detail-changes.component.html",
  styleUrls: ["./masterlist-detail-changes.component.scss"]
})
export class MasterlistDetailChangesComponent implements OnInit {
  @Input() ItemId: string;
  Item: Shift | WorkOrder;
  @Input() ItemComponent: string = "WorkOrder";

  masterListData: any = {};
  companyMap: any = {};
  hasDifferingAddress: boolean = true;
  showInventory: boolean = true;
  modalMode: boolean = true;

  pageSettings: any = {
    general: {
      isCollapsed: false
    },
    employees: {
      isCollapsed: true
    },
    inventory: {
      isCollapsed: false
    },
    specialFields: {
      isCollapsed: false
    },
    filledInteractionForms: {
      isCollapsed: false
    }
  };
  visibilityState: { [key: string]: boolean } = {};

  quillConfig = {
    toolbar: false
  };

  isLoading = true;
  defaultSpecialFields: any;

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private http: HttpClient,
    private storageService: StorageService,
    private modalController: ModalController
  ) {}

  async ngOnInit() {
    await this.getCompaniesMap();
    await this.loadMasterListData();
    let pageSettingsStorage = (await this.storageService.getDetailContentSettings()) as string;
    if (pageSettingsStorage) {
      this.pageSettings = JSON.parse(pageSettingsStorage);
    }
    await this.loadRest();
    this.defaultSpecialFields =
      (await this.api.get("masterlists/special-fields")) || [];
    this.isLoading = false;
  }

  async getCompaniesMap() {
    this.companyMap = await this.api.get("masterlists/companies");
  }

  async loadMasterListData() {
    try {
      this.Item = await this.api.get((this.ItemComponent == "WorkOrder" ? "work-orders/" : "shifts/") + this.ItemId);
      this.masterListData = await this.http.get(this.authService.loggingApi + "masterlists/" + this.Item.ExternalId).toPromise();
    } catch (err) {
      console.log(err);
    }
  }
  async loadRest() {
    this.masterListData.forEach(element => {
      element.isCollapsed = false;
    });
    this.initializeVisibilityState(this.masterListData);
  }

  toggleCollapse(field: string) {
    this.pageSettings[field].isCollapsed = !this.pageSettings[field].isCollapsed;
    this.storageService.setDetailContentSettings(JSON.stringify(this.pageSettings));
  }

  initializeVisibilityState(data: any[]) {
    data.forEach(company => {
      this.visibilityState[company.CompanyId] = true;
    });
  }

  setVisibilityState(companyId: string) {
    this.visibilityState[companyId] = !this.visibilityState[companyId];
  }

  async closeModal() {
    await this.modalController.dismiss(true);
  }
}
