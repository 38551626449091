import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TimeSlot } from "../../interfaces";

@Component({
  selector: "app-time-slot-application-indicator",
  templateUrl: "./time-slot-application-indicator.component.html",
  styleUrls: ["./time-slot-application-indicator.component.scss"],
})
export class TimeSlotApplicationIndicatorComponent implements OnInit {
  @Input() set TimeSlot(timeSlot: TimeSlot) {
    if (timeSlot) {
      this.count = (timeSlot.Applications || []).length;
    } else {
      this.count = 0;
      this.active = false;
    }
    this.updatePreviewText();
  }

  @Input() set Active(active: boolean) {
    this.active = active;
    this.updatePreviewText();
  }

  @Output() Click = new EventEmitter<void>();

  previewText: string;
  count: number = 0;
  active: boolean = false;

  constructor() {}

  ngOnInit() {}

  updatePreviewText() {
    if (!this.active) {
      this.previewText = "Bewerbung inaktiv";
    } else if (this.count === 0) {
      this.previewText = "Bewerbung aktiv";
    } else {
      this.previewText =
        "Bewerbung aktiv (" +
        (this.count === 1 ? "eine Bewerbung" : this.count + " Bewerbungen") +
        ")";
    }
  }

  emitClick() {
    if (this.count > 0) {
      return this.Click.emit();
    }
  }
}
