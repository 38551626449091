import { Component, Input, ViewChild } from "@angular/core";
import { BaseChartDirective } from "ng2-charts";

@Component({
  selector: "app-statistic-display",
  templateUrl: "./statistic-display.component.html",
  styleUrls: ["./statistic-display.component.scss"],
})
export class StatisticDisplayComponent {
  @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;
  @Input() StatisticOptions: any;

  @Input() set StatisticData(value) {
    this.Data = value;
    if (this.baseChart && this.baseChart.chart) {
      this.baseChart.chart.update();
    }
  }

  @Input() set ChartType(value) {
    this.StatisticOptions.type = value;
  }
  Data: any = {
    Data: [{ data: [], label: "Loading" }],
    Labels: [],
  };
  ColorPalette = [
    {
      backgroundColor: "rgba(0,121,145,0.8)",
      borderColor: "rgba(0,121,145,1)",
    },
  ];

  constructor() {
    this.StatisticOptions = {
      responsive: true,
      dataset: {
        barPercentage: 0.9,
        barThickness: "flex",
        maxBarThickness: 20,
        minBarLength: 10,
        gridLines: {
          offsetGridLines: true,
        },
      },
      type: "bar",
    };
  }
}
