import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class UrlStorageService {
  excludedRoutes: string[] = ["/selection", "/signup", "/setup"];

  constructor(private storageService: StorageService) {}

  isExcluded(route: string): boolean {
    if (!route) {
      return false;
    }
    for (const excluded of this.excludedRoutes) {
      if (route.indexOf(excluded) >= 0) {
        return true;
      }
    }
    return false;
  }

  setUrl(url: string) {
    const companyId = this.getCompanyId();
    if (!companyId || this.isExcluded(url)) {
      return;
    }
    this.setUrlByCompanyId(companyId, url);
  }

  getUrl(): string {
    const companyId = this.getCompanyId();
    const value = this.getUrlByCompanyId(companyId);
    return value || "/dashboard";
  }

  public setUrlByCompanyId(companyId: string, url: string) {
    return this.storageService.set("last-url-from-company-" + companyId, url);
  }

  public getUrlByCompanyId(companyId: string): string {
    const value = this.storageService.get("last-url-from-company-" + companyId);
    return value && value !== "null" && value !== "undefined" ? value : null;
  }

  public clear() {
    Object.keys(localStorage)
      .filter((keyName) => keyName.startsWith("last-url-from-company-"))
      .forEach((keyName) => localStorage.removeItem(keyName));
  }

  private getCompanyId(): string {
    const companyId = this.storageService.getCompanyId();
    return companyId && companyId !== "null" && companyId !== "undefined"
      ? companyId
      : null;
  }
}
