import { Pipe, PipeTransform } from "@angular/core";
import { AbsenceService } from "../services/api/absence.service";
import { TranslateService } from "@ngx-translate/core";
import { Absence } from "../interfaces";

@Pipe({
  name: "absenceState",
})
export class AbsenceStatePipe implements PipeTransform {
  constructor(
    private absenceService: AbsenceService,
    private translate: TranslateService,
  ) {}

  transform(absence: Absence): string {
    return this.translate.instant(
      "enums.AbsenceStatus." +
        (absence.Status || this.absenceService.getStatusKey(absence)),
    );
  }
}
