import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { User } from "src/app/interfaces";
import { ApiService } from "src/app/services/api/api.service";

@Component({
  selector: "app-emergency-contact-modal",
  templateUrl: "./emergency-contact-modal.component.html",
  styleUrls: ["./emergency-contact-modal.component.scss"],
})
export class EmergencyContactModalComponent implements OnInit {
  @Input() User: User;

  reason: string = ''
  contact: any = null;
  loading = false;

  constructor(private modalController: ModalController, private api: ApiService) {}

  ngOnInit(): void {}

  async closeModal() {
    this.contact = null;
    await this.modalController.dismiss();
  }

  async showEmergencyContact() {
    this.loading = true;
   this.contact = await this.api.post('employees/additional-user-informations/' + this.User.Id + '/emergency-contact', {reason:this.reason})
   this.loading = false;
  }
}
