import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (location) {
  if (location.search.indexOf("?xapikey") === 0) {
    localStorage.setItem(
      "x-api-key",
      location.search.split("?xapikey=")[1].split("&")[0],
    );
  }

  if (
    location.search.indexOf("?leasing-agreement") === 0 &&
    location.search.indexOf("&status") !== -1
  ) {
    localStorage.setItem(
      "leasing-agreement",
      location.search.split("?leasing-agreement=")[1].split("&")[0],
    );
    localStorage.setItem(
      "leasing-agreement-status",
      location.search.split("&status=")[1].split("&")[0],
    );
  }
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.production) {
  enableProdMode();
  bootstrap();
} else {
  bootstrap().catch((err) => console.log(err));
}
