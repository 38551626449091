import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Visibility } from "../../enums";
import { RemarkService } from "../../services/api/remark.service";
import { AuthService } from "../../services/api/auth.service";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { Remark, User } from "src/app/interfaces";

@Component({
  selector: "app-remark-detail-content",
  templateUrl: "./remark-detail-content.component.html",
  styleUrls: ["./remark-detail-content.component.scss"],
})
export class RemarkDetailContentComponent implements OnInit, OnDestroy {
  @Input() ItemId: string;
  @Input() Component: string;
  @Input() Date: string;
  @Input() Start: string;
  @Input() End: string;

  @Input() ShowDates: boolean = false;
  @Input() Modal: boolean = false;
  @Output() Saved = new EventEmitter<Remark[]>();

  RemarkVisibility = Visibility;

  remarks: Remark[] = [];
  user: User;
  selectedRemark: Remark;

  isSaving: boolean;
  loading: boolean;

  originalText: string;
  originalVisibility: Visibility;
  newDate: Date = new Date();
  saveSubscription: Subscription;
  startDate: any;
  endDate: any;

  constructor(
    private remarkService: RemarkService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.user = await this.authService.getUser();
    if (this.Start) {
      const start = moment(this.Start);
      const end = moment(this.End);
      this.startDate = {
        year: start.year(),
        month: start.month(),
        day: start.date(),
      };
      this.endDate = { year: end.year(), month: end.month(), day: end.date() };
      this.newDate = start.toDate();
    } else {
      this.startDate = {};
      this.endDate = {};
    }
    await this.loadRemarks();
    this.loading = false;
    this.saveSubscription = this.remarkService.Save.subscribe(async () =>
      this.save(true),
    );
  }

  async ngOnDestroy() {
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
  }

  async loadRemarks() {
    if (this.ItemId) {
      this.remarks = await this.remarkService.getAllByItemId(
        this.ItemId,
        this.Date,
        this.Start,
        this.End,
      );
    } else if (this.Date) {
      this.remarks = await this.remarkService.getAllDayRemarks(this.Date);
    }
    this.selectedRemark = null;
  }

  textHasChanges(): boolean {
    return !this.selectedRemark
      ? false
      : this.selectedRemark.Text !== this.originalText;
  }

  visibilityHasChanges(): boolean {
    return !this.selectedRemark
      ? false
      : this.selectedRemark.Visibility !== this.originalVisibility;
  }

  hasChanges(): boolean {
    return this.textHasChanges() || this.visibilityHasChanges();
  }

  isOwner(remark: Remark): boolean {
    return remark.UserId === this.user.Id;
  }

  isSelected(remark: Remark): boolean {
    return remark === this.selectedRemark;
  }

  isValid(remark: Remark): boolean {
    return remark.Text && remark.Text.length > 0;
  }

  async add() {
    if (this.isSaving || this.selectedRemark) {
      return;
    }
    const newNote = this.create();
    this.remarks.unshift(newNote);
    this.selectedRemark = newNote;
  }

  async edit(remark: Remark) {
    if (this.isSaving || this.selectedRemark || !this.isOwner(remark)) {
      return;
    }
    this.selectedRemark = remark;
    this.originalText = remark.Text;
    this.originalVisibility = remark.Visibility as Visibility;
  }

  async delete(remark: Remark) {
    if (this.isSaving || !remark) {
      return;
    }
    const success = await this.remarkService.destroy(remark);
    if (success) {
      this.selectedRemark = null;
      await this.loadRemarks();
    }
  }

  async saveProcessed(remark: Remark) {
    await this.remarkService.saveProcessed(remark);
  }

  revertChanges() {
    if (!this.selectedRemark.Id) {
      this.remarks.splice(0, 1);
    } else {
      this.selectedRemark.Text = this.originalText;
      this.selectedRemark.Visibility = this.originalVisibility;
    }
    this.selectedRemark = null;
  }

  async save(emit: boolean = false) {
    if (
      !this.selectedRemark ||
      !this.isValid(this.selectedRemark) ||
      this.isSaving
    ) {
      if (emit) {
        await this.Saved.emit(this.remarks);
      }
      return;
    }
    this.isSaving = true;
    if (this.hasChanges()) {
      await this.remarkService.saveOrCreate(this.selectedRemark);
      await this.loadRemarks();
    }
    this.isSaving = false;
    if (emit) {
      this.Saved.emit(this.remarks);
    }
  }

  create(): Remark {
    const remark: Remark = {
      Component: this.Component,
      Text: null,
      Visibility: Visibility.MANAGER,
      ItemId: this.ItemId,
      Creator: this.user,
      Dates: [],
    };
    if (this.Date || this.Start) {
      remark.Dates = [{ Date: this.Date || this.Start }];
    }
    return remark;
  }

  setToDate(newDate: any) {
    this.newDate = moment(newDate).endOf("d").toDate();
  }

  getCanAddDate(): boolean {
    if (this.isSaving) {
      return false;
    }
    const newDateFormat = moment(this.newDate).format("YYYY-MM-DD");
    for (const date of this.selectedRemark.Dates || []) {
      if (date.Date === newDateFormat) {
        return false;
      }
    }
    return true;
  }

  async saveDate() {
    if (!this.getCanAddDate()) {
      return;
    }
    if (this.selectedRemark.Id) {
      this.isSaving = true;
      const success = await this.remarkService.createDate(
        this.selectedRemark,
        this.newDate,
      );
      if (success) {
        this.selectedRemark.Dates = await this.remarkService.getAllDates(
          this.selectedRemark.Id,
        );
      }
      this.isSaving = false;
    } else {
      this.selectedRemark.Dates.push({
        Date: moment(this.newDate).format("YYYY-MM-DD"),
      });
    }
  }

  async deleteDate(index: number) {
    this.isSaving = true;
    if (this.selectedRemark.Id) {
      const success = await this.remarkService.deleteDate(
        this.selectedRemark.Dates[index],
      );
      if (success) {
        this.selectedRemark.Dates = await this.remarkService.getAllDates(
          this.selectedRemark.Id,
        );
      }
      this.isSaving = false;
    } else {
      this.selectedRemark.Dates.splice(index, 1);
    }
  }
}
