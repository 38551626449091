import { Injectable } from "@angular/core";
import { AbstractCustomWorkOrderImportService } from "./abstract-custom-work-order-import.service";
import { CellObject, WorkBook, WorkSheet } from "xlsx";
import { ToastService } from "../core/toast.service";
import { WorkOrder, WorkOrderImportResult } from "../../interfaces";

@Injectable({
  providedIn: "root"
})
export class SkyDeLiveSportMasterListWorkOrderImportService extends AbstractCustomWorkOrderImportService {
  constructor(toastService: ToastService) {
    super(toastService);
  }

  writeChangeLogFile(): boolean {
    return true;
  }

  getKey(): string {
    return "SkyDeLiveSportMasterListWorkOrderImportService";
  }

  getSelectAllDefault(): boolean {
    return false;
  }

  private contentMatches(cell: CellObject, lookup: string): boolean {
    if (!cell) {
      return false;
    }
    const content = ((cell.v || "") as string).trim().toLowerCase();
    return content === lookup.toLowerCase();
  }

  private sheetIsValid(
    sheet: WorkSheet,
    row: number = 1,
    indexColumn: string = "A",
    indexName: string = "Index",
    statusColumn: string = "B",
    statusName: string = "Status"
  ) {
    if (!sheet) {
      return false;
    }
    const indexCell = sheet[indexColumn + row];
    const statusCell = sheet[statusColumn + row];
    return this.contentMatches(indexCell, indexName) && this.contentMatches(statusCell, statusName);
  }


  public async createElementsFromSheet(sheet: WorkSheet): Promise<{ rows: string[]; values: { [row: string]: WorkOrder[] } }> {
    if (!this.sheetIsValid(sheet)) {
      return { rows: [], values: {} };
    }

    const startRow = 3;
    const endRow = this.getRows(sheet);

    const externalIdColumn = "A";
    const typeGroupColumn = "C";
    const dateColumn = "D";
    const nameColumn = "E";
    const name2Column = "F";
    const name3Column = "P";
    const timeStartColumn = "H";
    const timeEndColumn = "I";
    const specialColumn = "AA";

    const keys: string[] = [];
    const values: { [key: string]: WorkOrder[] } = {};

    for (let i = startRow; i <= endRow; i++) {
      const typeGroup = this.stringContent(sheet, typeGroupColumn, i, true).trim();
      const name1 = this.stringContent(sheet, nameColumn, i, true).trim();
      const name2 = this.stringContent(sheet, name2Column, i, true).trim();
      const name3 = this.stringContent(sheet, name3Column, i, true).trim();
      const typeName = this.stringContent(sheet, specialColumn, i, true).trim() || "Allgemein";

      const name = name1 + (name2 ? "-" + name2 : "") + (name3 ? "-" + name3 : "");
      const externalId = this.getExternalId(sheet, externalIdColumn, i);
      const { StartTime, EndTime } = this.createTimesFromDateAndTimeframe(sheet, i, dateColumn, timeStartColumn, timeEndColumn, 0);

      if (!name) {
        continue;
      }

      const workOrders = [];
      const workOrder: WorkOrder = {
        StartTime,
        EndTime,
        Name: name,
        ExternalId: externalId
      };

      workOrder.WorkOrderType = {
        Name: typeName,
        WorkOrderTypeGroup: {
          Name: typeGroup
        }
      };

      workOrders.push(workOrder);

      if (workOrders.length > 0) {
        values[i] = workOrders;
        keys.push(String(i));
      }
    }
    return { rows: keys, values };
  }

  public async createElements(workBook: WorkBook): Promise<WorkOrderImportResult> {
    const result = { sheets: [], rows: {}, values: {} };
    if (!workBook || workBook.SheetNames.length === 0) {
      return result;
    }
    for (const sheetName of workBook.SheetNames) {
      const sheet = await this.createElementsFromSheet(workBook.Sheets[sheetName]);
      if (sheet.rows.length > 0) {
        result.sheets.push(sheetName);
        result.rows[sheetName] = sheet.rows;
        for (const key in sheet.values) {
          result.values[sheetName + "-" + key] = sheet.values[key];
        }
      }
    }
    return result;
  }

  public getPreviewSettings(): { Icon: any; Cols: any } {
    return {
      Icon: {
        Style: { width: "5%" },
        Show: true,
        Icons: {
          MissingId: true,
          MissingType: true,
        }
      },
      Cols: {
        Sheet: {
          Style: { width: "7%" },
          Show: true
        },
        Row: {
          Style: { width: "5%" },
          Show: true
        },
        Date: {
          Style: { width: "10%" },
          Show: true
        },
        Start: {
          Style: { width: "5%" },
          Show: true
        },
        End: {
          Style: { width: "5%" },
          Show: true
        },
        Name: {
          Style: { width: "28%" },
          Show: true
        },
        TypeGroup: {
          Style: { width: "10%" },
          Show: true
        },
        Type: {
          Style: { width: "10%" },
          Show: true
        },
      }
    };
  }
}
