import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { Socket } from "ngx-socketio2";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  userId: string;
  visitSubject: Subject<any> = new Subject<any>();
  blockSaveSubject: Subject<boolean> = new Subject<boolean>();
  isBlocked = false;

  constructor(private socket: Socket) {
    this.receive();
  }

  public get Visit(): Observable<any> {
    return this.visitSubject.asObservable();
  }

  public get BlockSave(): Observable<boolean> {
    return this.blockSaveSubject.asObservable();
  }

  getBlocked() {
    return this.isBlocked;
  }

  removeBlock() {
    this.isBlocked = false;
    this.blockSaveSubject.next(this.isBlocked);
  }

  async login() {
    this.socket.emit("login", {
      CompanyId: localStorage.getItem("company-id"),
      XApiKey: localStorage.getItem("x-api-key"),
    });
  }

  public async visit(
    name: string,
    model: string,
    objectId: string = undefined,
    block: boolean = false,
  ) {
    this.socket.emit("visit", {
      Name: name,
      Model: model,
      ObjectId: objectId,
      Block: block,
    });
  }

  public async leave() {
    this.socket.emit("leave");
  }

  receive(): void {
    this.socket.on<string>("connect").subscribe((message) => this.login());
    this.socket
      .on<{ message: string; User: { Id: string } }>("login")
      .subscribe((message) => {
        if (message.message == "Login successful") {
          this.userId = message.User.Id;
        }
      });
    this.socket
      .on<string>("error")
      .subscribe((message) => console.log(message));
    this.socket.on<string>("visit").subscribe((message: any) => {
      this.visitSubject.next(message);
      if (this.blockSaveSubject) {
        if (message?.UserList?.length > 1 && message?.VisitObject?.Block) {
          if (this.userId === message.UserList[0].Id) {
            this.isBlocked = false;
          } else {
            this.isBlocked = true;
          }
        } else {
          this.isBlocked = false;
        }
        this.blockSaveSubject.next(false);
      }
    });
  }
}
