import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Nameable, ScheduleDisplayConfig } from "../../../interfaces";

@Component({
  selector: "app-schedule-time-slot-name",
  templateUrl: "./schedule-time-slot-name.component.html",
  styleUrls: ["./schedule-time-slot-name.component.scss"],
})
export class ScheduleTimeSlotNameComponent implements OnInit, OnDestroy {
  @Input() Item: any;
  @Input() Default: string;
  @Input() Config: ScheduleDisplayConfig;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
