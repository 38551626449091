import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { DisplaySettingService } from "src/app/services/core/display-setting.service";

@Component({
  selector: "app-display-settings-modal",
  templateUrl: "./display-settings-modal.component.html",
  styleUrls: ["./display-settings-modal.component.scss"],
})
export class DisplaySettingsModalComponent implements OnInit {
  scheduleGridSettings = Object.keys(this.displaySettingService.SCHEDULE_GRID);
  scheduleLetterCountSettings = Object.keys(
    this.displaySettingService.SCHEDULE_LETTER_COUNT,
  );
  userFormatSettings = Object.keys(this.displaySettingService.USER_FORMAT);
  userCustomOrderSettings = Object.keys(
    this.displaySettingService.USER_CUSTOM_ORDER,
  );
  userSortingSettings = Object.keys(this.displaySettingService.USER_SORTING);

  selectedScheduleGridSetting: string;
  selectedScheduleLetterCount: string;
  selectedUserFormat: string;
  selectedUserSorting: string;
  selectedCustomOrder: string;

  constructor(
    private modalController: ModalController,
    private displaySettingService: DisplaySettingService,
  ) { }

  async ngOnInit() {
    const userSetting = this.displaySettingService.getUserSetting();
    const scheduleSetting = this.displaySettingService.getScheduleSetting();
    this.selectedScheduleGridSetting = scheduleSetting.grid.name;
    this.selectedScheduleLetterCount = String(scheduleSetting.letterCount);
    this.selectedUserFormat = userSetting.format;
    this.selectedUserSorting = userSetting.sorting;
    this.selectedCustomOrder = userSetting.customOrder.toString();
  }

  updateGridSize(size: string) {
    this.selectedScheduleGridSetting = size;
    this.displaySettingService.setScheduleGridConfig(size);
  }

  updateLetterCount(letterCount: string) {
    this.selectedScheduleLetterCount = letterCount;
    this.displaySettingService.setScheduleLetterCount(letterCount);
  }
  updateCustomOrder(value: string) {
    this.selectedCustomOrder = value;
    this.displaySettingService.setUserCustomOrder(value === "true");
  }

  updateNameFormat(format: string) {
    this.selectedUserFormat = format;
    this.displaySettingService.setUserFormat(format);
  }

  updateNameSorting(sorting: string) {
    this.selectedUserSorting = sorting;
    this.displaySettingService.setUserSorting(sorting);
  }


  async closeModal() {
    return this.modalController.dismiss();
  }
}
