/* tslint:disable:no-string-literal */
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { from, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { GuardService } from "./services/core/guard.service";
import { ToastService } from "./services/core/toast.service";

@Injectable({
  providedIn: "root",
})
export class AppGuard implements CanActivate {
  constructor(
    private accessService: GuardService,
    private toastService: ToastService,
    private router:Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    let roles = route.data.roles as string[];
    if (!roles || roles.length === 0) {
      return true;
    }
    return from(this.getPermission(roles[0]));
  }

  async getPermission(componentName: string): Promise<boolean> {
    const canAccess = await this.canAccess(componentName);
    if (!canAccess) {

      this.toastService.presentError(
        "Du hast nicht die nötigen Zugriffsrechte.", {duration: 5000,}
      );
      this.router.navigateByUrl('/dashboard')
    }
    return canAccess;
  }

  async canAccess(componentName: string): Promise<boolean> {
    const menuItem =
      await this.accessService.getMenuItemByComponentName(componentName);
    return menuItem ? menuItem._access : false;
  }
}
