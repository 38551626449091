import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { ToastService } from "../core/toast.service";
import { QueueItem } from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private checkOnline = false;
  private api = this.authService.serverApi;
  private cache = {}; // TODO cache in pouchdb bringen;
  public queue: QueueItem[] = [];
  public online = true;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private toastService: ToastService,
    private translate: TranslateService
  ) { }

  async get(
    Path: string,
    Params: {} = {},
    disableWarning = false,
  ): Promise<any> {
    return this.Get(Path, Params, disableWarning);
  }

  async Get(
    Path: string,
    Params: {} = {},
    disableWarning = false,
  ): Promise<any> {
    try {
      const item = await this.http
        .get(this.api + Path, { params: Params })
        .toPromise();
      this.cache[Path] = item;
      return item;
    } catch (e) {
      const itemCache = this.Cache(Path, "EMPTY");
      if (this.checkOnline && itemCache === "EMPTY" && !disableWarning) {
        this.toastService.presentWarning(
          "Sie sind Offline es konnten keine neuen Daten abgeholt werden.",
        );
        this.online = false;
        return null;
      } else {
        return itemCache;
      }
    }
  }

  async save(
    Path: string,
    _Object: any,
    SuccessMessage: string = "",
    Params: {} = {},
  ): Promise<any> {
    return this.Save(Path, _Object, SuccessMessage, Params);
  }

  async post(
    Path: string,
    _Object: any,
    SuccessMessage: string = "",
    Params: {} = {},
  ): Promise<any> {
    return this.Save(Path, _Object, SuccessMessage, Params);
  }

  async Save(
    Path: string,
    _Object: any,
    SuccessMessage: string = "",
    Params: {} = {},
  ): Promise<any> {
    return this.SavePlain(Path, _Object, SuccessMessage, Params, {
      Method: "Save",
      Path,
      Params,
      _Object,
      SendCount: 1,
      CreatedAt: new Date(),
    });
  }

  async SavePlain(
    Path: string,
    _Object: any,
    SuccessMessage: string = "",
    Params: {} = {},
    QueueItem: QueueItem,
  ): Promise<any> {
    try {
      const responseObject = await this.http
        .post(this.api + Path, _Object, { params: Params })
        .toPromise();
      this.toastService.presentSuccess(SuccessMessage);
      this.online = true;
      return responseObject;
    } catch (e) {
      if (e.status === 408) {
        this.toastService.presentWarning(
          this.translate.instant('es_konnte_keine_verbindung_zum') +
          this.translate.instant('der_speichervorgang_wird_in_die'),
        );
        this.queue.push(QueueItem);
        this.online = false;
      } else {
        this.toastService.presentError(
          e.error || this.translate.instant('es_wurden_nicht_gengend_angaben'),
        );
        return QueueItem._Object;
      }
    }
  }

  async delete(Path: string, SuccessMessage: string = "") {
    return this.Delete(Path, SuccessMessage);
  }

  async Delete(Path: string, SuccessMessage: string = "") {
    return this.DeletePlain(Path, SuccessMessage, {
      Method: "Delete",
      Path,
      Params: {},
      SendCount: 1,
      CreatedAt: new Date(),
      SuccessMessage,
    });
  }

  async DeletePlain(
    Path: string,
    SuccessMessage: string = "",
    QueueItem: QueueItem,
  ): Promise<any> {
    try {
      const responseObject = await this.http
        .delete(this.api + Path)
        .toPromise();
      this.toastService.presentSuccess(SuccessMessage);
      this.online = true;
      return responseObject;
    } catch (e) {
      if (e.status === 403) {
        this.toastService.presentError(e.error);
      } else {
        this.toastService.presentWarning(
          this.translate.instant('es_konnte_keine_verbindung_zum'),
        );
        this.queue.push(QueueItem);
        this.online = false;
      }
      return null;
    }
  }

  Cache(Path: string, defaultValue: any) {
    if (this.cache[Path]) {
      return this.cache[Path];
    } else {
      return defaultValue;
    }
  }

  retry() {
    while (this.queue.length > 0) {
      const item = this.queue.pop();
      item.SendCount++;
      if (item.Method === "Delete") {
        this.DeletePlain(item.Path, item.SuccessMessage, item);
      } else if (item.Method === "Save") {
        this.SavePlain(
          item.Path,
          item._Object,
          item.SuccessMessage,
          item.Params,
          item,
        );
      }
    }
  }

  getFileText(Path: string, Params: any = {}): Promise<string> {
    return this.http
      .get(this.api + Path, { responseType: "text", params: Params })
      .toPromise();
  }

  getFileBlob(Path: string, Params: any = {}): Promise<Blob> {
    return this.http
      .get(this.api + Path, { responseType: "blob", params: Params })
      .toPromise();
  }

  postFile(Path: string, Body: any = {}, Params: any = {}): Promise<Blob> {
    return this.http
      .post(this.api + Path, Body, { responseType: "blob", params: Params })
      .toPromise();
  }
}
