import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api/api.service";
import * as moment from "moment";
import { WorkOrderService } from "../../services/api/work-order.service";
import { TypeGroupType } from "../../enums";
import { TypeGroupService } from "../../services/api/type-group.service";
import { WorkOrderType, WorkOrder } from "../../interfaces";
import { ObjectService } from "../../services/core/object.service";

@Component({
  selector: "app-create-work-order-button",
  templateUrl: "./create-work-order-button.component.html",
  styleUrls: ["./create-work-order-button.component.scss"],
})
export class CreateWorkOrderButtonComponent implements OnInit {
  @Input() StartTime: Date;

  workOrderTypes: WorkOrderType[] = [];
  filteredWorkOrderTypes: WorkOrderType[];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private workOrderService: WorkOrderService,
    private groupService: TypeGroupService,
  ) {}

  async ngOnInit() {
    this.workOrderTypes = await this.groupService.getAllTypes(
      TypeGroupType.WORK_ORDER,
    );
    await this.doFilter("");
  }

  async createWorkOrder() {
    const workOrder: WorkOrder = {};
    workOrder.Name = "";
    workOrder.StartTime =
      this.StartTime || moment().startOf("d").set({ hours: 8 }).toDate();
    workOrder.EndTime = moment(workOrder.StartTime).add(30, "minutes").toDate();
    workOrder.NumWorker = 1;
    return this.saveWorkOrder(workOrder);
  }

  createWorkOrderFromType(workOrderType: WorkOrderType) {
    return this.saveWorkOrder(
      this.workOrderService.createOrUpdateFromType(workOrderType),
    );
  }

  async saveWorkOrder(workOrder: WorkOrder) {
    const newWorkOrder = await this.workOrderService.save(workOrder);
    await this.router.navigate(["/work-orders/" + newWorkOrder.Id]);
  }

  async doFilter(filterInput: string) {
    if (this.workOrderTypes) {
      const filter = (filterInput || "").trim().toLowerCase();
      this.filteredWorkOrderTypes = this.workOrderTypes.filter((type) => {
        if (filter === "") {
          return true;
        }
        const name = (type.Name || "").toLowerCase();
        if (name.indexOf(filter) >= 0) {
          return true;
        }
        const group = (type.Group || "").toLowerCase();
        return group.indexOf(filter) >= 0;
      });
    } else {
      this.filteredWorkOrderTypes = [];
    }
  }
}
