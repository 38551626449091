import { Component, Input, OnInit } from "@angular/core";
import {
  FeedbackState,
  Inventory,
  KeyValuePair,
  TimeslotElement,
} from "../../interfaces";
import {b} from "@fullcalendar/core/internal-common";

@Component({
  selector: "app-badge-row",
  templateUrl: "./badge-row.component.html",
  styleUrls: ["./badge-row.component.scss"],
})
export class BadgeRowComponent implements OnInit {
  @Input() set Item(item: TimeslotElement) {
    this.item = item;
  }

  @Input() ShowInventories: boolean = true;

  specialFields: KeyValuePair[] = [];
  inventories: Inventory[] = [];
  item: TimeslotElement;
  feedbacks: FeedbackState[] = [];

  constructor() {}

  ngOnInit() {
    let specialFields = [];
    let inventories = [];
    if (this.item.SpecialFields) {
      const specialFieldsMap: KeyValuePair[] = [];
      for (const key in this.item.SpecialFields || {}) {
        if (this.item.SpecialFields.hasOwnProperty(key)) {
          const value = this.item.SpecialFields[key];
          if (
            value === null ||
            value.Evaluators ||
            String(value).length === 0
          ) {
            continue;
          }
          const stringValue = Array.isArray(value) ? value.join(", ") : value;
          specialFieldsMap.push({ Key: key, Value: stringValue });
        }
      }
      specialFields = specialFieldsMap;
    }
    if (this.item.Inventories) {
      inventories = this.item.Inventories;
    }

    this.inventories = inventories;
    this.specialFields = specialFields;
    this.feedbacks = this.item.FeedbackStates;
  }
}
