import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  Qualification,
  Remark,
  TimeSlot,
  TimeslotElement,
} from "../../interfaces";
import { ApiService } from "../../services/api/api.service";
import { AcceptDeclineModalComponent } from "../../modals/accept-decline-modal/accept-decline-modal.component";
import { ModalController } from "@ionic/angular";
import { ApplicationType } from "../../enums";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-time-slot-list",
  templateUrl: "./time-slot-list.component.html",
  styleUrls: ["./time-slot-list.component.scss"],
})
export class TimeSlotListComponent implements OnInit {
  @Input() set TimeSlots(timeSlot: TimeSlot[]) {
    this.timeSlots = timeSlot;
    this.apply();
  }
  @Input() Item: TimeslotElement = {};
  @Input() Qualifications: Qualification[] = [];
  @Input() Disabled: boolean = false;
  @Input() UserRemarks: { [id: string]: Remark[] } = {};
  @Input() ShowAccept: boolean = true;
  @Input() ExternalApproval: boolean = false;
  @Input() ShowFees: boolean = false;
  @Input() ShowApplications: boolean = false;
  @Input() ShowBillingNumber: boolean = false;
  @Input() ModalMode: boolean = false;
  @Input() ShowTimeSlotCustomHighlights: boolean = false;
  @Input() Component: string = "WorkOrder";

  @Output() UserRemove = new EventEmitter<TimeSlot>();
  @Output() UserChange = new EventEmitter<TimeSlot>();
  @Output() RemarkChange = new EventEmitter<{ [UserId: string]: Remark[] }>();
  @Output() Remove = new EventEmitter<void>();
  @Output() Change = new EventEmitter<void>();
  @Output() Poke = new EventEmitter<TimeSlot>();
  @Output() Accept = new EventEmitter<TimeSlot>();
  @Output() Decline = new EventEmitter<TimeSlot>();
  @Output() QualificationAdd = new EventEmitter<Qualification>();
  @Output() Add = new EventEmitter<void>();

  timeSlots: TimeSlot[] = [];
  isSaving: boolean = false;
  applicationTypes = [ApplicationType.MANUAL, ApplicationType.FIRST];
  applyApplications: boolean = false;
  applicationStates: { [id: string]: boolean };

  constructor(
    private api: ApiService,
    private modalController: ModalController,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.isSaving = false;
    this.applyApplications = false;
  }

  emitUserRemove(timeSlot: TimeSlot) {
    return this.UserRemove.emit(timeSlot);
  }

  emitUserChange(timeSlot: TimeSlot) {
    return this.UserChange.emit(timeSlot);
  }

  emitRemarkChange(remarks: { [UserId: string]: Remark[] }) {
    return this.RemarkChange.emit(remarks);
  }

  async removeTimeSlot(index: number) {
    this.isSaving = true;
    const timeSlot = this.timeSlots[index];
    if (timeSlot.Id && (await this.checkWorkTimes(timeSlot))) {
      await this.api.delete("time-slots/" + timeSlot.Id);
      this.timeSlots.splice(index, 1);
      this.emitRemove();
    } else if (!timeSlot.Id) {
      this.timeSlots.splice(index, 1);
      this.emitRemove();
    }
    this.isSaving = false;
  }

  emitRemove() {
    return this.Remove.emit();
  }

  emitPoke(timeSlot: TimeSlot) {
    return this.Poke.emit(timeSlot);
  }

  emitAccept(timeSlot: TimeSlot) {
    return this.Accept.emit(timeSlot);
  }
  emitDecline(timeSlot: TimeSlot) {
    return this.Decline.emit(timeSlot);
  }

  async checkWorkTimes(
    timeSlot: TimeSlot,
    okText: string = this.translate.instant('trotzdem_speichern'),
  ): Promise<boolean> {
    if (!timeSlot.WorkTime) {
      return true;
    }
    const modal = await this.modalController.create({
      componentProps: {
        Message: this.translate.instant('es_sind_schon_arbeitszeiten_gestartet'),
        OkText: okText,
      },
      component: AcceptDeclineModalComponent,
      cssClass: "custom-ion-modal",
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    return !!response.data;
  }

  addTimeSlot() {
    this.timeSlots = this.timeSlots || [];
    this.timeSlots.push({
      StartTime: this.Item.StartTime,
      EndTime: this.Item.EndTime,
      Description: this.Item.Description,
      Name: this.Item.Name,
      CustomAbbreviation: this.Item.CustomAbbreviation,
      [this.Component + "Id"]: this.Item.Id,
    });
    this.Add.emit();
  }

  hasOwnTime(timeSlot: TimeSlot): boolean {
    if (!timeSlot.StartTime || !timeSlot.EndTime) {
      return false;
    }
    const format = "YYYY-MM-DD HH:mm";
    const itemStart = moment(this.Item.StartTime);
    const itemEnd = moment(this.Item.EndTime);
    const start = moment(timeSlot.StartTime);
    const end = moment(timeSlot.EndTime);
    return (
      itemStart.format(format) != start.format(format) ||
      itemEnd.format(format) != end.format(format)
    );
  }

  startApplyApplication() {
    this.applicationStates = {};
    this.timeSlots
      .filter((timeSlot) => !!timeSlot.Id)
      .forEach(
        (timeSlot) =>
          (this.applicationStates[timeSlot.Id] = timeSlot.Application),
      );
    this.applyApplications = true;
  }

  async abort() {
    if (this.applyApplications) {
      this.applicationStates = this.applicationStates || {};
      for (const timeSlot of this.timeSlots || []) {
        timeSlot.Application = this.applicationStates[timeSlot.Id];
      }
      this.applyApplications = false;
    }
  }

  async apply() {
    if (this.applyApplications) {
      this.applyApplications = false;
      return this.Change.emit();
    }
  }
}
