import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SubtitleService {
  public Subtitle: string='';

  public SubtitleObservable: Subject<string> = new Subject();

  constructor() {}

  setSubtitle(Subtitle: string) {
    this.SubtitleObservable.next(Subtitle);
    this.Subtitle = Subtitle;
  }

  getSubtitle(): string {
    return this.Subtitle;
  }
}
