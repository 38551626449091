import { Injectable } from "@angular/core";
import { AbstractImportService } from "./abstract-import.service";
import { Sheet } from "xlsx";
import * as moment from "moment/moment";
import { ToastService } from "../core/toast.service";
import { Masterlist } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export abstract class AbstractCustomMasterlistImportService extends AbstractImportService<
  Masterlist[]
> {
  protected constructor(toastService: ToastService) {
    super(toastService);
  }

  public writeChangeLogFile(): boolean {
    return false;
  }

  abstract getKey(): string;

  abstract getPreviewSettings(): { Icon: any; Cols: any };

  public getSelectAllDefault(): boolean {
    return true;
  }

  protected contentOrNull(sheet: Sheet, col: string, row: number): any {
    return sheet[col + row] ? sheet[col + row].v : null;
  }

  protected stringContent(sheet: Sheet, col: string, row: number, replaceLineBreaks = false): string {
    const content = this.contentOrNull(sheet, col, row);
    const response = (content || '') + '';
    return replaceLineBreaks ? response.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s\s+/g, ' ') : response;
  }

  protected getRows(sheet: Sheet): number {
    const max = sheet["!ref"].split(":")[1];
    return Number(max.replace(/\D/g, ""));
  }

  protected getExternalId(sheet: Sheet, col: string, row: number): string {
    const value = this.contentOrNull(sheet, col, row);
    const parsed = String(value || "").trim();
    return parsed && parsed.length > 0 ? parsed : null;
  }

  protected parseDate(day: any): moment.Moment {
    if (typeof day === "number") {
      /**
       * Why do I subtract 2 you might ask
       * (1) 1900-01-01 is day 1 so you have to subtract 1
       * (2) also excel didn't count right because they counted 1900-02-29 as a day which did not exist so you have to subtract one in addition
       */
      const refDay = moment("1900-01-y01 00:00", "YYYY-MM-DD HH:mm").startOf("day");
      const response = refDay.add((day as number) - 2, "days");
      return response;
    } else if (typeof day === "string") {
      try {
        const datePart = (day as string).substr(day.length - 8, 8);
        const response = moment(datePart, "DD.MM.YY");
        return response;
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  protected parseTime(time: any): number {
    if (typeof time === "number") {
      const response = 24 * 60 * (time as number);
      return response;
    } else if (typeof time === "string") {
      try {
        const split = (time as string).split(/[:.]+/g);
        const hours = parseInt(split[0], 10);
        const minutes = parseInt(split[1], 10);
        const result = hours * 60 + minutes;
        return isNaN(result) ? 0 : result;
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  protected createTimesFromDateAndTimeframe(sheet: Sheet, row: number, dateCol: string, startTimeCol: string, endTimeCol: string, defaultTime?: number): { StartTime: Date; EndTime: Date } {
    const dateString = this.contentOrNull(sheet, dateCol, row);
    const startTimeString = this.contentOrNull(sheet, startTimeCol, row) || defaultTime;
    const endTimeString = this.contentOrNull(sheet, endTimeCol, row) || defaultTime;

    if (!dateString || startTimeString == null || endTimeString == null) {
      return { StartTime: null, EndTime: null };
    }
    const parsedDate = this.parseDate(dateString);
    if (!parsedDate || !parsedDate.isValid()) {
      return { StartTime: null, EndTime: null };
    }
    const startMinutes = this.parseTime(startTimeString);
    const endMinutes = this.parseTime(endTimeString);
    const StartTime = parsedDate.clone().add(startMinutes, "minutes").toDate();
    const EndTime = parsedDate.clone().add(endMinutes, "minutes").toDate();
    return { StartTime, EndTime };
  }
}
