import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { ApiService } from "src/app/services/api/api.service";

@Component({
  selector: "app-version-modal",
  templateUrl: "./version-modal.component.html",
  styleUrls: ["./version-modal.component.scss"]
})
export class VersionModalComponent implements OnInit {
  patchNotes: any = "";
  patchNotesHtml: any;
  versions: string[] = [];
  currentVersion: string = "Latest";

  constructor(private modalController: ModalController, private apiService: ApiService) { }

  async ngOnInit() {
    await this.getAllVersions();
    await this.getNotes();
  }

  async closeModal(saveAnyway: boolean) {
    await this.modalController.dismiss(saveAnyway);
  }

  async getAllVersions() {
    this.versions = await this.apiService.get("manager-version/history");
  }
  async getNotes(version?: string) {
    const data = await this.apiService.get("manager-version/notes", { Code: version });
    this.currentVersion = data.Version;
    if (data.Notes.title) {
      this.patchNotesHtml = data.Notes;
      this.patchNotes = ''
    } else {
      this.patchNotes = data.Notes;
      this.patchNotesHtml = undefined
    }

  }
}
