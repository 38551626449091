import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as moment from "moment/moment";
import { Team, InventoryHistory } from "../../interfaces";

@Component({
  selector: "app-inventory-history-modal",
  templateUrl: "./inventory-history-modal.component.html",
  styleUrls: ["./inventory-history-modal.component.scss"],
})
export class InventoryHistoryModalComponent implements OnInit {
  @Input() set InventoryHistory(inventoryHistory: InventoryHistory) {
    this.inventoryHistory = JSON.parse(JSON.stringify(inventoryHistory));
    if (inventoryHistory) {
      this.startTime = inventoryHistory.StartTime
        ? moment(inventoryHistory.StartTime).toDate()
        : new Date();
      this.endTime = inventoryHistory.EndTime
        ? moment(inventoryHistory.EndTime).toDate()
        : new Date();
    } else {
      this.startTime = null;
      this.endTime = null;
    }
    this.updateInvalidInventoryHistories();
    this.updateHasErrors();
  }
  @Input() Teams: Team[];
  @Input() set InventoryHistories(histories: InventoryHistory[]) {
    this.inventoryHistories = histories;
  }

  startTime: Date;
  endTime: Date;
  inventoryHistory: InventoryHistory;
  inventoryHistories: InventoryHistory[];
  invalidInventoryHistories: InventoryHistory[];
  hasError: boolean = false;
  canSetDefault: boolean;
  isDefault: boolean = false;

  constructor(private modalController: ModalController) {}

  async ngOnInit() {
    this.canSetDefault = false;
    this.updateCanSetDefault();
  }

  async closeModal(save: boolean = false) {
    if (this.isDefault) {
      this.inventoryHistory.StartTime = null;
      this.inventoryHistory.EndTime = null;
    } else {
      this.inventoryHistory.StartTime = moment(this.startTime)
        .startOf("day")
        .toDate();
      this.inventoryHistory.EndTime = moment(this.endTime)
        .startOf("day")
        .toDate();
    }
    await this.modalController.dismiss(save ? this.inventoryHistory : null);
  }

  updateCanSetDefault() {
    let canSetDefault = true;
    for (const history of this.inventoryHistories) {
      if (!history.StartTime && !history.EndTime) {
        canSetDefault = false;
        break;
      }
    }
    this.canSetDefault = canSetDefault;
    if (canSetDefault) {
      this.isDefault =
        !this.inventoryHistory.Id ||
        (!this.inventoryHistory.StartTime && !this.inventoryHistory.EndTime);
    } else {
      this.isDefault = false;
    }
  }

  timeChanged(date: Date) {
    this.removeTime(date);
    this.updateInvalidInventoryHistories();
    this.updateHasErrors();
  }

  updateHasErrors() {
    this.hasError =
      !this.isDefault &&
      this.startTime &&
      this.endTime &&
      (this.startTime > this.endTime ||
        this.invalidInventoryHistories.length > 0);
  }

  removeTime(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  updateInvalidInventoryHistories() {
    const invalidInventoryHistories = [];
    if (
      !this.startTime ||
      !this.endTime ||
      !this.inventoryHistory ||
      !this.inventoryHistories ||
      this.inventoryHistories.length === 0
    ) {
      this.invalidInventoryHistories = invalidInventoryHistories;
      return;
    }
    const startDate = moment(this.startTime);
    const endDate = moment(this.endTime);
    for (const history of this.inventoryHistories) {
      const historyStartDate = moment(history.StartTime);
      const historyEndDate = moment(history.EndTime);
      if (
        (historyStartDate.isSameOrAfter(startDate) &&
          historyStartDate.isSameOrBefore(endDate)) ||
        (historyEndDate.isSameOrAfter(startDate) &&
          historyEndDate.isSameOrBefore(endDate)) ||
        (historyStartDate.isBefore(startDate) &&
          historyEndDate.isAfter(endDate))
      ) {
        invalidInventoryHistories.push(history);
      }
    }
    this.invalidInventoryHistories = invalidInventoryHistories;
  }

  canSave(): boolean {
    return this.inventoryHistory.Team && !this.hasError;
  }

  toggleDefault() {
    this.isDefault = !this.isDefault;
    this.updateHasErrors();
  }
}
