import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { ShiftDetailXlsxExportService } from "../../services/export/shift-detail-xlsx-export.service";
import { Router } from "@angular/router";
import { AcceptDeclineModalComponent } from "../../modals/accept-decline-modal/accept-decline-modal.component";
import { ModalController } from "@ionic/angular";
import { ArrayService } from "../../services/core/array.service";
import { ShiftService } from "../../services/api/shift.service";
import { SelectInventoryModalComponent } from "../../modals/select-inventory-modal/select-inventory-modal.component";
import { RemarkService } from "../../services/api/remark.service";
import { ToastService } from "../../services/core/toast.service";
import { TypeGroupService } from "../../services/api/type-group.service";
import { NavigationService } from "src/app/services/core/navigation.service";
import {
  Company,
  EmployeeTask,
  Qualification,
  Remark,
  SearchFunction,
  Shift,
  ShiftType,
  Team,
  TimeSlot,
  User,
  FilledInteractionForm,
  HasTimes,
  Inventory,
} from "src/app/interfaces";
import { SortService } from "../../services/core/sort.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-shift-detail-content",
  templateUrl: "./shift-detail-content.component.html",
  styleUrls: ["./shift-detail-content.component.scss"],
})
export class ShiftDetailContentComponent implements OnInit, OnDestroy {
  @Input() set Id(shiftId: string) {
    const previousId = this.shiftId;
    this.shiftId = shiftId;
    if (this.isInitialized && previousId != shiftId) {
      this.loadShift();
    }
  }

  @Input() SplitViewMode;
  @Input() ModalMode: boolean = false;
  @Output() Reload = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() CloseView = new EventEmitter<void>();
  @Output() loading = new EventEmitter<void>();

  shiftId: string;
  shift: Shift;
  timeslots: TimeSlot[];
  tasks: EmployeeTask[] = [];
  qualifications: Qualification[];
  shiftTypeQualifications: Qualification[];
  shiftTypeMinutes: number = null;
  shiftTypes: ShiftType[];
  company: Company = {};
  timeSlotUsers: User[] = [];
  timeSlotUserIds: string[] = [];
  customTask = null;
  selectedShiftType: ShiftType;
  timeSlotFilterCache = {};
  hasDifferingAddress = false;
  canUseLocationTracking = false;
  hasWorkTimes = false;
  isLoading = false;
  isSaving = false;
  teams: Team[] = [];
  showInventory: boolean;
  userRemarks: { [UserId: string]: Remark[] } = {};
  isInitialized: boolean = false;
  triggerCollapseState: { [key: string]: boolean } = {};
  highlightChanges = false;
  showFees: boolean = false;
  showApplications: boolean = false;
  showBillingNumber: boolean = false;
  ShowTimeSlotCustomHighlights: boolean = false;

  userSearchFunction: SearchFunction = this.arrayService.createSearchFunction(
    "FirstName",
    "LastName",
  );
  typeSearchFct = this.groupService.getTypeSearchFunction();

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private xlsxExportService: ShiftDetailXlsxExportService,
    private router: Router,
    private modalController: ModalController,
    private arrayService: ArrayService,
    private shiftService: ShiftService,
    private remarkService: RemarkService,
    private toastService: ToastService,
    private groupService: TypeGroupService,
    private navigation: NavigationService,
    private sortService: SortService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.isInitialized = false;
    this.isLoading = true;
    this.triggerCollapseState = {};
    
    const data = await this.api.get('shifts/init');

    this.qualifications = data.qualifications;
    this.shiftTypeQualifications = data.qualifications;
    this.shiftTypes = data.shiftTypes;
    this.canUseLocationTracking = data.canUseLocationTracking;
    this.showInventory = data.showInventory;
    this.highlightChanges = data.highlightChanges;
    this.showFees = data.showFees;
    this.showBillingNumber = data.showBillingNumber;
    this.showApplications = data.showApplications;
    this.company = data.company;
    this.ShowTimeSlotCustomHighlights = data.showTimeSlotCustomHighlights;
    this.teams = data.teams;

    this.clearShift();
    if (this.shiftId && this.shiftId != "create") {
      await this.loadShift();
    }
    this.isInitialized = true;
    this.loading.emit();
    this.isLoading = false;
  }

  async ngOnDestroy() {}

  async loadShift(showSpinner= true) {
    if(showSpinner) {
      this.isLoading = true;
    }
    
    this.shift = await this.shiftService.get(this.shiftId);
    this.hasDifferingAddress =
      this.shift.City &&
      (this.shift.Latitude !== this.company.Latitude ||
        this.shift.Longitude !== this.company.Longitude);
    if (this.shift?.ShiftType) {
      if (this.shift.ShiftType.DeletedAt) {
        //ng select is not detecting changes, besides clearing the array once
        this.shift.ShiftType.disabled = true; //disabled because its deleted
        const shiftTypes =
          this.shiftTypes && this.shiftTypes.length > 0
            ? [...this.shiftTypes]
            : [];
        shiftTypes.push(this.shift.ShiftType);
      }
      if (this.shift.ShiftType.EstimatedDuration) {
        this.shiftTypeMinutes = this.shift.ShiftType.EstimatedDuration;
      }
    }
    this.shiftTypeQualifications = this.shift?.ShiftType?.Qualifications || [];
    this.userRemarks = await this.remarkService.getAllUserRemarksByTimeSlots(
      this.shift,
    );
    await this.prepareTimeSlots();
    this.isLoading = false;
  }

  async updateTimeSlotUsers(): Promise<void> {
    const persons = []; //for tasks
    const userIds = []; //for maillist
    for (let slot of this.timeslots) {
      if (slot.User) {
        persons.push(slot.User);
        userIds.push(slot.User.Id);
      }
    }
    this.timeSlotUsers = persons;
    this.timeSlotUserIds = userIds;
  }

  async prepareTimeSlots() {
    const qualificationIds = this.shift.QualificationIds || [];
    const numWorker = this.shift.NumWorker || 0;
    const timeSlots =
      this.shift.TimeSlots?.length > 0 ? [...this.shift.TimeSlots] : [];
    const newTimeSlots = [];
    const qualificationsMap = this.arrayService.toMap(
      this.shiftTypeQualifications || [],
    );

    // delete this.shift.TimeSlots;
    for (const qualificationId of qualificationIds) {
      newTimeSlots.push({
        QualificationId: qualificationId,
        Qualification: qualificationsMap[qualificationId],
        ShiftId: this.shift.Id,
        Description: this.shift.Description,
        Name: this.shift.Name,
        CustomAbbreviation: this.shift.CustomAbbreviation,
      });
    }
    for (let i = newTimeSlots.length; i < numWorker; i++) {
      newTimeSlots.push({
        ShiftId: this.shift.Id,
        Description: this.shift.Description,
        Name: this.shift.Name,
        CustomAbbreviation: this.shift.CustomAbbreviation,
      });
    }
    for (let i = 0; i < newTimeSlots.length; i++) {
      const newTimeSlot = newTimeSlots[i];
      const index = timeSlots.findIndex((ts) =>
        newTimeSlot.QualificationId
          ? ts.QualificationId === newTimeSlot.QualificationId
          : !ts.QualificationId,
      );
      if (index > -1) {
        newTimeSlots[i] = timeSlots[index];
        timeSlots.splice(index, 1);
      }
    }
    for (const timeslot of timeSlots) {
      newTimeSlots.push(timeslot);
    }
    this.sortService.sortByComparators(
      newTimeSlots,
      ...this.sortService.TIMESLOT_COMPARATORS,
    );
    this.timeslots = newTimeSlots;
    this.hasWorkTimes = false;
    for (const ts of this.timeslots) {
      if (ts.WorkTime) {
        this.hasWorkTimes = true;
      }
    }
    await this.updateTimeSlotUsers();
  }

  get AvailableQualifications(): Qualification[] {
    return this.shiftTypeQualifications.length === 0
      ? this.qualifications
      : this.shiftTypeQualifications;
  }

  updateAddress() {
    if (
      this.canUseLocationTracking &&
      this.shift.LocationTracking &&
      !this.hasDifferingAddress
    ) {
      this.shift.Street = this.company.Street;
      this.shift.Zip = this.company.Zip;
      this.shift.City = this.company.City;
    } else if (
      !this.hasDifferingAddress &&
      (!this.canUseLocationTracking || !this.shift.LocationTracking)
    ) {
      this.shift.Street = null;
      this.shift.Zip = null;
      this.shift.City = null;
    }
  }

  async save(goBack: boolean = false, modal: boolean = false) {
    this.isSaving = true;
    const response = modal || (await this.checkWorkTimes());
    if (response) {
      if (!this.shift.StartTime) {
        this.isSaving = false;
        return this.toastService.presentError(
          this.translate.instant('die_muss_eine_start_und'),
        );
      }
      this.shift.QualificationIds = this.timeslots
        .filter((timeSlot) => timeSlot.QualificationId)
        .map((timeSlot) => timeSlot.QualificationId);
      this.shift.TimeSlots = JSON.parse(JSON.stringify(this.timeslots));
      await this.shiftService.save(
        this.shift,
        this.translate.instant('components.shift_detail_content.saveSuccessMsg')
      );
      this.timeSlotFilterCache = {};
      this.Reload.emit();

      if (goBack) {
        if(this.ModalMode) {
          return this.CloseView.emit();
        }
        return this.goBack();
      }

      if (this.shiftId) {
        if (!modal) {
          await this.loadShift(false);
        }
      } else {
        await this.router.navigate(["/shifts/" + this.shift.Id]);
      }
    }
    this.isSaving = false;
  }

  goBack() {
    if(this.ModalMode) {
      return this.CloseView.emit();
    }
    if (this.SplitViewMode) {
      return;
    }
    this.navigation.back();
  }

  async delete() {
    const response = await this.checkWorkTimes(this.translate.instant('trotzdem_lschen'));
    if (response) {
      await this.shiftService.destroy(
        this.shift,
        this.translate.instant('components.shift_detail_content.deleteSuccessMsg')
      );
      this.goBack();
      this.Remove.emit();
    }
  }

  changeDay($event: any) {
    const newDate = new Date($event);
    this.shift.StartTime.setFullYear(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
    );
    this.shift.EndTime.setFullYear(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
    );
  }

  changeStartTime($event: any) {
    const newTime = $event.split(":");
    this.shift.StartTime.setMinutes(newTime[1]);
    this.shift.StartTime.setHours(newTime[0]);
  }

  changeEndTime($event: any) {
    const newTime = $event.split(":");
    this.shift.EndTime.setMinutes(newTime[1]);
    this.shift.EndTime.setHours(newTime[0]);
  }

  handleTimeSlotAdd() {
    this.shift.NumWorker = this.timeslots.length;
  }

  async handleTimeSlotRemove() {
    this.shift.NumWorker = this.timeslots.length;
    await this.save();
  }

  addTask(task: EmployeeTask) {
    this.shift.Tasks.push({ Name: task.Name, Employees: [] });
  }

  addCustomTask(taskName: string) {
    if (taskName !== null) {
      this.shift.Tasks.push({ Name: taskName, Employees: [] });
    }
    this.customTask = null;
  }

  removeTask(item) {
    const index: number = this.shift.Tasks.indexOf(item);
    if (index !== -1) {
      this.shift.Tasks.splice(index, 1);
    }
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  clearShiftType() {
    this.shiftTypeQualifications = [];
  }

  async setShiftType(shiftType: ShiftType) {
    await this.shiftService.createOrUpdateFromType(shiftType, this.shift);
    this.shiftTypeQualifications = shiftType.Qualifications;
    this.shift.AppSettings = shiftType.AppSettings;
    await this.checkTeam();
    await this.prepareTimeSlots();
    await this.save();
  }

  openSetShiftTypeModal(modal: any, shiftType: any) {
    this.selectedShiftType = shiftType as ShiftType;
    if (this.selectedShiftType) {
      this.modalService.open(modal, {
        windowClass: "modal-animated",
        centered: true,
      });
    }
  }

  async closeModal(modal, overWrite: boolean) {
    if (overWrite) {
      await this.setShiftType(this.selectedShiftType);
    }
    this.selectedShiftType = undefined;
    modal.close();
  }

  updateTime(newTime: HasTimes) {
    this.shift.StartTime = newTime.StartTime;
    this.shift.EndTime = newTime.EndTime;
  }

  async checkTeam() {
    if (this.shift.Teams && this.shift.Teams.length > 0) {
      for (let slot of this.timeslots) {
        if (slot.User) {
          if (!slot.User.TeamMembers) {
            slot.UserId = undefined;
            slot.User = undefined;
          } else {
            let inAnyTeam = false;
            for (let team of this.shift.Teams) {
              if (
                JSON.stringify(slot.User.TeamMembers).indexOf(team.Id) != -1
              ) {
                inAnyTeam = true;
              }
            }
            if (!inAnyTeam) {
              slot.UserId = undefined;
              slot.User = undefined;
            }
          }
        }
      }
    }
  }

  async checkWorkTimes(
    okText: string = this.translate.instant('trotzdem_speichern'),
  ): Promise<boolean> {
    if (!this.hasWorkTimes) {
      return true;
    }
    const modal = await this.modalController.create({
      componentProps: {
        Message:
          this.translate.instant('es_sind_schon_arbeitszeiten_gestartet'),
        OkText: okText,
      },
      component: AcceptDeclineModalComponent,
      cssClass: "custom-ion-modal",
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    return !!response.data;
  }

  clearShift() {
    this.shift = { Tasks: [], SpecialFields: {}, Triggers: [] };
    this.shift.StartTime = moment().seconds(0).milliseconds(0).toDate();
    this.shift.EndTime = moment()
      .seconds(0)
      .milliseconds(0)
      .add(60 * 8, "minutes")
      .toDate();
    this.shift.SpecialFields = this.shift.SpecialFields || {};
    this.shift.Tasks = this.shift.Tasks || [];
    this.shift.Triggers = this.shift.Triggers || [];
    this.userRemarks = {};
  }

  public getExportService(): ShiftDetailXlsxExportService {
    return this.xlsxExportService;
  }

  async CloseSplitView() {
    await this.CloseView.emit();
  }

  updateAppSettings(settings: any) {
    this.shift.AppSettings = JSON.parse(JSON.stringify(settings));
  }

  async deleteInventory(inventory: Inventory) {
    return this.shiftService.deleteInventory(this.shift, inventory);
  }

  async openInventoryModal() {
    const selectionList = await this.shiftService.getInventorySelectionList(
      this.shift,
    );
    const modal = await this.modalController.create({
      componentProps: {
        Item: this.shift,
        SelectionList: selectionList,
      },
      component: SelectInventoryModalComponent,
      cssClass: "custom-ion-modal",
    });
    modal.onDidDismiss().then(async (response) => {
      if (response.data && response.data.Id) {
        await this.shiftService.saveInventory(this.shift, response.data);
      }
    });
    await modal.present();
  }

  updateRemarks(remarks: { [id: string]: Remark[] }) {
    this.userRemarks = Object.assign({}, this.userRemarks, remarks);
  }

  async pokeForms() {
    return this.shiftService.sendFormReminder(this.shift);
  }

  isToday() {
    return moment().isSameOrAfter(this.shift.StartTime, "d");
  }

  hasTriggers() {
    return this.shift?.Triggers && this.shift.Triggers.length > 0;
  }

  showFormReminder() {
    return this.isToday() && this.hasTriggers();
  }

  async createCopy() {
    const newShift = await this.shiftService.copy(this.shift);
    if (this.SplitViewMode) {
      await this.CloseView.emit();
    } else {
      await this.router.navigate(["/shifts/" + newShift.Id]);
    }
  }
}
