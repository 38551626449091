import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { InventoryService } from "../../services/api/inventory.service";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { InventoryHistoryModalComponent } from "../../modals/inventory-history-modal/inventory-history-modal.component";
import { ApiService } from "../../services/api/api.service";
import { Inventory, InventoryHistory } from "../../interfaces";
import { SettingsService } from "src/app/services/api/settings.service";

@Component({
  selector: "app-inventory-detail-content",
  templateUrl: "./inventory-detail-content.component.html",
  styleUrls: ["./inventory-detail-content.component.scss"],
})
export class InventoryDetailContentComponent implements OnInit {
  @Input() set Id(inventoryId: string) {
    const previousId = this.inventoryId;
    this.inventoryId = inventoryId;
    if (this.isInitialized && previousId != inventoryId) {
      this.load();
    }
  }

  @Input() SplitViewMode;
  @Output() Reload = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() CloseView = new EventEmitter<void>();

  inventory: Inventory;
  TrackerInformations: any;
  inventoryTypes: string[] = [];
  MaterialTracking = false;
  inventoryId: string;
  isLoading: boolean;
  isInitialized = false;

  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    private modalController: ModalController,
    private api: ApiService,
    private settingsService: SettingsService
  ) { }

  async ngOnInit() {
    this.isInitialized = false;
    this.isLoading = true;
    this.resetValues();
    this.inventoryTypes = await this.inventoryService.getAllTypes();
    await this.load();
    this.isLoading = false;
    this.isInitialized = true;
    this.MaterialTracking = await this.settingsService.getBooleanValue('UseMaterialTracking');
  }

  async load() {
    this.resetValues();
    if (this.inventoryId && this.inventoryId != 'create') {
      this.inventory = await this.inventoryService.get(this.inventoryId);
      if (this.inventory.TrackerId) {
        this.TrackerInformations = await this.inventoryService.getTrackerInformations(this.inventoryId);
        console.log(this.TrackerInformations)
      }
      this.inventory.InventoryHistories =
        this.inventory.InventoryHistories || [];
    }
  }

  async openTraccar() {
    const domain = await this.settingsService.getStringValue('traccar-domain')
    const token = await this.settingsService.getStringValue('traccar-token')
    window.open(domain + '?token=' + token, "_blank");
  }

  resetValues() {
    this.TrackerInformations = undefined;
    this.inventory = {
      Name: "",
      Type: "",
      InventoryHistories: [],
    };
  }

  async save(goBack: boolean = false) {
    const newInventory = await this.inventoryService.save(this.inventory);
    this.Reload.emit();
    if (goBack && !this.SplitViewMode) {
      return this.goBack();
    }
    if (this.inventoryId != newInventory.Id) {
      await this.router.navigate(["/inventory", newInventory.Id]);
    } else {
      await this.load();
    }
  }

  async delete() {
    await this.inventoryService.destroy(this.inventory);
    this.Remove.emit();
    return this.goBack();
  }

  async goBack() {
    if (this.SplitViewMode) {
      return;
    }
    return this.router.navigate(["/inventory"]);
  }

  async closeSplitView() {
    await this.CloseView.emit();
  }

  addType(inventoryType: string) {
    const types =
      this.inventoryTypes && this.inventoryTypes.length > 0
        ? [...this.inventoryTypes]
        : [];
    types.push(inventoryType);
    types.sort();
    this.inventoryTypes = types;
    return inventoryType;
  }

  async createInventoryHistory() {
    const history = {
      TeamId: null,
      Team: null,
      InventoryId: this.inventory.Id,
      StartTime: null,
      EndTime: null,
    };
    return this.openInventoryHistoryModal(history);
  }

  async openInventoryHistoryModal(history: InventoryHistory) {
    const otherHistories = this.inventory.InventoryHistories.filter(
      (ih) => ih.Id != history.Id,
    );
    const modal = await this.modalController.create({
      component: InventoryHistoryModalComponent,
      componentProps: {
        InventoryHistory: history,
        Teams: await this.api.get("teams"),
        InventoryHistories: otherHistories || [],
      },
      cssClass: "inventory-history-modal",
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response && response.data) {
      const changedHistory = response.data;
      changedHistory.TeamId = changedHistory.Team.Id;
      delete changedHistory.Team;
      await this.inventoryService.saveHistory(changedHistory);
      await this.load();
    }
  }

  async deleteInventoryHistory(inventoryHistory: InventoryHistory) {
    await this.inventoryService.destroyHistory(inventoryHistory);
    await this.load();
  }
}
