import { Component, Input, OnInit } from "@angular/core";
import { Inventory } from "../../../interfaces";

@Component({
  selector: "app-schedule-inventory-badge",
  templateUrl: "./schedule-inventory-badge.component.html",
  styleUrls: ["./schedule-inventory-badge.component.scss"],
})
export class ScheduleInventoryBadgeComponent implements OnInit {
  @Input() Inventory: Inventory;

  constructor() {}

  ngOnInit() {}
}
