import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ObjectService {
  constructor() {}

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
