import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { DecimalPipe } from "@angular/common";
import { AbsenceStatePipe } from "./absence-state.pipe";
import { UserNamePipe } from "./user-name.pipe";
import { TranslateEnumPipe } from "./translate-enum.pipe";
import { NgxDatePipe } from "./translatedate.pipe";
import { HourMinPipe } from "./hour-min-pipe";

const declarations = [AbsenceStatePipe, TranslateEnumPipe, UserNamePipe,NgxDatePipe, HourMinPipe];

@NgModule({
  declarations: declarations,
  imports: [IonicModule],
  providers: [DecimalPipe, ...declarations],
  exports: [...declarations],
})
export class PipesModule {}
