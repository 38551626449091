import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { StorageService } from "../storage/storage.service";
import { User, Company } from "../../interfaces";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public User: User = {};
  public Roles: any;
  public Companies: Company[];
  public Company: Company;
  public BETA_API = "https://beta.api.teamcraft.app/";
  public MASTER_API = "https://api.teamcraft.app/";
  public serverApi = this.getUrl();
  public loggingApi = this.getLoggingUrl();
  private cachedAccountUrl: string;

  constructor(private http: HttpClient, private router: Router, private storageService: StorageService) { }

  private getUrl() {
    let api = location.href.indexOf("beta.manager.teamcraft.app") >= 0 ? this.BETA_API : this.MASTER_API;
    if (location.href.indexOf(":4200") >= 0) {
      api = "http://localhost:3000/";
    } else if (location.href.indexOf(":4201") >= 0) {
      api = this.BETA_API;
    }
    return api;
  }
  private getLoggingUrl() {
    let api = location.href.indexOf("beta.manager.teamcraft.app") >= 0 ? "https://beta.log.teamcraft.app/" : "https://log.teamcraft.app/";
    if (location.href.indexOf(":4200") >= 0) {
      api = "https://beta.log.teamcraft.app/"; //http://localhost:3200/
    } else if (location.href.indexOf(":4201") >= 0) {
      api = this.BETA_API;
    }
    return api;
  }

  async initAccount() {
    this.cachedAccountUrl = await this.httpGet("sign-in/account-url");
  }

  async initUser(redirect: boolean = false): Promise<boolean> {
    let redirected = false;
    try {
      await this.getUser();
      if ((redirect && !this.storageService.getCompanyId()) || this.storageService.getLeasingAgreement()) {
        redirected = true;
        await this.router.navigate(["/selection"]);
      }
      const preloader = document.getElementById("main-preloader");
      if (preloader && preloader.parentNode) {
        preloader.parentNode.removeChild(preloader);
      }
    } catch (err) {
      redirected = true;
      this.storageService.removeApiKey();
      await this.router.navigate(["signup"]);
    }
    return redirected;
  }

  private async httpGet(path: string): Promise<any> {
    return this.http.get(this.serverApi + path).toPromise();
  }

  async getUser(): Promise<User> {
    this.User = await this.httpGet("user");
    await this.getRoles();
    await this.getCompanies();
    await this.getCompany();
    return this.User;
  }

  public async getRoles(): Promise<any> {
    try {
      this.Roles = await this.httpGet("user/roles");
      /* if ((this.Roles || []).length === 0) {
        const errorMessage = document.getElementById('main-not-allowed');
        errorMessage.setAttribute('style', '');
      }*/
      return this.Roles;
    } catch (e) {
      console.log(e);
    }
  }

  public isAdmin(): boolean {
    return (this.Roles || []).indexOf("Administrator") >= 0;
  }

  public isBeta(): boolean {
    return this.serverApi?.indexOf("beta") >= 0 || this.serverApi?.indexOf("localhost") >= 0;
  }

  public hasRole(RoleName: string): boolean {
    return this.isAdmin() || (this.Roles || []).indexOf(RoleName) >= 0;
  }
  public anyRole(): boolean {
    return (this.Roles || []).length > 0;
  }

  public hasPermission(Model: string, Action: string): boolean {
    return true;
  }

  async getCompanies() {
    this.Companies = await this.httpGet("user/companies");
    return this.Companies;
  }

  async getCompany() {
    if (!this.User) {
      this.storageService.removeCompanyId();
      await this.router.navigate(["/selection"]);
    }
    this.Company = (this.Companies || []).find(company => company.Id === this.storageService.getCompanyId());
    return this.Company;
  }

  getAppId() {
    if (location.href.indexOf(":4200") >= 0 || location.href.indexOf(":4201") >= 0) {
      return "94853d15-fa5b-4226-b62c-7bc0aedbe88f";
    }
    return "a683679c-5812-43e5-a7c1-74ea9862f1d6";
  }

  public getAccountUrl(): string {
    return this.cachedAccountUrl || "https://account.youco-it.com/";
  }
}
