import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { TranslateService } from "@ngx-translate/core";
import { Qualification, WorkOrderType } from "../../interfaces";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class WorkOrderTypeService {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
  ) {}

  async getAll(
    filter: any = {},
    useCache: boolean = false,
  ): Promise<WorkOrderType[]> {
    if (useCache) {
      return this.api.Cache("work-order-types", []);
    }
    const params: any = {};
    if (filter) {
      params.Filter = JSON.stringify(filter);
    }
    return this.api.get("work-order-types", params);
  }

  create(): WorkOrderType {
    return {
      SpecialFields: {},
      Triggers: [],
      Inventories: [],
      Tasks: [],
      QualificationIds: [],
      Qualifications: [],
      EstimatedDuration: 0,
      WorkOrderStart: moment().seconds(0).milliseconds(0).toDate(),
    };
  }

  async get(id: string): Promise<WorkOrderType> {
    const workOrderType: WorkOrderType =
      (await this.api.get("work-order-types/" + id)) || {};
    workOrderType.EstimatedDuration = workOrderType.EstimatedDuration || 0;
    workOrderType.Triggers = workOrderType.Triggers || [];
    workOrderType.Qualifications = workOrderType.Qualifications || [];
    workOrderType.Inventories = workOrderType.Inventories || [];
    workOrderType.WorkOrderStart = new Date(workOrderType.WorkOrderStart);
    workOrderType.WorkOrderEnd = new Date(workOrderType.WorkOrderEnd);
    return workOrderType;
  }

  async save(
    workOrderType: WorkOrderType,
    qualifications: Qualification[],
    positions: any[],
  ): Promise<WorkOrderType> {
    const element: any = workOrderType;
    let sumPositions = 0;
    let positionsWithoutQualification = 0;
    const qualificationIds: string[] = [];
    for (const position of positions) {
      if (position.Qualification) {
        const ids = Array.from({ length: position.NumWorkers }).map(
          (i) => position.Qualification,
        );
        sumPositions += ids.length;
        qualificationIds.push(...ids);
      } else {
        positionsWithoutQualification += position.NumWorkers;
      }
    }
    element.NumWorker = sumPositions + positionsWithoutQualification;
    element.QualificationIds = JSON.stringify(qualificationIds);
    element.Qualifications = qualifications;
    const successMessage = this.translate.instant(
      "pages.work_order_type.work_order_type_detail.saveSuccessMsg",
    );
    return this.api.post(
      `work-order-types${element.Id ? "/" + element.Id : ""}`,
      element,
      successMessage,
    );
  }

  async destroy(workOrderType: WorkOrderType): Promise<WorkOrderType> {
    if (!workOrderType.Id) {
      return workOrderType;
    }
    const successMessage = this.translate.instant(
      "pages.work_order_type.work_order_type_detail.deleteSuccessMsg",
    );
    return this.api.delete(
      "work-order-types/" + workOrderType.Id,
      successMessage,
    );
  }

  async copy(source: WorkOrderType): Promise<WorkOrderType> {
    const successMessage = this.translate.instant(
      "pages.work_order_type.work_order_type_list.copySuccessMsg",
    );
    const copy: any = JSON.parse(JSON.stringify(source));
    copy.CreatedAt = null;
    copy.DeletedAt = null;
    copy.UpdatedAt = null;
    copy.Id = null;
    copy.Name = copy.Name + " - Kopie";
    if (copy.QualificationIds) {
      copy.QualificationIds = JSON.stringify(copy.QualificationIds);
    }
    copy.Triggers = copy.Triggers.map((trigger) => {
      delete trigger.Id;
      return trigger;
    });
    return this.api.post("work-order-types", copy, successMessage);
  }
}
