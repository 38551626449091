import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { WorkOrder } from "src/app/interfaces";
import { ApiService } from "src/app/services/api/api.service";
import { WorkOrderService } from "src/app/services/api/work-order.service";

@Component({
  selector: 'app-dashboard-open-workorders',
  templateUrl: './open-workorders.component.html',
  styleUrls: ['./open-workorders.component.scss'],
})
export class DashboardOpenWorkordersComponent implements OnInit {
  @Input() set GridWidth(value: number) {
    this.gridWidth = value;
    // this.folded = value == 1
  }
  @Input() set GridHeight(value: number) {
    this.gridHeight = value;
  }

  @Input() set IsLoading(value: boolean) {
    this.isLoading = value;
    if (value) {
      this.init();
    }
  }
  isLoading = false;
  gridWidth = 2;
  gridHeight = 2;

  OpenWorkOrders: WorkOrder[];

  constructor(
    private api: ApiService,
    private workOrderService: WorkOrderService,

  ) { }

  async ngOnInit() {
    this.init();
  }

  async init() {
    this.OpenWorkOrders = await this.workOrderService.getAllOpenUpcoming(10);
  }


  setWorkOrderColor(NumWorker, SlotsTaken, SlotsApproved) {
    if (SlotsTaken < NumWorker || SlotsTaken === 0) {
      return "bg-status-red";
    } else if (SlotsApproved < SlotsTaken) {
      return "bg-status-yellow";
    }
  }



}
