import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Company } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class CompanyDesignService {
  public Company: Company = this.api.Cache("company", { STMP: {} });
  private CompanyBase64: string;

  constructor(private api: ApiService) {
    this.Company = (this.api.get("company") as Company) || {};
  }

  async setCompanyDesign() {
    this.Company = await this.Company;
    document.documentElement.style.setProperty(
      "--youco-medium",
      this.Company.PrimaryColor,
    );
    document.documentElement.style.setProperty(
      "--youco-dark",
      this.Company.SecondaryColor,
    );
    document.documentElement.style.setProperty(
      "--youco-font",
      this.Company.TextColor,
    );
  }

  getCompanyLogo() {
    if (this.CompanyBase64) {
      return this.CompanyBase64;
    } else {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const canvas: HTMLCanvasElement = document.createElement(
          "CANVAS",
        ) as HTMLCanvasElement;
        const ctx = canvas.getContext("2d");
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        this.CompanyBase64 = canvas.toDataURL();
        return this.CompanyBase64;
      };
      img.src = this.Company.Logo;
      if (img.complete || img.complete === undefined) {
        img.src =
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        img.src = this.Company.Logo;
      }
    }
  }

  async getWorkingHours(): Promise<{
    WorkHourStart: number;
    WorkHourEnd: number;
  }> {
    this.Company = await this.Company;
    return {
      WorkHourStart: this.Company.WorkHoursStart as number,
      WorkHourEnd: this.Company.WorkHoursEnd as number,
    };
  }
}
