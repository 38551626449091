import { Injectable } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { TranslateService } from "@ngx-translate/core";
import { Qualification, MasterlistType } from "../../interfaces";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class MasterlistTypeService {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
  ) { }

  async getAll(
    filter: any = {},
    useCache: boolean = false,
  ): Promise<MasterlistType[]> {
    if (useCache) {
      return this.api.Cache("masterlist-types", []);
    }
    const params: any = {};
    if (filter) {
      params.Filter = JSON.stringify(filter);
    }
    return this.api.get("masterlist-types", params);
  }

  create(): MasterlistType {
    return {
      SpecialFields: {},
      Triggers: [],
      Inventories: [],
      Tasks: [],
      QualificationIds: [],
      Qualifications: [],
      EstimatedDuration: 0,
      MasterlistStart: moment().seconds(0).milliseconds(0).toDate(),
    };
  }

  async get(id: string): Promise<MasterlistType> {
    const masterlistType: MasterlistType =
      (await this.api.get("masterlist-types/" + id)) || {};
    masterlistType.EstimatedDuration = masterlistType.EstimatedDuration || 0;
    masterlistType.Triggers = masterlistType.Triggers || [];
    masterlistType.Qualifications = masterlistType.Qualifications || [];
    masterlistType.Inventories = masterlistType.Inventories || [];
    masterlistType.MasterlistStart = new Date(masterlistType.MasterlistStart);
    masterlistType.MasterlistEnd = new Date(masterlistType.MasterlistEnd);
    return masterlistType;
  }

  async save(
    masterlistType: MasterlistType,
    qualifications: Qualification[],
    positions: any[],
  ): Promise<MasterlistType> {
    const element: any = masterlistType;
    let sumPositions = 0;
    let positionsWithoutQualification = 0;
    const qualificationIds: string[] = [];
    for (const position of positions) {
      if (position.Qualification) {
        const ids = Array.from({ length: position.NumWorkers }).map(
          (i) => position.Qualification,
        );
        sumPositions += ids.length;
        qualificationIds.push(...ids);
      } else {
        positionsWithoutQualification += position.NumWorkers;
      }
    }
    element.NumWorker = sumPositions + positionsWithoutQualification;
    element.QualificationIds = JSON.stringify(qualificationIds);
    element.Qualifications = qualifications;
    const successMessage = this.translate.instant(
      "pages.work_order_type.work_order_type_detail.saveSuccessMsg",
    );
    return this.api.post(
      `masterlist-types${element.Id ? "/" + element.Id : ""}`,
      element,
      successMessage,
    );
  }

  async destroy(masterlistType: MasterlistType): Promise<MasterlistType> {
    if (!masterlistType.Id) {
      return masterlistType;
    }
    const successMessage = this.translate.instant(
      "pages.work_order_type.work_order_type_detail.deleteSuccessMsg",
    );
    return this.api.delete(
      "masterlist-types/" + masterlistType.Id,
      successMessage,
    );
  }

  async copy(source: MasterlistType): Promise<MasterlistType> {
    const successMessage = this.translate.instant(
      "pages.work_order_type.work_order_type_list.copySuccessMsg",
    );
    const copy: any = JSON.parse(JSON.stringify(source));
    copy.CreatedAt = null;
    copy.DeletedAt = null;
    copy.UpdatedAt = null;
    copy.Id = null;
    copy.Name = copy.Name + " - Kopie";
    if (copy.QualificationIds) {
      copy.QualificationIds = JSON.stringify(copy.QualificationIds);
    }
    copy.Triggers = copy.Triggers.map((trigger) => {
      delete trigger.Id;
      return trigger;
    });
    return this.api.post("masterlist-types", copy, successMessage);
  }
}
