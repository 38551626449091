import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastController } from "@ionic/angular";
import { duration } from "moment";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  public DEFAULT_OPTIONS = {
    duration: 2000,
    position: "top",
  };

  constructor(private toastController: ToastController) {}


  async presentSuccess(message: string, options?: any) {
    return this.present(message, "success", options);
  }

  async presentWarning(message: string, options?: any) {
    return this.present(message, "warning", options);
  }

  async presentInfo(message: string, options?: any) {
    return this.present(message, "tertiary", options);
  }

  async presentError(message: string, options?: any) {
    return this.present(message, "danger", options);
  }

  public async present(msg: any, color: string, options: any = {}) {
    try {
      if (!msg) {
        return;
      }
      let message = msg;
      if (typeof msg === "object") {
        if (msg.message) {
          message = msg.message;
        } else if (msg.error || msg.Error || msg.Err) {
          message = msg.error || msg.Error || msg.Err;
        } else {
          message = JSON.stringify(msg);
        }
      }
      if (typeof message === "string" && message.length <= 2) {
        return;
      }
      const toastOptions = Object.assign(
        Object.assign({}, this.DEFAULT_OPTIONS),
        { message, color },
        options,
      );
      const toast = await this.toastController.create(toastOptions);
      await toast.present();
    } catch (e) {
      console.log("error in ToastService", e);
    }
  }
}
