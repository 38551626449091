import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-created-hint",
  templateUrl: "./created-hint.component.html",
  styleUrls: ["./created-hint.component.scss"],
})
export class CreatedHintComponent implements OnInit {
  @Input() Name: string;
  @Input() CreatedAt: Date;
  @Input() UpdatedAt: Date;
  chosenLanguage
  constructor(
    private translateService: TranslateService
  ) {
    this.chosenLanguage = this.translateService.currentLang || localStorage.getItem('chosenLanguage') || 'de'
  }

  ngOnInit() { }
}
