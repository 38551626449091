import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { ExportService } from "./export.service";
import { AbstractTimeslotElementDetailXlsxExportService } from "./abstract-timeslot-element-detail-xlsx-export.service";
import { TranslateService } from "@ngx-translate/core";
import { ArrayService } from "../core/array.service";
import { InventoryService } from "../api/inventory.service";
import { DisplaySettingService } from "../core/display-setting.service";
import { Shift } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class ShiftDetailXlsxExportService extends AbstractTimeslotElementDetailXlsxExportService<Shift> {
  constructor(
    api: ApiService,
    arrayService: ArrayService,
    inventoryService: InventoryService,
    exportService: ExportService,
    translateService: TranslateService,
    settingService: DisplaySettingService,
  ) {
    super(
      api,
      arrayService,
      inventoryService,
      exportService,
      translateService,
      settingService,
    );
  }

  protected getClass(): string {
    return "ShiftDetailXlsxExportService";
  }

  get ApiPath(): string {
    return "shifts";
  }

  get TypeNameTranslated(): string {
    return this.translate("typeName");
  }

  get BaseDataKeyValueMap() {
    const settings: any = {
      Type: (s: Shift) => (s.ShiftType ? s.ShiftType.Name : ""),
      Name: (s: Shift) => s.Name,
      Date: (s: Shift) => this.getDateFormatted(s.StartTime),
      StartTime: (s: Shift) => this.getTimeFormatted(s.StartTime),
      EndTime: (s: Shift) => this.getTimeFormatted(s.EndTime),
      Duration: (s: Shift) => this.getDuration(s.StartTime, s.EndTime) + " min",
      Description: (s: Shift) => s.Description,
    };
    if (this.showInventory) {
      settings.Inventory = (s: Shift) =>
        (s.Inventories || []).map((i) => i.Name).join(", ");
    }
    return settings;
  }
}
