import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlatformStorageService {
  private updateSubject = new Subject<void>();

  constructor(private storageService: StorageService) {}

  public get Update(): Observable<void> {
    return this.updateSubject.asObservable();
  }

  public getIsTabled(): boolean {
    const value = this.storageService.get("is-tablet");
    return value ? JSON.parse(value) : false;
  }

  public setIsTabled(flag: boolean) {
    const previous = this.getIsTabled();
    this.storageService.set("is-tablet", String(flag));
    if (flag !== previous) {
      this.updateSubject.next();
    }
  }

  public getIsDesktop(): boolean {
    const value = this.storageService.get("is-desktop");
    return value ? JSON.parse(value) : false;
  }

  public setIsDesktop(flag: boolean) {
    this.storageService.set("is-desktop", String(flag));
  }

  public setVersion(version: string) {
    const previous = this.getVersion();
    this.storageService.set("version", version);
    if (version !== previous) {
      this.updateSubject.next();
    }
  }

  public getVersion(): string {
    return this.storageService.get("version");
  }
}
