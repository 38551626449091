import { Component, OnInit } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-payment-sidebar",
  templateUrl: "./payment-sidebar.component.html",
  styleUrls: ["./payment-sidebar.component.scss"],
})
export class PaymentSidebarComponent implements OnInit {
  ExpirationDate = "20-07-2020";
  Countdown: number;

  constructor() {}

  ngOnInit() {
    this.Countdown = moment
      .duration(moment(this.ExpirationDate, "DD-MM-YYYY").diff(moment()))
      .asDays();
  }
}
