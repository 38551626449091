import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "src/app/services/api/api.service";
import * as moment from "moment";
import { EmploymentTime } from "src/app/interfaces";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-employment-time-selection-modal",
  templateUrl: "./employment-time-selection-modal.component.html",
  styleUrls: ["./employment-time-selection-modal.component.scss"]
})
export class EmploymentTimeSelectionModalComponent implements OnInit {
  @Input() set WorkingDay(value: boolean | EmploymentTime[]) {
    if(typeof value == 'boolean') {
      this.workingDay = [];
    } else {
      this.workingDay = value;
    }
  }
  workingDay: EmploymentTime[] = [];
  times: EmploymentTime[] = [];

  constructor(private api: ApiService, private modalController: ModalController) {}

  async ngOnInit() {
    this.generateCompanyWorkTimes();
  }

  async generateCompanyWorkTimes() {
    const Company = await this.api.get("company");
    const start = moment().set({ hour: Company.WorkHoursStart }).startOf("hour");
    const end = moment().set({ hour: Company.WorkHoursEnd }).startOf("hour");
    while (start.isBefore(end)) {
      let Start = start.format("HH:mm");
      let End = start.add(15, "minutes").format("HH:mm");
      this.times.push({ Start, End, Active: this.findMatchingTime(End) });
    }
  }

  findMatchingTime(end: string): boolean {
    let active = false;
    for(let entry of this.workingDay) {
      if(entry.Start && entry.End) {
        if(moment(entry.Start, 'HH:mm').isBefore(moment(end, 'HH:mm')) && !moment(entry.End, 'HH:mm').isBefore(moment(end, 'HH:mm'))) {
          active = true;
          break;
        }
      }
    }
    return active;
  } 

  async closeModal(save: boolean = false) {
    let newTimes = [];
    let Start = null;
    let End = null;
    for (let i = 0; i < this.times.length; i++) {
      let time = this.times[i];
      if (!time.Active) {
        if (i == this.times.length - 1) {
          if(Start && End) {
            newTimes.push({ Start, End });
          }
        }
        continue;
      }
      if (i == 0) {
        Start = time.Start;
        End = time.End;
      }
      if (i == this.times.length - 1) {
        End = time.End;
        if(Start && End) {
          newTimes.push({ Start, End });
        }
      } else if (i != 0) {
        if (time.Start == End) {
          End = time.End;
        } else {
          if(Start && End) {
            newTimes.push({ Start, End });
          }
          Start = time.Start;
          End = time.End;
        }
      }
    }
    await this.modalController.dismiss(save ? newTimes : null);
  }
}
