import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SocketService } from "src/app/services/socket/socket.service";
import { ActionSheetController } from "@ionic/angular";
import { User } from "../../interfaces";
import { Subscription } from "rxjs";

@Component({
  selector: "app-socket-bar",
  templateUrl: "./socket-bar.component.html",
  styleUrls: ["./socket-bar.component.scss"],
})
export class SocketBarComponent implements OnInit, OnDestroy {
  @Input() Show: boolean = false;

  users: User[] = [];
  visitedMap: {
    Block?: Boolean;
    Model?: String;
    Name?: String;
    ObjectId?: String;
  } = {};
  colorName = "secondary";
  socketActive = false;
  show: boolean = false;
  visitSubscription: Subscription;

  constructor(
    private socket: SocketService,
    private actionSheetCtrl: ActionSheetController,
  ) {}

  ngOnInit(): void {
    this.visitSubscription = this.socket.Visit.subscribe((param) => {
      this.visitedMap = param.VisitObject;
      this.users = param.UserList;
      if (this.users?.length > 1) {
        if (this.visitedMap?.Block) {
          this.colorName = "danger";
        } else {
          this.colorName = "secondary";
        }
      } else {
        this.colorName = "secondary";
      }
    });
  }

  ngOnDestroy(): void {
    if (this.visitSubscription) {
      this.visitSubscription.unsubscribe();
    }
  }

  removeBlock() {
    this.socket.removeBlock();
  }

  getInitials(user: User) {
    if (user && user.FirstName && user.LastName) {
      return user.FirstName.charAt(0) + user.LastName.charAt(0);
    } else {
      return "";
    }
  }

  // Dieses actionsheet soll angezeigt werden, wenn ein anderer nutzer auf der seite interagieren möchte.
  // Bei dem nutzer der angefragt wird die seite zu verlassen muss es geöffnet werden. Die seite muss gespeichert und verlassen werden.
  // Nachdem der aktive view gespeichert wurde muss ein signal an den anfragenden nutzer gesendet werden, dass dieser automatisch einen reload des inhaltes bekommt.
  async presentActionSheet(user: User) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: "Konflikt",
      subHeader:
        user.FirstName + " " + user.LastName + " möchte hier weiter arbeiten.",
      buttons: [
        {
          text: "Zulassen",
          role: "destructive",
          data: {
            action: "save",
          },
        },
        {
          text: "Ablehnen",
          data: {},
        },
      ],
    });

    await actionSheet.present();

    const result = await actionSheet.onDidDismiss();
  }
}
