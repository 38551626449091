import { ModalController } from "@ionic/angular";
import { ApiService } from "../../services/api/api.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RemarkService } from "../../services/api/remark.service";
import {
  TimeSlot,
  Qualification,
  Remark,
  User,
  Team,
  TimeslotElement,
} from "../../interfaces";

@Component({
  selector: "app-employee-selection-modal",
  templateUrl: "./employee-selection-modal.component.html",
  styleUrls: ["./employee-selection-modal.component.scss"],
})
export class EmployeeSelectionModalComponent implements OnInit {
  @Input() TimeSlot: TimeSlot = {};
  @Input() Teams: Team[] = [];
  @Input() Item: TimeslotElement = {};
  @Input() Component: string = "WorkOrder";
  @Input() AppliedFilter: boolean = false;

  employees: (User & { SearchField?: string })[] = [];
  filteredEmployees: (User & { SearchField?: string })[] = [];
  qualification: Qualification = {};
  userRemarks: { [UserId: string]: Remark[] } = {};

  loading= false;

  constructor(
    private api: ApiService,
    private modalController: ModalController,
    private remarkService: RemarkService,
  ) {}

  async ngOnInit() {
    if (this.TimeSlot && this.TimeSlot.QualificationId) {
      await this.loadQualification();
    }
    await this.loadEmployees();
    await this.loadRemarks();
  }

  async loadRemarks() {
    this.userRemarks = await this.remarkService.getAllUserRemarks(
      this.employees,
      this.TimeSlot.StartTime,
      this.TimeSlot.EndTime,
    );
  }

  async loadQualification() {
    this.qualification = await this.api.get(
      "qualifications/" + this.TimeSlot.QualificationId,
    );
  }

  async loadEmployees() {
    this.loading = true;
    const timeSlot = JSON.parse(JSON.stringify(this.TimeSlot));
    timeSlot[this.Component + "Id"] = this.Item.Id;
    this.employees = await this.api.post(
      "employees/by-timeslot",
      {
        TimeSlot: timeSlot,
        TeamIds: this.Teams.map((team) => team.Id),
        Item: {
          Id: this.Item.Id,
          StartTime: this.Item.StartTime,
          EndTime: this.Item.EndTime,
          Street: this.Item.Street,
          City: this.Item.City,
          Zip: this.Item.Zip,
          [this.Component + "TypeGroupId"]:
            this.Item &&
            this.Item[this.Component + "Type"] &&
            this.Item[this.Component + "Type"][this.Component + "TypeGroup"]
              ? this.Item[this.Component + "Type"][this.Component + "TypeGroup"]
                  .Id
              : null,
        },
      },
      null,
      this.AppliedFilter ? { Applied: true } : null,
    );
    this.loading = false;
    await this.doLiveFilter();
  }

  async selectEmployee(user: User) {
    if (!user.disabled) {
      await this.modalController.dismiss({
        User: user,
        Remarks: this.userRemarks,
      });
    }
  }

  async closeModal() {
    await this.modalController.dismiss({ Remarks: this.userRemarks });
  }

  async doLiveFilter(lookup?: string) {
    const filter = (lookup || "").trim().toLowerCase();
    if (this.employees) {
      this.filteredEmployees = this.employees.filter((employee) => {
        employee.SearchField = "";

        if (employee.FirstName) {
          employee.SearchField += employee.FirstName + " ";
        }

        if (employee.LastName) {
          employee.SearchField += employee.LastName + " ";
        }
        return this.matchesFilter(filter, employee, "SearchField");
      });
    } else {
      this.filteredEmployees = [];
    }
  }

  matchesFilter(filter: string, employee: User, employeeKey: string): boolean {
    const value = employee[employeeKey];
    return filter == "" || String(value).toLowerCase().includes(filter);
  }

  setRemarks(userId: string, remarks: Remark[]) {
    this.userRemarks[userId] = remarks;
  }
}
