import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ColorPickerStorageService {
  public PresetObservable: Subject<string[]> = new Subject();

  constructor(private storageService: StorageService) {}

  addColorToPreset(color: string): void {
    if (color) {
      let set = this.getPreset();
      if (set.indexOf(color) === -1) {
        set.pop();
        set.unshift(color);
        this.storageService.set("color-picker-preset", JSON.stringify(set));
        this.PresetObservable.next(this.getPreset());
      }
    }
  }

  getPreset(): string[] {
    const value = this.storageService.get("color-picker-preset");
    return value
      ? JSON.parse(value)
      : [
          "#FFFFFF",
          "#C0C0C0",
          "#808080",
          "#0C0C0C",
          "#FF0000",
          "#800000",
          "#FFFF00",
          "#808000",
          "#00FF00",
          "#008000",
          "#00FFFF",
          "#008080",
          "#0000FF",
          "#000080",
          "#FF00FF",
        ];
  }
}
