import { Component, Input, OnInit } from "@angular/core";
import {
  FilledInteractionForm,
  InteractionForm,
  MissingReactions,
} from "../../interfaces";
import { FilledInteractionFormService } from "../../services/api/filled-interaction-form.service";
import * as moment from "moment";
import { saveAs } from "file-saver";

@Component({
  selector: "app-filled-interaction-form-list",
  templateUrl: "./filled-interaction-form-list.component.html",
  styleUrls: ["./filled-interaction-form-list.component.scss"],
})
export class FilledInteractionFormListComponent implements OnInit {
  @Input() set FilledInteractionForms(
    filledInteractionForms: FilledInteractionForm[],
  ) {
    this.filledInteractionForms = filledInteractionForms;
    this.updateInteractionForms();
  }
  @Input() Model: string;
  @Input() ItemId: string;

  filledInteractionForms: FilledInteractionForm[];
  missingReactions: MissingReactions;
  selectedForm: FilledInteractionForm;
  interactionForms: InteractionForm[] = [];
  sending: boolean;
  selectedPDF: string;
  pdfSelectionList: { Name: string; File: string }[] = [];

  constructor(private formService: FilledInteractionFormService) {}

  ngOnInit() {
    this.updateInteractionForms();
  }

  updateInteractionForms() {
    const interactionFormMap = {};
    if (this.Model && this.ItemId) {
      for (const filledForm of this.filledInteractionForms || []) {
        if (!interactionFormMap[filledForm.InteractionFormId]) {
          interactionFormMap[filledForm.InteractionFormId] = {
            Id: filledForm.InteractionFormId,
            Name: filledForm.Name,
            FilledInteractionForms: [],
          };
        }
        interactionFormMap[
          filledForm.InteractionFormId
        ].FilledInteractionForms.push(filledForm);
      }
    }
    this.interactionForms = Object.values(interactionFormMap);
  }

  async downloadPdf(interactionForm: InteractionForm) {
    const { Blob, FileName } = await this.formService.getMergedPdf(
      interactionForm.Id,
      this.Model,
      this.ItemId,
    );
    saveAs(Blob, (FileName || interactionForm.Name) + ".pdf");
  }

  async setSelectedForm(form: FilledInteractionForm) {
    this.missingReactions = null;
    if (form === this.selectedForm) {
      this.selectedForm = null;
      this.selectedPDF = null;
      this.pdfSelectionList = [];
    } else {
      this.selectedForm = form;
      this.selectedPDF = form.File;
      const selectionList = [];
      const selectionListMap = {};
      selectionList.push({ Name: "Original", File: form.File });
      selectionListMap[form.File] = true;
      for (const child of this.selectedForm.Children) {
        if (!selectionListMap[child.File]) {
          selectionList.push({
            Name: moment(child.CreatedAt).format("DD.MM.YYYY - HH:mm"),
            File: child.File,
          });
          selectionListMap[child.File] = true;
        }
      }
      this.pdfSelectionList = selectionList;
      this.missingReactions = await this.formService.getMissingReactions(form);
    }
  }

  setSelectedPdf(pdf: { File: string }) {
    this.selectedPDF = pdf.File;
  }

  async sendReactionReminder(form: FilledInteractionForm) {
    if (this.sending) {
      return;
    }
    this.sending = true;
    await this.formService.sendReactionReminder(form);
    this.sending = false;
  }

  copyFeedback(): string {
    if (!this.selectedForm) {
      return;
    }
    return this.formService.getFeedbackText(this.selectedForm.Reactions);
  }

  async feedbackCopied() {
    return this.formService.presentReactionsCopySuccess();
  }
}
