import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import {
  FilledInteractionForm,
  FilledInteractionFormReaction,
  InteractionForm,
  MissingReactions,
} from "../../interfaces";
import * as moment from "moment";
import { ToastService } from "../core/toast.service";
import { saveAs } from "file-saver";
import { ModalController } from "@ionic/angular";
import { InteractionFormSelectModalComponent } from "../../modals/interaction-form-select-modal/interaction-form-select-modal.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class FilledInteractionFormService {
  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private modalController: ModalController,
    private translate: TranslateService
  ) { }

  async getAll(
    filter: any = null,
    timeFilter: any = null,
  ): Promise<FilledInteractionForm[]> {
    const params: any = {};
    if (filter) {
      params.Filter = JSON.stringify(filter);
    }
    if (timeFilter) {
      params.TimeFilter = JSON.stringify(timeFilter);
    }
    return this.api.get("filled-interaction-forms", params);
  }

  async get(id: string): Promise<FilledInteractionForm> {
    return this.api.get("filled-interaction-forms/" + id);
  }

  async save(
    filledInteractionForm: FilledInteractionForm,
  ): Promise<FilledInteractionForm> {
    return this.api.post(
      "filled-interaction-forms/" + filledInteractionForm.Id,
      filledInteractionForm,
      this.translate.instant('das_formular_wurde_erfolgreich_gespeichert'),
    );
  }

  async destroy(
    filledInteractionForm: FilledInteractionForm,
  ): Promise<FilledInteractionForm> {
    if (!filledInteractionForm.Id) {
      return filledInteractionForm;
    }
    return this.api.delete(
      "filled-interaction-forms/" + filledInteractionForm.Id,
      this.translate.instant('das_formular_wurde_erfolgreich_gelscht'),
    );
  }

  async getMissingReactions(
    filledInteractionForm: FilledInteractionForm,
  ): Promise<MissingReactions> {
    return this.api.get(
      "filled-interaction-forms/reaction/missing/" + filledInteractionForm.Id,
    );
  }

  async sendReactionReminder(
    filledInteractionForm: FilledInteractionForm,
  ): Promise<void> {
    return this.api.post(
      "filled-interaction-forms/reaction/missing/remind/" +
      filledInteractionForm.Id,
      {},
      this.translate.instant('die_erinnerung_wurde_verschickt'),
    );
  }

  async createCopy(
    filledInteractionForm: FilledInteractionForm,
  ): Promise<FilledInteractionForm> {
    return this.api.post(
      "filled-interaction-forms/create-child/" + filledInteractionForm.Id,
      {},
      this.translate.instant('das_formular_wurde_erfolgreich_kopiert'),
    );
  }

  async getPreview(
    filledInteractionForm: FilledInteractionForm,
  ): Promise<Blob> {
    return this.api.postFile(
      "filled-interaction-forms/preview/" + filledInteractionForm.Id,
      filledInteractionForm,
    );
  }

  getFeedbackText(reactions: FilledInteractionFormReaction[]): string {
    let text = "";
    for (const reaction of reactions) {
      let userText =
        (reaction.User
          ? reaction.User
          : (reaction.Employee.FirstName || "") +
          " " +
          (reaction.Employee.LastName || "")) +
        " (" +
        moment(reaction.CreatedAt).format("DD.MM.YYYY HH:mm") +
        "): " +
        (reaction.Data.Accept ? this.translate.instant('akzeptiert') : this.translate.instant('abgelehnt')) +
        "\n" +
        (reaction.Data.Note ? this.translate.instant('notiz') + ": " + reaction.Data.Note + "\n" : "");
      text += userText + "\n";
    }
    return text;
  }

  async presentReactionsCopySuccess() {
    return this.toastService.presentSuccess(
      this.translate.instant('die_reaktionen_wurden_in_die'),
    );
  }

  async getMergedPdf(
    interactionFormId: string,
    model: string,
    id: string,
  ): Promise<{ Blob: Blob; FileName: string }> {
    const [fileName, blob] = await Promise.all([
      this.api.get(
        `filled-interaction-forms/create-pdf/file-name/${interactionFormId}/${id}`,
      ),
      this.api.postFile(
        `filled-interaction-forms/create-pdf/${interactionFormId}`,
        { [model + "Id"]: id },
      ),
    ]);
    return {
      FileName: fileName,
      Blob: blob,
    };
  }

  async saveWorkOrderMergedPdfByTime(start: any, end: any) {
    return this.saveMergedPdfByTime(start, end, { WorkOrder: true });
  }

  async saveShiftMergedPdfByTime(start: any, end: any) {
    return this.saveMergedPdfByTime(start, end, { Shift: true });
  }

  async saveMergedPdfByTime(start: any, end: any, queryParams: any = {}) {
    const startTime = moment(start).format("YYYY-MM-DD");
    const endTime = moment(end).format("YYYY-MM-DD");
    const interactionForms = await this.getInteractionFormsByTime(
      startTime,
      endTime,
      queryParams,
    );
    let selectedInteractionFormId = null;
    if (interactionForms?.length > 1) {
      const modal = await this.modalController.create({
        component: InteractionFormSelectModalComponent,
        componentProps: {
          InteractionForms: interactionForms,
        },
      });
      await modal.present();
      const response = await modal.onDidDismiss();
      if (response.data) {
        if (response.data.Dismiss) {
          return;
        } else {
          selectedInteractionFormId = response.data.Id;
        }
      }
    }
    const formName = selectedInteractionFormId
      ? interactionForms.find(
        (interactionForm) => interactionForm.Id === selectedInteractionFormId,
      )?.Name
      : null;
    let fileName = startTime;
    if (startTime !== endTime) {
      fileName += "_" + endTime;
    }
    if (formName) {
      fileName += "_" + formName;
    }
    const params = Object.assign(
      {},
      queryParams,
      selectedInteractionFormId
        ? { InteractionFormId: selectedInteractionFormId }
        : {},
    );
    const blob = await this.api.postFile(
      `filled-interaction-forms/create-pdf/by-time/${startTime}/${endTime}`,
      {},
      params,
    );
    if (blob && blob.size > 0) {
      saveAs(blob, fileName + ".pdf");
    } else {
      return this.toastService.presentWarning(
        this.translate.instant('es_gibt_keine_ausgefllten_formulare'),
      );
    }
  }

  async getInteractionFormsByTime(
    startTime: string,
    endTime: string,
    queryParams: any = {},
  ): Promise<InteractionForm[]> {
    return this.api.get(
      `filled-interaction-forms/create-pdf/by-time/interaction-forms/${startTime}/${endTime}`,
      queryParams,
    );
  }
}
