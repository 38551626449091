import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { StorageService } from "../storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class TeamFilterService {
  subject = new Subject<void>();

  constructor(private storageService: StorageService) {}

  get Filter(): Observable<void> {
    return this.subject.asObservable();
  }

  setFilter(teamIds: string[]) {
    this.storageService.setTeamFilter(teamIds);
    this.subject.next();
  }

  getFilter(): string[] {
    return this.storageService.getTeamFilter();
  }

  getParams() {
    const ids = this.getFilter();
    if (ids && ids.length > 0) {
      return { Teams: ids.join(",") };
    }
    return {};
  }
}
