import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class StateHolidaysService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
  ) {}

  currentYear = new Date().getFullYear();
  GermanStateHolidays: any;
  GermanStates = [
    { name: "Baden-Württemberg", code: "BW" },
    { name: "Bayern", code: "BY" },
    { name: "Berlin", code: "BE" },
    { name: "Brandenburg", code: "BB" },
    { name: "Bremen", code: "HB" },
    { name: "Hamburg", code: "HH" },
    { name: "Hessen", code: "HE" },
    { name: "Mecklenburg-Vorpommern", code: "MV" },
    { name: "Niedersachsen", code: "NI" },
    { name: "Nordrhein-Westfalen", code: "NW" },
    { name: "Rheinland-Pfalz", code: "RP" },
    { name: "Saarland", code: "SL" },
    { name: "Sachsen", code: "SN" },
    { name: "Sachsen-Anhalt", code: "ST" },
    { name: "Schleswig-Holstein", code: "SH" },
    { name: "Thüringen", code: "TH" },
  ];

  getGermanStateHolidays(state) {
    // TODO hier muss auch ein cache her!!
    return this.http.jsonp(
      "https://feiertage-api.de/api/?jahr=" +
        this.currentYear +
        "&nur_land=" +
        state,
      "test123",
    );
  }
}
