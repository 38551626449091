import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "../../../services/api/api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import validator from "validator";
import { ArrayService } from "../../../services/core/array.service";
import { WorkOrderTypeService } from "../../../services/api/work-order-type.service";
import { ShiftTypeService } from "../../../services/api/shift-type.service";
import * as moment from "moment";
import {
  Log,
  WorkOrderType,
  ShiftType,
  Qualification,
} from "../../../interfaces";

@Component({
  selector: "app-crud-bar-log",
  templateUrl: "./crud-bar-log.component.html",
  styleUrls: ["./crud-bar-log.component.scss"],
})
export class CrudBarLogComponent implements OnInit {
  @Input() ItemId: string;
  @Input() Disabled: boolean;

  loading = false;
  logs: Log[] = [];
  qualifications: Qualification[];
  idsMap: { [id: string]: ShiftType | WorkOrderType | Qualification } = {};
  workOrderTypes: WorkOrderType[];
  shiftTypes: ShiftType[];

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private arrayService: ArrayService,
    private workOrderTypeService: WorkOrderTypeService,
    private shiftTypeService: ShiftTypeService,
  ) {}

  ngOnInit() {}

  async openModal(ModalRef) {
    if (!this.ItemId) {
      return;
    }
    this.loading = true;
    this.modalService.open(ModalRef, {
      windowClass: "modal-animated log-modal",
      centered: true,
    });
    this.logs = await this.api.get("logs/items/" + this.ItemId + "/");
    this.qualifications = await this.api.get("qualifications");
    this.workOrderTypes = await this.workOrderTypeService.getAll();
    this.shiftTypes = await this.shiftTypeService.getAll();
    const types = this.qualifications
      .concat(this.workOrderTypes)
      .concat(this.shiftTypes);
    this.idsMap = this.arrayService.toMap(types);
    this.loading = false;
  }

  async closeModal(modalRef) {
    modalRef.close();
  }

  parseStringIds(ids: string) {
    try {
      if (ids.indexOf("GMT") !== -1 || ids.indexOf(".000Z") !== -1) {
        //parse utc to local format
        const parsed = moment(ids);
        ids = parsed.isValid() ? parsed.format("YYYY-MM-DD HH:mm") : ids;
      } else if (this.idsMap[ids]) {
        ids = this.idsMap[ids].Name || "Unbenannt";
      } else {
        //versuch zum object zu parsen
        ids = JSON.parse(ids);
      }
    } catch (err) {}
    return ids;
  }

  mapObjectIds(ids: any[]) {
    //leere arrays
    if (ids.length <= 0) {
      return "[]";
    }
    try {
      //versuch die ids aufzulösen
      return ids
        .map((id) => this.idsMap[id].Name || "Unbenannt")
        .join(", <br>");
    } catch (err) {
      //bei leerem object
      return "{}";
    }
  }

  //lesbare logs
  mapIds(ids: any) {
    try {
      //teilweise kommt es als string aus der db
      if (typeof ids == "string") {
        ids = this.parseStringIds(ids);
      }
      //wenn es vorher zum object geparsed wurde
      if (typeof ids == "object") {
        return this.mapObjectIds(ids);
      } else {
        //sonstige fälle
        if (ids == undefined) {
          return "null";
        } else if (ids.length == 0) {
          return "null";
        } else {
          return ids;
        }
      }
    } catch (err) {
      //wenn alles bricht
      return ids;
    }
  }
}
