import { Injectable } from "@angular/core";
import { WorkBook } from "xlsx";
import { AbstractImportService } from "./abstract-import.service";
import { SkyAutMasterlistImportService } from "./sky-aut-masterlist-import.service";
import { AbstractCustomMasterlistImportService } from "./abstract-custom-masterlist-import.service";
import { SettingsService } from "../api/settings.service";
import { ToastService } from "../core/toast.service";
import { Masterlist, MasterlistImportResult } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class MasterlistImportService extends AbstractImportService<Masterlist[]> {
  services: {
    [key: string]: AbstractCustomMasterlistImportService;
  } = {};
  activeService: AbstractCustomMasterlistImportService = null;

  constructor(
    private skyAutImportService: SkyAutMasterlistImportService,
    private settingService: SettingsService,
    toastService: ToastService,
  ) {
    super(toastService);
    const serviceArray: AbstractCustomMasterlistImportService[] = [
      skyAutImportService,
    ];
    for (const service of serviceArray) {
      this.services[service.getKey()] = service;
    }
  }

  getKeys(): string[] {
    return Object.keys(this.services);
  }
  async setActiveService(service) {
    this.activeService = await this.getService(service);
  }

  async getService(Service?): Promise<AbstractCustomMasterlistImportService> {
    let serviceBySetting;
    if (Service) {
      serviceBySetting = Service
    } else {
      serviceBySetting = await this.settingService.getStringValue(
        "MasterlistImportService",
      );
    }
    return serviceBySetting ? this.services[serviceBySetting] : null;
  }

  public async getPreviewSettings(): Promise<{ Icon: any; Cols: any }> {
    if (this.activeService == null) {
      return { Icon: {}, Cols: {} };
    }
    return this.activeService.getPreviewSettings();
  }

  public async createElements(
    workBook: WorkBook,
  ): Promise<MasterlistImportResult> {
    if (this.activeService == null) {
      await this.toastService.presentError(
        "Keine Importkonfiguration für das Unternehmen gefunden",
      );
      return { sheets: [], rows: {}, values: {} };
    }
    return this.activeService.createElements(workBook);
  }

  public async getSelectAllDefault(): Promise<boolean> {
    if (!this.activeService) {
      return true;
    }
    return this.activeService.getSelectAllDefault();
  }

  public async writeChangeLogFile(): Promise<boolean> {
    if (!this.activeService) {
      return false;
    }
    return this.activeService.writeChangeLogFile();
  }
}
