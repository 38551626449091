import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { ExportService } from "./export.service";
import { AbstractTimeslotElementDetailXlsxExportService } from "./abstract-timeslot-element-detail-xlsx-export.service";
import { TranslateService } from "@ngx-translate/core";
import { ArrayService } from "../core/array.service";
import { InventoryService } from "../api/inventory.service";
import { DisplaySettingService } from "../core/display-setting.service";
import { Shift, WorkOrder } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class WorkOrderDetailXlsxExportService extends AbstractTimeslotElementDetailXlsxExportService<WorkOrder> {
  constructor(
    api: ApiService,
    arrayService: ArrayService,
    inventoryService: InventoryService,
    exportService: ExportService,
    translateService: TranslateService,
    settingService: DisplaySettingService,
  ) {
    super(
      api,
      arrayService,
      inventoryService,
      exportService,
      translateService,
      settingService,
    );
  }

  protected getClass(): string {
    return "WorkOrderDetailXlsxExportService";
  }

  get TypeNameTranslated(): string {
    return this.translate("typeName");
  }

  get ApiPath(): string {
    return "work-orders";
  }

  get BaseDataKeyValueMap() {
    const settings: any = {
      Type: (wo: WorkOrder) => (wo.WorkOrderType ? wo.WorkOrderType.Name : ""),
      Name: (wo: WorkOrder) => wo.Name,
      Street: (wo: WorkOrder) => wo.Street,
      Zip: (wo: WorkOrder) => wo.Zip,
      City: (wo: WorkOrder) => wo.City,
      Date: (wo: WorkOrder) => this.getDateFormatted(wo.StartTime),
      StartTime: (wo: WorkOrder) => this.getTimeFormatted(wo.StartTime),
      EndTime: (wo: WorkOrder) => this.getTimeFormatted(wo.EndTime),
      Duration: (wo: WorkOrder) =>
        this.getDuration(wo.StartTime, wo.EndTime) + " min",
      Description: (wo: WorkOrder) => wo.Description,
    };
    if (this.showInventory) {
      settings.Inventory = (wo: WorkOrder) =>
        (wo.Inventories || []).map((i) => i.Name).join(", ");
    }
    return settings;
  }
}
