import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import * as moment from "moment/moment";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../storage/storage.service";
import { Observable, Subject } from "rxjs";
import {
  HasTimeRange,
  Remark,
  RemarkData,
  RemarkDateMap,
  RemarkSchedule,
  Shift,
  TimeslotElement,
  User,
  WorkOrder,
} from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class RemarkService {
  components: { [key: string]: string };
  private saveSubject: Subject<void> = new Subject<void>();

  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private storageService: StorageService,
  ) { }

  public get Save(): Observable<void> {
    return this.saveSubject.asObservable();
  }

  public emitSave() {
    return this.saveSubject.next();
  }

  getComponentsMap(): { [key: string]: string } {
    if (!this.components) {
      this.components = {
        Absence: this.translate.instant(
          "services.RemarkService.Components.Absence",
        ),
        Inventory: this.translate.instant(
          "services.RemarkService.Components.Inventory",
        ),
        Shift: this.translate.instant(
          "services.RemarkService.Components.Shift",
        ),
        TimeSlot: this.translate.instant(
          "services.RemarkService.Components.TimeSlot",
        ),
        User: this.translate.instant(
          "services.RemarkService.Components.User",
        ),
        WorkOrder: this.translate.instant(
          "services.RemarkService.Components.WorkOrder",
        ),
        WorkTime: this.translate.instant(
          "services.RemarkService.Components.WorkTime",
        ),
        Null: this.translate.instant(
          "services.RemarkService.Components.Null",
        ),
      };
    }
    return this.components;
  }

  getComponents(): { Name: string; Translation: string }[] {
    const componentsMap = this.getComponentsMap();
    return Object.keys(componentsMap).map((key) => {
      return { Name: key, Translation: componentsMap[key] };
    });
  }

  async createDate(
    remark: Remark,
    date: string | Date | moment.Moment,
  ): Promise<RemarkDateMap> {
    if (!remark.Id) {
      return;
    }
    return this.api.post("remarks/dates/" + remark.Id, { Date: date });
  }

  async deleteDate(date: RemarkDateMap): Promise<RemarkDateMap> {
    if (!date.Id) {
      return;
    }
    return this.api.delete("remarks/dates/" + date.Id);
  }

  async getAllDates(remarkId: string): Promise<RemarkDateMap[]> {
    return this.api.get("remarks/dates/" + remarkId);
  }

  async destroy(remark: Remark): Promise<boolean> {
    if (!remark || !remark.Id) {
      return true;
    }
    return this.api.delete("remarks/" + remark.Id);
  }

  async saveProcessed(remark: Remark): Promise<void> {
    if (!remark?.Id) {
      return;
    }
    return this.api.save(
      "remarks/processed/" + remark.Id,
      remark,
      this.translate.instant('der_bearbeitetstatus_der_notiz_wurde'),
    );
  }

  async saveOrCreate(remark: Remark): Promise<Remark> {
    if (!remark) {
      return null;
    }
    return this.api.post(
      "remarks" + (remark.Id ? "/" + remark.Id : ""),
      remark,
    );
  }

  async getAllByItemId(
    id: string,
    date?: string,
    start?: string,
    end?: string,
  ): Promise<Remark[]> {
    let query: any = {};
    if (date) {
      query.Date = date;
    }
    if (start) {
      query.Start = start;
    }
    if (end) {
      query.End = end;
    }
    return this.api.get("remarks/" + id, query);
  }

  async getCountByItemId(id: string): Promise<number> {
    return this.api.get("remarks/count/" + id);
  }

  async getComponent(component: string, itemId: string): Promise<any> {
    return this.api.get("remarks/component/" + component + "/" + itemId);
  }

  async getAllUserRemarks(
    users: User[],
    startTime: any,
    endTime: any,
  ): Promise<{ [UserId: string]: Remark[] }> {
    const usersIds = users.map((u) => u.Id);
    const start = moment(startTime).startOf("d").format("YYYY-MM-DD");
    const end = moment(endTime).format("YYYY-MM-DD");
    return this.api.post("remarks/component/User/" + start + "/" + end, {
      Ids: usersIds,
    });
  }

  async getAllDayRemarks(date: string): Promise<Remark[]> {
    return this.api.get("remarks/component/Day/" + date);
  }

  async getAllDayRemarksInTime(
    startTime: any,
    endTime: any,
  ): Promise<{ [day: string]: Remark[] }> {
    const start = moment(startTime).startOf("d");
    const end = endTime ? moment(endTime).startOf("d") : null;
    const items = await this.api.get(
      "remarks/component/Day/" +
      start.format("YYYY-MM-DD") +
      "/" +
      end.format("YYYY-MM-DD"),
    );
    for (const day = start; start.isSameOrBefore(end); day.add(1, "d")) {
      const format = day.format("YYYY-MM-DD");
      items[format] = items[format] || [];
    }
    return items;
  }

  async getAllUserRemarksByTimeSlots(
    element: TimeslotElement,
  ): Promise<{ [UserId: string]: Remark[] }> {
    const users = element.TimeSlots.filter((ts) => ts.User || ts.UserId).map(
      (ts) => ts.UserId || ts.User.Id,
    );
    const start = moment(element.StartTime).startOf("d").format("YYYY-MM-DD");
    const end = moment(element.EndTime).format("YYYY-MM-DD");
    return this.api.post("remarks/component/User/" + start + "/" + end, {
      Ids: users,
    });
  }

  async getAllByTime(start: any, end: any): Promise<RemarkSchedule> {
    const startTime = moment(start).format("YYYY-MM-DD");
    const endTime = moment(end).format("YYYY-MM-DD");
    const componentFilter = this.getComponentFilter();
    const originFilter = this.getOriginFilter();
    const processedFilter = this.getProcessedFilter();
    const params: any = {};
    if (componentFilter) {
      params.Component = componentFilter;
    }
    if (originFilter) {
      params.Origin = originFilter;
    }
    if (processedFilter) {
      params.Processed = processedFilter;
    }

    let schedule = await this.api.get(
      "remarks/by-time/" + startTime + "/" + endTime,
      params,
    );
    schedule.ProcessedCount = this.getProcessedRemarksCount(
      schedule.Remarks[Object.keys(schedule.Remarks)[0]],
    );
    this.prepareSchedule(schedule);
    return schedule;
  }

  private prepareSchedule(schedule: RemarkSchedule) {
    if (!schedule) {
      return;
    }
    for (const date of schedule?.Dates || []) {
      for (const remark of schedule.Remarks[date] || []) {
        if (!remark.Creator) {
          remark.Creator = { FirstName: this.translate.instant('gelschter'), LastName: this.translate.instant('benutzer') };
        }
        remark.Data = this.getData(remark);
      }
    }
  }

  getProcessedRemarksCount(remarks: Remark[]): number {
    let processedCount = 0;
    if (remarks && remarks.length > 0) {
      for (const remark of remarks) {
        if (remark.Processed) {
          processedCount++;
        }
      }
    }
    return processedCount;
  }

  getPathByComponent(component: string): string {
    if (component === "Absence") {
      return "/absences";
    } else if (component === "User") {
      return "/employees";
    } else if (component === "TimeSlot") {
      return "/time-slots";
    } else if (component === "WorkTime") {
      return "/work-times";
    } else if (component === "WorkOrder") {
      return "/work-orders";
    } else if (component === "Shift") {
      return "/shifts";
    } else if (component === "Inventory") {
      return "/inventory";
    }
    return null;
  }

  getData(remark: Remark): RemarkData {
    const data = {
      Url: null,
      Icon: null,
      Title: null,
      User: null,
      Time: null,
    };
    let hint = "";

    if (remark.Absence) {
      data.Icon = "fa fa-bed";
      data.User = remark.Absence.User;
      data.Title = remark.Absence.AbsenceType?.Name;
      data.Time = this.getDateRangeText(remark.Absence);
    } else if (remark.User) {
      data.Icon = "fa fa-user";
      data.User = remark.User;
    } else if (remark.TimeSlot) {
      const name = remark.TimeSlot.WorkOrder
        ? this.getWorkOrderName(remark.TimeSlot.WorkOrder)
        : this.getShiftName(remark.TimeSlot.Shift);
      data.Icon = "far fa-calendar-check";
      data.User = remark.TimeSlot.User;
      data.Title =
        (this.getComponentsMap()[remark.Component] || "Zeit-Slot") +
        " (" +
        name +
        ")";
      data.Time = this.getDateRangeText(remark.TimeSlot);
    } else if (remark.WorkTime) {
      data.Icon = "fa fa-calendar";
      data.User = remark.WorkTime.User;
      data.Time = this.getDateRangeText(remark.WorkTime || remark.WorkTime);
    } else if (remark.WorkOrder) {
      data.Icon = "fa fa-calendar-day";
      data.Title = this.getWorkOrderName(remark.WorkOrder);
      data.Time = this.getDateRangeText(remark.WorkOrder);
    } else if (remark.Shift) {
      data.Icon = "fa fa-calendar";
      data.Title = this.getShiftName(remark.Shift);
      data.Time = this.getDateRangeText(remark.Shift);
    } else if (remark.Inventory) {
      data.Icon = "fas fa-boxes";
      data.Title = remark.Inventory.Name;
    } else {
      hint = " (" + this.translate.instant('gelscht') + ")";
    }

    data.Url = this.getPathByComponent(remark.Component);

    if (!remark.Component) {
      data.Icon = "fas fa-unlink";
    }
    data.Title = data.Title || this.getComponentsMap()[remark.Component];
    if (data.Title) {
      data.Title += hint;
    }
    return data;
  }

  getWorkOrderName(workOrder: WorkOrder) {
    return workOrder.Name || workOrder.WorkOrderType?.Name;
  }

  getShiftName(shift: Shift) {
    return shift.Name || shift.ShiftType?.Name;
  }

  getDateRangeText(obj: HasTimeRange) {
    const start = this.datePipe.transform(obj.StartTime, "dd.MM.YYYY");
    const end = this.datePipe.transform(obj.EndTime, "dd.MM.YYYY");
    return obj.StartTime && obj.EndTime ? start + " - " + end : start || end;
  }

  setComponentFilter(name: string) {
    this.storageService.setRemarkComponentFilter(name);
  }
  setProcessedFilter(status: string) {
    this.storageService.setRemarkProcessedFilter(status);
  }

  getComponentFilter() {
    return this.storageService.getRemarkComponentFilter();
  }

  setOriginFilter(name: string) {
    this.storageService.setRemarkOriginFilter(name);
  }

  getOriginFilter() {
    return this.storageService.getRemarkOriginFilter();
  }
  getProcessedFilter() {
    return this.storageService.getRemarkProcessedFilter();
  }
}
