import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { EvaluationService } from "../../services/api/evaluation.service";
import { SpecialFieldType } from "../../enums";
import { Qualification, SpecialField } from "../../interfaces";

@Component({
  selector: "app-special-fields",
  templateUrl: "./special-fields.component.html",
  styleUrls: ["./special-fields.component.scss"],
})
export class SpecialFieldsComponent implements OnInit {
  @Input() Id: string;

  @Input() set SpecialFields(fields: any) {
    if (
      this.evaluation &&
      this.evaluation.key &&
      !fields[this.evaluation.key]
    ) {
      fields[this.evaluation.key] = this.evaluation.element;
    }
    this.specialFields = Object.assign({}, fields || {});
  }

  get SpecialFields(): any {
    return this.specialFields;
  }

  @Output() SpecialFieldsChange = new EventEmitter();
  @Input() Component = "";

  SpecialFieldTypes = SpecialFieldType;
  defaultSpecialFields: SpecialField[] = this.api.Cache(
    "special-fields/component/" + this.Component,
    []
  );
  specialFields: any = {};
  hasSpecialItems = false;
  qualificationsLoaded: boolean = false;
  qualifications: Qualification[] = [];
  categories: string[];
  evaluation: any = {
    element: {},
    key: null,
  };

  constructor(
    private api: ApiService,
    private evaluationService: EvaluationService
  ) {}

  async ngOnInit() {
    const defaultSpecialFields =
      (await this.api.get("special-fields/component/" + this.Component)) || [];
    this.specialFields = this.specialFields || {};
    const Categories = {};
    let emptyCategory = false;
    for(let SpecialField of defaultSpecialFields) {
      if (SpecialField.Category) {
        Categories[SpecialField.Category] = true;
      } else {
        emptyCategory = true;
      }
    }
    this.categories = Object.keys(Categories).sort();
    if(emptyCategory) {
      this.categories.push("");
    }
    await this.loadQualifications();
    let hasChanges = false;
    for (const s of defaultSpecialFields) {
      s.Field.Value = s.Field.Value || "";
      s.Field.Suffix = s.Field.Suffix || "";

      if (s.Field.Object && s.Type === "Evaluation") {
        let element = this.specialFields[s.Key];
        if (!element) {
          this.specialFields[s.Key] = {};
          element = this.specialFields[s.Key];
        }
        if (!element.hasOwnProperty("Evaluators")) {
          element.Evaluators = s.Field.Object.Evaluators
            ? [...s.Field.Object.Evaluators]
            : [];
          hasChanges = true;
        }
        if (!element.hasOwnProperty("Evaluated")) {
          element.Evaluated = s.Field.Object.Evaluated
            ? [...s.Field.Object.Evaluated]
            : [];
          hasChanges = true;
        }
        if (!element.hasOwnProperty("Duration")) {
          element.Duration = s.Field.Object.Duration;
          hasChanges = true;
        }
        this.evaluation.element = element;
        this.evaluation.key = s.Key;
      }
    }
    if (hasChanges) {
      this.emitChanges();
    }
    this.defaultSpecialFields = defaultSpecialFields;
    this.hasSpecialItems = this.defaultSpecialFields.length > 0;
  }

  async loadQualifications() {
    if (this.qualificationsLoaded) {
      return;
    }
    if (await this.evaluationService.getEvaluationIsVisible()) {
      this.qualifications = (await this.api.get("qualifications")) || [];
    }
  }

  emitChanges() {
    this.SpecialFieldsChange.emit(this.specialFields);
  }

  openEvaluation() {
    window.open(
      this.evaluationService.getComponentUrl(this.Component, this.Id)
    );
  }

  openLink(field: SpecialField, specialField: any) {
    if (specialField === "" || specialField == null) {
      specialField = field.Field.Value;
    }
    const generatedLink =
      field.Field.Prefix + specialField + field.Field.Suffix;
    if (generatedLink.indexOf("http") >= 0) {
      window.open(generatedLink);
    }
  }
}
