import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { SettingsService } from "./settings.service";
import { StorageService } from "../storage/storage.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class EvaluationService {
  cachedSetting: boolean;

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private settingService: SettingsService,
    private storageService: StorageService,
  ) {}

  async getEvaluationIsVisible(): Promise<boolean> {
    if (this.cachedSetting === undefined || this.cachedSetting == null) {
      this.cachedSetting =
        await this.settingService.getBooleanValue("EmployeeEvaluation");
    }
    return this.cachedSetting;
  }

  getRoute(Params: any = {}): string {
    const apiKey = this.storageService.getApiKey() || "-1";
    const companyId = this.storageService.getCompanyId() || "-1";
    let queryParams = `?x-api-key=${apiKey}&company-id=${companyId}`;
    for (const key in Params) {
      if (Params.hasOwnProperty(key)) {
        queryParams += `&${key}=${Params[key]}`;
      }
    }
    return `${this.authService.serverApi}evaluations/open${queryParams}`;
  }

  async evaluationExistsForUser(
    userId: string,
  ): Promise<{ Evaluator?: boolean; Evaluated?: boolean }> {
    if (!(await this.getEvaluationIsVisible())) {
      return { Evaluator: false, Evaluated: false };
    }
    const result = await this.api.get(`evaluations/users/exists/${userId}`);
    return { Evaluator: result.Evaluator > 0, Evaluated: result.Evaluated > 0 };
  }

  getEvaluatorUrl(userId: string) {
    const params = { View: "Evaluator", User: userId };
    return this.getRoute(params);
  }

  getEvaluatedUrl(userId: string) {
    const params = { View: "Evaluated", User: userId };
    return this.getRoute(params);
  }

  getComponentUrl(component: string, id: string) {
    const params = { View: "Result" };
    params[component] = id;
    return this.getRoute(params);
  }
}
