import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { PlatformStorageService } from "../../services/storage/platform-storage.service";

@Component({
  selector: "app-head",
  templateUrl: "./head.component.html",
  styleUrls: ["./head.component.scss"],
})
export class HeadComponent implements OnInit {
  platformSubscription: Subscription;
  isTablet: boolean = true;
  @Input() RouterLink: string = null;

  constructor(private platformStorage: PlatformStorageService) {}

  ngOnInit(): void {
    this.isTablet = this.platformStorage.getIsTabled();
    this.platformSubscription = this.platformStorage.Update.subscribe(
      () => (this.isTablet = this.platformStorage.getIsTabled()),
    );
  }

  ngOnDestroy() {
    if (this.platformSubscription) {
      this.platformSubscription.unsubscribe();
    }
  }
}
