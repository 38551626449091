import { Component, OnInit } from "@angular/core";
import { ScheduleStorageService } from "../../../services/storage/schedule-storage.service";
import { ScheduleStateService } from "../../../services/core/schedule-state.service";

@Component({
  selector: "app-schedule-work-order-view-toggle",
  templateUrl: "./schedule-work-order-view-toggle.component.html",
  styleUrls: ["./schedule-work-order-view-toggle.component.scss"],
})
export class ScheduleWorkOrderViewToggleComponent implements OnInit {
  public LIST: string = this.scheduleStateService.VIEWS.WORK_ORDER_LIST;
  public DAY: string = this.scheduleStateService.VIEWS.WORK_ORDER_DAY;
  public WEEK: string = this.scheduleStateService.VIEWS.WORK_ORDER_WEEK;
  public MONTH: string = this.scheduleStateService.VIEWS.WORK_ORDER_MONTH;

  constructor(private scheduleStateService: ScheduleStateService) {}

  ngOnInit() {}

  async navigate(url: string) {
    return this.scheduleStateService.goToWorkOrderView(url);
  }
}
