import { Component, Input } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-time-display",
  templateUrl: "./time-display.component.html",
  styleUrls: ["./time-display.component.scss"],
})
export class TimeDisplayComponent {
  @Input()
  set StartTime(StartTime: moment.Moment | Date | string | any) {
    if (moment.isMoment(StartTime)) {
      this.StartTimeMoment = StartTime;
    } else if (StartTime) {
      const StartTimeMoment = moment(StartTime);
      if (StartTimeMoment.isValid()) {
        this.StartTimeMoment = StartTimeMoment;
      }
    } else {
      this.StartTimeMoment = null;
    }
  }

  @Input()
  set EndTime(EndTime: moment.Moment | Date | string) {
    if (moment.isMoment(EndTime)) {
      this.EndTimeMoment = EndTime;
    } else if (EndTime) {
      const EndTimeMoment = moment(EndTime);
      if (EndTimeMoment.isValid()) {
        this.EndTimeMoment = EndTimeMoment;
      }
    } else {
      this.EndTimeMoment = null;
    }
  }

  StartTimeMoment: moment.Moment;
  EndTimeMoment: moment.Moment;

  constructor() {}
}
