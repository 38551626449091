import { Injectable } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import * as moment from "moment/moment";
import { InventoryService } from "../../services/api/inventory.service";
import { SettingsService } from "../../services/api/settings.service";
import { SortService } from "../../services/api/../core/sort.service";
import { MasterlistTypeService } from "./masterlist-type.service";
import {
  Inventory,
  MonthMap,
  OpenTimeSlot,
  TimeSlotReaction,
  Masterlist,
  MasterlistType,
} from "../../interfaces";
import { TeamFilterService } from "../../services/filter/team-filter.service";
import { ToastService } from "../../services/core/toast.service";
import { ObjectService } from "../../services/core/object.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class MasterlistService {
  constructor(
    private api: ApiService,
    private inventoryService: InventoryService,
    private settingService: SettingsService,
    private sortService: SortService,
    private masterlistTypeService: MasterlistTypeService,
    private teamFilterService: TeamFilterService,
    private toastService: ToastService,
    private objectService: ObjectService,
    private translate: TranslateService,
  ) { }

  async publishDrafts(time: {
    startDay: string;
    endDay: string;
    Team?: string;
  }): Promise<boolean> {
    return this.api.post("masterlists/draft", time);
  }
  async acceptTimeSlots(time: {
    startDay: string;
    endDay: string;
    Team?: string;
  }): Promise<boolean> {
    return this.api.post("masterlists/accept-timeslots", time);
  }
  async unPublishDrafts(time: {
    startDay: string;
    endDay: string;
    Team?: string;
  }): Promise<boolean> {
    return this.api.post("masterlists/draft/undo", time);
  }
  async resetChanges(time: {
    startDay: string;
    endDay: string;
    Team?: string;
  }): Promise<boolean> {
    return this.api.post("masterlists/changes", time);
  }

  async import(masterlist: Masterlist): Promise<Masterlist> {
    if (!masterlist.ExternalId) {
      return masterlist;
    }
    return this.api.post(
      "masterlists/import/" + masterlist.ExternalId,
      masterlist,
    );
  }

  async importAll(masterlists: Masterlist[]): Promise<boolean> {
    return this.api.post("masterlists/import/", { Masterlists: masterlists });
  }

  async importAllAndDownloadFile(masterlists: Masterlist[]): Promise<Blob> {
    return this.api.postFile("masterlists/import/file", {
      Masterlists: masterlists,
    });
  }

  async destroy(
    masterlist: Masterlist,
    successMessage?: string,
  ): Promise<Masterlist> {
    if (!masterlist.Id) {
      return masterlist;
    }
    return this.api.delete("masterlists/" + masterlist.Id, successMessage);
  }

  async save(
    masterlist: Masterlist,
    successMessage?: string,
  ): Promise<Masterlist> {
    const element: any = masterlist;
    if (element.QualificationIds && Array.isArray(element.QualificationIds)) {
      element.QualificationIds = JSON.stringify(element.QualificationIds);
    }
    if (element.Id) {
      return this.api.post(
        "masterlists/" + element.Id,
        element,
        successMessage,
      );
    }
    return this.api.post("masterlists", element, successMessage);
  }

  async get(id: string): Promise<Masterlist> {
    const masterlist = await this.api.get("masterlists/" + id);
    if (masterlist) {
      if (masterlist.MasterlistTypeId && !masterlist.MasterlistType) {
        masterlist.MasterlistType = await this.masterlistTypeService.get(
          masterlist.MasterlistTypeId,
        );
      }
      let startTime = masterlist.StartTime
        ? moment(masterlist.StartTime)
        : moment().add(2, "h").startOf("hour");
      let endTime = masterlist.EndTime
        ? moment(masterlist.EndTime)
        : moment(startTime).add(
          masterlist.MasterlistType?.EstimatedDuration || 8 * 60,
          "minutes",
        );

      masterlist.StartTime = startTime.toDate();
      masterlist.EndTime = endTime.toDate();
      masterlist.SpecialFields = masterlist.SpecialFields || {};
      masterlist.Tasks = masterlist.Tasks || [];
      masterlist.Triggers = masterlist.Triggers || [];
      masterlist.Inventories = masterlist.Inventories || [];
      if (typeof masterlist.QualificationIds === "string") {
        masterlist.QualificationIds = JSON.parse(masterlist.QualificationIds);
      }
      masterlist.QualificationIds = masterlist.QualificationIds || [];

      this.sortService.sortAscByFields(masterlist.Inventories, "Type", "Name");
    }
    return masterlist;
  }

  async getAll(params?: any, useCache: boolean = false): Promise<Masterlist[]> {
    if (useCache) {
      return this.api.Cache("masterlists", []);
    }
    params = params || {};
    for (const key in params) {
      if (params[key] && typeof params[key] === "object") {
        params[key] = JSON.stringify(params[key]);
      }
    }
    return this.api.get("masterlists", params);
  }

  async getAllOpen(useCache: boolean = false): Promise<Masterlist[]> {
    if (useCache) {
      return this.api.Cache("masterlists/open", []);
    }
    return this.api.get("masterlists/open");
  }

  async getAllByMonth(
    year: string | number,
    month: string | number,
  ): Promise<MonthMap> {
    return await this.api.get(`masterlists/time/${year}/${month}`, {
      DateMap: true,
    });
  }

  async getAllOpenByWeek(
    year: number | string,
    week: number | string,
  ): Promise<{ [key: string]: OpenTimeSlot[] }> {
    const filter = {
      ...this.teamFilterService.getParams(),
    };
    return this.api.get(`masterlists/open/${year}/${week}`, filter);
  }

  async getAllOpenUpcoming(days: number = 10): Promise<Masterlist[]> {
    return this.api.get("masterlists/open/days/" + days);
  }

  async getAllReactions(masterlist: Masterlist): Promise<TimeSlotReaction[]> {
    if (!masterlist.Id) {
      return [];
    }
    return this.api.get("masterlists/" + masterlist.Id + "/reactions");
  }

  async deleteInventory(
    masterlist: Masterlist,
    inventory: Inventory,
  ): Promise<void> {
    await this.api.delete(
      `masterlists/inventory/${masterlist.Id}/${inventory.Id}`,
    );
    await this.updateInventories(masterlist);
  }

  async saveInventory(
    masterlist: Masterlist,
    inventory: Inventory,
  ): Promise<void> {
    await this.api.post("masterlists/inventory", {
      MasterlistId: masterlist.Id,
      InventoryId: inventory.Id,
    });
    await this.updateInventories(masterlist);
  }

  async updateInventories(masterlist: Masterlist): Promise<void> {
    const inventories: Inventory[] = await this.api.get(
      "masterlists/inventory/" + masterlist.Id,
    );
    this.sortService.sortAscByFields(inventories, "Type", "Name");
    masterlist.Inventories = inventories || [];
  }

  async getInventorySelectionList(masterlist: Masterlist): Promise<Inventory[]> {
    return this.api.get("masterlists/inventory/selection-list/" + masterlist.Id);
  }

  async showInventory(): Promise<boolean> {
    return this.inventoryService.show();
  }

  async showExternalApproval(): Promise<boolean> {
    return this.settingService.getBooleanValue("ExternalApproval");
  }


  async sendFormReminder(masterlist: Masterlist): Promise<void> {
    if (masterlist.Draft) {
      return this.toastService.presentWarning(
        this.translate.instant('im_draft_werden_keine_benachrichtigungen'),
      );
    }
    await this.api.post(
      "masterlists/push/trigger/" + masterlist.Id,
      {},
      this.translate.instant('die_ausstehenden_formulare_wurden_versendet'),
    );
  }

  createOrUpdateFromType(
    masterlistType: MasterlistType,
    masterlist: Masterlist = {},
  ): Masterlist {
    masterlist.StartTime =
      masterlist.StartTime || moment().startOf("d").set({ hours: 8 }).toDate();
    masterlist.Triggers = masterlist.Triggers || [];
    masterlist.SpecialFields = masterlist.SpecialFields || {};

    if (masterlistType.MasterlistStart && masterlistType.MasterlistEnd) {
      const shiftStart = moment(masterlistType.MasterlistStart);
      const duration = Math.abs(
        moment(masterlistType.MasterlistEnd).diff(shiftStart, "minutes"),
      );
      const startTime = moment(masterlist.StartTime)
        .startOf("d")
        .set({ hours: shiftStart.hours(), minutes: shiftStart.minutes() });
      const endTime = moment(startTime).add(duration, "minutes");
      masterlist.StartTime = moment(startTime).toDate();
      masterlist.EndTime = moment(endTime).toDate();
    }

    for (const key in masterlistType) {
      if (
        key === "Qualifications" ||
        key === "CreatedAt" ||
        key === "UpdatedAt"
      ) {
        continue;
      }
      if (key === "Id") {
        masterlist.MasterlistTypeId = masterlistType.Id;
        continue;
      }
      if (key === "SpecialFields") {
        for (const specialField in masterlistType[key]) {
          if (this.objectService.isEmpty(masterlist[key][specialField])) {
            masterlist[key][specialField] = masterlistType[key][specialField];
          }
        }
        continue;
      }
      if (key === "Triggers" && masterlist.Triggers.length === 0) {
        for (const trigger of masterlistType.Triggers || []) {
          const copyTrigger = JSON.parse(JSON.stringify(trigger));
          delete copyTrigger.Id;
          delete copyTrigger.MasterlistTypeId;
          masterlist.Triggers.push(copyTrigger);
        }
        continue;
      }
      if (
        key === "EstimatedDuration" &&
        (masterlistType.EstimatedDuration || !masterlist.EndTime)
      ) {
        masterlist.EndTime = moment(masterlist.StartTime)
          .add(masterlistType.EstimatedDuration || 0, "minutes")
          .toDate();
        continue;
      }
      if (this.objectService.isEmpty(masterlist[key])) {
        masterlist[key] = JSON.parse(JSON.stringify(masterlistType[key]));
      }
    }
    return masterlist;
  }

  async copy(source: Masterlist): Promise<Masterlist> {
    const successMessage = this.translate.instant(
      "pages.work_order.work_order_detail.copySuccessMsg",
    );
    const copy: any = JSON.parse(JSON.stringify(source));
    copy.CreatedAt = null;
    copy.DeletedAt = null;
    copy.UpdatedAt = null;
    copy.Id = null;
    copy.Name = copy.Name + " - " + this.translate.instant('kopie');
    if (copy.QualificationIds) {
      copy.QualificationIds = JSON.stringify(copy.QualificationIds);
    }
    copy.Triggers = copy.Triggers.map((trigger) => {
      delete trigger.Id;
      return trigger;
    });
    return this.api.post("masterlists", copy, successMessage);
  }
}
