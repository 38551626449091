import { Injectable } from "@angular/core";
import * as uuid from "uuid";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  subscriptions: any = {};

  constructor() {}

  getKey(previousKey?: string): string {
    this.unsubscribeAll(previousKey);
    return uuid.v4();
  }

  add(key: string, ...subscriptions: Subscription[]): void {
    if (key) {
      this.subscriptions[key] = this.subscriptions[key] || [];
      subscriptions.forEach((sub) => this.subscriptions[key].push(sub));
    } else {
      console.log("SubscriptionKey is missing");
    }
  }

  unsubscribeAll(key: string) {
    if (key && this.subscriptions[key]) {
      this.subscriptions[key].forEach((sub) => sub.unsubscribe());
      delete this.subscriptions[key];
    }
  }
}
