import { Injectable } from "@angular/core";
import { Sheet, WorkBook } from "xlsx";
import { AbstractCustomWorkOrderImportService } from "./abstract-custom-work-order-import.service";
import { ToastService } from "../core/toast.service";
import * as moment from "moment";
import {
  WorkOrder,
  WorkOrderImportResult,
  WorkOrderType,
} from "../../interfaces";
@Injectable({
  providedIn: "root",
})
export class SkyDeLiveSportWorkOrderImportService extends AbstractCustomWorkOrderImportService {
  constructor(toastService: ToastService) {
    super(toastService);
  }

  getKey(): string {
    return "SkyDeLiveSportWorkOrderImportService";
  }

  writeChangeLogFile(): boolean {
    return true;
  }

  private customGetExternalId(
    sheet: Sheet,
    row: number,
    referenceColumn: string,
  ) {
    const reference = this.contentOrNull(sheet, referenceColumn, row) as string;
    return reference || "";
  }

  private customCreateTimesFromDateAndTimeframe(
    sheet: Sheet,
    row: number,
    dateCol: string,
    timeCol: string,
  ): { StartTime: Date; EndTime: Date } {
    const dateString = this.contentOrNull(sheet, dateCol, row);
    const timeString = this.contentOrNull(sheet, timeCol, row);

    if (!dateString || !timeString) {
      return { StartTime: null, EndTime: null };
    }
    const parsedDate = this.customParseDate(dateString);

    if (!parsedDate) {
      return { StartTime: null, EndTime: null };
    }
    const { StartTime, EndTime } = this.customParseTime(parsedDate, timeString);

    return { StartTime, EndTime };
  }

  private customParseDate(date): moment.Moment {
    return moment(date, "DD, dd.MM.YYYY");
  }
  private customParseTime(date, time): { StartTime: Date; EndTime: Date } {
    let start = time.split(" -")[0];
    let end = time.split(" -")[1];

    const StartTime = moment(date);
    const EndTime = moment(date);

    StartTime.set({ hours: start.split(":")[0], minutes: start.split(":")[1] });
    EndTime.set({ hours: end.split(":")[0], minutes: end.split(":")[1] });

    if(EndTime.isBefore(StartTime)) {
      EndTime.add(1, 'day');
    }

    return { StartTime: StartTime.toDate(), EndTime: EndTime.toDate() };
  }

  public async createElements(
    workBook: WorkBook,
  ): Promise<WorkOrderImportResult> {
    if (!workBook || workBook.SheetNames.length === 0) {
      return { sheets: [], rows: {}, values: {} };
    }

    const keys: string[] = [];
    const values: { [key: string]: WorkOrder[] } = {};
    const sheetName = workBook.SheetNames[0];
    const sheet = workBook.Sheets[sheetName];
    const startRow = 2;
    const endRow = this.getRows(sheet);
    const typeColumn = "E";
    const dateColumn = "C";
    const timeColumn = "D";
    const subFeedColumn = "B";
    const nameColumn = "F";
    const referenceColumn = "G";
    for (let i = startRow; i <= endRow; i++) {
      const name = (this.contentOrNull(sheet, nameColumn, i) || "") as string;
      const typeName = (this.contentOrNull(sheet, typeColumn, i) ||
        "") as string;
      const externalId = this.customGetExternalId(sheet, i, referenceColumn);
      const specialFields = {};
      specialFields["Feed"] = (this.contentOrNull(sheet, subFeedColumn, i) ||
        "") as string;
      specialFields["Referenz"] = (this.contentOrNull(
        sheet,
        referenceColumn,
        i,
      ) || "") as string;
      const { StartTime, EndTime } = this.customCreateTimesFromDateAndTimeframe(
        sheet,
        i,
        dateColumn,
        timeColumn,
      );
      if (name && StartTime && EndTime) {
        const workOrder: WorkOrder = {
          StartTime,
          EndTime,
          Name: name,
          ExternalId: externalId,
          SpecialFields: specialFields,
        };
        const workOrderType: WorkOrderType = {
          Name: typeName,
        };
        workOrder.WorkOrderType = workOrderType;
        keys.push(String(i));
        values[sheetName + "-" + i] = [workOrder];
      }
    }
    const hasWorkOrders = Object.keys(values).length > 0;
    return {
      sheets: hasWorkOrders ? [sheetName] : [],
      rows: hasWorkOrders ? { [sheetName]: keys } : {},
      values,
    };
  }

  public getPreviewSettings(): { Icon: any; Cols: any } {
    return {
      Icon: {
        Style: { width: "5%" },
        Show: true,
        Icons: { MissingId: true, MissingType: true, WillBeDeleted: false },
      },
      Cols: {
        Row: {
          Style: { width: "5%" },
          Show: true,
        },
        Date: {
          Style: { width: "10%" },
          Show: true,
        },
        Start: {
          Style: { width: "5%" },
          Show: true,
        },
        End: {
          Style: { width: "5%" },
          Show: true,
        },
        Name: {
          Style: { width: "30%" },
          Show: true,
        },
        Type: {
          Style: { width: "20%" },
          Show: true,
        },
        Feed: {
          Style: { width: "20%" },
          Show: true,
        },
        Ref: {
          Style: { width: "20%" },
          Show: true,
        },
      },
    };
  }
}
