import { Injectable } from "@angular/core";
import { TimeslotType } from "../../enums";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() {}

  public getWorkTimeType(): TimeslotType {
    const value = this.get("work-time-type");
    return value === "WORK_ORDER"
      ? TimeslotType.WORK_ORDER
      : TimeslotType.SHIFT;
  }

  public setWorkTimeType(timeslotType: TimeslotType) {
    this.set("work-time-type", String(timeslotType));
  }

  public getDragAndDropEnabled(): boolean {
    const value = this.get("drag-and-drop");
    return value ? JSON.parse(value) : null;
  }

  public setDragAndDropEnabled(flag: boolean) {
    this.set("drag-and-drop", String(flag));
  }

  public getIsChrome(): boolean {
    const value = this.get("chrome");
    return value ? JSON.parse(value) : null;
  }

  public setIsChrome(flag: boolean) {
    this.set("chrome", String(flag));
  }

  public getWelcomeFinished(): boolean {
    const value = this.get("welcomeModalFinished");
    return value ? JSON.parse(value) : null;
  }

  public setWelcomeFinished(isFinished: boolean) {
    this.set("welcomeModalFinished", String(isFinished));
  }

  public setQualificationFilter(qualificationIds: string[]) {
    this.set("QualificationFilter", JSON.stringify(qualificationIds));
  }

  public getQualificationFilter(): string[] {
    const qualificationIds = this.get("QualificationFilter");
    let result = [];
    try {
      result =
        qualificationIds == null || qualificationIds == "null"
          ? []
          : JSON.parse(qualificationIds);
    } catch (e) {
      result = JSON.parse(JSON.stringify(qualificationIds));
    }
    return Array.isArray(result) ? result : [result];
  }

  public setTeamFilter(teamIds: string[]) {
    this.set("TeamFilter", JSON.stringify(teamIds));
  }

  public getTeamFilter(): string[] {
    const teamIds = this.get("TeamFilter");
    let result = [];
    try {
      result = teamIds == null || teamIds == "null" ? [] : JSON.parse(teamIds);
    } catch (e) {
      result = JSON.parse(JSON.stringify(teamIds));
    }
    return Array.isArray(result) ? result : [result];
  }

  public setRemarkComponentFilter(component: string) {
    this.set("RemarkComponentFilter", component);
  }
  public setRemarkProcessedFilter(status: string) {
    this.set("RemarkProcessedFilter", status);
  }

  public getRemarkProcessedFilter(): string {
    return this.get("RemarkProcessedFilter");
  }
  public getRemarkComponentFilter(): string {
    return this.get("RemarkComponentFilter");
  }

  public setRemarkOriginFilter(origin: string) {
    this.set("RemarkOriginFilter", origin);
  }

  public getRemarkOriginFilter(): string {
    return this.get("RemarkOriginFilter");
  }

  public setMaterialTypeFilter(teamId: string) {
    this.set("material-type-filter", teamId);
  }

  public getMaterialTypeFilter(): string {
    const teamId = this.get("material-type-filter");
    return teamId === "null" ? null : teamId;
  }

  public setEmployeeAbsenceFilter(filter: { [year: string]: boolean }) {
    this.set("employee-absence-filter", JSON.stringify(filter));
  }

  public getEmployeeAbsenceFilter(): { [year: string]: boolean } {
    const filter = this.get("employee-absence-filter");
    return filter ? JSON.parse(filter) : null;
  }

  public setComponentFilter(component: string, filter: any[]) {
    this.set(`filter-${component}`, JSON.stringify(filter));
  }

  public getComponentFilter(component: string): any[] {
    const value = this.get(`filter-${component}`);
    return value ? JSON.parse(value) : [];
  }

  public getComponentHideFilter(component: string): any {
    const value = this.get(`hide-filter-${component}`);
    return value ? JSON.parse(value) : [];
  }

  public setComponentHideFilter(component: string, filter: any) {
    this.set(`hide-filter-${component}`, JSON.stringify(filter));
  }

  public setApiKey(id: string) {
    return this.set("x-api-key", id);
  }

  public getApiKey(): string {
    return this.get("x-api-key");
  }

  public removeApiKey() {
    return this.remove("x-api-key");
  }

  public setCompanyId(id: string) {
    return this.set("company-id", id);
  }

  public getCompanyId(): string {
    return this.get("company-id");
  }
  public getLeasingAgreement(): string {
    return this.get("leasing-agreement");
  }
  public getLeasingAgreementStatus(): string {
    return this.get("leasing-agreement-status");
  }

  public removeCompanyId() {
    return this.remove("company-id");
  }
  public removeLeasingAgreement() {
    return this.remove("leasing-agreement");
  }
  public removeLeasingAgreementStatus() {
    return this.remove("leasing-agreement-status");
  }

  public setLastSplitView(type: string, id: string) {
    return this.set("split-" + type, id);
  }

  public getLastSplitView(type: string): string {
    return this.get("split-" + type);
  }

  public clearSplitViews(): void {
    Object.keys(localStorage)
      .filter((keyName) => keyName.startsWith("split-"))
      .forEach((keyName) => localStorage.removeItem(keyName));
  }

  public setScheduleSideBarContentType(type: string): void {
    this.set("schedule-sidebar-content-type", type);
  }
  public getScheduleSideBarContentType(): string {
    return this.get("schedule-sidebar-content-type");
  }

  public getDetailContentSettings(): Object{
    return this.get('detailContentSetting')
  }

  public setDetailContentSettings(settings: string): void{
    this.set('detailContentSetting', settings )
  }

  get(key: string): any {
    if (!key) {
      return null;
    }
    return localStorage.getItem(key);
  }

  set(key: string, value: string) {
    if (!key) {
      return;
    }
    if (!value) {
      this.remove(key);
    } else {
      localStorage.setItem(key, value);
    }
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  removeAll(keys: string[] = []) {
    keys.forEach((key) => this.remove(key));
  }
}
