import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "hourMin"
})
export class HourMinPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(minutes: number): string {
    let hours = Math.trunc(minutes / 60);
    let mins = Math.round(minutes - hours * 60);
    if (hours < 0) {
      hours = hours * -1;
    }
    if (mins < 0) {
      mins = mins * -1;
    }
    return (minutes < 0 ? '-' : '') + hours + " " + this.translate.instant("hour") + " " + mins + " " + this.translate.instant("min");
  }
}
