import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-searchbar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
})
export class SearchbarComponent implements OnInit {
  @ViewChild("search") search: any;

  @Input() Placeholder: string;
  @Input() Lookup: string;
  @Input() CssClasses = "";
  @Output() Filter = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.search.setFocus();
    }, 500);
  }

  emitSearch() {
    return this.Filter.emit(this.Lookup);
  }
}
