import { Injectable } from "@angular/core";
import * as moment from "moment";
import { SearchFunction } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class ArrayService {
  constructor() {}

  createDateArray(
    startDate: Date,
    length: number,
    lengthUnit: moment.unitOfTime.DurationConstructor,
    stepsUnit: moment.unitOfTime.DurationConstructor = "day",
  ): Date[] {
    const start = moment(startDate).startOf("day");
    const end = moment(startDate).add(length, lengthUnit);
    const dates: Date[] = [];
    for (let date = start; date.isBefore(end); date.add(1, stepsUnit)) {
      dates.push(date.toDate());
    }
    return dates;
  }

  toMap<Type>(anArray: Type[], key: string = "Id"): { [key: string]: Type } {
    if (!Array.isArray(anArray)) {
      return {};
    }
    return (anArray || []).reduce(
      (a, v) => ({ ...a, [key ? v[key] : v]: v }),
      {},
    );
  }

  createSearchFunction(...keys: string[]): SearchFunction {
    return (term: string, item: any) => {
      const termLowercase = term.toLowerCase();
      let matches = false;
      for (const key of keys) {
        if (item.hasOwnProperty(key)) {
          const value = String(item[key] || "").toLowerCase();
          matches = value.indexOf(termLowercase) >= 0;
          if (matches) {
            break;
          }
        }
      }
      return matches;
    };
  }
}
