import { AuthService } from "src/app/services/api/auth.service";
import { LOCALE_ID, NgModule } from "@angular/core";
import localeDe from "@angular/common/locales/de";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { AuthInterceptor } from "./app.auth.interceptor";

import { DatePipe, registerLocaleData } from "@angular/common";
import { SidebarComponent } from "./layouts/sidebar/sidebar.component";
import { PaymentHeadComponent } from "./components/payment-head/payment-head.component";
import { PaymentSidebarComponent } from "./components/payment-sidebar/payment-sidebar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Deeplinks } from "@awesome-cordova-plugins/deeplinks/ngx";
import { ThemeService } from "ng2-charts";
import { AppGuard } from "./app.guard";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslationProviderService } from "./languageResolver";
import { AppComponentModule } from "./app-component.module";
import { PipesModule } from "./pipes/pipes.module";
import { SocketioModule } from "ngx-socketio2";
import { NgxPopperjsModule } from "ngx-popperjs";
import { QuillConfigModule, QuillModule } from 'ngx-quill'

export function TranslationProviderFactory(
  http: HttpClient,
  authService: AuthService,
) {
  return new TranslationProviderService(http, authService);
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    PaymentHeadComponent,
    PaymentSidebarComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    IonicModule.forRoot({
      animated: true,
      hardwareBackButton: true,
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationProviderFactory,
        deps: [HttpClient, AuthService],
      },
    }),
    QuillModule,
    AppComponentModule,
    PipesModule,
    SocketioModule.forRoot({
      url: "https://socket.teamcraft.app/",
    }),
    NgxPopperjsModule,
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic',],        // toggled buttons
          [{ 'list': 'bullet' }, { 'list': 'ordered' },],
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'align': [] }],
        ]
      }
    })
  ],
  providers: [
    ThemeService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: LOCALE_ID, useValue: "de" },
    Deeplinks,
    AppGuard,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, "de");
  }
}
