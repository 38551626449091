import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "app-accept-decline-modal",
  templateUrl: "./accept-decline-modal.component.html",
  styleUrls: ["./accept-decline-modal.component.scss"],
})
export class AcceptDeclineModalComponent implements OnInit {
  @Input() Title: string = "Sind Sie sicher?";
  @Input() Message: string;
  @Input() OkText: string = "Trotzdem speichern";

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {}

  async closeAcceptDeclineModal(saveAnyway: boolean) {
    await this.modalController.dismiss(saveAnyway);
  }
}
