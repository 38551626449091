import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DisplaySettingService } from "../../services/core/display-setting.service";
import { Subscription } from "rxjs";
import { User } from "src/app/interfaces";

@Component({
  selector: "app-user-name",
  templateUrl: "./user-name.component.html",
  styleUrls: ["./user-name.component.scss"],
})
export class UserNameComponent implements OnInit, OnDestroy {
  @Input() User: User;
  subscription: Subscription;
  format: string;

  constructor(private settingService: DisplaySettingService) {}

  ngOnInit() {
    this.format = this.settingService.getUserSetting().format;
    this.subscription = this.settingService.User.subscribe(
      (setting) => (this.format = setting.format),
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
