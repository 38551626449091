import { Injectable } from "@angular/core";
import * as moment from "moment";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root"
})
export class TimeParseService {
  constructor() {}

  decodeTimeString(TimeString = "Day-" + moment().format("YYYY-MM-DD")) {
    const timeArray = (TimeString || "").split("-");
    if (timeArray[0] === "Week") {
      return this.decodeWeek(timeArray);
    } else if (timeArray[0] === "Month") {
      return this.decodeMonth(timeArray);
    } else if (timeArray[0] === "Day") {
      return this.decodeDay(timeArray);
    } else if (timeArray[0] === "None") {
      return {
        Start: null,
        End: null
      };
    }
  }

  encodeTimeString(TimeObject, Mode): string {
    if (Mode === "Week") {
      return this.encodeWeek(TimeObject);
    } else if (Mode === "Month") {
      return this.encodeMonth(TimeObject);
    } else if (Mode === "Day") {
      return this.encodeDay(TimeObject);
    } else if (Mode === "None") {
      return "None";
    }
  }

  getMode(TimeString) {
    return (TimeString || "").split("-")[0];
  }

  private decodeWeek(TimeArray) {
    return {
      Start: moment().year(TimeArray[1]).week(TimeArray[2]).isoWeekday(1).format("YYYY-MM-DD"),
      End: moment().year(TimeArray[1]).week(TimeArray[2]).isoWeekday(7).format("YYYY-MM-DD")
    };
  }

  private encodeWeek(FilterObject) {
    let date = moment(FilterObject.Start);
    if (!date.isValid()) {
      date = moment();
    }
    return "Week-" + date.isoWeekYear() + "-" + date.week();
  }

  private decodeMonth(TimeArray) {
    const timeObject = moment()
      .year(TimeArray[1])
      .month(parseInt(TimeArray[2], 10) - 1);
    return {
      Start: timeObject.startOf("month").format("YYYY-MM-DD"),
      End: timeObject.endOf("month").format("YYYY-MM-DD")
    };
  }

  private encodeMonth(FilterObject) {
    let date = moment(FilterObject.Start);
    if (!date.isValid()) {
      date = moment();
    }
    return "Month-" + date.year() + "-" + (date.month() + 1);
  }

  private decodeDay(TimeArray) {
    const startDay = moment()
      .year(parseInt(TimeArray[1], 10))
      .month(parseInt(TimeArray[2], 10) - 1)
      .date(parseInt(TimeArray[3], 10));
    let endDay = moment(startDay);
    if (TimeArray.length > 4) {
      endDay = moment()
        .year(parseInt(TimeArray[5], 10))
        .month(parseInt(TimeArray[6], 10) - 1)
        .date(parseInt(TimeArray[7], 10));
    }
    return {
      Start: startDay.format("YYYY-MM-DD"),
      End: endDay.format("YYYY-MM-DD")
    };
  }

  private encodeDay(FilterObject) {
    let startDay = moment(FilterObject.Start);
    let endDay = moment(FilterObject.End);
    if (!startDay.isValid()) {
      startDay = moment();
    }
    if (!endDay.isValid()) {
      endDay = startDay;
    }
    let timeString = "Day-" + startDay.format("YYYY-MM-DD");
    if (endDay.diff(startDay) > 0) {
      timeString += "--" + endDay.format("YYYY-MM-DD");
    }
    return timeString;
  }

  decodeNgbDate(TimeObject) {
    return moment()
      .year(TimeObject.year)
      .month(TimeObject.month - 1)
      .date(TimeObject.day)
      .format("YYYY-MM-DD");
  }

  encodeNgbDate(TimeString) {
    const TimeArray = (TimeString || "").split("-");
    return new NgbDate(parseInt(TimeArray[0], 10), parseInt(TimeArray[1], 10), parseInt(TimeArray[2], 10));
  }
}
