import { Component, Input, OnInit } from "@angular/core";
import { RemarkService } from "../../services/api/remark.service";
import { ModalController } from "@ionic/angular";
import { Remark } from "../../interfaces";

@Component({
  selector: "app-remark-modal",
  templateUrl: "./remark-modal.component.html",
  styleUrls: ["./remark-modal.component.scss"],
})
export class RemarkModalComponent implements OnInit {
  @Input() ItemId: string;
  @Input() Component: string;
  @Input() Headline: string;
  @Input() SubHeadline: string;
  @Input() Date: string;
  @Input() Start: string;
  @Input() End: string;
  @Input() ShowDates: boolean = false;

  constructor(
    private remarkService: RemarkService,
    private modalController: ModalController,
  ) {}

  async ngOnInit() {}

  async save() {
    this.remarkService.emitSave();
  }

  async closeModal(remarks: Remark[]) {
    let filteredRemarks = remarks.filter(remark => !remark.Processed);
    await this.modalController.dismiss({ count: filteredRemarks.length, remarks });
  }
}
