import { Component, ViewChild } from "@angular/core";
import { NgbCarousel, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../../services/api/api.service";
import { StateHolidaysService } from "../../services/thirdparty/state-holidays.service";
import { StorageService } from "../../services/storage/storage.service";
import { SettingsService } from "../../services/api/settings.service";
import {
  Company,
  User,
  AbsenceType,
  CompanyLocation,
  EmploymentType,
  Setting,
  Team,
} from "src/app/interfaces";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent {
  @ViewChild("modal") private content;

  WelcomeCompany: Company = {};
  WelcomeUser: User = {};
  AbsenceTypes: AbsenceType[] = [];
  ServerAbsenceTypes: AbsenceType[] = [];
  CompanyLocations: CompanyLocation[] = [];
  EmploymentTypes: EmploymentType[] = [];
  ServerEmploymentTypes: EmploymentType[] = [];
  Employees: User[] = [];
  Settings: Setting[] = [];
  ServerTeams: Team[] = [];
  isLoading = false;

  allAbsenceTypesSelected: boolean;
  allEmploymentTypesSelected: boolean;
  checkedAbsenceTypes: any;
  checkedEmploymentTypes: any;

  NewEmploymentType: EmploymentType = {};
  NewCompanyLocation: CompanyLocation = {};
  NewEmployee: User = {};
  NewAbsenceType: AbsenceType = {};

  DefaultAbsenceTypes = [
    {
      Name: this.translate.instant('krankheit'),
      Description: this.translate.instant('einreichen_eines_attests_im_krankheitsfall'),
      DayMin: 0,
      DayMax: 30,
      Approval: false,
      Obligation: true,
      isSelected: true,
      WorktimeCompensation: true,
    },
    {
      Name: this.translate.instant('urlaub'),
      Description:
        this.translate.instant('urlaubsbeantragung_im_sinne_der_tariflich'),
      DayMin: 0,
      DayMax: 30,
      Approval: true,
      Obligation: false,
      isSelected: true,
      WorktimeCompensation: true,
    },
    {
      Name: this.translate.instant('sonderurlaub'),
      Description:
        this.translate.instant('freistellung_wegen_todesfllen_taufen_hochzeiten'),
      DayMin: 0,
      DayMax: 30,
      Approval: true,
      Obligation: false,
      isSelected: true,
      WorktimeCompensation: true,
    },
    {
      Name: this.translate.instant('schule'),
      Description: this.translate.instant('schulanwesenheit_im_sinne_der_beruflichen'),
      DayMin: 0,
      DayMax: 30,
      Approval: false,
      Obligation: true,
      isSelected: true,
      WorktimeCompensation: false,
    },
    {
      Name: this.translate.instant('universitt'),
      Description:
        this.translate.instant('prsenz_an_der_universitt_im'),
      DayMin: 0,
      DayMax: 30,
      Approval: false,
      Obligation: true,
      isSelected: true,
      WorktimeCompensation: false,
    },
    {
      Name: this.translate.instant('fortbildung'),
      Description: this.translate.instant('berufliche_fortbildung'),
      DayMin: 0,
      DayMax: 30,
      Approval: true,
      Obligation: false,
      isSelected: true,
      WorktimeCompensation: false,
    },
    {
      Name: this.translate.instant('freistellung_kind_krank'),
      Description: this.translate.instant('einreichen_eines_attests_im_krankheitsfall'),
      DayMin: 0,
      DayMax: 30,
      Approval: false,
      Obligation: true,
      isSelected: true,
      WorktimeCompensation: true,
    },
    {
      Name: this.translate.instant('elternzeit'),
      Description:
        this.translate.instant('unbezahlte_freistellung_von_der_arbeit'),
      DayMin: 0,
      DayMax: 30,
      Approval: true,
      Obligation: false,
      isSelected: true,
      WorktimeCompensation: false,
    },
    {
      Name: this.translate.instant('mutterschutz'),
      Description: this.translate.instant('beschftigungsverbot_fr_mtter_vor_und'),
      DayMin: 0,
      DayMax: 30,
      Approval: true,
      Obligation: false,
      isSelected: true,
      WorktimeCompensation: false,
    },
  ];

  DefaultEmploymentTypes = [
    { Name: "Vollzeit", HourMin: 40, HourMax: 40 },
    { Name: "Teilzeit (80%)", HourMin: 32, HourMax: 32 },
    { Name: "Teilzeit (50%)", HourMin: 20, HourMax: 20 },
    { Name: "Ausbildung (volljährig)", HourMin: 0, HourMax: 48 },
    { Name: "Ausbildung (minderjährig)", HourMin: 0, HourMax: 40 },
    { Name: "Minijob", HourMin: 0, HourMax: 13 },
    { Name: "Praktikum", HourMin: 0, HourMax: 40 },
    { Name: "Extern", HourMin: 0, HourMax: 60 },
    { Name: "Freelancer", HourMin: 0, HourMax: 20 },
    { Name: "Studentische Hilfskraft", HourMin: 0, HourMax: 20 },
  ];

  Team: Team = {
    TeamMembers: [],
    TeamManagers: [],
    SpecialFields: {},
  };

  StaticValuesCache: {} = {};

  constructor(
    private modalService: NgbModal,
    config: NgbCarouselConfig,
    private api: ApiService,
    private settingsService: SettingsService,
    public stateHolidaysService: StateHolidaysService,
    private storageService: StorageService,
    private translate: TranslateService
  ) {
    config.interval = 0;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationArrows = false;

    this.getCheckedAbsenceTypesList();
    this.getCheckedEmploymentTypesList();
  }

  async ionViewWillEnter() {
    if (!this.storageService.getWelcomeFinished()) {
      this.WelcomeUser = (await this.api.get("user")) as User;
      this.WelcomeCompany = (await this.api.get("company")) as Company;
      this.ServerAbsenceTypes = (await this.api.get(
        "absence-types",
      )) as AbsenceType[];
      this.ServerEmploymentTypes = (await this.api.get(
        "employment-types",
      )) as EmploymentType[];
      this.ServerTeams = (await this.api.get("teams")) as Team[];
      this.Employees = (await this.api.get("employees")) as User[];
      this.CompanyLocations = (await this.api.get(
        "company-locations",
      )) as CompanyLocation[];
      this.Settings = await this.settingsService.getAll();
      if (
        !this.hasValuesStatic("WelcomeUser", this.WelcomeUser, [
          "FirstName",
          "LastName",
          "Street",
          "City",
          "Phone",
          "Mail",
          "Zip",
        ]) ||
        !this.hasValuesStatic("WelcomeCompany", this.WelcomeCompany, [
          "Name",
          "Street",
          "Zip",
          "City",
          "Phone",
          "Mail",
        ]) ||
        !this.hasValuesStatic("AbsenceTypes", this.ServerAbsenceTypes, ["0"]) ||
        !this.hasValuesStatic("EmploymentTypes", this.ServerEmploymentTypes, [
          "0",
        ]) ||
        // !this.hasValuesStatic('CompanyLocations', this.CompanyLocations, ['0']) ||
        !this.hasValuesStatic("Employees", this.Employees, ["0"])
        // || !this.hasValuesStatic('Settings', this.Settings, ['0'])
      ) {
        this.EmploymentTypes =
          this.ServerEmploymentTypes.length > 0
            ? this.ServerEmploymentTypes
            : this.DefaultEmploymentTypes;
        this.AbsenceTypes =
          this.ServerAbsenceTypes.length > 0
            ? this.ServerAbsenceTypes
            : this.DefaultAbsenceTypes;
        if (this.ServerTeams.length === 0) {
          this.Team = {
            Name: "Allgemein",
            TeamManagers: [],
            TeamMembers: [],
          };
          this.Team.TeamManagers.push(this.WelcomeUser);
          this.Team.TeamMembers.push(this.WelcomeUser);
          await this.api.post(
            "teams" + (this.Team.Id ? "/" + this.Team.Id : ""),
            this.Team,
          );
        }
        this.openWelcomeModal(this.content);
      } else {
        this.storageService.setWelcomeFinished(true);
      }

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.AbsenceTypes.length; i++) {
        this.AbsenceTypes[i].isSelected = true;
      }
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.EmploymentTypes.length; i++) {
        this.EmploymentTypes[i].isSelected = true;
      }
    }
  }

  openWelcomeModal(Modal) {
    this.modalService.open(Modal, {
      windowClass: "modal-animated",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  uncheckAllAbsenceTypes() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.AbsenceTypes.length; i++) {
      this.AbsenceTypes[i].isSelected = this.allAbsenceTypesSelected;
    }
    this.getCheckedAbsenceTypesList();
  }

  areAllAbsenceTypesSelected() {
    this.allAbsenceTypesSelected = this.AbsenceTypes.every((item: any) => {
      return item.isSelected === true;
    });
    this.getCheckedAbsenceTypesList();
  }

  getCheckedAbsenceTypesList() {
    this.checkedAbsenceTypes = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.AbsenceTypes.length; i++) {
      if (this.AbsenceTypes[i].isSelected) {
        this.checkedAbsenceTypes.push(this.AbsenceTypes[i]);
      }
    }
    this.checkedAbsenceTypes = JSON.stringify(this.checkedAbsenceTypes);
  }

  uncheckAllEmploymentTypes() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.EmploymentTypes.length; i++) {
      this.EmploymentTypes[i].isSelected = this.allEmploymentTypesSelected;
    }
    this.getCheckedEmploymentTypesList();
  }

  areAllEmploymentTypesSelected() {
    this.allEmploymentTypesSelected = this.EmploymentTypes.every(
      (item: any) => {
        return item.isSelected === true;
      },
    );
    this.getCheckedEmploymentTypesList();
  }

  getCheckedEmploymentTypesList() {
    this.checkedEmploymentTypes = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.EmploymentTypes.length; i++) {
      if (this.EmploymentTypes[i].isSelected) {
        this.checkedEmploymentTypes.push(this.EmploymentTypes[i]);
      }
    }
    this.checkedEmploymentTypes = JSON.stringify(this.checkedEmploymentTypes);
  }

  addNewAbsenceType() {
    this.NewAbsenceType.isSelected = true;
    this.AbsenceTypes.push(this.NewAbsenceType);
    this.NewAbsenceType = {};
  }

  addNewEmploymentType() {
    this.NewEmploymentType.isSelected = true;
    this.EmploymentTypes.push(this.NewEmploymentType);
    this.NewEmploymentType = {};
  }

  addNewCompanyLocation() {
    this.NewCompanyLocation.isSelected = true;
    this.CompanyLocations.push(this.NewCompanyLocation);
    this.NewCompanyLocation = {};
  }

  deleteCompanyLocation(item) {
    const index: number = this.CompanyLocations.indexOf(item);
    if (index !== -1) {
      this.CompanyLocations.splice(index, 1);
    }
  }

  addNewEmployee(user) {
    this.NewEmployee.isSelected = true;
    this.Employees.push(this.NewEmployee);
    this.NewEmployee = {};
  }

  deleteNewEmployee(user) {
    const index: number = this.Employees.indexOf(user);
    if (index !== -1) {
      this.Employees.splice(index, 1);
    }
  }

  hasValues(Item: any = null, Values: string[] = []): boolean {
    let hasValues = true;
    if (Item) {
      Values.forEach((Value) => {
        if (
          Item[Value] === null ||
          Item[Value] === undefined ||
          Item[Value] === ""
        ) {
          hasValues = false;
        }
      });
    } else {
      hasValues = false;
    }
    return hasValues;
  }

  hasValuesStatic(
    ItemString: string = "",
    Item: any = null,
    Values: string[] = [],
  ): boolean {
    if (Item) {
      if (this.StaticValuesCache[ItemString] === undefined) {
        this.StaticValuesCache[ItemString] = this.hasValues(Item, Values);
      }
    }
    return this.StaticValuesCache[ItemString] || false;
  }

  async saveItem(item: any, path: string, carousel: NgbCarousel = null) {
    if (item && path) {
      try {
        let savedItem;
        if (path === "user") {
          this.isLoading = true;
          savedItem = await this.api.post(path, item);
          this.isLoading = false;
        } else {
          savedItem = await this.api.post(
            path + (item.Id ? "/" + item.Id : ""),
            item,
          );
        }

        item.Id = savedItem.Id;
        if (carousel) {
          carousel.next();
        }
      } catch (error) {
        console.log(error);
        if (carousel) {
          carousel.next();
        }
      }
    }
  }

  async saveItems(items: any, path: string, carousel: NgbCarousel) {
    try {
      if (items && path) {
        for (const item of items) {
          if (item && (!item.isSelected || item.isSelected === true)) {
            this.isLoading = true;
            await this.saveItem(item, path);
          }
        }
        this.isLoading = false;
        carousel.next();
      }
    } catch (error) {
      console.log(error);
      carousel.next();
    }
  }
}
