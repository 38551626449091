import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FileUploadService } from "./file-upload.service";
import { AuthService } from "../../services/api/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "../../services/core/toast.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent {
  @Input() file: string;
  @Input() index: number;
  @Input() placeholder: string;
  @Output() fileChange = new EventEmitter<string>();
  @Output() documentUploaded = new EventEmitter<{file: string, fileName: string}>();

  fileToUpload: File = null;
  processingUpload = false;
  FileIsImage = true;

  dragAndDropHover = false;
  hasFile = false;

  constructor(
    private authService: AuthService,
    private fileUploadService: FileUploadService,
    private toastService: ToastService,
    private modalService: NgbModal,
  ) {
    this.index = this.index || makeid(10);
  }

  async filesDropped(files: File[]) {
    return this.handleFileInput({ target: { files } });
  }

  async handleFileInput(event: any) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      this.fileToUpload = event.target.files.item(0);
      return this.uploadFileToActivity();
    }
  }

  async uploadFileToActivity() {
    this.processingUpload = true;
    this.FileIsImage = true;
    try {
      this.file = await this.fileUploadService.postFile(this.fileToUpload);
      this.fileChange.emit(this.file);
      this.documentUploaded.emit({file: this.file, fileName: this.fileToUpload.name})
      this.processingUpload = false;
      await this.toastService.presentSuccess(
        "Bitte speichern Sie, um die Zuordnung beizubehalten.",
      );
    } catch (e) {
      this.processingUpload = false;
      await this.toastService.presentError(
        "Die Bilddatei konnte nicht hochgeladen werden!",
      );
    }
  }

  removeFile() {
    this.file = null;
    this.FileIsImage = true;
    this.fileChange.emit("");
    this.documentUploaded.emit(null);
  }

  checkIfFileIsImage($event) {
    this.FileIsImage = false;
    $event.error = null;
  }
  openModal(Modal) {
    this.modalService.open(Modal, {
      windowClass: "modal-animated image-modal",
      centered: true,
      keyboard: false,
    });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}

function makeid(length): number {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return parseInt(result, 10);
}
