import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  history: string[] = [];
  excludedRoutes: string[] = ["/setup", "/selection", "/signup"];

  constructor(
    private router: Router,
    private location: Location,
  ) {}

  init() {
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        !this.isExcluded(event.urlAfterRedirects)
      ) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  isExcluded(route: string): boolean {
    if (!route) {
      return false;
    }
    for (const excluded of this.excludedRoutes) {
      if (route.indexOf(excluded) >= 0) {
        return true;
      }
    }
    return false;
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/");
    }
  }
}
