import { Injectable } from "@angular/core";
import { Comparator } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class SortService {
  public TIMESLOT_COMPARATORS = [
    {
      field: "UserId",
      compareFct: (a, b) => {
        if (a && !b) {
          return 1;
        } else if (!a && b) {
          return -1;
        }
        return 0;
      },
      order: 1,
    },
    {
      field: "CreatedAt",
      compareFct: (a, b) => {
        const nameA = "" + (a || "");
        const nameB = "" + (b || "");
        return nameA.localeCompare(nameB);
      },
      order: 2,
    },
  ];
  public USER_TIMESLOT_COMPARATORS: Comparator[] = [
    {
      field: "disabled",
      compareFct: (a, b) => {
        if (a && !b) {
          return 1;
        } else if (!a && b) {
          return -1;
        }
        return 0;
      },
      order: 1,
    },
    {
      field: "distance",
      compareFct: (a: number, b: number) => {
        if ((a && !b) || (a && b && a < b)) {
          return -1;
        } else if ((!a && b) || (a && b && a > b)) {
          return 1;
        }
        return 0;
      },
      order: 2,
    },
    {
      field: "LastName",
      compareFct: (a, b) => {
        const c = (a || "").toLowerCase().trim();
        const d = (b || "").toLowerCase().trim();
        return c.localeCompare(d);
      },
      order: 3,
    },
    {
      field: "FirstName",
      compareFct: (a, b) => {
        const c = (a || "").toLowerCase().trim();
        const d = (b || "").toLowerCase().trim();
        return c.localeCompare(d);
      },
      order: 4,
    },
  ];
  public USER_COMPARATORS: Comparator[] = [
    {
      field: "LastName",
      compareFct: (a, b) => {
        const c = (a || "").toLowerCase().trim();
        const d = (b || "").toLowerCase().trim();
        return c.localeCompare(d);
      },
      order: 1,
    },
    {
      field: "FirstName",
      compareFct: (a, b) => {
        const c = (a || "").toLowerCase().trim();
        const d = (b || "").toLowerCase().trim();
        return c.localeCompare(d);
      },
      order: 2,
    },
  ];

  public USER_COMPARATORS_CUSTOM: Comparator[] = [
    {
      field: "CustomOrder",
      compareFct: (a: number, b: number) => {
        if ((a && !b) || (a && b && a < b)) {
          return -1;
        } else if ((!a && b) || (a && b && a > b)) {
          return 1;
        }
        return 0;
      },
      order: 0,
    },
    ...this.USER_COMPARATORS,
  ];

  public USER_COMPARATORS_DESC: Comparator[] = this.createDesc(
    this.USER_COMPARATORS,
  );
  public USER_COMPARATORS_CUSTOM_DESC: Comparator[] = [
    ...this.USER_COMPARATORS_CUSTOM,
    ...this.USER_COMPARATORS_DESC,
  ];

  constructor() {}

  createDesc(comparators: Comparator[]): Comparator[] {
    const descComparators: Comparator[] = [];
    for (const comparator of comparators) {
      const copyComparator: Comparator = {
        field: comparator.field,
        compareFct: comparator.compareFct,
        raw: comparator.raw,
        order: comparator.order,
        desc: true,
      };
      descComparators.push(copyComparator);
    }
    return descComparators;
  }

  public sortAndFilterComparators(comparators: Comparator[]): Comparator[] {
    const filtered = (comparators || []).filter(
      (comparator) =>
        comparator.order === null ||
        !comparator.hasOwnProperty("order") ||
        comparator.order >= 0,
    );
    filtered.sort((a, b) => (a.order || 0) - (b.order || 0));
    return filtered;
  }

  public sortByComparators(array: any[], ...comparators: Comparator[]) {
    const filteredComparators = this.sortAndFilterComparators(comparators);
    if (
      !array ||
      !Array.isArray(array) ||
      array.length === 0 ||
      filteredComparators.length === 0
    ) {
      return array;
    }
    const defaultComparator = (a, b) => {
      return (a || "").toString().localeCompare((b || "").toString());
    };

    const sortFunction = (a: any, b: any) => {
      let compareValue = 0;
      for (const comparator of filteredComparators) {
        if (!comparator || !comparator.field || comparator.field.length === 0) {
          continue;
        }
        const compareFct = comparator.compareFct || defaultComparator;
        compareValue = compareFct(
          !a || comparator.raw ? a : a[comparator.field],
          !b || comparator.raw ? b : b[comparator.field],
        );
        if (comparator.desc) {
          compareValue *= -1;
        }
        if (compareValue !== 0) {
          break;
        }
      }
      return compareValue;
    };
    array.sort(sortFunction);
    return array;
  }

  public sortAscByFields(array: any[], ...fields: string[]): any[] {
    return this.sortByFields(array, "asc", ...fields);
  }

  public sortDescByFields(array: any[], ...fields: string[]): any[] {
    return this.sortByFields(array, "desc", ...fields);
  }

  public sortByFields(
    array: any[],
    sort: string = "asc",
    ...fields: string[]
  ): any[] {
    if (
      !array ||
      !Array.isArray(array) ||
      array.length === 0 ||
      !fields ||
      fields.length === 0
    ) {
      return array;
    }
    const multiplier = sort === "desc" ? -1 : 1;

    const sortFunction = (a: any, b: any) => {
      let compareValue = 0;
      for (const field of fields) {
        if (!field || field.length === 0) {
          continue;
        }
        const aField = String((a ? a[field] : "") || "")
          .toLowerCase()
          .trim();
        const bField = String((b ? b[field] : "") || "")
          .toLowerCase()
          .trim();
        compareValue = aField.localeCompare(bField);
        if (compareValue !== 0) {
          break;
        }
      }
      return compareValue * multiplier;
    };

    array.sort(sortFunction);
    return array;
  }
}
