import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { View, Map, Feature } from "ol";
import { Control } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import TileLayer from 'ol/layer/Tile';
import { useGeographic } from 'ol/proj';
import XYZ from 'ol/source/XYZ';
import { Company, Inventory } from 'src/app/interfaces';
import { ApiService } from 'src/app/services/api/api.service';
import VectorSource from "ol/source/Vector";
import { Point } from 'ol/geom';
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from 'ol/layer/Vector';
import { Styles } from './styles';
import { SettingsService } from 'src/app/services/api/settings.service';
import { InventoryService } from 'src/app/services/api/inventory.service';


@Component({
  selector: 'app-dashboard-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class DashboardMapComponent implements OnChanges, OnInit {

  @Input() set GridWidth(value: number) {
    this.gridWidth = value;
    // this.folded = value == 1
  }
  @Input() set GridHeight(value: number) {
    this.gridHeight = value;
  }

  @Input() set IsLoading(value: boolean) {
    this.isLoading = value;
  }
  folded = true
  isLoading = false;
  gridWidth = 2;
  gridHeight = 2;

  MaterialTracking: boolean = true;
  ActiveSource: string = 'WorkTime';
  AllFeatures: any = {};
  Company: Company = this.api.Cache("company", { STMP: {} });
  Inventories: Inventory[];
  WorkTimes: any;
  lat = 52.1598058;
  lng = 9.9451048;
  coordinate: Coordinate = [this.lng, this.lat];

  map: Map;
  workTimeSource: VectorSource;
  workTimeLayer: VectorLayer<VectorSource>;
  inventorySource: VectorSource;
  inventoryLayer: VectorLayer<VectorSource>;
  noWorkTimes = true;

  constructor(private api: ApiService, private settingsService: SettingsService, private inventoryService: InventoryService) {

  }

  async ngOnInit() {
    this.Company = (await this.api.get("company")) as Company;
    this.MaterialTracking = await this.settingsService.getBooleanValue('UseMaterialTracking');
    this.startMap();
  }


  async ngOnChanges() {
    if (!this.isLoading && this.map) {
      this.WorkTimes = (await this.api.get("dashboard/locations")) as [];

      this.addLayers();
      this.addCompany();
      this.generateWOrkTimeFeatures();

      if (this.MaterialTracking) {
        this.Inventories = (await this.api.get('inventory')) as Inventory[];
        this.addInventories();
      }

    }

  }
  async openTraccar() {
    const domain = await this.settingsService.getStringValue('traccar-domain')
    const token = await this.settingsService.getStringValue('traccar-token')
    window.open(domain + '?token=' + token, "_blank");
  }


  startMap() {
    const controls: Control[] = [];
    useGeographic();

    this.map = new Map({
      target: 'dashboardmap',
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en'
          })
        })
      ],
      view: new View({
        center: [this.Company?.Coordinates?.lng, this.Company?.Coordinates?.lat],
        zoom: 16,
        minZoom: 5,
        maxZoom: 18
      }),
      controls
    });


    this.map.on('click', (event) => {
      let foundFeature = false;
      this.map.forEachFeatureAtPixel(event.pixel, async (feature, layer) => {
        if (this.AllFeatures[feature.getId()].source === this.ActiveSource) {
          if (this.AllFeatures[feature.getId()].data.Parent) {
            this.AllFeatures[feature.getId()].data.Parent.active = false;
            this.setPinActive(this.AllFeatures[feature.getId()].data.Parent);
          } else {
            this.setPinActive(this.AllFeatures[feature.getId()]);
          }

          foundFeature = true;
        }
      });
      if (!foundFeature) {
        this.setPinActive();
      }
    });
    this.ngOnChanges()
  }

  addLayers() {
    if (this.map.getAllLayers().length > 1) {
      this.map.removeLayer(this.workTimeLayer);
      this.map.removeLayer(this.inventoryLayer);
    }
    this.workTimeSource = new VectorSource({
      features: [],
      format: new GeoJSON(),
    });
    this.inventorySource = new VectorSource({
      features: [],
      format: new GeoJSON(),
    });
    this.workTimeLayer = new VectorLayer({ source: this.workTimeSource });
    this.inventoryLayer = new VectorLayer({ source: this.inventorySource });
    this.inventoryLayer.setOpacity(0.5);
    this.map.addLayer(this.workTimeLayer)
    this.map.addLayer(this.inventoryLayer)
    this.AllFeatures = {};
  }

  addFeature(Coordinates: { lng: number, lat: number }, Style: string, source: string, data: any) {
    const markerFeature = new Feature({
      type: "icon",
      geometry: new Point([Coordinates?.lng, Coordinates?.lat]),
    });
    markerFeature.setId(Object.keys(this.AllFeatures).length);
    markerFeature.setStyle(Styles[Style]);
    this.getSource(source).addFeature(markerFeature);

    const newFeature = {
      active: false,
      source,
      defaultStyleName: Style,
      Coordinates,
      markerFeature,
      data,
      id: markerFeature.getId()
    }

    this.AllFeatures[markerFeature.getId()] = newFeature;
    this.centerViewPort();
    return newFeature;
  }

  setPinActive(Feature = undefined) {
    const wasActive = Feature?.active;
    for (let FeatureKey of Object.keys(this.AllFeatures)) {
      this.AllFeatures[FeatureKey].active = false;
      this.AllFeatures[FeatureKey].markerFeature.setStyle(Styles[this.AllFeatures[FeatureKey].defaultStyleName]);
    }
    if (Feature && !wasActive && Feature.defaultStyleName !== 'Company') {
      Feature.markerFeature.setStyle(Styles['Active']);
      Feature.active = true;
      this.centerViewPort();
      setTimeout(() => {
        document.getElementById(Feature.id).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }, 200)
    }
  }

  centerViewPort() {
    if (this.selectSource() && this.selectSource().getExtent()[0] !== Infinity) {
      this.map
        .getView()
        .fit(this.selectSource().getExtent(), { padding: [100, this.folded ? 100 : 400, 100, 100] });
    }
  }

  switchLayer(event) {
    if (this.map) {
      this.ActiveSource = event.detail.value;
      this.workTimeLayer.setOpacity(0.5)
      this.inventoryLayer.setOpacity(0.5)
      this.selectLayer().setOpacity(1)

      this.centerViewPort();
    }
  }


  selectSource(): VectorSource {
    return this.getSource(this.ActiveSource);
  }

  getSource(name): VectorSource {
    return (name === 'WorkTime' ? this.workTimeSource : this.inventorySource);
  }

  selectLayer(): VectorLayer<VectorSource> {
    return (this.ActiveSource === 'WorkTime' ? this.workTimeLayer : this.inventoryLayer);
  }

  addCompany(): void {
    this.addFeature(this.Company.Coordinates, 'Company', 'WorkTime', this.Company);
  }

  async addInventories() {
    for (let Inventory of this.Inventories) {
      if(Inventory && Inventory.TrackerId) {
        const trackerPoints = await this.getHistoryForTracker(Inventory.Id);
      const Parent = await this.addFeature({
        lat: trackerPoints?.lastPosition?.lat,
        lng: trackerPoints?.lastPosition?.lng,
      }, 'Material', 'Inventory', { ...Inventory, lastPosition: trackerPoints?.lastPosition })


      // for (let Feature of trackerPoints.today) {

      //   Feature.Parent = Parent;
      //   this.addFeature({
      //     lat: Feature?.lat,
      //     lng: Feature?.lng
      //   }, 'Circle', 'Inventory', Feature)
      // }
      }
    }
  }

  async getHistoryForTracker(InventoryId: string) {
    return this.inventoryService.getLocationHistory(InventoryId)
  }


  generateWOrkTimeFeatures() {
    for (let Feature of this.WorkTimes) {
      this.addFeature({
        lat: Feature.Latitude || this.Company.Latitude,
        lng: Feature.Longitude || this.Company.Longitude
      }, 'Location', 'WorkTime', Feature)
    }
    this.folded = false;
  }

  getFeaturesForWorkTimes(ActiveSourceName) {
    const selectedFeatures = [];
    if (ActiveSourceName == "WorkTime") {
      for (let key of Object.keys(this.AllFeatures)) {
        if (this.AllFeatures[key].source === ActiveSourceName && !!this.AllFeatures[key].data.TimeSlots) {
          selectedFeatures.push(this.AllFeatures[key]);
        }
      }

      this.noWorkTimes = selectedFeatures.length == 0;
    }
    return selectedFeatures
  }

  getFeaturesForInventories(ActiveSourceName) {
    const selectedFeatures = [];
    if (ActiveSourceName == "Inventory") {
      for (let key of Object.keys(this.AllFeatures)) {
        if (this.AllFeatures[key].source === ActiveSourceName && !this.AllFeatures[key].data.Contract && !this.AllFeatures[key].data.Parent && this.AllFeatures[key].data.TrackerId) {
          selectedFeatures.push(this.AllFeatures[key]);
        }
      }

      this.noWorkTimes = selectedFeatures.length == 0;
    }
    return selectedFeatures
  }

  getActivePersons(data) {
    let activeWorker = 0;
    let numberOfTimeslots = 0;
    for (let Timeslot of data.TimeSlots) {
      numberOfTimeslots++;
      if (Timeslot.WorkTime) {
        activeWorker++;
      }
    }
    return activeWorker + '/' + numberOfTimeslots
  }

  countPersonsMissing(data) {
    if (data.TimeSlots) {
      let activeWorker = 0;
      let numberOfTimeslots = 0;
      for (let Timeslot of data.TimeSlots) {
        numberOfTimeslots++;
        if (Timeslot.WorkTime) {
          activeWorker++;
        }
      }
      return numberOfTimeslots - activeWorker
    } else {
      return 0
    }
  }


  changeFold() {
    this.folded = !this.folded;
    this.centerViewPort();
  }
}
