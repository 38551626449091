import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "translateEnum",
})
export class TranslateEnumPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(enumValue: any, enumType: string): string {
    if (!enumValue) {
      return "";
    }
    return this.translate.instant(`enums.${enumType}.${enumValue}`);
  }
}
