import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SelectTimeslotModalComponent } from "../../modals/select-timeslot-modal/select-timeslot-modal.component";
import * as moment from "moment/moment";
import { TypeGroupType } from "../../enums";
import {
  ScheduleMonthSidebarContainer,
  User,
  UserSchedule,
  UserScheduleDayItem,
} from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class ScheduleMonthSidebarService {
  constructor(private modalController: ModalController) {}

  create(type: TypeGroupType): ScheduleMonthSidebarContainer {
    return {
      Active: false,
      SelectedType: null,
      SelectedScheduleItem: null,
      SelectedAbsenceType: null,
      SelectedTimeSlots: {},
      Type: type,
    };
  }

  showDayLoad(
    container: ScheduleMonthSidebarContainer,
    dayList: { dayNumber: number }[],
    dayNumber,
  ): boolean {
    return (
      this.getDayLoad(container, dayList[dayNumber].dayNumber) &&
      this.getDayLoad(container, dayList[dayNumber].dayNumber)
        .SelectedWorker !== 0
    );
  }

  getDayLoad(
    container: ScheduleMonthSidebarContainer,
    day,
  ): { NumWorker: any; SelectedWorker: number; isFull: boolean } | undefined {
    if (
      (container.SelectedType || container.SelectedScheduleItem) &&
      container.SelectedTimeSlots &&
      container.SelectedTimeSlots[day]
    ) {
      let SelectedWorker = container.SelectedTimeSlots[day].filter(
        (ts) => ts.UserId != null,
      );
      if (container.SelectedScheduleItem) {
        SelectedWorker =
          SelectedWorker.length +
          ((container.SelectedScheduleItem.NumWorker || 0) -
            (container.SelectedScheduleItem.openCount || 0));
      }

      return {
        NumWorker: container.SelectedType
          ? container.SelectedType.NumWorker
          : container.SelectedScheduleItem.NumWorker,
        SelectedWorker: container.SelectedScheduleItem
          ? SelectedWorker
          : SelectedWorker.length,
        isFull: container.SelectedScheduleItem
          ? SelectedWorker >= container.SelectedScheduleItem.NumWorker
          : SelectedWorker.length >= container.SelectedType.NumWorker,
      };
    }
    return null;
  }

  async showSelectTimeslotModal(
    Timeslots: { QualificationId?: string; UserId?: string }[],
    employee: UserSchedule,
  ) {
    const modal = await this.modalController.create({
      component: SelectTimeslotModalComponent,
      componentProps: { Timeslots },
      cssClass: "select-timeslot-modal",
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response.data >= 0) {
      Timeslots[response.data].UserId = employee.Id;
    }
  }

  getMatchingTimeslots(
    dummyTimeslots: { QualificationId?: string; UserId?: string }[],
    employee: User,
  ): { QualificationId?: string; UserId?: string }[] {
    const matches = [];
    const userQualifications = (employee.Qualifications || [])
      .map((q) => q.Id)
      .join(",");
    let timeslotQualifications = "";
    for (const dummyTimeslot of dummyTimeslots) {
      if (
        dummyTimeslot.UserId ||
        (dummyTimeslot.QualificationId &&
          timeslotQualifications.indexOf(dummyTimeslot.QualificationId) !== -1)
      ) {
        continue;
      }
      if (!dummyTimeslot.QualificationId && matches.length === 0) {
        matches.push(dummyTimeslot);
        break;
      }
      if (userQualifications.indexOf(dummyTimeslot.QualificationId) !== -1) {
        matches.push(dummyTimeslot);
        timeslotQualifications += dummyTimeslot.QualificationId + ",";
      }
    }
    return matches;
  }

  generateDummyTimeslots(
    container: ScheduleMonthSidebarContainer,
  ): { QualificationId?: string; UserId?: string }[] {
    if (!container.SelectedType && !container.SelectedScheduleItem) {
      return [];
    }
    let qualificationIds;
    if (container.SelectedType) {
      qualificationIds = container.SelectedType.QualificationIds || [];
    }
    if (container.SelectedScheduleItem) {
      qualificationIds = container.SelectedScheduleItem.QualificationIds || [];
    }

    const timeslots = [];
    for (const QualificationId of qualificationIds) {
      timeslots.push({ QualificationId });
    }
    if (container.SelectedType) {
      for (
        let i = qualificationIds.length;
        i < container.SelectedType.NumWorker;
        i++
      ) {
        timeslots.push({});
      }
    }
    if (container.SelectedScheduleItem) {
      for (
        let i = qualificationIds.length;
        i < container.SelectedScheduleItem.NumWorker;
        i++
      ) {
        timeslots.push({});
      }
    }

    return timeslots;
  }

  async selectTimeSlot(
    container: ScheduleMonthSidebarContainer,
    employee: UserSchedule,
    day: UserScheduleDayItem,
  ) {
    if (
      (container.SelectedType || container.SelectedScheduleItem || container.SelectedAbsenceType) &&
      employee &&
      employee.Id &&
      day
    ) {
      let isAllowedToCreateSlot = true;
      if (container.SelectedScheduleItem) {
        isAllowedToCreateSlot = container.SelectedScheduleItem.Day.isSame(
          moment(day.Day),
        );
        if (isAllowedToCreateSlot) {
          if (container.Type === TypeGroupType.WORK_ORDER) {
            if (
              day.dayEvents[0] &&
              day.dayEvents[0].WorkOrder &&
              day.dayEvents[0].WorkOrder.Id ===
                container.SelectedScheduleItem.Id
            ) {
              isAllowedToCreateSlot = false;
            }
          } else {
            if (
              day.dayEvents[0] &&
              day.dayEvents[0].Shift &&
              day.dayEvents[0].Shift.Id === container.SelectedScheduleItem.Id
            ) {
              isAllowedToCreateSlot = false;
            }
          }
        }
      }
      if (isAllowedToCreateSlot) {
        if (!container.SelectedTimeSlots[moment(day.Day).date()]) {
          container.SelectedTimeSlots[moment(day.Day).date()] =
            this.generateDummyTimeslots(container);
        }
        const timeslotId = container.SelectedTimeSlots[
          moment(day.Day).date()
        ].findIndex((dummyTimeSlot) => dummyTimeSlot.UserId === employee.Id);
        if (timeslotId === -1) {
          const matchingTimeslots = this.getMatchingTimeslots(
            container.SelectedTimeSlots[moment(day.Day).date()],
            employee,
          );
          if (matchingTimeslots.length === 0) {
            container.SelectedTimeSlots[moment(day.Day).date()].push({
              UserId: employee.Id,
              overflow: true,
            });
          } else if (matchingTimeslots.length === 1) {
            matchingTimeslots[0].UserId = employee.Id;
          } else {
            await this.showSelectTimeslotModal(matchingTimeslots, employee);
          }
        } else {
          if (
            container.SelectedTimeSlots[moment(day.Day).date()][timeslotId]
              .overflow
          ) {
            container.SelectedTimeSlots[moment(day.Day).date()].splice(
              timeslotId,
              1,
            );
          } else {
            delete container.SelectedTimeSlots[moment(day.Day).date()][
              timeslotId
            ].UserId;
          }
        }
      }
    }
    this.getDayLoad(container, moment(day.Day).date());
  }

  abortAssignment(container: ScheduleMonthSidebarContainer) {
    container.SelectedType = null;
    container.SelectedScheduleItem = null;
    container.SelectedAbsenceType = null;
    container.SelectedTimeSlots = {};
  }

  hasSelected(container: ScheduleMonthSidebarContainer): boolean {
    return container.SelectedScheduleItem || container.SelectedType || container.SelectedAbsenceType;
  }

  isSelectedTimeSlot(
    container: ScheduleMonthSidebarContainer,
    userId: string,
    date: moment.Moment,
  ): boolean {
    let day = moment(date).date();
    return (
      userId &&
      day &&
      container.SelectedTimeSlots[day] &&
      container.SelectedTimeSlots[day].find((ts) => ts.UserId === userId)
    );
  }

  getStyleTimeslot(
    container: ScheduleMonthSidebarContainer,
    employee: UserSchedule,
    dayItem: UserScheduleDayItem,
  ) {
    let style = {
      "is-weekend": dayItem.notWorking || dayItem.hasHoliday,
      "is-selected": this.isSelectedTimeSlot(
        container,
        employee.Id,
        dayItem.Day,
      ),
    };
    if (container.SelectedScheduleItem) {
      if (
        container.SelectedScheduleItem.Day.isSame(moment(dayItem.Day), "day")
      ) {
        style["day-matching"] = true;
      } else {
        style["day-not-matching"] = true;
      }

      const element = dayItem.dayEvents[0];
      if (
        element &&
        (element.WorkOrderId === container.SelectedScheduleItem.Id ||
          element.ShiftId === container.SelectedScheduleItem.Id)
      ) {
        style["selected-shift"] = true;
      } else {
        style["not-selected-shift"] = true;
      }
    } else {
      style["day-matching"] = true;
      style["not-selected-shift"] = true;
    }

    return style;
  }
}
