import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "../../services/core/toast.service";
import { StorageService } from "../../services/storage/storage.service";

@Component({
  selector: "app-filter-button",
  templateUrl: "./filter-button.component.html",
  styleUrls: ["./filter-button.component.scss"],
})
export class FilterButtonComponent implements OnInit {
  @Input() Component: string;
  @Input() IsSmallFilter: boolean;
  @Input() ShowFilter: boolean;
  @Output() ShowFilterChange: EventEmitter<boolean> = new EventEmitter(true);
  @Input() FilterData: {};
  @Output() FilterDataChange: EventEmitter<{}> = new EventEmitter(true);
  @ViewChild("FilterDropDown", { static: true }) FilterDropDown: any;
  @Output() doFilter: EventEmitter<any> = new EventEmitter<any>(true);
  FilterScenarios: any;

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.loadFilterScenarios();
  }

  loadFilterScenarios() {
    this.FilterScenarios = this.storageService.getComponentFilter(
      this.Component,
    );
  }

  saveFilterScenarios() {
    this.storageService.setComponentFilter(
      this.Component,
      this.FilterScenarios,
    );
  }

  async removeFilterScenario(index?: number) {
    this.FilterScenarios.splice(index, 1);
    this.saveFilterScenarios();
    await this.toastService.presentSuccess(
      "Das Filterszenario wurde erfolgreich gelöscht!",
    );
  }

  toggleFilterWindow() {
    this.ShowFilterChange.emit((this.ShowFilter = !this.ShowFilter));
  }

  openFilterScenarioModal(Modal) {
    this.modalService.open(Modal, {
      windowClass: "modal-animated",
      centered: true,
    });
  }

  selectScenario(item: any) {
    this.FilterDataChange.emit(item);
    if (this.FilterDropDown) {
      this.FilterDropDown.close();
    }
    this.doFilter.emit(item);
  }
}
