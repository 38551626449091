import { Injectable } from "@angular/core";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import { ApiService } from "./api.service";
import { ModalController } from "@ionic/angular";
import { AppVersionModalPage } from "src/app/modals/app-version-modal/app-version-modal.page";

@Injectable({
  providedIn: "root",
})
export class AppVersionService {
  modal: any;

  constructor(
    private api: ApiService,
    private modalController: ModalController,
  ) {
    App.addListener("appStateChange", async (status) => {
      if (status.isActive === true) {
        this.checkVersion();
      }
    });
  }

  async getAppInfo() {
    try {
      const version = await App.getInfo();
      return version;
    } catch (e) {
      return null;
    }
  }

  async checkVersion() {
    const deviceInfo = await Device.getInfo();
    const appInfo = await this.getAppInfo();

    let status = null;
    if (appInfo !== null) {
      //if (true) {
      status = await this.api.get("app/versions", {
        Code: appInfo?.version || null,
        //Code: '1.5.0' || null,
        Os: deviceInfo?.operatingSystem || null,
      });
    } else {
      status = 200;
    }

    if (!status || status === 426) {
      return this.openModal(deviceInfo.operatingSystem);
    } else {
      return this.closeModal();
    }
  }

  async openModal(os) {
    if (!this.modal) {
      this.modal = await this.modalController.create({
        component: AppVersionModalPage,
        cssClass: "fullscreen-modal",
        componentProps: { status: 426, os },
      });

      return await this.modal.present();
    }
  }

  async closeModal() {
    if (this.modal) {
      await this.modal.dismiss();
      this.modal = null;
    }
  }
}
