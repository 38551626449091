import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';

import localeDe from '@angular/common/locales/de';
import '../../i18n/moment/de.js'
import localeEn from '@angular/common/locales/en';

import localePl from '@angular/common/locales/pl';
import 'moment/locale/pl'
import localeSq from '@angular/common/locales/sq';
import 'moment/locale/sq'
import localeIt from '@angular/common/locales/it';
import 'moment/locale/it'
import localeFr from '@angular/common/locales/fr';
import 'moment/locale/fr'
import localeEs from '@angular/common/locales/es';
import 'moment/locale/es'
import localeJa from '@angular/common/locales/ja';
import 'moment/locale/ja'

registerLocaleData(localeEn, 'en');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeSq, 'sq');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeJa, 'ja');

@Pipe({
  name: 'date',
  pure: false
})
export class NgxDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  public transform(value: any, pattern: string = 'fullDate'): any {
    const selectedLang = this.translateService.currentLang || localStorage.getItem('chosenLanguage') || 'de'
    const datePipe = new DatePipe(selectedLang);
    return datePipe.transform(value, pattern);
  }
}