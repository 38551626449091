import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { DisplaySettingService } from "../core/display-setting.service";
import { UserNamePipe } from "../../pipes/user-name.pipe";
import { TimeSlot, User } from "../../interfaces";

export abstract class AbstractXlsxExportService {
  protected constructor(
    protected translateService: TranslateService,
    protected settingService: DisplaySettingService,
  ) {}

  protected addTableHeaderRow(sheet: any, keyValueMap: any, row: number) {
    let columnCount = 0;
    for (const columnTitle in keyValueMap) {
      if (keyValueMap.hasOwnProperty(columnTitle)) {
        const column = this.indexToColumn(columnCount++);
        this.setCellValue(sheet, column + row, this.translate(columnTitle));
      }
    }
  }

  protected addContentRow(
    sheet: any,
    keyValueMap: any,
    row: number,
    ...data: any[]
  ) {
    let columnCount = 0;
    for (const key in keyValueMap) {
      if (keyValueMap.hasOwnProperty(key)) {
        const column = this.indexToColumn(columnCount++);
        const getValue = keyValueMap[key];
        this.setCellValue(sheet, column + row, getValue(...data));
      }
    }
  }

  protected addSheetSettings(
    sheet: any,
    cols: number,
    rows: number,
    startRow: number,
  ) {
    sheet["!ref"] = "A1:" + this.indexToColumn(cols) + (rows - 1);
    sheet["!cols"] = this.getWidths(sheet, startRow);
  }

  protected abstract getClass(): string;

  protected translate(key: string): string {
    return this.translateService.instant(
      `services.export.${this.getClass()}.${key}`,
    );
  }

  protected setCellValue(sheet: any, cell: string, value: any) {
    sheet[cell] = { t: "s", v: value ? value : "" };
  }

  protected indexToColumn(index: number): string {
    return String.fromCharCode("A".charCodeAt(0) + index);
  }

  protected getDuration(start, end): number {
    return Math.round(moment(end).diff(moment(start), "minutes"));
  }

  protected getTimeFormatted(date: Date) {
    return moment(date).format("HH:mm");
  }

  protected getDateFormatted(date: Date) {
    return moment(date).format("DD.MM.YYYY");
  }

  getUserName(user: User): string {
    return new UserNamePipe().transform(
      user,
      this.settingService.getUserSetting().format,
    );
  }

  getUserPhone(user: User): string {
    return user ? user.Phone : "";
  }
  getTimeSlotCost(ts: TimeSlot): string {
    return ts && ts.Cost ? '' + ts.Cost : '';
  }
  getTimeSlotBillingNumber(ts: TimeSlot): string {
    return ts ? ts.BillingNumber : '';
  }

  getUserMail(user: User): string {
    return user ? user.Mail : "";
  }

  protected getWidths(sheet, startRow): { wch: number }[] {
    const maxCell = sheet["!ref"].split(":")[1];
    const maxRow = parseInt(maxCell.replace(/\D/g, ""), 10);
    const maxColumn =
      maxCell.replace(/\d/g, "").charCodeAt(0) - "A".charCodeAt(0);
    const cols = [];
    for (let row = startRow; row <= maxRow; row++) {
      for (let column = 0; column < maxColumn; column++) {
        const cell = this.indexToColumn(column) + row;
        if (!sheet.hasOwnProperty(cell)) {
          continue;
        }
        const text = sheet[cell].v;
        if (!text) {
          continue;
        }
        const textWidth = text.length;
        cols[column] = cols[column] || { wch: 10 };
        cols[column].wch = Math.max(cols[column].wch, textWidth);
      }
    }
    return cols;
  }

  protected createTableSheet(tableData: any[], keyValueMap: any): any {
    if (!tableData || tableData.length === 0) {
      return null;
    }
    const sheet: any = {};

    // TableHeader
    let rowCount = 1;
    let columnCount = 0;
    for (const columnTitle in keyValueMap) {
      if (keyValueMap.hasOwnProperty(columnTitle)) {
        const column = this.indexToColumn(columnCount++);
        this.setCellValue(
          sheet,
          column + rowCount,
          this.translate(columnTitle),
        );
      }
    }

    // TableBody
    rowCount++;
    for (const rowData of tableData) {
      columnCount = 0;
      for (const key in keyValueMap) {
        if (keyValueMap.hasOwnProperty(key)) {
          const column = this.indexToColumn(columnCount++);
          const getValue = keyValueMap[key];
          this.setCellValue(sheet, column + rowCount, getValue(rowData));
        }
      }
      rowCount++;
    }

    // SheetSettings
    sheet["!ref"] =
      "A1:" +
      this.indexToColumn(Object.keys(keyValueMap).length) +
      (rowCount - 1);
    sheet["!cols"] = this.getWidths(sheet, 1);
    return sheet;
  }
}
