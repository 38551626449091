import { Injectable } from "@angular/core";
import { AbstractCustomWorkOrderImportService } from "./abstract-custom-work-order-import.service";
import { CellObject, WorkBook, WorkSheet } from "xlsx";
import { ToastService } from "../core/toast.service";
import { LeasingAgreement, WorkOrder, LeasingImportResult } from "../../interfaces";
import { AbstractCustomLeasingImportService } from "./abstract-custom-leasing-import.service";

@Injectable({
  providedIn: "root",
})
export class BuchMichLeasingImportService extends AbstractCustomLeasingImportService {
  constructor(toastService: ToastService) {
    super(toastService);
  }

  writeChangeLogFile(): boolean {
    return true;
  }

  getKey(): string {
    return "BuchMichLeasingImportService";
  }

  getSelectAllDefault(): boolean {
    return false;
  }

  private contentMatches(cell: CellObject, lookup: string): boolean {
    if (!cell) {
      return false;
    }
    const content = ((cell.v || "") as string).trim().toLowerCase();
    return content === lookup.toLowerCase();
  }

  private sheetIsValid(sheet: WorkSheet, row: number = 1, indexColumn: string = "A", indexName: string = "Employee ID", statusColumn: string = "B", statusName: string = "Worker Name",) {
    if (!sheet) {
      return false;
    }
    const indexCell = sheet[indexColumn + row];
    const statusCell = sheet[statusColumn + row];
    return (
      this.contentMatches(indexCell, indexName) &&
      this.contentMatches(statusCell, statusName)
    );
  }


  public async createElementsFromSheet(sheet: WorkSheet,): Promise<{ rows: string[]; values: { [row: string]: LeasingAgreement[] } }> {
    if (!this.sheetIsValid(sheet)) {
      return { rows: [], values: {} };
    }


    const startRow = 2;
    const endRow = this.getRows(sheet);


    const externalIdColumn = "A";
    const nameColumn = "B";
    const contractColumn = "C";
    const startDate = "D";
    const endDate = "E";
    const hiringManagerColumn = "F";
    const qualificationColumn = "G";

    const keys: string[] = [];
    const values: { [key: string]: LeasingAgreement[] } = {};

    for (let i = startRow; i <= endRow; i++) {
      const title = this.stringContent(sheet, qualificationColumn, i, true).trim();
      const hiringManager = this.stringContent(sheet, hiringManagerColumn, i, true).trim();

      const externalId = this.getExternalId(sheet, externalIdColumn, i);
      const contractId = this.getExternalId(sheet, contractColumn, i);
      const { StartTime, EndTime } = this.createTimesFromDateAndTimeframe(sheet, i, startDate, endDate);


      if (!externalId && !contractId && !(StartTime && EndTime)) {
        continue;
      }

      const leasings = [];
      const leasing: LeasingAgreement = {
        Settings: {
          StartDate: StartTime,
          EndDate: EndTime,
          ContractId: contractId,
          HiringManager: hiringManager,
          Title: title
        },
        User: {
          CustomId: externalId
        }
      };

      if (externalId && contractId) {
        const clone = JSON.parse(JSON.stringify(leasing));
        leasings.push(clone);
      }

      if (leasings.length > 0) {
        values[i] = leasings;
        keys.push(String(i));
      }
    }
    return { rows: keys, values };
  }

  public async createElements(workBook: WorkBook): Promise<LeasingImportResult> {
    const result = { sheets: [], rows: {}, values: {} };
    if (!workBook || workBook.SheetNames.length === 0) {
      return result;
    }
    for (const sheetName of workBook.SheetNames) {
      const sheet = await this.createElementsFromSheet(workBook.Sheets[sheetName]);
      if (sheet.rows.length > 0) {
        result.sheets.push(sheetName);
        result.rows[sheetName] = sheet.rows;
        for (const key in sheet.values) {
          result.values[sheetName + "-" + key] = sheet.values[key];
        }
      }
    }
    return result;
  }

  public getPreviewSettings(): { Icon: any; Cols: any } {
    return {
      Icon: {
        Style: { width: "5%" },
        Show: true,
        Icons: {
          MissingId: true,
          MissingContractId: true,
        },
      },
      Cols: {
        Sheet: {
          Style: { width: "7%" },
          Show: false,
        },
        Row: {
          Style: { width: "5%" },
          Show: true,
        },
        CustomId: {
          Style: { width: "10%" },
          Show: true,
        },
        Name: {
          Style: { width: "10%" },
          Show: true,
        },
        AssignmentId: {
          Style: { width: "5%" },
          Show: true,
        },
        Start: {
          Style: { width: "10%" },
          Show: true,
        },
        End: {
          Style: { width: "10%" },
          Show: true,
        },
        HiringManager: {
          Style: { width: "10%" },
          Show: true,
        },
        Title: {
          Style: { width: "15%" },
          Show: true,
        },
      },
    };
  }
}
