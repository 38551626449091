import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { MaterialTypeFilterService } from "../../services/filter/material-type-filter.service";

@Component({
  selector: "app-material-type-filter",
  templateUrl: "./material-type-filter.component.html",
  styleUrls: ["./material-type-filter.component.scss"],
})
export class MaterialTypeFilterComponent implements OnInit {
  @Input() set Types(types: string[]) {
    this.types = types;
    this.selectedType = this.filterService.getFilter();
  }

  @Output() Update = new EventEmitter<string>();

  types: string[];
  selectedType: string;

  constructor(private filterService: MaterialTypeFilterService) {}

  ngOnInit() {}

  updateFilter(id: string) {
    this.filterService.setFilter(id);
    this.Update.emit(this.selectedType);
  }
}
