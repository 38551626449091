import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthService } from './services/api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationProviderService implements TranslateLoader {
  constructor(private http: HttpClient, private authService: AuthService) { }

  getTranslation(lang: string) {
    return new Observable((observer) => {
      this.http.get('./i18n/' + lang + '.json').subscribe((local) => {
        this.http.get(this.authService.serverApi + 'language/' + lang).subscribe((remote) => {
          observer.next(this.combineLanguages(this.flattenObject(local), this.flattenObject(remote)));
          observer.complete();
        },
          (err) => {
            observer.next(local);
            observer.complete();
          })
      });
    });
  }

  combineLanguages(local, remote) {
    for (const key in remote) {
      local[key] = remote[key]
    }
    return local;
  }


  flattenObject(ob) {
    const toReturn = {};

    for (let i in ob) {
      if (!ob.hasOwnProperty(i)) continue;
      if ((typeof ob[i]) == 'object' && ob[i] !== null) {
        const flatObject = this.flattenObject(ob[i]);
        for (let x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }
}
