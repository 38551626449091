import { Fill, Icon, Stroke, Style } from "ol/style"
import CircleStyle from "ol/style/Circle"

export const Styles: any = {
    Company: new Style({
        image: new Icon({
            anchor: [0.5, 92],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: "../../../../assets/img/PinCompany.png",
            scale: 0.5,
        }),
        zIndex: 50
    }),
    Travel: new Style({
        image: new Icon({
            anchor: [0.5, 92],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: "../../../../assets/img/PinTravel.png",
            scale: 0.5,
        }),
    }),
    Home: new Style({
        image: new Icon({
            anchor: [0.5, 92],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: "../../../../assets/img/PinHome.png",
            scale: 0.5,
        }),
    }),
    Circle: new Style({
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({ color: "#007991" }),
            stroke: new Stroke({
                color: "#007991",
                width: 1,
            }),
        }),
    }),
    Location: new Style({
        image: new Icon({
            anchor: [0.5, 92],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: "../../../assets/img/PinDefault.png",
            scale: 0.5,
        }),
    }),
    Active: new Style({
        image: new Icon({
            anchor: [0.5, 92],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: "../../../assets/img/PinActive.png",
            scale: 0.5,
        }),
        zIndex: 100
    }),
    Material: new Style({
        image: new Icon({
            anchor: [0.5, 92],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: "../../../assets/img/PinMaterial.png",
            scale: 0.5,
        }),
    }),
    WorkTime: new Style({
        image: new Icon({
            anchor: [0.5, 92],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: "../../../assets/img/PinWorkTime.png",
            scale: 0.5,
        }),
    }),
    Absence: new Style({
        image: new Icon({
            anchor: [0.5, 92],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: "../../../assets/img/PinAbsence.png",
            scale: 0.5,
        }),
    })
}