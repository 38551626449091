import { Component, OnInit } from "@angular/core";
import { ScheduleStorageService } from "../../services/storage/schedule-storage.service";
import { ScheduleStateService } from "../../services/core/schedule-state.service";

@Component({
  selector: "app-absence-view-toggle",
  templateUrl: "./absence-view-toggle.component.html",
  styleUrls: ["./absence-view-toggle.component.scss"],
})
export class AbsenceViewToggleComponent implements OnInit {
  public LIST: string = this.scheduleStateService.VIEWS.ABSENCE_LIST;

  constructor(private scheduleStateService: ScheduleStateService) {}

  ngOnInit() {}

  async navigate() {
    return this.scheduleStateService.goToAbsenceView(this.LIST);
  }
}
