/* tslint:disable:no-string-literal */
import { AbstractDetailXlsxExportService } from "./abstract-detail-xlsx-export.service";
import { ExportService } from "./export.service";
import { ApiService } from "../api/api.service";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { ArrayService } from "../core/array.service";
import { InventoryService } from "../api/inventory.service";
import { DisplaySettingService } from "../core/display-setting.service";
import {
  EmployeeTask,
  Remark,
  TimeSlot,
  TimeslotElement,
} from "../../interfaces";

export abstract class AbstractTimeslotElementDetailXlsxExportService<
  Type extends TimeslotElement,
> extends AbstractDetailXlsxExportService<Type> {
  protected showInventory: boolean;

  protected constructor(
    protected api: ApiService,
    protected arrayService: ArrayService,
    protected inventoryService: InventoryService,
    exportService: ExportService,
    translateService: TranslateService,
    settingService: DisplaySettingService,
  ) {
    super(exportService, translateService, settingService);
  }

  abstract get ApiPath(): string;

  async load(element: Type): Promise<{ Element: Type; Remarks: Remark[] }> {
    if (!element.Id) {
      this.showInventory = false;
      const copy = JSON.parse(JSON.stringify(element));
      copy.Timeslots = [];
      return { Element: copy, Remarks: [] };
    }
    this.showInventory = await this.inventoryService.show();
    const Element: Type = await this.api.get(`${this.ApiPath}/${element.Id}`);
    await this.addQualifications(Element);
    await this.addUsers(Element);
    const Remarks = (await this.api.get(`remarks/${element.Id}`)) as Remark[];
    return { Element, Remarks };
  }

  async addUsers(element: Type) {
    if (!element.TimeSlots || element.TimeSlots.length === 0) {
      return;
    }
    const Employees = this.arrayService.toMap(await this.api.get("employees"));
    for (const timeslot of element.TimeSlots) {
      if (timeslot.UserId && Employees.hasOwnProperty(timeslot.UserId)) {
        timeslot.User = Employees[timeslot.UserId];
      }
    }
  }

  async addQualifications(element: Type) {
    if (!element.TimeSlots || element.TimeSlots.length === 0) {
      return;
    }
    const Qualifications = this.arrayService.toMap(
      await this.api.get("qualifications"),
    );
    for (const timeslot of element.TimeSlots) {
      if (
        timeslot.QualificationId &&
        Qualifications.hasOwnProperty(timeslot.QualificationId)
      ) {
        timeslot.Qualification = Qualifications[timeslot.QualificationId];
      }
    }
  }

  protected getDuration(start, end): number {
    const Start = moment(start);
    const End = moment(end);
    if (Start.format("x") > End.format("x")) {
      End.add(1, "day");
    }
    return super.getDuration(Start, End);
  }

  get RemarkKeyValueMap() {
    return {
      Name: (r: Remark) => this.getUserName(r.User),
      Date: (r: Remark) =>
        this.getDateFormatted(r.UpdatedAt) +
        " - " +
        this.getTimeFormatted(r.UpdatedAt),
      Visibility: (r: Remark) =>
        this.translateService.instant("enums.Visibility." + r.Visibility),
      Remark: (r: Remark) => r.Text,
    };
  }

  get TimeslotKeyValueMap() {
    return {
      Confirmed: (ts: TimeSlot) =>
        !!ts.AcceptReaction
          ? this.translateService.instant(
              "enums.TimeSlotReactionType." + ts.AcceptReaction.Type,
            )
          : this.translate("Waiting"),
      Qualification: (ts: TimeSlot) =>
        ts.Qualification ? ts.Qualification.Name : "",
      Employee: (ts: TimeSlot) => this.getUserName(ts.User),
      Phone: (ts: TimeSlot) => this.getUserPhone(ts.User),
      Mail: (ts: TimeSlot) => this.getUserMail(ts.User),
    };
  }

  get TasksKeyValueMap() {
    return {
      Task: (t: EmployeeTask) => t.Name,
      Employee: (t: EmployeeTask) =>
        t["Employees"].map((user) => this.getUserName(user)).join(", "),
    };
  }

  public async export(element: Type) {
    const { Element, Remarks } = await this.load(element);
    const baseData = this.createBaseDateSheet(
      Element,
      this.BaseDataKeyValueMap,
    );
    const remarks = this.createTableSheet(Remarks, this.RemarkKeyValueMap);
    const timeslots = this.createTableSheet(
      (Element.TimeSlots || []).filter((timeSlot) => !!timeSlot.User),
      this.TimeslotKeyValueMap,
    );
    const tasks = this.createTableSheet(Element.Tasks, this.TasksKeyValueMap);
    await this.exportService.exportSheetToXlsx(
      [baseData, timeslots, tasks, remarks],
      this.getFileName(Element),
      [
        this.TypeNameTranslated,
        this.translate("ResponsibleEmployee"),
        this.translate("Tasks"),
        this.translate("Remarks"),
      ],
    );
  }
}
