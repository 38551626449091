import { Component, OnInit } from "@angular/core";
import { NavParams } from "@ionic/angular";

@Component({
  selector: "app-app-version-modal",
  templateUrl: "./app-version-modal.page.html",
  styleUrls: ["./app-version-modal.page.scss"],
})
export class AppVersionModalPage implements OnInit {
  status = this.navParams.data.status;
  os = this.navParams.data.os;

  constructor(private navParams: NavParams) {}

  ngOnInit() {}
}
