import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"],
})
export class DeleteModalComponent {
  @Input() ItemId: string;
  @Output() Delete: EventEmitter<string> = new EventEmitter(true);
  @Input() DeleteMessage: string;
  @Input() Disabled: boolean = false;

  constructor(private modalService: NgbModal) {}

  async openDeleteModal(Modal) {
    if (this.Disabled) {
      return;
    }
    this.modalService.open(Modal, {
      windowClass: "modal-animated",
      centered: true,
    });
  }

  async closeModal(modal, acceptDelete: boolean) {
    if (acceptDelete) {
      await this.Delete.emit(this.ItemId);
    }
    modal.close();
  }
}
