import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ApiService } from "../../services/api/api.service";
import { ToastService } from "../../services/core/toast.service";
import { SettingsService } from "../../services/api/settings.service";
import { InventoryService } from "../../services/api/inventory.service";
import { SocketService } from "../../services/socket/socket.service";
import { ColorPickerStorageService } from "../../services/storage/color-picker-storage.service";
import { ShiftTypeService } from "../../services/api/shift-type.service";
import * as moment from "moment/moment";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { ArrayService } from "../../services/core/array.service";
import { TypeGroupService } from "../../services/api/type-group.service";
import { ApplicationType, TypeGroupType } from "../../enums";
import {
  Company,
  EmployeeTask,
  Inventory,
  Qualification,
  ShiftType,
  ShiftTypeGroup,
  Team,
} from "../../interfaces";

@Component({
  selector: "app-shift-type-detail-content",
  templateUrl: "./shift-type-detail-content.component.html",
  styleUrls: ["./shift-type-detail-content.component.scss"],
})
export class ShiftTypeDetailContentComponent implements OnInit {
  @Input() set Id(id: string) {
    const previousId = this.id;
    this.id = id;
    if (this.isInitialized && previousId != id) {
      this.loadShiftType();
    }
  }

  @Input() SplitViewMode;
  @Output() Reload = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() CloseView = new EventEmitter<void>();

  id: string;
  shiftType: ShiftType = {};
  qualifications: Qualification[] = [];
  tasks: EmployeeTask[] = [];
  shiftTypeGroups: ShiftTypeGroup[] = [];
  company: Company = {};
  teams: Team[] = [];
  inventories: Inventory[] = [];

  customTask: string = null;
  isLoading = true;
  selectableQualifications: Qualification[] = [];
  selectedQualificationIds: string[] = [];
  positions: { Qualification: string; NumWorkers: number }[] = [];
  addressIsDifferent: boolean = false;
  locationTracking: boolean = false;
  isInitialized = false;
  triggerCollapseState: { [key: string]: boolean } = {};
  endTimeWithDays: string;
  applicationTypes = [ApplicationType.MANUAL, ApplicationType.FIRST];
  showApplications: boolean = false;

  constructor(
    private location: Location,
    private api: ApiService,
    private toastService: ToastService,
    private settingService: SettingsService,
    private inventoryService: InventoryService,
    private socketService: SocketService,
    private colorPickerStorageService: ColorPickerStorageService,
    private shiftTypeService: ShiftTypeService,
    private router: Router,
    private arrayService: ArrayService,
    private groupService: TypeGroupService,
  ) {}

  async ngOnInit() {
    this.isInitialized = false;
    this.triggerCollapseState = {};
    this.isLoading = true;
    this.locationTracking = await this.settingService.getBooleanValue(
      "ShiftLocationTracking",
    );
    // this.tasks = await this.api.get("tasks");
    this.company = await this.api.get("company");
    this.teams = await this.api.get("teams");
    this.qualifications = await this.api.get("qualifications");
    this.showApplications =
      await this.settingService.getBooleanValue("UseApplications");
    this.inventories = await this.inventoryService.getAll();
    this.shiftTypeGroups = await this.groupService.getAll(TypeGroupType.SHIFT);
    await this.loadShiftType();
    this.isLoading = false;
    this.isInitialized = true;
  }

  async loadShiftType() {
    this.positions = [];
    this.addressIsDifferent = false;
    if (this.id === "create") {
      this.shiftType = this.shiftTypeService.create();
    } else {
      this.shiftType = await this.shiftTypeService.get(this.id);
    }
    this.selectedQualificationIds = this.shiftType.Qualifications.map(
      (qualification) => qualification.Id,
    );
    this.updateSelectableQualifications();
    this.generateEndTime();
    this.updateAddressFlag();
    this.generatePositions();
  }

  updateAddressFlag() {
    if (!(this.shiftType.Street && this.shiftType.City && this.shiftType.Zip)) {
      this.addressIsDifferent = false;
      return;
    }
    if (
      this.shiftType.Latitude !== this.company.Latitude ||
      this.shiftType.Longitude !== this.company.Longitude
    ) {
      this.addressIsDifferent = true;
    }
  }

  updateSelectableQualifications() {
    if (this.selectedQualificationIds.length === 0) {
      this.selectableQualifications = [...this.qualifications];
      return;
    }
    this.selectableQualifications = this.getSelectedQualifications();
  }

  getSelectedQualifications(): Qualification[] {
    if (
      !this.selectedQualificationIds ||
      this.selectedQualificationIds.length === 0
    ) {
      return [];
    }
    const idMap = this.arrayService.toMap(this.selectedQualificationIds, "");
    return this.qualifications.filter(
      (qualification) => idMap[qualification.Id],
    );
  }

  changeDay($event: any) {
    const newDate = new Date($event);
    this.shiftType.ShiftStart.setFullYear(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
    );
    this.shiftType.ShiftEnd.setFullYear(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
    );
  }

  changeShiftStart($event: any) {
    const newTime = $event.split(":");
    this.shiftType.ShiftStart.setHours(newTime[0], newTime[1], 0);
  }

  changeShiftEnd($event: any) {
    const newTime = $event.split(":");
    this.shiftType.ShiftEnd.setMinutes(newTime[1]);
    this.shiftType.ShiftEnd.setHours(newTime[0]);
  }

  updateEndTimeWithDays() {
    if (!this.shiftType) {
      this.endTimeWithDays = "";
      return;
    }
    let daysDiff = "";
    const days = moment(this.shiftType.ShiftEnd).diff(
      this.shiftType.ShiftStart,
      "days",
    );
    if (days) {
      daysDiff = " +" + days + (days > 1 ? " Tage" : "Tag");
    }
    const endTime = this.shiftType?.EndTimeString || "";
    this.endTimeWithDays = endTime + daysDiff;
  }

  addTask(task: EmployeeTask) {
    this.shiftType.Tasks.push({ Name: task.Name, Employees: [] });
  }

  addCustomTask(name: string) {
    if (name !== null) {
      this.shiftType.Tasks.push({ Name: name, Employees: [] });
    }
    this.customTask = null;
  }

  removeTask(item: Task) {
    const index: number = this.shiftType.Tasks.indexOf(item);
    if (index !== -1) {
      this.shiftType.Tasks.splice(index, 1);
    }
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  async save(goBack: boolean = false) {
    this.colorPickerStorageService.addColorToPreset(this.shiftType.Color);
    if (!this.shiftType.ShiftTypeGroupId) {
      return this.toastService.presentError(
        "Es muss eine Vorlagen-Gruppe ausgewählt werden.",
      );
    } else if (
      this.shiftType.EstimatedDuration < 0 ||
      this.shiftType.EstimatedDuration > 1440
    ) {
      return this.toastService.presentError(
        "Die Dauer muss zwischen 0 und 1440 Minuten liegen",
      );
    }
    if (
      this.locationTracking &&
      this.shiftType.LocationTracking &&
      !this.addressIsDifferent
    ) {
      this.shiftType.Street = this.company.Street;
      this.shiftType.Zip = this.company.Zip;
      this.shiftType.City = this.company.City;
    } else if (
      !this.addressIsDifferent &&
      (!this.locationTracking || !this.shiftType.LocationTracking)
    ) {
      this.shiftType.Street = null;
      this.shiftType.Zip = null;
      this.shiftType.City = null;
    }
    const response = await this.shiftTypeService.save(
      this.shiftType,
      this.getSelectedQualifications(),
      this.positions,
    );
    this.Reload.emit();
    if (goBack && !this.SplitViewMode) {
      return this.goBack();
    }

    if (response.Id != this.id) {
      this.id = response.Id;
      if (!this.SplitViewMode) {
        this.location.replaceState("/shift-types/" + this.id);
      }
    }

    return this.loadShiftType();
  }

  addPosition(qualification: string = null, numWorkers: number = 1) {
    this.positions.push({
      Qualification: qualification,
      NumWorkers: numWorkers,
    });
  }

  removePosition(index: number) {
    this.positions.splice(index, 1);
  }

  private generatePositions() {
    let numWorker = this.shiftType.NumWorker || 1;
    let qualificationIds = this.shiftType.QualificationIds as string[];
    const positions = {};
    for (const Key in qualificationIds) {
      if (Key) {
        positions[qualificationIds[Key]] =
          positions[qualificationIds[Key]] || 0;
        positions[qualificationIds[Key]]++;
      }
    }
    for (const key in positions) {
      if (key) {
        this.addPosition(key, positions[key]);
      }
    }
    if (qualificationIds.length) {
      if (numWorker > qualificationIds.length) {
        this.addPosition(null, numWorker - qualificationIds.length);
      }
    }
  }

  addNewQualification() {
    return (name: string) => {
      return this.api.post("qualifications", { Name: name }) as Qualification;
    };
  }

  addNewTypeGroup() {
    return (name) => {
      const group = this.groupService.create();
      group.Name = name;
      return this.groupService.save(TypeGroupType.SHIFT, group);
    };
  }

  async delete() {
    await this.shiftTypeService.destroy(this.shiftType);
    this.Remove.emit();
    return this.goBack();
  }

  generateEndTime() {
    this.shiftType.ShiftEnd = moment(this.shiftType.ShiftStart)
      .add(this.shiftType.EstimatedDuration, "minutes")
      .subtract(this.shiftType.DefaultBreak || 0, "minutes")
      .seconds(0)
      .toDate();
    this.shiftType.EndTimeString = moment(this.shiftType.ShiftEnd).format(
      "HH:mm",
    );
    this.updateEndTimeWithDays();
  }

  async goBack() {
    if (this.SplitViewMode) {
      return;
    }
    return this.router.navigate(["shift-types"]);
  }

  async createCopy() {
    await this.shiftTypeService.copy(this.shiftType);
    await this.Reload.emit();
  }

  setEstimatedDuration(estimatedDuration: number) {
    this.shiftType.EstimatedDuration = estimatedDuration;
    this.generateEndTime();
  }
}
