import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterlistDetailChangesComponent } from '../../masterlist-detail-changes/masterlist-detail-changes.component';

@Component({
  selector: 'app-crud-bar-masterlist-log',
  templateUrl: './crud-bar-masterlist-log.component.html',
  styleUrls: ['./crud-bar-masterlist-log.component.scss'],
})
export class CrudBarMasterlistLogComponent implements OnInit {
  @Input() ItemId: string;
  @Input() ItemComponent: string;

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  async openModal() {
    const modal = await this.modalController.create({
      component: MasterlistDetailChangesComponent,
      componentProps: {
        ItemId: this.ItemId,
        ItemComponent: this.ItemComponent
      },
      cssClass: 'modal-50-80 ion-page-overload'
    })

    modal.present()
  }

}
