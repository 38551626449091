import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../../../services/api/api.service";
import { AuthService } from "../../../services/api/auth.service";
import { ToastService } from "../../../services/core/toast.service";
import { ArrayService } from "../../../services/core/array.service";
import { SearchFunction, Team, TimeSlot, User, WorkOrder } from "../../../interfaces";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-crud-bar-mail",
  templateUrl: "./crud-bar-mail.component.html",
  styleUrls: ["./crud-bar-mail.component.scss"]
})
export class CrudBarMailComponent implements OnInit {
  @Input() Recipients: string[];
  @Input() set RecipientTeams(TeamIds: Team[]) {
    this.SelectedTeamIds = TeamIds.map(team => team.Id);
  }
  @Input() set WorkOrder(content: WorkOrder) {
    if (content && content.Id) {
      this.Mail.html = "";
      let txt = "";
      txt += "<a href=https://" + (location.href.indexOf('beta.manager.teamcraft.app') >= 0 ? "beta." : "") + "manager.teamcraft.app/work-orders/" + content.Id + ">" + content.Name + "</a>";
      txt += "<br>"+this.translate.instant('Typ')+": " + content.WorkOrderType.Name;
      txt += "<br>"+this.translate.instant('Zeit')+": " + moment(content.StartTime).format("DD.MM.YYYY HH:mm");
      txt += " - " + moment(content.EndTime).format("HH:mm");
      if (content.Street && content.Zip && content.City) {
        txt += "<br>"+this.translate.instant('Adresse')+": " + content.Street + " " + content.Zip + " " + content.City;
      }
      if (content.Description) {
        txt += "<br><br>"+this.translate.instant('Beschreibung')+":<br>" + content.Description;
      }
      if (txt.length > 0) {
        this.Mail.html += txt;
      }
    }
  }
  @Input() set Subject(subject: string) {
    this.Mail.subject = subject;
  }
  @Input() set TimeSlots(slots: TimeSlot[]) {
    if (slots && slots.length > 0) {
      let txt = "";
      txt += "<br><br>"+this.translate.instant('Zeitslots')+":";
      for (let ts of slots) {
        txt += "<br>";
        if (ts.Qualification) {
          txt += ts.Qualification.Name + ": ";
        }
        if (ts.User) {
          txt += "" + ts.User.FirstName + " " + ts.User.LastName;
        }
      }
      if (txt.length > 0) {
        this.Mail.html += txt;
      }
    }
  }
  @Input() ItemId: string;
  @Input() Url: string;

  loading = false;
  Mail: {
    html?: string;
    subject?: string;
    Recipients?: { [Id: string]: User };
    attachments?: any[];
  } = { html: "" };
  Teams: Team[] = this.api.Cache("teams", []);
  Users: User[] = this.api.Cache("employees", []);
  SelectedTeamIds: string[] = [];
  SelectedUserIds: string[] = [];
  SelectTeamManagers = true;
  SelectTeamMembers = false;
  SelectMode = "all";
  Sender: User;
  userSearchFunction: SearchFunction = this.arrayService.createSearchFunction("FirstName", "LastName");

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private toastService: ToastService,
    private authService: AuthService,
    private arrayService: ArrayService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.loading = false;
    this.Mail = this.Mail || {};
    (this.Mail.attachments = []), (this.Teams = this.api.Cache("teams", []));
    this.Users = this.api.Cache("employees", []);
    this.SelectTeamManagers = true;
    this.SelectTeamMembers = false;
    this.Mail.attachments.push({ filename: "", path: "" });
  }

  async closeModal(ModalRef) {
    ModalRef.close();
  }

  saveFile() {
    this.Mail.attachments.push({ filename: "", path: "" });
  }

  async openModal(Modal) {
    this.loading = true;
    this.modalService.open(Modal, {
      windowClass: "modal-animated mail-modal",
      centered: true
    });
    this.Teams = (await this.api.get("teams")) as Team[];
    this.Users = (await this.api.get("employees")) as User[];
    if (this.Recipients && this.Recipients.length > 0) {
      this.SelectMode = "costume";
      this.SelectedUserIds = [...this.Recipients];
    }
    this.select(this.SelectMode);
    this.Sender = await this.authService.getUser();
    this.loading = false;
  }

  get TeamMap() {
    return this.arrayService.toMap(this.Teams);
  }

  get UserMap() {
    return this.arrayService.toMap(this.Users);
  }

  get TeamIds(): string[] {
    return this.Teams.map(team => team.Id);
  }

  getHasRecipients(): boolean {
    return this.Mail.Recipients && Object.keys(this.Mail.Recipients).length > 0;
  }

  getHasInvalidRecipients() {
    return (
      this.getHasRecipients() &&
      !!Object.keys(this.Mail.Recipients)
        .map(key => this.Mail.Recipients[key])
        .find(user => !user.Mail || user.Mail.trim().length === 0)
    );
  }

  getHasValidRecipients(): boolean {
    return (
      this.getHasRecipients() &&
      !!Object.keys(this.Mail.Recipients)
        .map(key => this.Mail.Recipients[key])
        .find(user => !!user.Mail)
    );
  }

  getHasSubject(): boolean {
    return this.Mail.subject && this.Mail.subject.trim().length > 0;
  }

  canSend() {
    return this.getHasValidRecipients() && this.getHasSubject();
  }

  select(mode: string) {
    this.SelectMode = mode;
    if (mode === "all") {
      this.updateReceivers();
    } else {
      this.updateReceivers(this.SelectedTeamIds, this.SelectedUserIds);
    }
  }

  updateReceivers(SelectedTeamIds: string[] = this.TeamIds, SelectedUserIds: string[] = []) {
    const Recipients = {};
    const TeamMap = this.TeamMap;
    const UserMap = this.UserMap;
    for (const TeamId of SelectedTeamIds) {
      const Team = TeamMap[TeamId] as Team;
      if (!Team) {
        continue;
      }
      if (this.SelectMode === "all" || this.SelectTeamMembers) {
        for (const TeamMember of Team.TeamMembers) {
          const User = UserMap[TeamMember.Id] || TeamMember;
          Recipients[TeamMember.Id] = User;
        }
      }
      if (this.SelectMode === "all" || this.SelectTeamManagers) {
        for (const TeamManager of Team.TeamManagers) {
          const User = UserMap[TeamManager.Id] || TeamManager;
          Recipients[TeamManager.Id] = User;
        }
      }
    }
    for (const UserId of SelectedUserIds) {
      const User = UserMap[UserId];
      if (User) {
        Recipients[UserId] = User;
      }
    }
    this.Mail.Recipients = Recipients;
  }

  async sendMail(modalRef) {
    const mail = JSON.parse(JSON.stringify(this.Mail));
    mail.bcc = Object.keys(this.Mail.Recipients)
      .map(key => this.Mail.Recipients[key])
      .filter(user => !!user.Mail)
      .map(user => user.Mail)
      .join(",");
    mail.Url = this.Url;
    mail.ItemId = this.ItemId;
    mail.receiver = this.Sender.Mail;
    delete mail.Recipients;
    await this.api.post("mail/info-mail", mail);
    await this.toastService.presentSuccess(this.translate.instant('die_nachricht_wurde_erfolgreich_verschickt'));
    this.closeModal(modalRef);
  }
}
