import { Injectable } from "@angular/core";
import { AbstractImportService } from "./abstract-import.service";
import { Sheet } from "xlsx";
import * as moment from "moment/moment";
import { ToastService } from "../core/toast.service";
import { WorkOrder } from "../../interfaces";

@Injectable({
  providedIn: "root"
})
export abstract class AbstractCustomLeasingImportService extends AbstractImportService<WorkOrder[]> {
  protected constructor(toastService: ToastService) {
    super(toastService);
  }

  public writeChangeLogFile(): boolean {
    return false;
  }

  abstract getKey(): string;

  abstract getPreviewSettings(): { Icon: any; Cols: any };

  public getSelectAllDefault(): boolean {
    return true;
  }

  protected contentOrNull(sheet: Sheet, col: string, row: number): any {
    return sheet[col + row] ? sheet[col + row].v : null;
  }

  protected stringContent(sheet: Sheet, col: string, row: number, replaceLineBreaks = false): string {
    const content = this.contentOrNull(sheet, col, row);
    const response = (content || "") + "";
    return replaceLineBreaks ? response.replace(/(\r\n|\n|\r)/gm, " ").replace(/\s\s+/g, " ") : response;
  }

  protected getRows(sheet: Sheet): number {
    const max = sheet["!ref"].split(":")[1];
    return Number(max.replace(/\D/g, ""));
  }

  protected getExternalId(sheet: Sheet, col: string, row: number): string {
    const value = this.contentOrNull(sheet, col, row);
    const parsed = String(value || "").trim();
    return parsed && parsed.length > 0 ? parsed : null;
  }

  protected parseDate(day: any): moment.Moment {
    try {
      const response = moment(day, "DD/MM/YYYY");
      return response;
    } catch (e) {
      return null;
    }
  }

  protected parseTime(time: any): number {
    if (typeof time === "number") {
      const response = 24 * 60 * (time as number);
      return response;
    } else if (typeof time === "string") {
      try {
        const split = (time as string).split(/[:.]+/g);
        const hours = parseInt(split[0], 10);
        const minutes = parseInt(split[1], 10);
        const result = hours * 60 + minutes;
        return isNaN(result) ? 0 : result;
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  protected createTimesFromDateAndTimeframe(
    sheet: Sheet,
    row: number,
    startTimeCol: string,
    endTimeCol: string
  ): { StartTime: Date; EndTime: Date } {
    const startTimeString = this.contentOrNull(sheet, startTimeCol, row);
    const endTimeString = this.contentOrNull(sheet, endTimeCol, row);

    if (startTimeString == null || endTimeString == null) {
      return { StartTime: null, EndTime: null };
    }
    const StartTime = this.parseDate(startTimeString).startOf('day').toDate();
    const EndTime = this.parseDate(endTimeString).endOf('day').toDate();
    return { StartTime, EndTime };
  }
}
