import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { EmployeeDashboard, TimeSlot, User, WorkTime } from "src/app/interfaces";
import { ApiService } from "src/app/services/api/api.service";


@Component({
  selector: "app-dashboard-employee-overview",
  templateUrl: "./employee-overview.component.html",
  styleUrls: ["./employee-overview.component.scss"]
})
export class DashboardEmployeeOverviewComponent implements OnInit {
  @Input() set GridWidth(value: number) {
    this.gridWidth = value;
  }
  @Input() set GridHeight(value: number) {
    this.gridHeight = value;
  }

  @Input() set IsLoading(value: boolean) {
    this.isLoading = value;
    if (value) {
      this.loadData();
    }
  }
  isLoading = false;
  gridWidth = 2;
  gridHeight = 2;


  EmployeeDashboard: EmployeeDashboard[] = this.api.Cache("employees/dashboard", []);

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.EmployeeDashboard = await this.api.Get("employees/dashboard");
  }

  openShift() {
    //TODO
  }

  checkTooLate(timeSlot: TimeSlot){
    let now = moment().toDate()
    if(timeSlot.Shift){
      let start = moment(timeSlot.Shift.StartTime).add(10,'minutes').toDate()
      if(moment(start).isAfter(moment(now))){
        return 'warning'
      }else if(moment(start).isBefore(moment(now))){
        return 'danger'
      }
    }else  if(timeSlot.WorkOrder){
      let start = moment(timeSlot.WorkOrder.StartTime).add(10,'minutes').toDate()
      if(moment(start).isAfter(moment(now))){
        return 'danger'
      }else if(moment(start).isBefore(moment(now))){
        return 'warning'
      }
    }
  }



    
}
