import { Component, ElementRef, OnInit, ViewChild, Input } from "@angular/core";
import { ApiService } from "../../../services/api/api.service";
import { ModalController } from "@ionic/angular";
import { MasterlistImportService } from "../../../services/import/masterlist-import.service";
import { Subscription } from "rxjs";
import { MasterlistService } from "../masterlist.service";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { InventoryService } from "../../../services/api/inventory.service";
import { ArrayService } from "../../../services/core/array.service";
import { Inventory, Masterlist, MasterlistImportResult, MasterlistType } from "../../../interfaces";

@Component({
  selector: "app-masterlist-import",
  templateUrl: "./masterlist-import.component.html",
  styleUrls: ["./masterlist-import.component.scss"]
})
export class MasterlistImportComponent implements OnInit {
  @ViewChild("FileSelect", { static: true }) FileSelect: ElementRef;
  @Input() Name: string;
  @Input() set Service(service: string) {
    this.masterlistImportService.setActiveService(service);
  }

  selectionConfig: {
    all: boolean;
    subset: boolean;
    excludeMasterlistWithoutType: boolean; //if true, workorder without matching types will be imported without tpye, if false they will be skipped
    time: boolean; // if true time filter is evaluated
    sheet: boolean; // if true sheet filter is evaluated
    sheets: {
      // which sheets are selected for import and which rows of that sheet, only used when sheet=true
      [key: string]: {
        selected: boolean;
        from: number;
        to: number;
        range: string;
      };
    };
    times: {
      // only Masterlists in time range are imported, only used when time=true
      from: Date;
      to: Date;
      range: { from: Date; to: Date };
    };
  };

  imports: MasterlistImportResult = { sheets: [], rows: {}, values: {} };
  selectedImports: { sheet: string; row: string; masterlist: Masterlist }[] = [];

  synchronizing = false;
  loading = false;

  subscription: Subscription;

  dragAndDropHover = false;
  hasFile = false;
  hasMasterlists = false;
  fileName: string;
  previewSettings: { Icon: any; Cols: any } = { Icon: {}, Cols: {} };

  constructor(
    private api: ApiService,
    private modalController: ModalController,
    private masterlistImportService: MasterlistImportService,
    private masterlistService: MasterlistService,
    private inventoryService: InventoryService,
    private arrayService: ArrayService,
  ) { }

  async ngOnInit() {
    this.previewSettings = await this.masterlistImportService.getPreviewSettings();
    this.synchronizing = false;
    this.subscription = this.masterlistImportService.Parsed.subscribe(async parsedElements => this.initImports(parsedElements));
    this.hasFile = false;
    this.dragAndDropHover = false;
    this.hasMasterlists = false;
    this.selectedImports = [];
    this.loading = false;
  }

  async initImports(data: MasterlistImportResult) {
    this.loading = false;
    this.hasMasterlists = false;
    this.imports = data;
    const selectAllDefault = await this.masterlistImportService.getSelectAllDefault();
    const selected = {
      all: selectAllDefault,
      excludeMasterlistWithoutType: false,
      subset: false,
      time: false,
      sheet: false,
      sheets: {},
      times: { from: null, to: null, range: { from: null, to: null } }
    };
    let timeFrom: moment.Moment;
    let timeTo: moment.Moment;
    for (const key in this.imports.values) {
      for (const masterlist of this.imports.values[key]) {
        if (!masterlist.StartTime) {
          continue;
        }
        const date = moment(masterlist.StartTime);
        if (!timeFrom || date.isBefore(timeFrom)) {
          timeFrom = date;
        }
        if (!timeTo || date.isAfter(timeFrom)) {
          timeTo = date;
        }
      }
    }
    selected.times.from = timeFrom?.toDate() || new Date();
    selected.times.to = timeTo?.toDate() || new Date();
    selected.times.range.from = selected.times.from;
    selected.times.range.to = selected.times.to;
    const selectedSheets = {};
    for (const sheet of this.imports.sheets) {
      const sheetItem: any = {
        selected: true,
        from: Number(this.imports.rows[sheet][0]),
        to: Number(this.imports.rows[sheet][this.imports.rows[sheet].length - 1])
      };
      sheetItem.range = sheetItem.from + " - " + sheetItem.to;
      selectedSheets[sheet] = sheetItem;
    }
    selected.sheets = selectedSheets;
    this.selectionConfig = selected;
    this.hasMasterlists = this.imports.sheets.length > 0;
    if (selectAllDefault) {
      this.selectAll();
    } else {
      this.selectNothing();
    }
  }

  selectSubset() {
    this.selectionConfig.all = false;
    this.selectionConfig.subset = true;
    this.filter();
  }

  setFromTime(newDate: any) {
    this.selectionConfig.times.from = moment(newDate).startOf("d").toDate();
    this.filter();
  }

  setToTime(newDate: any) {
    this.selectionConfig.times.to = moment(newDate).endOf("d").toDate();
    this.filter();
  }

  filter() {
    const selectedImports = [];
    for (const sheet of this.imports.sheets) {
      for (const row of this.imports.rows[sheet]) {
        const key = sheet + "-" + row;
        for (const masterlist of this.imports.values[key]) {
          if (!masterlist) {
            continue;
          }
          if (this.isSelectedBySheetFilter(sheet, Number(row)) && this.isSelectedByTimeFilter(masterlist)) {
            selectedImports.push({ sheet, row, masterlist });
          }
        }
      }
    }
    this.selectedImports = selectedImports;
  }

  isSelectedBySheetFilter(sheetName: string, row: number): boolean {
    const sheetSelection = this.selectionConfig.sheets[sheetName];
    return (
      !this.selectionConfig.subset ||
      !this.selectionConfig.sheet ||
      (sheetSelection.selected && row >= sheetSelection.from && row <= sheetSelection.to)
    );
  }

  isSelectedByTimeFilter(masterlist: Masterlist) {
    if (!this.selectionConfig.time) {
      return true;
    } else if (!masterlist || !masterlist.StartTime) {
      return false;
    }
    const from: moment.Moment = moment(this.selectionConfig.times.from);
    const to: moment.Moment = moment(this.selectionConfig.times.to);
    const startTime = moment(masterlist.StartTime);
    return !this.selectionConfig.subset || (startTime.isSameOrAfter(from) && startTime.isSameOrBefore(to));
  }

  selectNothing() {
    for (const key in this.selectionConfig.sheets) {
      this.selectionConfig.sheets[key].selected = false;
    }
    this.selectionConfig.sheet = true;
    this.selectionConfig.time = false;
    this.selectionConfig.subset = true;
    this.selectedImports = [];
  }

  selectAll() {
    this.selectionConfig.all = true;
    this.selectionConfig.subset = false;
    this.selectionConfig.sheet = false;
    this.selectionConfig.time = false;
    const selectedImports = [];
    for (const sheet of this.imports.sheets) {
      for (const row of this.imports.rows[sheet]) {
        const key = sheet + "-" + row;
        for (const masterlist of this.imports.values[key]) {
          if (!masterlist) {
            continue;
          }
          selectedImports.push({ sheet, row, masterlist });
        }
      }
    }
    this.selectedImports = selectedImports;
  }

  async closeModal(save: boolean = true) {
    if (save) {
      await this.save();
    } else {
      await this.modalController.dismiss();
    }
  }

  openFileSelection() {
    const e: HTMLElement = this.FileSelect.nativeElement;
    e.click();
  }

  async filesDropped(files: File[]) {
    return this.parseFile({ target: { files } });
  }

  async parseFile(event: any) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      this.fileName = event.target.files[0].name;
      this.hasFile = true;
    }
    this.loading = true;
    await this.masterlistImportService.import(event);
  }

  async save() {
    this.synchronizing = true;
    const masterlists = [];
    for (const item of this.selectedImports) {
      const masterlist = item.masterlist;
      const copy = JSON.parse(JSON.stringify({}));
      delete copy.Id;
      delete copy.CreatedAt;
      delete copy.UpdatedAt;
      for (const masterlistKey in masterlist) {
        if (masterlist.hasOwnProperty(masterlistKey) && masterlist[masterlistKey]) {
          copy[masterlistKey] = masterlist[masterlistKey];
        }
      }
      masterlists.push(copy);
    }
    console.log(masterlists)
    const writeChangeLogFile = await this.masterlistImportService.writeChangeLogFile();
    if (writeChangeLogFile) {
      const date = moment().format("YYYY_MM_DD_HH_mm_ss");
      const blob: Blob = await this.masterlistService.importAllAndDownloadFile(masterlists);

      saveAs(blob, "import_" + date + ".xlsx");
    } else {
      await this.masterlistService.importAll(masterlists);
    }
    this.synchronizing = false;
    return this.modalController.dismiss("update");
  }


  getAddress(masterlist: Masterlist): string {
    let address = "";
    if (masterlist.Street) {
      address += masterlist.Street + " ";
    }
    if (masterlist.Zip) {
      address += masterlist.Zip + " ";
    }
    if (masterlist.City) {
      address += masterlist.City;
    }
    return address;
  }
  setTimes(range: string) {
    if (range === "year") {
      this.setFromTime(moment().startOf("year"));
      this.setToTime(moment().endOf("year"));
    } else if (range === "month") {
      this.setFromTime(moment().startOf("month"));
      this.setToTime(moment().endOf("month"));
    } else {
      this.setFromTime(this.selectionConfig.times.range.from);
      this.setToTime(this.selectionConfig.times.range.to);
    }
  }
}
