import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Inventory, TimeslotElement } from "src/app/interfaces";

@Component({
  selector: "app-select-inventory-modal",
  templateUrl: "./select-inventory-modal.component.html",
  styleUrls: ["./select-inventory-modal.component.scss"],
})
export class SelectInventoryModalComponent implements OnInit {
  @Input() set Item(item: TimeslotElement) {
    this.item = item;
  }
  @Input() set SelectionList(inventories: Inventory[]) {
    this.selectionList = inventories;
    if (this.selectionList && this.selectionList.length > 0) {
      this.filteredSelectionList = [...this.selectionList];
    } else {
      this.filteredSelectionList = [];
    }
  }

  item: TimeslotElement;
  selectionList: Inventory[];
  filteredSelectionList: Inventory[] = [];
  noTeamMessage: string;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  async select(inventory: Inventory) {
    // if (inventory.Disabled) {
    //   return;
    // }
    return this.closeModal(inventory);
  }

  async closeModal(inventory?: Inventory) {
    await this.modalController.dismiss(inventory);
  }

  filter(lookup?: string) {
    const filterTerm = (lookup || "").trim().toLowerCase();
    if (!filterTerm) {
      this.filteredSelectionList =
        this.selectionList && this.selectionList.length > 0
          ? [...this.selectionList]
          : [];
    } else {
      const filteredSelectionList = [];
      for (const inventory of this.selectionList || []) {
        const name = (inventory.Name || "").toLowerCase();
        const type = (inventory.Type || "").toLowerCase();
        if (name.indexOf(filterTerm) > -1 || type.indexOf(filterTerm) > -1) {
          filteredSelectionList.push(inventory);
        }
      }
      this.filteredSelectionList = filteredSelectionList;
    }
  }
}
