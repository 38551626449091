import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[appDragAndDrop]",
})
export class DragAndDropDirective {
  @Output() hover = new EventEmitter<boolean>();
  @Output() files = new EventEmitter<any>();

  @HostListener("dragover", ["$event"]) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.hover.emit(true);
  }
  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.hover.emit(false);
  }
  @HostListener("drop", ["$event"]) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.hover.emit(false);
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.files.emit(files);
    }
  }
}
