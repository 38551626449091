import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api/api.service";
import * as moment from "moment";
import { ShiftService } from "../../services/api/shift.service";
import { TypeGroupService } from "../../services/api/type-group.service";
import { TypeGroupType } from "../../enums";
import { ShiftType, Shift } from "../../interfaces";

@Component({
  selector: "app-create-shift-button",
  templateUrl: "./create-shift-button.component.html",
  styleUrls: ["./create-shift-button.component.scss"],
})
export class CreateShiftButtonComponent implements OnInit {
  @Input() StartTime: Date;

  shiftTypes: ShiftType[] = [];
  filteredShiftTypes: ShiftType[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private shiftService: ShiftService,
    private groupService: TypeGroupService,
  ) {}

  async ngOnInit() {
    this.shiftTypes = await this.groupService.getAllTypes(TypeGroupType.SHIFT);
    await this.doFilter("");
  }

  async createShift() {
    const shift: Shift = {};
    shift.Name = "";
    shift.NumWorker = 1;
    shift.StartTime =
      this.StartTime || moment().startOf("d").set({ hours: 8 }).toDate();
    shift.EndTime = moment(shift.StartTime).add(8.5, "hours").toDate();
    return this.saveShift(shift);
  }

  createShiftFromType(shiftType: ShiftType) {
    return this.saveShift(this.shiftService.createOrUpdateFromType(shiftType));
  }

  async saveShift(shift: Shift) {
    const newShift = await this.shiftService.save(shift);
    await this.router.navigate(["/shifts/" + newShift.Id]);
  }

  async doFilter(filterInput: string) {
    if (this.shiftTypes) {
      const filter = (filterInput || "").trim().toLowerCase();
      this.filteredShiftTypes = this.shiftTypes.filter((type) => {
        if (filter === "") {
          return true;
        }
        const name = (type.Name || "").toLowerCase();
        if (name.indexOf(filter) >= 0) {
          return true;
        }
        const group = (type.Group || "").toLowerCase();
        return group.indexOf(filter) >= 0;
      });
    } else {
      this.filteredShiftTypes = [];
    }
  }
}
