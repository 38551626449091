import { Injectable } from "@angular/core";
import { AuthService } from "../../services/api/auth.service";
import { StorageService } from "../../services/storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  private imageNameCache = {};
  private loading = false;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
  ) {}

  public postFile(fileToUpload: File): Promise<string> {
    const endpoint = this.authService.serverApi + "upload";
    return new Promise<string>((resolve, reject) => {
      if (!fileToUpload) {
        resolve("Nothing to upload");
      }
      const formData: FormData = new FormData();
      formData.append("file", fileToUpload, fileToUpload.name);
      const http = new XMLHttpRequest();
      http.open("POST", endpoint);
      http.setRequestHeader("x-api-key", this.storageService.getApiKey());
      http.setRequestHeader("company-id", this.storageService.getCompanyId());
      http.onreadystatechange = () => {
        if (http.readyState === 4) {
          if (http.status === 200) {
            resolve(http.responseText);
          } else {
            reject(http.responseText);
          }
        }
      };
      http.send(formData);
    });
  }

  private getNameOfFile(FileUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const client = new XMLHttpRequest();
      client.open("GET", FileUrl, true);
      client.send();

      client.onreadystatechange = function () {
        if (this.readyState === this.HEADERS_RECEIVED) {
          const contentType = client.getResponseHeader("content-disposition");
          resolve(
            contentType
              ? contentType.split("attachment; filename=")[1]
              : "Datei",
          );
          client.abort();
        }
      };
    });
  }

  public getFileName(FileUrl: string) {
    if (!this.loading && !this.imageNameCache[FileUrl]) {
      this.loading = true;
      this.getNameOfFile(FileUrl).then((name) => {
        this.imageNameCache[FileUrl] = name;
        this.loading = false;
      });
    }
    return this.imageNameCache[FileUrl] || "Datei";
  }
}
