import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { SubtitleService } from "src/app/services/core/subtitle.service";

@Component({
  selector: "app-employee-sub-header",
  templateUrl: "./employee-sub-header.component.html",
  styleUrls: ["./employee-sub-header.component.scss"],
})
export class EmployeeSubHeaderComponent implements OnInit, OnDestroy {
  Subtitle = "";
  SubtitleObservable: Subscription;

  constructor(public subtitleService: SubtitleService) {}

  ngOnInit() {
    this.Subtitle = this.subtitleService.getSubtitle();
    this.SubtitleObservable = this.subtitleService.SubtitleObservable.subscribe(
      (title) => {
        this.Subtitle = null;
        this.Subtitle = title;
      },
    );
  }

  ngOnDestroy(): void {
    if (this.SubtitleObservable) {
      this.SubtitleObservable.unsubscribe();
    }
  }
}
