import { ToastService } from 'src/app/services/core/toast.service';
import { Injectable } from "@angular/core";
import { Platform, ToastController } from "@ionic/angular";
import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ApiService } from "../api/api.service";
import { duration } from 'moment';
import { Router } from '@angular/router';
import { du } from '@fullcalendar/core/internal-common';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: "root",
})
export class PushService {
  constructor(
    private platform: Platform,
    private api: ApiService,
    private toastController: ToastController,
    private router: Router,
    private translate: TranslateService
  ) {
    this.platform.ready().then(() => {
      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          await this.initMessaging();
        }
      });
    });
  }

  async initMessaging() {
    const app = initializeApp({
      "apiKey": "AIzaSyA5ksaS_024jrxg1qgbO5v3aDGl52iWpxE",
      "appId": "1:232729725273:web:abfed2e6ec6a955854a448",
      "authDomain": "teamcraft-6f85f.firebaseapp.com",
      "databaseURL": "https://teamcraft-6f85f.firebaseio.com",
      "measurementId": "G-VCG5X5ZQTE",
      "messagingSenderId": "232729725273",
      "projectId": "teamcraft-6f85f",
      "storageBucket": "teamcraft-6f85f.appspot.com"
    })
    const messaging = getMessaging(app);

    const Token = await getToken(messaging, { vapidKey: 'BMkvN38mE1WZculXhle5BrQPGwpryuqT0wR-rg5f3bHdD7F-HCxzt9g0VyIHvDkhPd1TAl2dOuZjKVM4mEjIQd0' })
    await this.api.post('user/push-token', {
      Type: 'web',
      Token
    })
    onMessage(messaging, async (payload) => {
      const buttons = []
      if (payload && payload.data && payload.data.detailsTopic) {
        buttons.push({
          text: this.translate.instant('show'),
          handler: async () => {
            if (payload.data.detailsId) {
              await this.router.navigateByUrl(`/${payload.data.detailsTopic}/${payload.data.detailsId}`)
            } else {
              await this.router.navigateByUrl(`/${payload.data.detailsTopic}`)
            }
          },
        })
      }
      buttons.push({
        icon: 'close-outline',
        // text: this.translate.instant('services.push.close'),
        role: 'cancel',
        handler: async () => {
        },
      })
      const toast = await this.toastController.create({
        header: payload.notification.title,
        message: payload.notification.body,
        position: 'top',
        buttons,
        color: 'tertiary'
      })
      await toast.present()
    });
  }


}
