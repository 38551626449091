import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ScheduleStorageService } from "../storage/schedule-storage.service";

@Injectable({
  providedIn: "root",
})
export class ScheduleStateService {
  VIEWS: any = this.storageService.VIEWS;

  constructor(
    private router: Router,
    private location: Location,
    private storageService: ScheduleStorageService,
  ) {}

  setFilledFormsSchedule(page: string) {
    this.location.replaceState(page);
    this.storageService.setFilledFormsSchedule(page);
  }

  setRemarkSchedule(page: string) {
    this.location.replaceState(page);
    this.storageService.setRemarkSchedule(page);
  }

  setWorkTimeSchedule(page: string) {
    this.location.replaceState(page);
    this.storageService.setWorkTimeSchedule(page);
  }

  setAbsenceSchedule(page: string) {
    this.location.replaceState(page);
    this.storageService.setAbsenceSchedule(page);
  }

  setInventorySchedule(page: string) {
    this.location.replaceState(page);
    this.storageService.setInventorySchedule(page);
  }

  setShiftSchedule(page: string) {
    this.location.replaceState(page);
    this.storageService.setShiftSchedule(page);
  }

  setWorkOrderSchedule(page: string) {
    this.location.replaceState(page);
    this.storageService.setWorkOrderSchedule(page);
  }
  setMasterlistSchedule(page: string) {
    this.location.replaceState(page);
    this.storageService.setMasterlistSchedule(page);
  }

  async goToToday(sourceUrl: string) {
    return this.router.navigate([
      this.storageService.getTodayRoute(sourceUrl) || sourceUrl,
    ]);
  }

  async goToRemarkView(sourceUrl: string) {
    return this.router.navigate([
      this.storageService.getRemarkSchedule(sourceUrl),
    ]);
  }

  async goToWorkOrderView(sourceUrl: string) {
    return this.router.navigate([
      this.storageService.getWorkOrderSchedule(sourceUrl),
    ]);
  }

  async goToWorkTimeView(sourceUrl: string) {
    return this.router.navigate([
      this.storageService.getWorkTimeSchedule(sourceUrl),
    ]);
  }

  async goToAbsenceView(sourceUrl: string) {
    return this.router.navigate([
      this.storageService.getAbsenceSchedule(sourceUrl),
    ]);
  }

  async goToInventoryView(sourceUrl: string) {
    return this.router.navigate([
      this.storageService.getInventorySchedule(sourceUrl),
    ]);
  }

  async goToShiftView(sourceUrl: string) {
    return this.router.navigate([
      this.storageService.getShiftSchedule(sourceUrl),
    ]);
  }

  async goToFilledForms(force?) {
    return this.router.navigate([
      force
        ? this.VIEWS.FILLED_FORMS_LIST
        : this.storageService.getFilledFormsSchedule() ||
          this.VIEWS.FILLED_FORMS_LIST,
    ]);
  }

  async goToRemarks(force?) {
    return this.router.navigate([
      force
        ? this.VIEWS.REMARK_LIST
        : this.storageService.getRemarkSchedule() || this.VIEWS.REMARK_LIST,
    ]);
  }

  async goToWorkTime(force?) {
    return this.router.navigate([
      force
        ? this.VIEWS.WORK_TIME_LIST
        : this.storageService.getWorkTimeSchedule() ||
          this.VIEWS.WORK_TIME_LIST,
    ]);
  }

  async goToAbsence(force?) {
    return this.router.navigate([
      force
        ? this.VIEWS.ABSENCE_LIST
        : this.storageService.getAbsenceSchedule() || this.VIEWS.ABSENCE_LIST,
    ]);
  }

  async goToInventory(force?) {
    return this.router.navigate([
      force
        ? this.VIEWS.INVENTORY_DAY
        : this.storageService.getInventorySchedule() ||
          this.VIEWS.INVENTORY_DAY,
    ]);
  }

  async goToWorkOrder(force?) {
    return this.router.navigate([
      force
        ? this.VIEWS.WORK_ORDER_MONTH
        : this.storageService.getWorkOrderSchedule() ||
          this.VIEWS.WORK_ORDER_MONTH,
    ]);
  }

  async goToShift(force?) {
    return this.router.navigate([
      force
        ? this.VIEWS.SHIFT_MONTH
        : this.storageService.getShiftSchedule() || this.VIEWS.SHIFT_MONTH,
    ]);
  }
}
