import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import * as moment from "moment";
import { SortService } from "../core/sort.service";
import { Absence, UserAbsences } from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AbsenceService {
  public ABSENCE_STATE = {
    confirmed: {
      Approved: true,
      Verified: true,
    },
    pending: {
      Approved: { eq: null },
      Verified: true,
    },
    declined: {
      Approved: false,
    },
    verification_missing: {
      Approved: true,
      Verified: { eq: null },
      File: { eq: null },
    },
    verified: {
      Approved: true,
      Verified: { eq: null },
      File: { not: null },
    },
  };

  constructor(
    private api: ApiService,
    private sortService: SortService,
    private translate: TranslateService
  ) { }

  getStatusKey(absence: Absence): string {
    let foundKey = null;
    for (const key in this.ABSENCE_STATE) {
      let matches = true;
      for (const field in this.ABSENCE_STATE[key]) {
        const condition = this.ABSENCE_STATE[key][field];
        if (condition.hasOwnProperty("eq")) {
          matches = absence[field] === condition.eq;
        } else if (condition.hasOwnProperty("not")) {
          matches = absence[field] !== condition.not;
        } else {
          matches = absence[field] === condition;
        }
        if (!matches) {
          break;
        }
      }
      if (matches) {
        foundKey = key;
        break;
      }
    }
    return foundKey || "";
  }

  async destroy(absence: Absence): Promise<void> {
    if (!absence.Id) {
      return;
    }
    await this.api.delete(
      "absences/" + absence.Id,
      this.translate.instant('die_abwesenheit_wurde_erfolgreich_gelscht'),
    );
  }

  async saveOrUpdate(absence: Absence): Promise<Absence> {
    const params = absence.Id ? "/" + absence.Id : "";
    return this.api.post(
      "absences/" + params,
      absence,
      this.translate.instant('die_abwesenheit_wurde_erfolgreich_gespeichert'),
    );
  }

  async get(id: string, useCache: boolean = false): Promise<Absence> {
    let absence: Absence;
    if (!id) {
      absence = {
        UserId: null,
        AbsenceTypeId: null,
        StartTime: null,
        EndTime: null,
      };
    } else if (useCache) {
      absence = this.api.Cache("absences/" + id, {});
    } else {
      absence = await this.api.get("absences/" + id);
    }
    absence = absence || {};
    absence.SpecialFields = absence.SpecialFields || {};
    absence.Status = this.getStatusKey(absence);
    return absence;
  }

  async getWithAllowedAbsences(
    id: string,
    userId?: string,
    useCache: boolean = false,
  ): Promise<{
    Absence: Absence;
    Days: { AllowedAbsences: any; ApprovedAbsences: any };
  }> {
    const Absence = await this.get(id, useCache);
    Absence.UserId = Absence.UserId || userId;
    const Year =
      Absence && Absence.StartTime ? moment(Absence.StartTime).year() : null;
    const Days = await this.getAllowedAbsences(Absence.UserId, Year, useCache);
    return { Absence, Days };
  }

  async getAllowedAbsences(
    userId: string,
    year?: number,
    useCache: boolean = false,
  ): Promise<{ AllowedAbsences: any; ApprovedAbsences: any }> {
    let days = { AllowedAbsences: {}, ApprovedAbsences: {} };
    if (userId) {
      if (useCache) {
        days = this.api.Cache("employees/absences/allowed/" + userId, {});
      } else {
        days = await this.api.get(
          "employees/absences/allowed/" + userId,
          year ? { Year: year } : null,
        );
      }
    }
    return days;
  }

  async getAllByUserId(
    userId: string,
    useCache: boolean = false,
  ): Promise<UserAbsences> {
    let userAbsences: UserAbsences;
    if (useCache) {
      userAbsences = this.api.Cache("employees/absences/by-year/" + userId, {});
    } else {
      userAbsences = await this.api.get("employees/absences/by-year/" + userId);
    }
    for (const year in userAbsences) {
      userAbsences[year].Absences = [];
      for (const absenceTypeId in userAbsences[year].AbsenceTypes) {
        if (
          userAbsences[year].AbsenceTypes[absenceTypeId].Absences.length > 0
        ) {
          for (const absence of userAbsences[year].AbsenceTypes[absenceTypeId]
            .Absences) {
            absence.Status = this.getStatusKey(absence);
            userAbsences[year].Absences.push(absence);
          }
        }
      }
      this.sortService.sortAscByFields(
        userAbsences[year].Absences,
        "StartTime",
      );
    }

    return userAbsences;
  }

  async getActiveByUserId(
    userId: string,
    useCache: boolean = false,
  ): Promise<Absence> {
    if (!userId) {
      return {};
    }
    if (useCache) {
      return this.api.Cache("employees/" + userId + "/active-absence", {});
    }
    return this.api.get("employees/" + userId + "/active-absence");
  }

  async getAll(
    useCache: boolean = false,
    objectFilter?: any,
    timeFilter?: any,
  ): Promise<Absence[]> {
    if (useCache) {
      return this.api.Cache("absences", []);
    }
    let filter = Object.assign({}, objectFilter);
    if (filter.hasOwnProperty("Absence_-_Status")) {
      const key = filter["Absence_-_Status"]?.key;
      const statusFilter =
        key && this.ABSENCE_STATE[key] ? { ...this.ABSENCE_STATE[key] } : [];
      if (statusFilter) {
        filter = Object.assign({}, filter, statusFilter);
      }
      delete filter["Absence_-_Status"];
    }
    timeFilter = timeFilter || {};
    if (filter.StartTime) {
      timeFilter.Start = filter.StartTime;
    }
    if (filter.EndTime) {
      timeFilter.End = filter.EndTime;
    }
    const params: any = {
      SoftTimeFilter: JSON.stringify(timeFilter),
      Filter: JSON.stringify(filter),
      Qualification:
        filter["Qualification_-_Name"] &&
          filter["Qualification_-_Name"].length > 0
          ? true
          : "",
      Team:
        filter["Team_-_Name"] && filter["Team_-_Name"].length > 0 ? true : "",
    };
    const absences: Absence[] = await this.api.get("absences", params);
    for (const absence of absences) {
      absence.Status = this.getStatusKey(absence);
    }
    return absences;
  }

  async getAbsentUserIds(start: any, end: any): Promise<string[]> {
    return this.api.get(`absences/absences-between/${start}/${end}`);
  }
}
