import { Injectable } from "@angular/core";
import { SortService } from "../core/sort.service";
import { DisplaySettingService } from "../core/display-setting.service";
import { Trigger } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class TriggerService {
  constructor(
    private sortService: SortService,
    private displaySettingService: DisplaySettingService,
  ) {}

  sortAll(input: Trigger[]): Trigger[] {
    const triggers = input || [];
    for (const trigger of triggers) {
      this.sortService.sortByFields(trigger.Qualifications, "asc", "Name");
      let setting = this.displaySettingService.getUserSetting();
      this.sortService.sortByComparators(
        trigger.Users,
        ...(setting.sorting == "desc"
          ? setting.customOrder
            ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
            : this.sortService.USER_COMPARATORS_DESC
          : setting.customOrder
          ? this.sortService.USER_COMPARATORS_CUSTOM
          : this.sortService.USER_COMPARATORS),
      );
      // for angular change detection
      trigger.Qualifications =
        trigger.Qualifications?.length > 0 ? [...trigger.Qualifications] : [];
      trigger.Users = trigger.Users?.length > 0 ? [...trigger.Users] : [];
    }
    return triggers;
  }
}
