import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
})
export class DatepickerComponent implements OnInit {
  constructor() {}
  @Input() StartDate: Date;
  currentHours: any;
  currentMinutes: any;

  ngOnInit() {
    this.currentHours = this.StartDate.getHours();
    this.currentMinutes = this.StartDate.getMinutes();
  }
}
