/* tslint:disable:no-string-literal */
import { ExportService } from "./export.service";
import { AbstractXlsxExportService } from "./abstract-xlsx-export.service";
import { TranslateService } from "@ngx-translate/core";
import { DisplaySettingService } from "../core/display-setting.service";
import { HasSpecialField } from "../../interfaces";

export abstract class AbstractDetailXlsxExportService<
  Type,
> extends AbstractXlsxExportService {
  protected constructor(
    protected exportService: ExportService,
    translateService: TranslateService,
    settingService: DisplaySettingService,
  ) {
    super(translateService, settingService);
  }

  abstract get BaseDataKeyValueMap(): any;
  abstract get TypeNameTranslated(): string;

  protected createBaseDateSheet(element: Type, keyValueMap: any): any {
    const sheet: any = {};
    let columnCount = 0;
    for (const key in keyValueMap) {
      if (keyValueMap.hasOwnProperty(key)) {
        const column = this.indexToColumn(columnCount++);
        const getValue = keyValueMap[key];
        this.setCellValue(sheet, column + 1, this.translate(key));
        this.setCellValue(sheet, column + 2, getValue(element));
      }
    }
    if (element.hasOwnProperty("SpecialFields")) {
      const specialFieldElement = element as HasSpecialField;
      for (const specialField in specialFieldElement.SpecialFields) {
        if (
          specialFieldElement.SpecialFields.hasOwnProperty(specialField) &&
          typeof specialFieldElement.SpecialFields[specialField] !== "object"
        ) {
          const column = this.indexToColumn(columnCount++);
          this.setCellValue(sheet, column + 1, specialField);
          this.setCellValue(
            sheet,
            column + 2,
            specialFieldElement.SpecialFields[specialField],
          );
        }
      }
    }
    // SheetSettings
    sheet["!ref"] = "A1:" + this.indexToColumn(columnCount - 1) + "2";
    sheet["!cols"] = this.getWidths(sheet, 1);
    return sheet;
  }

  protected getFileName(element: Type): string {
    const name = element["Name"] ? element["Name"] : "";
    return (
      this.TypeNameTranslated +
      (name && name.length > 0
        ? "_" + name
        : element["Id"]
        ? "_" + element["Id"]
        : "")
    );
  }

  public async export(element: Type) {
    if (!element) {
      return;
    }
    const sheet = this.createBaseDateSheet(element, this.BaseDataKeyValueMap);
    await this.exportService.exportSheetToXlsx(
      sheet,
      this.getFileName(element),
      this.TypeNameTranslated,
    );
  }
}
