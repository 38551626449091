import { Component } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent {
  //Wenn komplett neu:

  PaymentData: any = {
    isAdmin: true,
    expirationDate: "20-07-2020",
    currentPlan: 1,
    currentUsers: 10,
    maxUsers: 1,
  };

  Countdown: number;
  PaymentNoticeOpen = false;
  constructor() {}

  ionViewWillEnter() {
    this.Countdown = moment
      .duration(
        moment(this.PaymentData.expirationDate, "DD-MM-YYYY").diff(moment()),
      )
      .asDays();

    if (
      this.PaymentData.isAdmin === true &&
      this.PaymentData.currentUsers > this.PaymentData.maxUsers
    ) {
      this.PaymentNoticeOpen = true;
    }
  }

  ClosePaymentNotice() {
    this.PaymentNoticeOpen = false;
  }
}
