import { SkyDeProgramPlanImportService } from './sky-de-program-plan-import.service';
import { Injectable } from "@angular/core";
import { WorkBook } from "xlsx";
import { AbstractImportService } from "./abstract-import.service";
import { SkyAutWorkOrderImportService } from "./sky-aut-work-order-import.service";
import { AbstractCustomWorkOrderImportService } from "./abstract-custom-work-order-import.service";
import { SettingsService } from "../api/settings.service";
import { ToastService } from "../core/toast.service";
import { SkyDeWorkOrderImportService } from "./sky-de-work-order-import.service";
import { SkyDeLiveSportWorkOrderImportService } from "./sky-de-live-sport-work-order-import.service";
import { LeasingImportResult, WorkOrder, WorkOrderImportResult } from "../../interfaces";
import { AbstractCustomLeasingImportService } from './abstract-custom-leasing-import.service';
import { BuchMichLeasingImportService } from './buch-mich-leasing-import.service';

@Injectable({
  providedIn: "root",
})
export class LeasingImportService extends AbstractImportService<any[]> {
  services: {
    [key: string]: AbstractCustomLeasingImportService;
  } = {};
  activeService: AbstractCustomLeasingImportService = null;

  constructor(
    private buchMichLeasingImportService: BuchMichLeasingImportService,
    private settingService: SettingsService,
    toastService: ToastService,
  ) {
    super(toastService);
    const serviceArray: AbstractCustomLeasingImportService[] = [
      buchMichLeasingImportService,
    ];
    for (const service of serviceArray) {
      this.services[service.getKey()] = service;
    }
  }

  getKeys(): string[] {
    return Object.keys(this.services);
  }
  async setActiveService(service) {
    this.activeService = await this.getService(service);
  }

  async getService(Service?): Promise<AbstractCustomLeasingImportService> {
    let serviceBySetting;
    if(Service) {
      serviceBySetting = Service
    } else {
      serviceBySetting = await this.settingService.getStringValue(
        "LeasingImportService",
      );
    }
    return serviceBySetting ? this.services[serviceBySetting] : null;
  }

  public async getPreviewSettings(): Promise<{ Icon: any; Cols: any }> {
    if (this.activeService == null) {
      return { Icon: {}, Cols: {} };
    }
    return this.activeService.getPreviewSettings();
  }

  public async createElements(
    workBook: WorkBook,
  ): Promise<LeasingImportResult> {
    if (this.activeService == null) {
      await this.toastService.presentError(
        "Keine Importkonfiguration für das Unternehmen gefunden",
      );
      return { sheets: [], rows: {}, values: {} };
    }
    return this.activeService.createElements(workBook);
  }

  public async getSelectAllDefault(): Promise<boolean> {
    if (!this.activeService) {
      return true;
    }
    return this.activeService.getSelectAllDefault();
  }

  public async writeChangeLogFile(): Promise<boolean> {
    if (!this.activeService) {
      return false;
    }
    return this.activeService.writeChangeLogFile();
  }
}
