import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { ArrayService } from "../../services/core/array.service";
import { DisplaySettingService } from "../../services/core/display-setting.service";
import { SortService } from "../../services/core/sort.service";
import { Subscription } from "rxjs";
import { TriggerService } from "../../services/api/trigger.service";
import { InteractionFormService } from "../../services/api/interaction-form.service";
import { SpecialFieldType } from "../../enums";
import {
  InteractionForm,
  Qualification,
  SpecialField,
  Trigger,
  User,
  KeyValuePair,
  Comparator,
  Team,
} from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-trigger",
  templateUrl: "./trigger.component.html",
  styleUrls: ["./trigger.component.scss"],
})
export class TriggerComponent implements OnInit, OnDestroy {
  @Input() set Triggers(Triggers: Trigger[]) {
    this.triggers = Triggers || [];
    this.updateTriggers();
  }

  @Input() set Teams(teams: Team[]) {
    this.teams = teams || [];
  }

  @Input() set SelectableTeams(selectableTeams: Team[]) {
    this.selectableTeams = selectableTeams || [];
    this.updateTeamSelection();
  }

  @Input() set QualificationIds(qualificationIds: string[]) {
    this.qualificationIds = qualificationIds;
    this.updateQualificationSelection();
    this.updateComponentFieldDisabled();
  }

  @Input() CollapseState: { [key: string]: boolean } = {};

  @Output() TriggersChange = new EventEmitter<Trigger[]>();
  @Input() Component = "";
  @Input() Mode = "";

  qualificationIds: string[];
  interactionForms: InteractionForm[] = this.api.Cache("interaction-forms", []);
  interactionFormsMap: { [id: string]: InteractionForm } = {};
  triggers: Trigger[] = [];
  specialFields: SpecialField[] = this.api.Cache(
    "special-fields/component/" + this.Component,
    [],
  );
  qualifications: Qualification[] = [];
  users: User[] = [];
  teams: Team[] = [];
  selectableTeams: Team[] = [];
  componentFields: (KeyValuePair & { Order: number; disabled?: boolean })[] = [
    { Key: "Name", Value: this.translate.instant("bezeichnung"), Order: 0, disabled: false },
    { Key: "Description", Value: this.translate.instant("beschreibung"), Order: 1, disabled: false },
    { Key: "Street", Value: this.translate.instant("auftragsort_strae"), Order: 2, disabled: false },
    { Key: "Zip", Value: this.translate.instant("auftragsort_plz"), Order: 3, disabled: false },
    { Key: "City", Value: this.translate.instant("auftragsort_stadt"), Order: 4, disabled: false },
    { Key: "StartTime", Value: this.translate.instant("startzeit"), Order: 5, disabled: false },
    { Key: "EndTime", Value: this.translate.instant("endzeit"), Order: 6, disabled: false },
  ];
  loaded: boolean = false;
  userSortSubscription: Subscription;

  constructor(
    private api: ApiService,
    private arrayService: ArrayService,
    private displaySettingService: DisplaySettingService,
    private sortService: SortService,
    private triggerService: TriggerService,
    private formService: InteractionFormService,
    private translate:TranslateService
  ) {}

  async ngOnInit() {
    this.loaded = false;
    this.userSortSubscription = this.displaySettingService.UserSort.subscribe(
      () => {
        this.sortUsers(this.users);
        this.triggerService.sortAll(this.triggers);
        this.users = this.users?.length > 0 ? [...this.users] : [];
      },
    );
    const [specialFields, interactionsForms, users, qualifications] =
      await Promise.all([
        this.api.get("special-fields/component/" + this.Component),
        this.formService.getAll(),
        this.api.get("employees/list"),
        this.api.get("qualifications"),
      ]);

    this.specialFields = specialFields.filter(
      (specialField) => specialField.Type != SpecialFieldType.EVALUATION,
    );
    this.interactionForms = interactionsForms;
    this.users = this.sortUsers(users);
    this.qualifications = qualifications;
    this.updateQualificationSelection();
    this.updateTeamSelection();
    this.updateComponentFields();
    this.updateComponentFieldDisabled();
    this.interactionFormsMap = this.arrayService.toMap(this.interactionForms);
    this.updateTriggers();
    this.loaded = true;
  }

  async ngOnDestroy() {
    if (this.userSortSubscription) {
      this.userSortSubscription.unsubscribe();
    }
  }

  updateTeamSelection() {
    if (!this.teams || this.teams.length === 0) {
      return;
    }
    if (!this.selectableTeams || this.selectableTeams.length === 0) {
      (this.teams || []).forEach((team) => (team.disabled = false));
    } else {
      const teamIdMap = this.arrayService.toMap(this.selectableTeams);
      for (const team of this.teams) {
        if (!teamIdMap[team.Id]) {
          team.disabled = true;
        } else {
          team.disabled = false;
        }
      }
    }
    this.sortService.sortAscByFields(this.teams, "disabled", "Name");
    this.teams = [...this.teams];
  }

  updateQualificationSelection() {
    if (!this.qualifications) {
      return;
    } else if (!this.qualificationIds || this.qualificationIds.length === 0) {
      this.qualifications.forEach(
        (qualification) => (qualification.disabled = false),
      );
    } else {
      const qualificationIdMap = this.arrayService.toMap(
        this.qualificationIds,
        null,
      );
      for (const qualification of this.qualifications || []) {
        if (!qualificationIdMap[qualification.Id]) {
          qualification.disabled = true;
        } else {
          qualification.disabled = false;
        }
      }
    }
    this.sortService.sortAscByFields(this.qualifications, "disabled", "Name");
    this.qualifications = [...this.qualifications];
  }

  updateComponentFieldDisabled() {
    const qualificationIdMap = this.arrayService.toMap(
      this.qualificationIds,
      null,
    );
    this.componentFields.forEach((field) => (field.disabled = false));
    for (const field of this.componentFields) {
      const [association, id] = field.Key.split("--.--");
      if (this.qualificationIds && this.qualificationIds.length > 0) {
        field.disabled =
          association === "Qualifications" && !qualificationIdMap[id];
      }
    }
    const comparators: Comparator[] = [
      {
        field: "disabled",
        order: 0,
      },
      {
        field: "Order",
        order: 1,
        compareFct: (orderA: number, orderB: number) => orderA - orderB,
      },
    ];
    this.sortService.sortByComparators(this.componentFields, ...comparators);
  }

  updateComponentFields() {
    this.componentFields.push({
      Key: this.Component + "Type",
      Value: this.translate.instant("Typ"),
      Order: this.componentFields.length,
    });
    for (const qualification of this.qualifications || []) {
      this.componentFields.push({
        Key: "Qualifications" + "--.--" + qualification.Id,
        Value: this.translate.instant('qualifikation') + " > " + qualification.Name,
        Order: this.componentFields.length,
      });
    }
    for (const specialField of this.specialFields || []) {
      this.componentFields.push({
        Key: "SpecialFields" + "--.--" + specialField.Key,
        Value: this.translate.instant('spezialfeld') +" > " + specialField.Key,
        Order: this.componentFields.length,
      });
    }
    this.updateComponentFieldDisabled();
  }

  sortUsers(users: User[]): User[] {
    let setting = this.displaySettingService.getUserSetting();
    this.sortService.sortByComparators(
      users,
      ...(setting.sorting == "desc"
        ? setting.customOrder
          ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
          : this.sortService.USER_COMPARATORS_DESC
        : setting.customOrder
        ? this.sortService.USER_COMPARATORS_CUSTOM
        : this.sortService.USER_COMPARATORS),
    );
    return users;
  }

  updateTriggers() {
    for (let trigger of this.triggers) {
      if (trigger.InteractionFormId && trigger.Type === "Form") {
        trigger.Fields = trigger.Fields || [];
        trigger.Tables = trigger.Tables || [];
        trigger.TableCells = trigger.TableCells || [];
        trigger.InteractionForm =
          this.interactionFormsMap[trigger.InteractionFormId];
      }
    }
    this.triggerService.sortAll(this.triggers);
  }

  addTrigger() {
    this.triggers.push({
      Fields: [],
      Tables: [],
      TableCells: [],
      Mode: this.Mode,
      Reactions: { Active: false, Users: [], Mails: [] },
    });
    this.TriggersChange.emit(this.triggers);
    this.CollapseState[this.triggers.length - 1] = true;
  }

  removeTrigger(index: number) {
    this.triggers.splice(index, 1);
    if (this.triggers.length === 0) {
      this.triggers = [];
    } else {
      this.triggers = [...this.triggers];
    }
    this.TriggersChange.emit(this.triggers);
  }

  copyTrigger(index: number) {
    const triggerToCopy = JSON.parse(JSON.stringify(this.triggers[index]));
    let deleteOld = false;
    if (triggerToCopy.Mode !== this.Mode) {
      triggerToCopy.Mode = this.Mode;
      deleteOld = true;
    }
    delete triggerToCopy.Id;
    delete triggerToCopy.WorkOrderId;
    delete triggerToCopy.WorkOrderTypeId;
    delete triggerToCopy.ShiftId;
    delete triggerToCopy.ShiftTypeId;
    this.triggers.push(triggerToCopy);
    if (deleteOld) {
      this.removeTrigger(index);
    }
  }

  handleToggle(index: number) {
    this.CollapseState[index] = this.CollapseState[index] || false;
    this.CollapseState[index] = !this.CollapseState[index];
  }
}
