import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { InteractionForm } from "../../interfaces";

@Component({
  selector: "app-interaction-form-select-modal",
  templateUrl: "./interaction-form-select-modal.component.html",
  styleUrls: ["./interaction-form-select-modal.component.scss"],
})
export class InteractionFormSelectModalComponent implements OnInit {
  @Input() InteractionForms: InteractionForm[];

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  async close(data?: any) {
    return this.modalController.dismiss(data);
  }

  async select(id?: string) {
    return this.close({ Id: id });
  }

  async dismiss() {
    return this.close({ Dismiss: true });
  }
}
