import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PlatformStorageService } from "../../services/storage/platform-storage.service";
import { Subscription } from "rxjs";
import { CustomImportSetting } from "../../interfaces";

@Component({
  selector: "app-import",
  templateUrl: "./import.component.html",
  styleUrls: ["./import.component.scss"]
})
export class ImportComponent implements OnInit, OnDestroy {
  @Output() Close = new EventEmitter<boolean>();
  @Input() CustomImports: CustomImportSetting[] = [];

  isTablet: boolean = true;
  platformSubscription: Subscription;

  constructor(private modalController: ModalController, private platformStorage: PlatformStorageService) {}

  ngOnInit(): void {
    this.isTablet = this.platformStorage.getIsTabled();
    this.platformSubscription = this.platformStorage.Update.subscribe(() => (this.isTablet = this.platformStorage.getIsTabled()));
  }

  ngOnDestroy(): void {
    if (this.platformSubscription) {
      this.platformSubscription.unsubscribe();
    }
  }

  async openModal(setting) {
    const obj = {
      component: setting.Component,
      cssClass: "work-order-import-modal",
      componentProps: {
        Name: setting.Name,
        Service: setting.Key
      }
    };
    if (setting.ComponentCssClass) {
      obj.cssClass = setting.ComponentCssClass;
    }
    const modal = await this.modalController.create(obj);
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response) {
      this.Close.emit(!!response.data);
    }
  }
}
