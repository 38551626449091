import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api/api.service";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { BreakType } from "../../interfaces";

@Component({
  selector: "app-break-type-detail-content",
  templateUrl: "./break-type-detail-content.component.html",
  styleUrls: ["./break-type-detail-content.component.scss"],
})
export class BreakTypeDetailContentComponent implements OnInit {
  @Input() set Id(id: string) {
    const previousId = this.id;
    this.id = id;
    if (this.isInitialized && previousId != id) {
      this.loadBreakType();
    }
  }

  @Input() SplitViewMode;
  @Output() Reload = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() CloseView = new EventEmitter<void>();

  id: string;

  breakType: BreakType = {};
  isLoading: boolean;
  isInitialized = false;
  triggerCollapseState: { [key: string]: boolean } = {};

  constructor(
    private api: ApiService,
    private location: Location,
    private translate: TranslateService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.isInitialized = false;
    this.isLoading = true;
    this.triggerCollapseState = {};
    await this.loadBreakType();
    this.isLoading = false;
    this.isInitialized = true;
  }

  async loadBreakType() {
    if (this.id === "create") {
      this.breakType = { Name: "", Minutes: 0, Description: "" };
    } else {
      this.breakType = await this.api.get("break-types/" + this.id);
    }
  }

  async save(goBack: boolean = false) {
    let response;
    if (this.breakType.Id) {
      response = await this.api.post(
        "break-types/" + this.breakType.Id,
        this.breakType,
      );
    } else {
      response = await this.api.post("break-types", this.breakType);
    }

    this.Reload.emit();
    if (goBack && !this.SplitViewMode) {
      return this.goBack();
    }
    if (response.Id != this.id) {
      this.id = response.Id;
      if (!this.SplitViewMode) {
        this.location.replaceState("/break-types/" + this.id);
      }
    }
    return this.loadBreakType();
  }

  async delete() {
    await this.api.delete("break-types/" + this.id);
    this.Remove.emit();
    return this.goBack();
  }

  async goBack() {
    if (this.SplitViewMode) {
      return;
    }
    return this.router.navigate(["/break-types"]);
  }

  getDeleteMessage(): string {
    return (
      this.translate.instant("pages.break_type.break_type_detail.deleteMsg1") +
      "<br>" +
      this.translate.instant("pages.break_type.break_type_detail.deleteMsg2")
    ); 
  }
}
