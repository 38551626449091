import { ShiftGeneratorComponent } from "../../pages/shift-generator/shift-generator.component";
import { Injectable } from "@angular/core";
import { EmployeeListComponent } from "../../pages/employee/employee-list/employee-list.component";
import { EmployeeDataComponent } from "../../pages/employee/employee-detail/employee-data/employee-data.component";
import { EmployeeDetailComponent } from "../../pages/employee/employee-detail/employee-detail.component";
import { EmployeeAbsencesComponent } from "../../pages/employee/employee-detail/employee-absences/employee-absences.component";
import { EmployeeWorkTimeComponent } from "../../pages/employee/employee-detail/employee-work-time/employee-work-time.component";
import { TeamListComponent } from "../../pages/team/team-list/team-list.component";
import { TeamDetailComponent } from "../../pages/team/team-detail/team-detail.component";
import { AbsenceListComponent } from "../../pages/absence/absence-list/absence-list.component";
import { AbsenceDetailComponent } from "../../pages/absence/absence-detail/absence-detail.component";
import { WorkTimeListComponent } from "../../pages/work-time/work-time-list/work-time-list.component";
import { WorkTimeDetailComponent } from "../../pages/work-time/work-time-detail/work-time-detail.component";
import { StatisticsComponent } from "../../pages/statistics/statistics.component";
import { WorkOrderTypeListComponent } from "../../pages/work-order-type/work-order-type-list/work-order-type-list.component";
import { WorkOrderTypeDetailComponent } from "../../pages/work-order-type/work-order-type-detail/work-order-type-detail.component";
import { ShiftTypeListComponent } from "../../pages/shift-type/shift-type-list/shift-type-list.component";
import { ShiftTypeDetailComponent } from "../../pages/shift-type/shift-type-detail/shift-type-detail.component";
import { TaskListComponent } from "../../pages/task/task-list/task-list.component";
import { TaskDetailComponent } from "../../pages/task/task-detail/task-detail.component";
import { QualificationListComponent } from "../../pages/qualifications/qualification-list/qualification-list.component";
import { QualificationDetailComponent } from "../../pages/qualifications/qualification-detail/qualification-detail.component";
import { FilledFormListComponent } from "../../pages/filled-form/filled-form-list/filled-form-list.component";
import { InteractionFormListComponent } from "../../pages/interaction-form/interaction-form-list/interaction-form-list.component";
import { InteractionFormDetailComponent } from "../../pages/interaction-form/interaction-form-detail/interaction-form-detail.component";
import { FilledFormDetailComponent } from "../../pages/filled-form/filled-form-detail/filled-form-detail.component";
import { ApiService } from "../api/api.service";
import * as moment from "moment";
import { AuthService } from "../api/auth.service";
import { Observable, Subject } from "rxjs";
import { MenuItem } from "../../enums";
import { TranslateService } from "@ngx-translate/core";
import { SettingsService } from "../api/settings.service";
import { InventoryListComponent } from "../../pages/inventory/inventory-list/inventory-list.component";
import { InventoryDetailComponent } from "../../pages/inventory/inventory-detail/inventory-detail.component";
import { TranslateEnumPipe } from "../../pipes/translate-enum.pipe";
import { PermissionService } from "../api/permission.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class GuardService {
  CachedMenuItems: any;
  CacheTime: moment.Moment;
  subject = new Subject<void>();

  constructor(
    private auth: AuthService,
    private translateService: TranslateService,
    private translateEnumPipe: TranslateEnumPipe,
    private settingService: SettingsService,
    private permissionService: PermissionService
  ) {
    this.generateViewsMap();
  }

  translate(key: string): string {
    return this.translateService.instant(`services.PermissionAccessService.${key}`);
  }

  translateEnum(key: string): string {
    return this.translateEnumPipe.transform(key, "MenuItem");
  }

  async generateViewsMap(): Promise<any> {
    const DefaultMenuItems = {};
    this.addMenuItem(DefaultMenuItems, "EmployeeListComponent", this.translate("EmployeeListComponent"));
    this.addMenuItem(DefaultMenuItems, "EmployeeDetailComponent", this.translate("EmployeeDetailComponent"), "EmployeeListComponent");
    this.addMenuItem(DefaultMenuItems, "EmployeeDataComponent", "Allgemein", "EmployeeDetailComponent");
    this.addMenuItem(DefaultMenuItems, "EmployeeAbsencesComponent", "Abwesenheiten", "EmployeeDetailComponent");
    this.addMenuItem(DefaultMenuItems, "EmployeeWorkTimeComponent", "Arbeitszeiten", "EmployeeDetailComponent");
    this.addMenuItem(DefaultMenuItems, "EmployeeEmploymentsComponent", "Verträge", "EmployeeDetailComponent");
    if (await this.settingService.getBooleanValue("UseAdditionalUserInformation")) {
      this.addMenuItem(
        DefaultMenuItems,
        "EmployeeAdditionalUserInformationsComponent",
        "Zusätzliche Informationen",
        "EmployeeDetailComponent"
      );
      this.addMenuItem(DefaultMenuItems, "EmployeeDocumentsComponent", "Dokumente", "EmployeeDetailComponent");
    }
    if (await this.settingService.getBooleanValue("UseUserActivities")) {
      this.addMenuItem(DefaultMenuItems, "EmployeeActivityComponent", "Einsätze", "EmployeeDetailComponent");
    }
    if (await this.settingService.getBooleanValue("UseLeasing")) {
      this.addMenuItem(DefaultMenuItems, "EmployeeLeasingsComponent", "Leasing", "EmployeeDetailComponent");
    }
    if (await this.settingService.getBooleanValue("UseFees")) {
      this.addMenuItem(DefaultMenuItems, "EmployeeFeesComponent", "Honorare", "EmployeeDetailComponent");
    }
    this.addMenuItem(DefaultMenuItems, "TeamListComponent", "Teams");
    this.addMenuItem(DefaultMenuItems, "TeamDetailComponent", "Team bearbeiten", "TeamListComponent");
    this.addMenuItem(DefaultMenuItems, MenuItem.SHIFTS, this.translateEnum(MenuItem.SHIFTS));
    this.addMenuItem(DefaultMenuItems, MenuItem.WORK_ORDERS, this.translateEnum(MenuItem.WORK_ORDERS));
    if (await this.settingService.getBooleanValue("ShiftGenerator")) {
      this.addMenuItem(DefaultMenuItems, "ShiftGeneratorComponent", this.translate("ShiftGeneratorComponent"));
    }
    if (await this.settingService.getBooleanValue("Timetable")) {
      this.addMenuItem(DefaultMenuItems, "TimetablePage", this.translate("TimetablePage"));
    }
    this.addMenuItem(DefaultMenuItems, "AbsenceListComponent", "Abwesenheiten");
    this.addMenuItem(DefaultMenuItems, "AbsenceDetailComponent", "Abwesenheit bearbeiten", "AbsenceListComponent");
    this.addMenuItem(DefaultMenuItems, "WorkTimeListComponent", "Arbeitszeiten");
    this.addMenuItem(DefaultMenuItems, "WorkTimeDetailComponent", "Arbeitszeit bearbeiten", "WorkTimeListComponent");
    if (await this.settingService.getBooleanValue("UseMaterials")) {
      this.addMenuItem(DefaultMenuItems, "InventoryListComponent", this.translate("InventoryListComponent"));
      this.addMenuItem(DefaultMenuItems, "InventoryDetailComponent", this.translate("InventoryDetailComponent"), "InventoryListComponent");
      this.addMenuItem(DefaultMenuItems, "InventorySchedule", this.translate("InventorySchedule"), "InventoryListComponent");
    }
    // this.addMenuItem(DefaultMenuItems, 'StatisticsComponent', 'Statistiken');
    this.addMenuItem(DefaultMenuItems, MenuItem.SETTINGS, this.translateEnum(MenuItem.SETTINGS));
    this.addMenuItem(DefaultMenuItems, "WorkOrderTypeListComponent", this.translate("WorkOrderTypeListComponent"), MenuItem.SETTINGS);
    this.addMenuItem(
      DefaultMenuItems,
      "WorkOrderTypeDetailComponent",
      this.translate("WorkOrderTypeDetailComponent"),
      "WorkOrderTypeListComponent"
    );
    this.addMenuItem(DefaultMenuItems, "ShiftTypeListComponent", this.translate("ShiftTypeListComponent"), MenuItem.SETTINGS);
    this.addMenuItem(DefaultMenuItems, "ShiftTypeDetailComponent", this.translate("ShiftTypeDetailComponent"), "ShiftTypeListComponent");
    this.addMenuItem(DefaultMenuItems, "TypeGroupListComponent", "Vorlagen-Gruppen", MenuItem.SETTINGS);
    this.addMenuItem(DefaultMenuItems, "TypeGroupDetailComponent", "Vorlagen-Gruppe bearbeiten", "TypeGroupListComponent");
    this.addMenuItem(DefaultMenuItems, "CalendarListComponent", "Kalender", MenuItem.SETTINGS);
    this.addMenuItem(DefaultMenuItems, "CalendarDetailComponent", "Kalender bearbeiten", "CalendarListComponent");
    this.addMenuItem(DefaultMenuItems, "QualificationListComponent", "Qualifikationen", MenuItem.SETTINGS);
    this.addMenuItem(DefaultMenuItems, "QualificationDetailComponent", "Qualifikation bearbeiten", "QualificationListComponent");
    this.addMenuItem(DefaultMenuItems, "InteractionFormListComponent", "Formulare", MenuItem.SETTINGS);
    this.addMenuItem(DefaultMenuItems, "InteractionFormDetailComponent", "Formular bearbeiten", "InteractionFormListComponent");
    this.addMenuItem(DefaultMenuItems, "FilledFormListComponent", "Ausgefüllte Formulare", MenuItem.SETTINGS);
    this.addMenuItem(DefaultMenuItems, "FilledFormDetailComponent", "Ausgefüllte Formulare anzeigen", "FilledFormListComponent");
    if (await this.settingService.getBooleanValue("showMasterlist")) {
      this.addMenuItem(DefaultMenuItems, "MasterlistPage", this.translate("MasterlistPage"));
    }
    return DefaultMenuItems;
  }

  get MenuItems(): Observable<void> {
    return this.subject.asObservable();
  }

  async getAllMenuItemsByRoleName(roleName: string): Promise<any> {
    const permission = await this.permissionService.getMenuPermission(roleName);
    if (permission?.MenuItemsDefinition) {
      return this.updateMenuItems(permission.MenuItemsDefinition);
    }
    return this.getDefaultMenuItems();
  }

  private async updateMenuItems(menuItems: any) {
    const newMenuItems = await this.getDefaultMenuItems();
    this.updateMenuItemsFromDefault(newMenuItems, menuItems);
    if (JSON.stringify(newMenuItems) !== JSON.stringify(menuItems)) {
      return newMenuItems;
    }
    return menuItems;
  }

  private updateMenuItemsFromDefault(defaultMenuItems: any, menuItems: any) {
    for (const key of this.getKeysOfMenuItem(defaultMenuItems)) {
      const item = this.getMenuItem(menuItems, key);
      if (item) {
        defaultMenuItems[key]._access = item._access;
        this.updateMenuItemsFromDefault(defaultMenuItems[key], menuItems);
      }
    }
  }

  private async getAllMenuItems() {
    if (!this.CacheTime || moment().diff(this.CacheTime, "minutes") > 5) {
      this.CachedMenuItems = await this.permissionService.getAvailableMenuPermissions();
      this.CacheTime = moment();
      this.subject.next();
    }
    return this.CachedMenuItems;
  }

  async save(roleName: string, menuItemsDefinition: any): Promise<any> {
    const result = await this.permissionService.updateMenuPermission(roleName, menuItemsDefinition);
    return result ? result.MenuItemsDefinition : {};
  }

  async getMenuItemByComponentName(componentName: string): Promise<{ _access: boolean; _name: string }> {
    if (!this.auth?.User?.Id) {
      await this.auth.getUser();
    }

    if (this.auth.isAdmin()) {
      return { _access: true, _name: componentName };
    }
    const availableMenuPermissions = await this.getAllMenuItems();
    if (!componentName || !availableMenuPermissions || Object.keys(availableMenuPermissions).length === 0) {
      return null;
    }
    return this.getMenuItem(availableMenuPermissions, componentName);
  }

  async getDefaultMenuItems(): Promise<any> {
    return this.generateViewsMap();
  }

  private addMenuItem(container: any, component: string, name: string, parent: string = null, defaultAccess: boolean = true) {
    let menuItem: any;
    if (parent == null) {
      menuItem = container;
    } else {
      menuItem = this.getMenuItem(container, parent);
    }
    if (menuItem) {
      menuItem[component] = { _name: name, _access: defaultAccess };
    }
  }

  private getMenuItem(container: any, lookup: string): any {
    if (!container || !lookup) {
      return null;
    }
    if (container.hasOwnProperty(lookup)) {
      return container[lookup];
    }
    for (const key in container) {
      if (key !== "_name" && key !== "_access" && typeof container[key] === "object" && container[key].hasOwnProperty("_access")) {
        const result = this.getMenuItem(container[key], lookup);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  getAllDeniedMenuItemNames(viewContainer: any): string[] {
    const result: any = [];
    for (const key of this.getKeysOfMenuItem(viewContainer)) {
      if (viewContainer.hasOwnProperty(key)) {
        const child = viewContainer[key];
        if (child.hasOwnProperty("_access") && child.hasOwnProperty("_name")) {
          if (child._access === false) {
            result.push(child._name);
          } else {
            result.push(...this.getAllDeniedMenuItemNames(child));
          }
        }
      }
    }
    return result;
  }

  getKeysOfMenuItem(menuItem: any): string[] {
    if (!menuItem) {
      return [];
    }
    return Object.keys(menuItem).filter(key => key !== "_name" && key !== "_access");
  }

  hasChildren(menuItem: any) {
    return this.getKeysOfMenuItem(menuItem).length > 0;
  }

  cascadeAccess(menuItem: any, access: boolean) {
    for (const key of this.getKeysOfMenuItem(menuItem)) {
      if (menuItem[key].hasOwnProperty("_access")) {
        menuItem[key]._access = access;
        this.cascadeAccess(menuItem[key], access);
      }
    }
  }
}
