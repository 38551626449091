/* tslint:disable:no-string-literal */

import * as XLSX from "xlsx";
import { Observable, Subject } from "rxjs";
import { WorkBook } from "xlsx";
import { ToastService } from "../core/toast.service";
import { ImportResult } from "../../interfaces";

export abstract class AbstractImportService<Type> {
  subject = new Subject<ImportResult<Type>>();

  protected constructor(protected toastService: ToastService) {}

  get Parsed(): Observable<ImportResult<Type>> {
    return this.subject.asObservable();
  }

  public abstract createElements(
    workBook: WorkBook,
  ): Promise<ImportResult<Type>>;

  protected async importData(data: Uint8Array) {
    try {
      const result = XLSX.read(data, { type: "array" });
      const parsed = await this.createElements(result);
      this.subject.next(parsed);
    } catch (err) {
      console.log("error", err);
      await this.toastService.presentError("Falsches Dateiformat");
      this.subject.next({
        sheets: [],
        rows: {},
        values: {},
      });
    }
  }

  public async import(event: any) {
    if (
      !event ||
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      return;
    }
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (evt) =>
      this.importData(new Uint8Array(evt.target["result"] as ArrayBuffer));
    fileReader.onerror = (error) => console.log(error);
    fileReader.readAsArrayBuffer(file);
  }
}
