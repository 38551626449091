import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ArrayService } from "../../services/core/array.service";
import {
  FormFieldType,
  FormTableColumnType,
  FormType,
  SpecialFieldType,
  UserRestriction,
} from "../../enums";
import { TranslateService } from "@ngx-translate/core";
import {
  CompareFunction,
  FormElement,
  FormTable,
  FormTableRowColumn,
  Identifiable,
  InteractionForm,
  KeyValuePair,
  Qualification,
  SearchFunction,
  SpecialField,
  Team,
  Trigger,
  User,
} from "../../interfaces";
import { TranslateEnumPipe } from "../../pipes/translate-enum.pipe";

@Component({
  selector: "app-trigger-detail",
  templateUrl: "./trigger-detail.component.html",
  styleUrls: ["./trigger-detail.component.scss"],
})
export class TriggerDetailComponent implements OnInit {
  @Input() Trigger: Trigger;
  @Input() Index: number;
  @Input() Mode = "";
  @Input() InteractionForms: InteractionForm[];
  @Input() Qualifications: Qualification[] = [];
  @Input() Teams: Team[] = [];
  @Input() Users: User[] = [];
  @Input() SpecialFields: SpecialField[] = [];
  @Input() Component = "";
  @Input() ComponentFields: (KeyValuePair & {
    disabled?: boolean;
    Order?: number;
  })[] = [];
  @Input() ShowDetails: boolean;

  @Output() Copy = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() ShowDetailsChange = new EventEmitter<void>();

  triggerOptions = [
    { Id: "Start", Name: "Start" },
    { Id: "End", Name: "Ende" },
    { Id: "Always", Name: "Immer" },
  ];
  triggerTypes = [{ Id: "Form", Name: "Formular" }];

  formElements: FormElement[] = [];
  dynamicUserTables: FormTable[] = [];
  staticTableColumns: FormTableRowColumn[] = [];

  formElementMap: { [id: string]: FormElement };
  formTableMap: { [id: string]: FormTable };
  formCellMap: { [id: string]: FormTableRowColumn };

  fieldMappings: {
    Model: string;
    FormElementId: string;
    Hint?: string;
    Warning?: boolean;
  }[] = [];
  tableMappings: {
    FormTableId: string;
    UserRestriction: UserRestriction;
    Users: string[];
    QualificationFilter: boolean;
    Qualifications: string[];
    TeamFilter: boolean;
    Teams: string[];
  }[] = [];
  cellMappings: {
    Model: string;
    ColumnId: string;
    FormTableRowId: string;
    ColumnIndex: string;
    Hint?: string;
    Warning?: boolean;
  }[] = [];
  newMail = "";
  newNotificationMail = "";

  componentMap: {
    [Key: string]: KeyValuePair & { disabled?: boolean; Order?: number };
  } = {};
  specialFieldsMap: { [Key: string]: SpecialField } = {};
  hasDynamicUserTable: boolean;
  hasStaticTable: boolean;

  userRestrictionValues = Object.values(UserRestriction);
  UserRestrictions = UserRestriction;

  userSearchFunction: SearchFunction = this.arrayService.createSearchFunction(
    "FirstName",
    "LastName",
  );
  formElementSearchFct: SearchFunction = this.arrayService.createSearchFunction(
    "FormContainerName",
    "FormGroupName",
    "Name",
  );
  formTableSearchFct: SearchFunction = this.arrayService.createSearchFunction(
    "FormContainerName",
    "Name",
  );
  formCellSearchFct: SearchFunction = this.arrayService.createSearchFunction(
    "FormContainerName",
    "FormTableName",
    "FormColumnName",
  );
  componentFieldSearchFct: SearchFunction =
    this.arrayService.createSearchFunction("Value");
  nameableSearchFct: SearchFunction =
    this.arrayService.createSearchFunction("Name");
  identifiableCompareFunction: CompareFunction<Identifiable> = (
    objA: Identifiable,
    objB: Identifiable,
  ) => objA.Id === objB.Id;

  constructor(
    private arrayService: ArrayService,
    private translateService: TranslateService,
    private translateEnum: TranslateEnumPipe,
  ) {}

  ngOnInit() {
    this.Trigger.NotificationMail = this.Trigger.NotificationMail || {
      Recipient: [],
      Content: null,
      Subject: null,
    };
    this.Trigger.Tables = this.Trigger.Tables || [];
    this.Trigger.Fields = this.Trigger.Fields || [];
    this.Trigger.TableCells = this.Trigger.TableCells || [];
    this.Trigger.Reactions = this.Trigger.Reactions || {
      Active: false,
      Users: [],
      Mails: [],
    };
    this.specialFieldsMap = this.arrayService.toMap(this.SpecialFields, "Key");
    this.componentMap = this.arrayService.toMap(this.ComponentFields, "Key");
    this.filterInteractionForm();
    this.updateHasDynamicTable();
    this.updateHasStaticTable();
    this.setMappings();
    this.updateFieldWarnings();
    this.updateCellWarnings();
  }

  updateHasDynamicTable() {
    this.hasDynamicUserTable =
      !!this.dynamicUserTables && this.dynamicUserTables.length > 0;
  }

  updateHasStaticTable() {
    this.hasStaticTable =
      !!this.staticTableColumns && this.staticTableColumns.length > 0;
  }

  filterInteractionForm() {
    // filter out elements that can not be filled with any data
    const formFields = [];
    const dynamicUserTables = [];
    const staticTableCells = [];
    if (this.Trigger?.InteractionForm) {
      for (const container of this.Trigger.InteractionForm.Containers || []) {
        for (const group of container.FormGroups || []) {
          for (const element of group.FormElements || []) {
            if (
              element.Type === FormType.DYNAMIC ||
              element.FieldType === FormFieldType.DATE_STATIC ||
              element.FieldType === FormFieldType.DIVIDER ||
              element.FieldType === FormFieldType.SIGNATURE ||
              element.FieldType === FormFieldType.MULTI_SELECTION ||
              element.FieldType === FormFieldType.SINGLE_SELECTION ||
              element.FieldType === FormFieldType.BOOLEAN
            ) {
              continue;
            }
            formFields.push(element);
          }
        }
        for (const table of container.FormTables || []) {
          if (table.Type === FormType.DYNAMIC) {
            let isUserTable = false;
            for (const column of table.FormTableColumns) {
              if (column.Type === FormTableColumnType.TIMESLOT) {
                isUserTable = true;
                break;
              }
            }
            if (isUserTable) {
              dynamicUserTables.push(table);
            }
          } else if (table.Type === FormType.STATIC) {
            for (const row of table.FormTableRows) {
              if (
                row.Type === FormType.STATIC &&
                row.Columns &&
                row.Columns.length > 0
              ) {
                for (const column of row.Columns) {
                  if (
                    column.Type === FormTableColumnType.SINGLE_SELECTION ||
                    column.Type === FormTableColumnType.MULTI_SELECTION ||
                    column.Type === FormTableColumnType.TEXT_STATIC ||
                    column.Type === FormTableColumnType.TIMESLOT
                  ) {
                    continue;
                  }
                  staticTableCells.push(column);
                }
              }
            }
          }
        }
      }
    }
    this.formElements = formFields;
    this.dynamicUserTables = dynamicUserTables;
    this.staticTableColumns = staticTableCells;
    this.formElementMap = this.arrayService.toMap(this.formElements);
    this.formTableMap = this.arrayService.toMap(this.dynamicUserTables);
    this.formCellMap = this.arrayService.toMap(
      this.staticTableColumns,
      "ColumnId",
    );
  }

  setFieldMapping() {
    const fieldMappings = [];
    for (const field of this.Trigger.Fields) {
      const item: { Model: string; FormElementId: string } = {
        Model: null,
        FormElementId: null,
      };
      if (field.Attribute) {
        item.Model = field.Attribute;
      } else if (field.Association) {
        item.Model =
          field.Association +
          "--.--" +
          (field.AssociationId || field.AssociationKey);
      }
      if (field.FormElementId) {
        item.FormElementId = field.FormElementId;
      }
      fieldMappings.push(item);
    }
    this.fieldMappings = fieldMappings;
  }

  setTableMapping() {
    const tableMappings = [];
    for (const table of this.Trigger.Tables) {
      const item: {
        FormTableId: string;
        UserRestriction: UserRestriction;
        Users: string[];
        Qualifications: string[];
        Teams: string[];
        QualificationFilter: boolean;
        TeamFilter: boolean;
      } = {
        FormTableId: null,
        UserRestriction: UserRestriction.ALL,
        Users: [],
        Qualifications: [],
        Teams: [],
        TeamFilter: false,
        QualificationFilter: false,
      };
      if (table.FormTableId) {
        item.FormTableId = table.FormTableId;
      }
      if (table.UserRestriction) {
        item.UserRestriction = table.UserRestriction;
      }
      if (table.Users && table.Users.length > 0) {
        item.Users = [...table.Users];
      }
      if (table.Qualifications && table.Qualifications.length > 0) {
        item.Qualifications = [...table.Qualifications];
      }
      if (table.Teams && table.Teams.length > 0) {
        item.Teams = [...table.Teams];
      }
      item.QualificationFilter =
        table.QualificationFilter || item.QualificationFilter;
      item.TeamFilter = table.TeamFilter || item.TeamFilter;
      tableMappings.push(item);
    }
    this.tableMappings = tableMappings;
  }

  setCellMapping() {
    const cellMappings = [];
    for (const cell of this.Trigger.TableCells) {
      const item: {
        Model: string;
        ColumnId: string;
        FormTableRowId: string;
        ColumnIndex: number;
      } = {
        Model: null,
        ColumnId: null,
        FormTableRowId: null,
        ColumnIndex: null,
      };
      if (cell.Attribute) {
        item.Model = cell.Attribute;
      } else if (cell.Association) {
        item.Model =
          cell.Association +
          "--.--" +
          (cell.AssociationId || cell.AssociationKey);
      }
      if (cell.ColumnIndex && cell.FormTableRowId) {
        item.ColumnId = cell.FormTableRowId + "--.--" + cell.ColumnIndex;
      }
      cellMappings.push(item);
    }
    this.cellMappings = cellMappings;
  }

  setMappings() {
    this.setFieldMapping();
    this.setTableMapping();
    this.setCellMapping();
    this.updateFormElementDisabled();
    this.updateTableDisabled();
    this.updateCellsDisabled();
  }

  updateFormElementDisabled() {
    this.formElements.forEach((field) => (field.disabled = false));
    for (const mapping of this.fieldMappings) {
      if (mapping.FormElementId && this.formElementMap[mapping.FormElementId]) {
        this.formElementMap[mapping.FormElementId].disabled = true;
      }
    }
    this.formElements = [...this.formElements];
  }

  updateTableDisabled() {
    this.dynamicUserTables.forEach((table) => (table.disabled = false));
    for (const mapping of this.tableMappings) {
      if (mapping.FormTableId && this.formTableMap[mapping.FormTableId]) {
        this.formTableMap[mapping.FormTableId].disabled = true;
      }
    }
    this.dynamicUserTables = [...this.dynamicUserTables];
  }

  updateCellsDisabled() {
    this.staticTableColumns.forEach((cell) => (cell.disabled = false));
    for (const mapping of this.cellMappings) {
      if (mapping.ColumnId) {
        this.formCellMap[mapping.ColumnId].disabled = true;
      }
    }
    this.staticTableColumns = [...this.staticTableColumns];
  }

  copyTrigger() {
    return this.Copy.emit();
  }

  deleteCell(index: number) {
    this.cellMappings.splice(index, 1);
    this.updateTrigger();
  }

  deleteField(index: number) {
    this.fieldMappings.splice(index, 1);
    this.updateTrigger();
  }

  deleteTable(index: number) {
    this.tableMappings.splice(index, 1);
    this.updateTrigger();
  }

  addField() {
    this.fieldMappings.push({
      Model: null,
      FormElementId: null,
      Hint: null,
      Warning: false,
    });
    this.updateTrigger();
  }

  addTable() {
    this.tableMappings.push({
      FormTableId: null,
      UserRestriction: UserRestriction.ALL,
      Users: [],
      QualificationFilter: false,
      TeamFilter: false,
      Teams: [],
      Qualifications: [],
    });
    this.updateTrigger();
  }

  addCell() {
    this.cellMappings.push({
      Model: null,
      ColumnId: null,
      FormTableRowId: null,
      ColumnIndex: null,
      Hint: null,
      Warning: false,
    });
    this.updateTrigger();
  }

  removeTrigger() {
    return this.Remove.emit();
  }

  removeFieldModel(mapping: { Model: string }) {
    mapping.Model = null;
    this.updateFieldWarnings();
  }

  removeCellModel(mapping: { Model: string }) {
    mapping.Model = null;
    this.updateCellWarnings();
  }

  removeCell(mapping: {
    ColumnId: string;
    FormTableRowId: string;
    ColumnIndex: string;
  }) {
    mapping.ColumnId = null;
    mapping.ColumnIndex = null;
    mapping.FormTableRowId = null;
    this.updateCells();
  }

  updateCell(mapping: {
    ColumnId: string;
    FormTableRowId: string;
    ColumnIndex: string;
  }) {
    const [rowId, columnIndex] = (mapping.ColumnId || "").split("--.--");
    mapping.ColumnIndex = columnIndex;
    mapping.FormTableRowId = rowId;
    this.updateCells();
  }

  removeField(mapping: { FormElementId: string }) {
    mapping.FormElementId = null;
    this.updateFields();
  }

  removeTable(mapping: { FormTableId: string }) {
    mapping.FormTableId = null;
    this.changeTable();
  }

  isTimeSpecialField(specialField: SpecialField): boolean {
    return (
      specialField.Type === SpecialFieldType.TIME ||
      specialField.Type === SpecialFieldType.DATE ||
      specialField.Type === SpecialFieldType.DEADLINE
    );
  }

  isTimeAttribute(attribute: string): boolean {
    return (
      attribute === "CreatedAt" ||
      attribute === "UpdatedAt" ||
      attribute === "StartTime" ||
      attribute === "EndTime"
    );
  }

  isTimeElement(formElement: FormElement): boolean {
    return (
      formElement.FieldType === FormFieldType.DATE ||
      formElement.FieldType === FormFieldType.TIME
    );
  }

  isTimeCell(column: FormTableRowColumn): boolean {
    return (
      column.Type === FormTableColumnType.DATE ||
      column.Type === FormTableColumnType.TIME
    );
  }

  updateCellWarnings() {
    for (let i = 0; i < this.cellMappings.length; i++) {
      const mapping = this.cellMappings[i];
      mapping.Warning = false;
      mapping.Hint = "";
      if (!mapping.ColumnId || !mapping.Model) {
        continue;
      }
      const column = this.formCellMap[mapping.ColumnId];
      const triggerCell = this.Trigger.TableCells[i];
      const key =
        (triggerCell.Attribute || triggerCell.Association) +
        (triggerCell.Attribute
          ? ""
          : "--.--" +
            (triggerCell.AssociationKey || triggerCell.AssociationId));
      const componentField = this.componentMap[key] || {
        Value: "Das Feld",
        disabled: false,
      };
      if (componentField.disabled) {
        mapping.Warning = true;
        mapping.Hint += this.translate(
          "components.trigger-detail.hint_missing_qualification",
        );
      }
      if (!column) {
        mapping.Warning = true;
        mapping.Hint += "Die Tabellen-Zelle kann nichts zugeordnet werden. ";
        continue;
      }
      if (this.isTimeCell(column)) {
        if (
          triggerCell.Attribute &&
          !this.isTimeAttribute(triggerCell.Attribute)
        ) {
          mapping.Warning = true;
          mapping.Hint +=
            componentField.Value +
            " kann nicht auf eine Tabellen-Zelle vom Typ " +
            this.translateEnum.transform(column.Type, "FormTableColumnType") +
            " angewandt werden. ";
        } else if (triggerCell.Association === "Qualifications") {
          mapping.Warning = true;
          mapping.Hint +=
            "Qualifikationen können nicht auf eine Tabellen-Zelle vom Typ " +
            this.translateEnum.transform(column.Type, "FormTableColumnType") +
            " angewandt werden. ";
        } else if (triggerCell.Association === "SpecialFields") {
          const specialField =
            this.specialFieldsMap[triggerCell.AssociationKey];
          if (
            specialField &&
            (!this.isTimeSpecialField(specialField) ||
              (column.Type === FormTableColumnType.TIME &&
                specialField.Type !== SpecialFieldType.TIME) ||
              (column.Type === FormTableColumnType.DATE &&
                specialField.Type === SpecialFieldType.TIME))
          ) {
            mapping.Warning = true;
            mapping.Hint +=
              "Das Spezialfeld " +
              specialField.Key +
              " vom Typ " +
              this.translateEnum.transform(column.Type, "FormTableColumnType") +
              " kann  nicht auf ein Formularfeld vom Typ " +
              this.translateEnum.transform(column.Type, "FormTableColumnType") +
              " angewandt werden. ";
          }
        }
      }
    }
  }

  updateFieldWarnings() {
    for (let i = 0; i < this.fieldMappings.length; i++) {
      const mapping = this.fieldMappings[i];
      mapping.Warning = false;
      mapping.Hint = "";
      if (!mapping.FormElementId || !mapping.Model) {
        continue;
      }
      const formElement = this.formElementMap[mapping.FormElementId];
      const triggerField = this.Trigger.Fields[i];
      const key =
        (triggerField.Attribute || triggerField.Association) +
        (triggerField.Attribute
          ? ""
          : "--.--" +
            (triggerField.AssociationKey || triggerField.AssociationId));
      const componentField = this.componentMap[key] || {
        Value: "Das Feld",
        disabled: false,
      };
      if (componentField.disabled) {
        mapping.Warning = true;
        mapping.Hint += this.translate(
          "components.trigger-detail.hint_missing_qualification",
        );
      }
      if (!formElement) {
        mapping.Warning = true;
        mapping.Hint += "Dem Formularfeld kann nichts zugeordnet werden. ";
        continue;
      }
      if (formElement.FieldType === FormFieldType.IMAGE) {
        if (triggerField.Association === "SpecialFields") {
          const specialField =
            this.specialFieldsMap[triggerField.AssociationKey];
          if (specialField && specialField.Type !== SpecialFieldType.FILE) {
            mapping.Warning = true;
            mapping.Hint +=
              "Das Spezialfeld " +
              specialField.Key +
              " vom Typ " +
              this.translateEnum.transform(
                formElement.FieldType,
                "FormFieldType",
              ) +
              " kann  nicht auf ein Formularfeld vom Typ " +
              this.translateEnum.transform(
                formElement.FieldType,
                "FormFieldType",
              ) +
              " angewandt werden. ";
          }
        } else {
          mapping.Warning = true;
          mapping.Hint +=
            "Das Formularfeld vom Typ " +
            this.translateEnum.transform(
              formElement.FieldType,
              "FormFieldType",
            ) +
            " kann nur mit Bilddaten verknüpft werden. ";
        }
      } else if (this.isTimeElement(formElement)) {
        if (
          triggerField.Attribute &&
          !this.isTimeAttribute(triggerField.Attribute)
        ) {
          mapping.Warning = true;
          mapping.Hint +=
            componentField.Value +
            " kann nicht auf ein Formularfeld vom Typ " +
            this.translateEnum.transform(
              formElement.FieldType,
              "FormFieldType",
            ) +
            " angewandt werden. ";
        } else if (triggerField.Association === "Qualifications") {
          mapping.Warning = true;
          mapping.Hint +=
            "Qualifikationen können nicht auf ein Formularfeld vom Typ " +
            this.translateEnum.transform(
              formElement.FieldType,
              "FormFieldType",
            ) +
            " angewandt werden. ";
        } else if (triggerField.Association === "SpecialFields") {
          const specialField =
            this.specialFieldsMap[triggerField.AssociationKey];
          if (
            specialField &&
            (!this.isTimeSpecialField(specialField) ||
              (formElement.FieldType === FormFieldType.TIME &&
                specialField.Type !== SpecialFieldType.TIME) ||
              (formElement.FieldType === FormFieldType.DATE &&
                specialField.Type === SpecialFieldType.TIME))
          ) {
            mapping.Warning = true;
            mapping.Hint +=
              "Das Spezialfeld " +
              specialField.Key +
              " vom Typ " +
              this.translateEnum.transform(
                formElement.FieldType,
                "FormFieldType",
              ) +
              " kann  nicht auf ein Formularfeld vom Typ " +
              this.translateEnum.transform(
                formElement.FieldType,
                "FormFieldType",
              ) +
              " angewandt werden. ";
          }
        }
      }
    }
  }

  updateCells() {
    this.updateTrigger();
    this.updateCellsDisabled();
    this.updateCellWarnings();
  }

  updateFields() {
    this.updateTrigger();
    this.updateFormElementDisabled();
    this.updateFieldWarnings();
  }

  updateFieldModel() {
    this.updateTrigger();
    this.updateFieldWarnings();
  }

  updateCellModel() {
    this.updateTrigger();
    this.updateCellWarnings();
  }

  updateTrigger() {
    const fields = [];
    const cells = [];
    const tables = [];
    for (const mapping of this.cellMappings) {
      const [association, id] = (mapping.Model || "").split("--.--");
      const modelData: any = {};
      if (association) {
        if (id) {
          modelData.Association = association;
          if (association === "SpecialFields") {
            modelData.AssociationKey = id;
          } else {
            modelData.AssociationId = id;
          }
        } else {
          modelData.Attribute = association;
        }
      }
      const formCell = this.formCellMap[mapping.ColumnId];
      const cellData = formCell
        ? {
            ColumnIndex: formCell.ColumnIndex,
            FormTableRowId: formCell.FormTableRowId,
            FormTableId: formCell.FormTableId,
            FormContainerId: formCell.FormContainerId,
          }
        : {};
      cells.push({ ...cellData, ...modelData });
    }
    for (const mapping of this.fieldMappings) {
      const [association, id] = (mapping.Model || "").split("--.--");
      const modelData: any = {};
      if (association) {
        if (id) {
          modelData.Association = association;
          if (association === "SpecialFields") {
            modelData.AssociationKey = id;
          } else {
            modelData.AssociationId = id;
          }
        } else {
          modelData.Attribute = association;
        }
      }
      const formField = this.formElementMap[mapping.FormElementId];
      const fieldData = formField
        ? {
            FormElementId: formField.Id,
            FormGroupId: formField.FormGroupId,
            FormContainerId: formField.FormContainerId,
          }
        : {};
      fields.push({ ...fieldData, ...modelData });
    }
    for (const mapping of this.tableMappings) {
      const formTable = this.formTableMap[mapping.FormTableId];
      const tableData = formTable
        ? {
            FormContainerId: formTable.FormContainerId,
          }
        : {};
      const modelData = JSON.parse(JSON.stringify(mapping));
      modelData.QualificationFilter =
        modelData.QualificationFilter &&
        modelData.Qualifications &&
        modelData.Qualifications.length > 0;
      modelData.TeamFilter =
        modelData.TeamFilter && modelData.Teams && modelData.Teams.length > 0;
      tables.push({ ...tableData, ...modelData });
    }

    this.Trigger.TableCells = cells;
    this.Trigger.Tables = tables;
    this.Trigger.Fields = fields;
  }

  changeForm() {
    for (const form of this.InteractionForms) {
      if (form.Id === this.Trigger.InteractionFormId) {
        this.Trigger.InteractionForm = form;
        break;
      }
    }
    this.filterInteractionForm();
    this.formElements.forEach((field) => (field.disabled = false));
    this.dynamicUserTables.forEach((table) => (table.disabled = false));
    this.staticTableColumns.forEach((row) => (row.disabled = false));
    this.fieldMappings = [];
    this.tableMappings = [];
    this.cellMappings = [];
    this.updateTrigger();
    this.updateHasStaticTable();
    this.updateHasDynamicTable();
  }

  changeTable() {
    this.updateTrigger();
  }

  translate(key: string): any {
    return this.translateService.instant(key);
  }

  addMail() {
    this.Trigger.Reactions.Mails.push(this.newMail);
    this.Trigger.Reactions.Mails = [...this.Trigger.Reactions.Mails];
    this.newMail = "";
  }

  addNotificationMail() {
    this.Trigger.NotificationMail.Recipient.push(this.newNotificationMail);
    this.Trigger.NotificationMail.Recipient = [
      ...this.Trigger.NotificationMail.Recipient,
    ];
    this.newNotificationMail = "";
  }

  emitToggle() {
    this.ShowDetailsChange.emit();
  }
}
