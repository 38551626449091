import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ModalController } from "@ionic/angular";
import { RemarkModalComponent } from "../../modals/remark-modal/remark-modal.component";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";
import { DisplaySettingService } from "../../services/core/display-setting.service";
import { UserNamePipe } from "../../pipes/user-name.pipe";
import { Remark, User } from "../../interfaces";

@Component({
  selector: "app-time-slot-user-remark",
  templateUrl: "./time-slot-user-remark.component.html",
  styleUrls: ["./time-slot-user-remark.component.scss"],
})
export class TimeSlotUserRemarkComponent implements OnInit, OnDestroy {
  @Input() set Remarks(remarks: Remark[]) {
    this.remarks = remarks;
    this.count = remarks ? remarks.length : 0;
    this.previewText = remarks && remarks.length > 0 ? remarks[0].Text : "";
  }
  @Input() User: User;
  @Input() Start: any;
  @Input() End: any;
  @Input() Disabled: boolean = false;

  @Output() RemarksUpdate = new EventEmitter<Remark[]>();

  remarks: Remark[];
  count: number;
  previewText: string;
  userFormat: string;
  settingSubscription: Subscription;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private datePipe: DatePipe,
    private settingService: DisplaySettingService,
    private userPipe: UserNamePipe,
  ) {}

  ngOnInit() {
    this.userFormat = this.settingService.getUserSetting().format;
    this.settingSubscription = this.settingService.User.subscribe(
      (settings) => (this.userFormat = settings.format),
    );
  }

  ngOnDestroy() {
    if (this.settingSubscription) {
      this.settingSubscription.unsubscribe();
    }
  }

  async open() {
    const start = moment(this.Start);
    const end = moment(this.End);
    const startFormat = start.format("YYYY-MM-DD");
    const endFormat = end.format("YYYY-MM-DD");
    const props: any = {
      ItemId: this.User.Id,
      Component: "User",
      Headline: this.userPipe.transform(this.User, this.userFormat),
    };
    if (startFormat === endFormat) {
      props.Date = startFormat;
      props.ShowDates = false;
    } else {
      props.SubHeadline =
        this.datePipe.transform(startFormat, "shortDate") +
        " - " +
        this.datePipe.transform(endFormat, "shortDate");
      props.ShowDates = true;
      props.Start = startFormat;
      props.End = endFormat;
    }
    const modal = await this.modalController.create({
      component: RemarkModalComponent,
      componentProps: props,
      cssClass: "custom-ion-modal modal-70",
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response && response.data && response.data.remarks) {
      this.RemarksUpdate.emit(response.data.remarks);
    }
  }
}
