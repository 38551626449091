import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavParams } from "@ionic/angular";
import { ApiService } from "src/app/services/api/api.service";

@Component({
  selector: "app-schedule-detail-modal",
  templateUrl: "./schedule-detail-modal.component.html",
  styleUrls: ["./schedule-detail-modal.component.scss"]
})
export class ScheduleDetailModalComponent implements OnInit {
  @Input() Id: string;
  @Input() Type: string = 'shifts'
  @Input() Link: string = null;
  isLoading= false;

  constructor(private modalController: ModalController, private router: Router) {}

  async ngOnInit() {
    this.isLoading = true;
  }

  async closeModal() {
    await this.modalController.dismiss(true);
  }

  async goToDetail() {
    await this.router.navigateByUrl(this.Link)
    await this.modalController.dismiss();
  }

}
