import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { TeamFilterService } from "../filter/team-filter.service";
import { QualificationFilterService } from "../filter/qualification-filter.service";
import { ArrayService } from "../core/array.service";
import { SortService } from "../core/sort.service";
import * as moment from "moment/moment";
import { DisplaySettingService } from "../core/display-setting.service";
import { ShiftService } from "./shift.service";
import { WorkOrderService } from "./work-order.service";
import { DraftType } from "../../enums";
import { Holiday, TimeRange, UserSchedule } from "../../interfaces";
import { ScheduleDetailModalComponent } from "src/app/modals/schedule-detail-modal/schedule-detail-modal.component";
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { MasterlistService } from "src/app/pages/masterlist/masterlist.service";

@Injectable({
  providedIn: "root",
})
export class ScheduleService {
  constructor(
    private apiService: ApiService,
    private teamFilterService: TeamFilterService,
    private qualificationFilterService: QualificationFilterService,
    private arrayService: ArrayService,
    private sortService: SortService,
    private displaySettingService: DisplaySettingService,
    private workOrderService: WorkOrderService,
    private shiftService: ShiftService,
    private masterlistService: MasterlistService,
    private modalController: ModalController,
    private router: Router
  ) { }

  updateSchedule(
    schedules: UserSchedule[],
    added: UserSchedule[],
    limit: number = 20,
  ): {
    Schedule: UserSchedule[];
    CanLoadMore: boolean;
    UserCount: number;
  } {
    const addedUsers = this.getUserCount(added);
    const canLoadMore = addedUsers >= limit;
    const newUserSchedule =
      schedules && schedules.length > 0 ? [...schedules] : [];
    if (added && added.length > 0) {
      newUserSchedule.push(...added);
    }
    const teamMap = {};
    const userMap = {};
    for (let i = 0; i < newUserSchedule.length; i++) {
      const item = newUserSchedule[i];
      // if schedule is loaded through 'load more' a team might be included multiple times in the list, therefore hide all but one
      if (item.Type === "Team") {
        if (teamMap[item.Id]) {
          item.hide = true;
        } else {
          teamMap[item.Id] = true;
        }
      } else if (item.Type === "User") {
        // if users are listed multiple times, each user still has the same object reference after this (e.g. for mass deletion)
        if (userMap[item.Id]) {
          newUserSchedule[i] = userMap[item.Id];
        } else {
          userMap[item.Id] = item;
        }
      }
    }
    return {
      Schedule: newUserSchedule,
      CanLoadMore: canLoadMore,
      UserCount: this.getUserCount(newUserSchedule),
    };
  }

  getUserCount(userSchedules: UserSchedule[]): number {
    let count = 0;
    (userSchedules || []).forEach(
      (userSchedule) => (count += userSchedule.Type === "User" ? 1 : 0),
    );
    return count;
  }

  async publishDrafts(type: DraftType, timeRange: TimeRange): Promise<boolean> {
    const param = {
      startDay: timeRange.Start.format(),
      endDay: timeRange.End.format(),
      ...this.teamFilterService.getParams(),
    };
    if (type === DraftType.SHIFT) {
      return this.shiftService.publishDrafts(param);
    } else if (type === DraftType.WORK_ORDER) {
      return this.workOrderService.publishDrafts(param);
    } else if (type === DraftType.MASTERLIST) {
      delete param.Teams;
      return this.masterlistService.publishDrafts(param);
    }
    return false;
  }
  async acceptTimeSlots(timeRange: TimeRange): Promise<boolean> {
    const param = {
      startDay: timeRange.Start.format(),
      endDay: timeRange.End.format(),
      ...this.teamFilterService.getParams(),
    };
    return this.workOrderService.acceptTimeSlots(param);
  }
  async unPublishDrafts(type: DraftType, timeRange: TimeRange): Promise<boolean> {
    const param = {
      startDay: timeRange.Start.format(),
      endDay: timeRange.End.format(),
      ...this.teamFilterService.getParams(),
    };
    if (type === DraftType.SHIFT) {
      return this.shiftService.unPublishDrafts(param);
    } else if (type === DraftType.WORK_ORDER) {
      return this.workOrderService.unPublishDrafts(param);
    } else if (type === DraftType.MASTERLIST) {
      delete param.Teams;
      return this.masterlistService.unPublishDrafts(param);
    }
    return false;
  }
  async resetChanges(type: DraftType, timeRange: TimeRange): Promise<boolean> {
    const param = {
      startDay: timeRange.Start.format(),
      endDay: timeRange.End.format(),
      ...this.teamFilterService.getParams(),
    };
    if (type === DraftType.SHIFT) {
      return this.shiftService.resetChanges(param);
    } else if (type === DraftType.WORK_ORDER) {
      return this.workOrderService.resetChanges(param);
    } else if (type === DraftType.MASTERLIST) {
      delete param.Teams;
      return this.masterlistService.resetChanges(param);
    }
    return false;
  }

  async getWorkOrderDay(
    year: number,
    week: number,
    day: number,
    offset?: number,
    limit?: number,
    useCache: boolean = false,
  ) {
    return this.getWorkOrderSchedule(
      ["day", year, week, day],
      offset,
      limit,
      useCache,
    );
  }

  async getWorkOrderWeek(
    year: number,
    week: number,
    offset?: number,
    limit?: number,
    useCache: boolean = false,
  ): Promise<UserSchedule[]> {
    const schedules = await this.getWorkOrderSchedule(
      ["week", year, week],
      offset,
      limit,
      useCache,
    );
    for (const schedule of schedules) {
      if (schedule.Type !== "User") {
        continue;
      }
      const absences = this.arrayService.toMap(schedule.Absences || []);
      for (const day of schedule.Days) {
        const dayEvents = [];
        for (const dayEvent of day.dayEvents) {
          if (!absences[dayEvent.Id]) {
            dayEvents.push(dayEvent);
          } else {
            const copy = Object.assign({}, dayEvent, absences[dayEvent.Id]);
            dayEvents.push(copy);
          }
        }
        day.dayEvents = dayEvents;
      }
    }
    return schedules;
  }

  async getWorkOrderMonth(
    year: number,
    month: number,
    offset?: number,
    limit?: number,
    useCache: boolean = false,
  ): Promise<UserSchedule[]> {
    return this.getSchedule(
      ["month", year, month],
      { Shift: false, limit, offset },
      useCache,
    );
  }

  private async getWorkOrderSchedule(
    params: any[],
    offset: number,
    limit: number,
    useCache: boolean,
  ): Promise<UserSchedule[]> {
    return this.getSchedule(params, { Shift: true, limit, offset }, useCache);
  }

  async getShiftMonth(
    year: number,
    month: number,
    offset?: number,
    limit?: number,
    useCache: boolean = false,
  ): Promise<UserSchedule[]> {
    return this.getShiftSchedule(
      ["month", year, month],
      offset,
      limit,
      useCache,
    );
  }

  async getShiftWeek(
    year: number,
    week: number,
    offset?: number,
    limit?: number,
    useCache: boolean = false,
  ): Promise<UserSchedule[]> {
    return this.getShiftSchedule(["week", year, week], offset, limit, useCache);
  }

  private async getShiftSchedule(
    params: any[],
    offset: number,
    limit: number,
    useCache: boolean,
  ): Promise<UserSchedule[]> {
    return this.getSchedule(
      params,
      { WorkOrder: false, limit, offset },
      useCache,
    );
  }

  private async getSchedule(
    params: any[],
    queryParams: any = {},
    useCache: boolean,
  ) {
    const setting = this.displaySettingService.getUserSetting();
    const filter = {
      ...this.teamFilterService.getParams(),
      ...this.qualificationFilterService.getParams(),
    };
    const mergedQueryParams = Object.assign({}, queryParams || {}, filter, {
      sort: setting.sorting,
      customOrder: setting.customOrder,
    });
    let paramsJoined = (params || []).join("/");
    const schedule: UserSchedule[] = useCache
      ? await this.apiService.Cache(`schedule/${paramsJoined}`, [])
      : await this.apiService.get(
        `schedule/${paramsJoined}`,
        mergedQueryParams,
      );

    return this.updateQualification(schedule);
  }

  private updateQualification(schedule: UserSchedule[]): UserSchedule[] {
    for (const userSchedule of schedule) {
      if (!userSchedule.Days) {
        continue;
      }
      const qualifications = this.arrayService.toMap(
        userSchedule.Qualifications,
      );
      for (const day of userSchedule.Days) {
        if (!day.hasShift) {
          continue;
        }
        for (const event of day.dayEvents) {
          const qualification = qualifications[event.QualificationId];
          if (qualification) {
            event.Qualification = qualification;
          }
        }
      }
    }
    return schedule;
  }

  async getHolidays(
    start: Date | moment.Moment | string,
    end: Date | moment.Moment | string,
  ): Promise<Holiday[]> {
    const startDate = moment(start);
    const endDate = moment(end);
    const holidays = await this.apiService.get("holiday", {
      StartTime: startDate.format("YYYY-MM-DD"),
      EndTime: endDate.format("YYYY-MM-DD"),
    });
    const MasterlistDays = await this.apiService.get("masterlists/holiday", {
      StartTime: startDate.format("YYYY-MM-DD"),
      EndTime: endDate.format("YYYY-MM-DD"),
    });
    const holidaysByDay: Holiday[] = [];
    
    for (let date = startDate; date.isSameOrBefore(endDate); date.add(1, "d")) {
      holidaysByDay.push(this.isHoliday(date, holidays.concat(MasterlistDays)));
    }
    return holidaysByDay;
  }

  isHoliday(day: Date | moment.Moment | string, holidays: Holiday[]): Holiday {
    if (!day) {
      return null;
    }
    let isHoliday = null;
    for (const Holiday of holidays || []) {
      if (moment(day).isSame(moment(Holiday.Date), "d")) {
        if (!isHoliday) {
          isHoliday = Holiday;
        } else {
          isHoliday.Name += '<br>' + Holiday.Name
        }
      }
    }
    return isHoliday;
  }

  async openScheduleDetailModal(popItem) {
    if (popItem.Link) {
      if (popItem.Link.indexOf('absence') == -1) {
        const split = popItem.Link.split('/')
        const modal = await this.modalController.create({
          component: ScheduleDetailModalComponent,
          componentProps: {
            Id: split[2],
            Type: split[1],
            Link: popItem.Link
          },
          cssClass: 'modal-50-80'
        });
        await modal.present();
        const dismiss = await modal.onDidDismiss();
        if (dismiss && dismiss.data) {
          return true;
        }
      } else {
        await this.router.navigateByUrl(popItem.Link)
      }
    }
  }
}
