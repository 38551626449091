import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api/api.service";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { InventoryService } from "../../services/api/inventory.service";
import { SocketService } from "../../services/socket/socket.service";
import { ColorPickerStorageService } from "../../services/storage/color-picker-storage.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { ArrayService } from "../../services/core/array.service";
import { WorkOrderTypeService } from "../../services/api/work-order-type.service";
import { TypeGroupService } from "../../services/api/type-group.service";
import { ApplicationType, TypeGroupType } from "../../enums";
import { ToastService } from "../../services/core/toast.service";
import {
  InteractionForm,
  Qualification,
  Team,
  WorkOrderType,
  WorkOrderTypeGroup,
  Inventory,
  EmployeeTask,
  Company,
} from "../../interfaces";
import * as moment from "moment";
import { SettingsService } from "src/app/services/api/settings.service";

@Component({
  selector: "app-work-order-type-detail-content",
  templateUrl: "./work-order-type-detail-content.component.html",
  styleUrls: ["./work-order-type-detail-content.component.scss"],
})
export class WorkOrderTypeDetailContentComponent implements OnInit {
  @Input() set Id(id: string) {
    const previousId = this.id;
    this.id = id;
    if (this.isInitialized && previousId != id) {
      this.loadWorkOrderType();
    }
  }

  @Input() SplitViewMode;
  @Output() Reload = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() CloseView = new EventEmitter<void>();

  id: string;

  interactionForms: InteractionForm[] = this.api.Cache("interaction-forms", []);
  workOrderType: WorkOrderType = {
    SpecialFields: {},
    Triggers: [],
    Inventories: [],
  };
  workOrderTypeGroups: WorkOrderTypeGroup[] = [];
  qualifications: Qualification[] = [];
  inventories: Inventory[] = [];
  teams: Team[] = [];
  tasks: EmployeeTask[] = [];

  selectableQualifications: Qualification[] = [];
  selectedQualificationIds: string[] = [];
  positions: any[] = [];
  customTask: string = null;
  isLoading: boolean;
  showInventory: boolean;
  isInitialized = false;
  triggerCollapseState: { [key: string]: boolean } = {};
  endTimeWithDays: string;
  addressIsDifferent: boolean = false;
  showApplications: boolean = false;
  company: Company;
  applicationTypes = [ApplicationType.MANUAL, ApplicationType.FIRST];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private translate: TranslateService,
    private inventoryService: InventoryService,
    private socketService: SocketService,
    private colorPickerStorageService: ColorPickerStorageService,
    private router: Router,
    private arrayService: ArrayService,
    private workOrderTypeService: WorkOrderTypeService,
    private groupService: TypeGroupService,
    private toastService: ToastService,
    private settingsService: SettingsService,
  ) { }

  async ngOnInit() {
    this.isInitialized = false;
    this.isLoading = true;
    this.triggerCollapseState = {};
    // this.tasks = await this.api.get("tasks");
    this.interactionForms = await this.api.get("interaction-forms");
    this.qualifications = await this.api.get("qualifications");
    this.teams = await this.api.get("teams");
    this.company = await this.api.get("company");
    this.showApplications =
      await this.settingsService.getBooleanValue("UseApplications");
    this.inventories = await this.inventoryService.getAll();
    this.workOrderTypeGroups = await this.groupService.getAll(
      TypeGroupType.WORK_ORDER,
    );
    await this.loadWorkOrderType();
    this.isLoading = false;
    this.isInitialized = true;
  }

  async loadWorkOrderType() {
    this.positions = [];
    this.addressIsDifferent = false;
    if (this.id === "create") {
      this.workOrderType = this.workOrderTypeService.create();
    } else {
      this.workOrderType = await this.workOrderTypeService.get(this.id);
    }
    this.selectedQualificationIds = this.workOrderType.Qualifications.map(
      (qualification) => qualification.Id,
    );
    this.updateSelectableQualifications();
    this.generateEndTime();
    this.updateAddressFlag();
    this.generatePositions();
  }

  updateAddressFlag() {
    if (
      !(
        this.workOrderType.Street &&
        this.workOrderType.City &&
        this.workOrderType.Zip
      )
    ) {
      this.addressIsDifferent = false;
      return;
    }
    if (
      this.workOrderType.Latitude !== this.company.Latitude ||
      this.workOrderType.Longitude !== this.company.Longitude
    ) {
      this.addressIsDifferent = true;
    }
  }

  updateSelectableQualifications() {
    if (this.selectedQualificationIds.length === 0) {
      this.selectableQualifications = [...this.qualifications];
      return;
    }
    this.selectableQualifications = this.getSelectedQualifications();
  }

  getSelectedQualifications(): Qualification[] {
    if (
      !this.selectedQualificationIds ||
      this.selectedQualificationIds.length === 0
    ) {
      return [];
    }
    const idMap = this.arrayService.toMap(this.selectedQualificationIds, "");
    return this.qualifications.filter(
      (qualification) => idMap[qualification.Id],
    );
  }

  async save(goBack: boolean = false) {
    this.colorPickerStorageService.addColorToPreset(this.workOrderType.Color);

    if (!this.workOrderType.WorkOrderTypeGroupId) {
      return this.toastService.presentError(
        "Es muss eine Vorlagen-Gruppe ausgewählt werden.",
      );
    } else if (
      this.workOrderType.EstimatedDuration < 0 ||
      this.workOrderType.EstimatedDuration > 1440
    ) {
      return this.toastService.presentError(
        "Die Dauer muss zwischen 0 und 1440 Minuten liegen",
      );
    }

    const response = await this.workOrderTypeService.save(
      this.workOrderType,
      this.getSelectedQualifications(),
      this.positions,
    );

    this.Reload.emit();
    if (goBack && !this.SplitViewMode) {
      return this.goBack();
    }
    if (response.Id != this.id) {
      this.id = response.Id;
      if (!this.SplitViewMode) {
        this.location.replaceState("/work-order-types/" + this.id);
      }
    }
    return this.loadWorkOrderType();
  }

  async delete() {
    await this.workOrderTypeService.destroy(this.workOrderType);
    this.Remove.emit();
    return this.goBack();
  }

  async goBack() {
    if (this.SplitViewMode) {
      return;
    }
    return this.router.navigate(["/work-order-types"]);
  }

  addPosition(qualification: string = null, numWorkers: number = 1) {
    this.positions.push({
      Qualification: qualification,
      NumWorkers: numWorkers,
    });
  }

  removePosition(item) {
    const index: number = this.positions.indexOf(item);
    if (index !== -1) {
      this.positions.splice(index, 1);
    }
  }

  addTask(item: EmployeeTask) {
    this.workOrderType.Tasks.push({ Name: item.Name, Employees: [] });
  }

  addCustomTask(item: string) {
    if (item !== null) {
      this.workOrderType.Tasks.push({ Name: item, Employees: [] });
    }
    this.customTask = null;
  }

  removeTask(item: EmployeeTask) {
    const index: number = this.workOrderType.Tasks.indexOf(item);
    if (index !== -1) {
      this.workOrderType.Tasks.splice(index, 1);
    }
  }

  onDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  private generatePositions() {
    let numWorker = this.workOrderType.NumWorker;
    let qualificationIds = this.workOrderType.QualificationIds as string[];
    const positions = {};
    for (const key in qualificationIds) {
      if (key) {
        positions[qualificationIds[key]] =
          positions[qualificationIds[key]] || 0;
        positions[qualificationIds[key]]++;
      }
    }
    for (const key in positions) {
      if (key) {
        this.addPosition(key, positions[key]);
      }
    }
    if (numWorker > qualificationIds.length) {
      this.addPosition(null, numWorker - qualificationIds.length);
    }
  }

  addNewQualification() {
    return (name: string) => {
      return this.api.post("qualifications", { Name: name }) as Qualification;
    };
  }

  addNewTypeGroup() {
    return (name) => {
      const group = this.groupService.create();
      group.Name = name;
      return this.groupService.save(TypeGroupType.WORK_ORDER, group);
    };
  }

  async createCopy() {
    await this.workOrderTypeService.copy(this.workOrderType);
    await this.Reload.emit();
  }

  getDeleteMessage(): string {
    return (
      this.translate.instant(
        "pages.work_order_type.work_order_type_detail.deleteMsg1",
      ) +
      "<br>" +
      this.translate.instant(
        "pages.work_order_type.work_order_type_detail.deleteMsg2",
      )
    );
  }

  generateEndTime() {
    this.workOrderType.WorkOrderEnd = moment(this.workOrderType.WorkOrderStart)
      .add(this.workOrderType.EstimatedDuration, "minutes")
      .subtract(this.workOrderType.DefaultBreak  || 0, "minutes")
      .seconds(0)
      .toDate();
    this.workOrderType.EndTimeString = moment(
      this.workOrderType.WorkOrderEnd,
    ).format("HH:mm");
    this.updateEndTimeWithDays();
  }

  updateEndTimeWithDays() {
    if (!this.workOrderType) {
      this.endTimeWithDays = "";
      return;
    }
    let daysDiff = "";
    const days = moment(this.workOrderType.WorkOrderEnd).diff(
      this.workOrderType.WorkOrderStart,
      "days",
    );
    if (days) {
      daysDiff = " +" + days + (days > 1 ? " Tage" : "Tag");
    }
    const endTime = this.workOrderType?.EndTimeString || "";
    this.endTimeWithDays = endTime + daysDiff;
  }

  setEstimatedDuration(estimatedDuration: number) {
    this.workOrderType.EstimatedDuration = estimatedDuration;
    this.generateEndTime();
  }

  updateAppSettings(settings: any) {
    this.workOrderType.AppSettings = JSON.parse(JSON.stringify(settings));
  }
}
