import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StorageService } from "./services/storage/storage.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    };
    const companyId = this.storageService.getCompanyId();
    const apiKey = this.storageService.getApiKey();
    if (companyId) {
      headers["company-id"] = companyId;
    }
    if (apiKey) {
      headers["x-api-key"] = apiKey;
    }
    req = req.clone({
      setHeaders: headers,
    });

    return next.handle(req);
  }
}
