import { Injectable } from "@angular/core";
import { TimeslotType } from "../../enums";
import * as moment from "moment";
import { Shift, TimeSlot, WorkOrder, WorkTime } from "../../interfaces";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class WorkTimeService {
  constructor(private api: ApiService) {}

  getItemType(workTime: WorkTime): TimeslotType {
    if (!workTime) {
      return null;
    } else if (workTime.ShiftId) {
      return TimeslotType.SHIFT;
    } else if (workTime.WorkOrderId) {
      return TimeslotType.WORK_ORDER;
    } else {
      return null;
    }
  }

  getShiftOrWorkOrder(workTime: WorkTime): Shift | WorkOrder {
    if (!workTime) {
      return null;
    } else if (workTime.WorkOrderId) {
      return workTime.WorkOrder;
    } else if (workTime.ShiftId) {
      return workTime.Shift;
    } else {
      return null;
    }
  }
  getTimeSlot(workTime: WorkTime): TimeSlot {
    if (!workTime) {
      return null;
    } else if (workTime.TimeSlot) {
      return workTime.TimeSlot;
    } else {
      return null;
    }
  }

  getStartDate(item: WorkTime): Date {
    const timeSlot = this.getTimeSlot(item);
    if (timeSlot && timeSlot.StartTime) {
      return timeSlot.StartTime;
    } else {
      return item.StartTime;
    }
  }

  getStartTimeDifference(item: WorkTime): number {
    const timeSlot = this.getTimeSlot(item);
    if (timeSlot && timeSlot.StartTime) {
      return moment(item.StartTime).diff(moment(timeSlot.StartTime), "minutes");
    } else {
      return null;
    }
  }

  getEndTimeDifference(item: WorkTime): number {
    const timeSlot = this.getTimeSlot(item);
    if (timeSlot && timeSlot.EndTime) {
      return moment(item.EndTime).diff(moment(timeSlot.EndTime), "minutes");
    } else {
      return null;
    }
  }

  getPlannedWorkTime(workTime: WorkTime): number {
    const shiftTime = this.getPlannedShiftWorkTime(workTime) || 0;
    const workOrderTime = this.getPlannedWorkOrderWorkTime(workTime) || 0;
    return shiftTime + workOrderTime;
  }

  private getPlannedWorkTimeByType(workTime: WorkTime, type: string) {
    if (!workTime[type + 'Id'])  {
      return null;
    }
    const timeSlot = this.getTimeSlot(workTime);
    if (timeSlot && timeSlot.StartTime && timeSlot.EndTime) {
      const diff = moment(timeSlot.EndTime).diff(moment(timeSlot.StartTime), "minutes");
      const breakTime = this.getCalculatedBreakTime(timeSlot.StartTime, timeSlot.EndTime);
      return diff - breakTime;
    } else {
      return null;
    }
  }

  getPlannedWorkOrderWorkTime(workTime: WorkTime): number {
    return this.getPlannedWorkTimeByType(workTime, 'WorkOrder');
  }

  getPlannedShiftWorkTime(workTime: WorkTime): number {
    return this.getPlannedWorkTimeByType(workTime, 'Shift');
  }

  getCalculatedBreakTime(StartTime, EndTime) {
    const minutes = moment(EndTime).diff(moment(StartTime), "minutes");
    if (minutes > 9.5 * 60) {
      return 45;
    } else if (minutes > 6 * 60) {
      return 30;
    }
    return 0;
  }

  getActualWorkTime(workTime: WorkTime): number {
    if (workTime.StartTime && workTime.EndTime) {
      return moment(workTime.EndTime)
        .subtract(workTime.BreakTime, "minutes")
        .diff(moment(workTime.StartTime), "minutes");
    } else {
      return null;
    }
  }

  getActualWorkOrderWorkTime(workTime: WorkTime) {
    if (workTime.WorkOrderId != null && workTime.StartTime && workTime.EndTime) {
      return moment(workTime.EndTime)
        .subtract(workTime.BreakTime, "minutes")
        .diff(moment(workTime.StartTime), "minutes");
    } else {
      return null;
    }
  }

  getActualShiftWorkTime(workTime: WorkTime) {
    if (workTime.ShiftId != null && workTime.StartTime && workTime.EndTime) {
      return moment(workTime.EndTime)
        .subtract(workTime.BreakTime, "minutes")
        .diff(moment(workTime.StartTime), "minutes");
    } else {
      return null;
    }
  }

  async destroy(workTime: WorkTime, successMessage?: string): Promise<WorkTime> {
    if (!workTime.Id) {
      return workTime;
    }
    return this.api.delete("work-times/" + workTime.Id, successMessage);
  }
}
