import { Pipe, PipeTransform } from "@angular/core";
import { User } from "../interfaces";

@Pipe({
  name: "userName",
})
export class UserNamePipe implements PipeTransform {
  constructor() {}

  transform(user: User, format: string = "fl"): string {
    if (!user) {
      return "";
    }
    if (!user.FirstName || !user.LastName) {
      return user.FirstName || user.LastName;
    }

    if (format === "lf") {
      return `${user.LastName}, ${user.FirstName}`;
    } else {
      return `${user.FirstName} ${user.LastName}`;
    }
  }
}
