import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-schedule-inventory-button-group",
  templateUrl: "./schedule-inventory-button-group.component.html",
  styleUrls: ["./schedule-inventory-button-group.component.scss"],
})
export class ScheduleInventoryButtonGroupComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
