import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TimeParseService } from "../../../services/core/time-parse.service";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { TimeMode } from "../../../enums";

@Component({
  selector: "app-default-filter-time",
  templateUrl: "./default-filter-time.component.html",
  styleUrls: ["./default-filter-time.component.scss"],
})
export class DefaultFilterTimeComponent {
  @Input()
  set Filter(Filter) {
    this.TimeFilter = Filter;
    if (this.TimeFilter && this.TimeFilter.Start) {
      this.StartDay = this.timeParseService.encodeNgbDate(
        this.TimeFilter.Start,
      );
    }
    if (this.TimeFilter && this.TimeFilter.End) {
      this.EndDay = this.timeParseService.encodeNgbDate(this.TimeFilter.End);
    }
  }

  @Output() FilterChange: EventEmitter<{}> = new EventEmitter<{}>();
  previewMode = TimeMode.MONTH;
  TimeFilter: { Start: string; End: string };
  hoveredDate: NgbDate;
  StartDay: NgbDate;
  EndDay: NgbDate;

  @Input()
  set Mode(newMode: TimeMode) {
    if (this.previewMode !== newMode && this.TimeFilter) {
      this.previewMode = newMode || TimeMode.DAY;
      const TimeFilterOldStart = this.TimeFilter.Start;
      this.TimeFilter = this.timeParseService.decodeTimeString(
        this.timeParseService.encodeTimeString(this.TimeFilter, newMode),
      );
      if (TimeFilterOldStart !== this.TimeFilter.Start) {
        this.FilterChange.emit(this.TimeFilter);
      }
      this.StartDay = this.timeParseService.encodeNgbDate(
        this.TimeFilter.Start,
      );
      this.EndDay = this.timeParseService.encodeNgbDate(this.TimeFilter.End);
    }
  }

  constructor(
    public timeParseService: TimeParseService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) {}

  getCurrentWeek() {
    return moment(this.TimeFilter.Start).week();
  }

  async changeWeek(val: string) {
    if (val === "previous") {
      this.TimeFilter.Start = moment(this.TimeFilter.Start)
        .subtract(1, "week")
        .startOf("week")
        .format("YYYY-MM-DD");
      this.TimeFilter.End = moment(this.TimeFilter.End)
        .subtract(1, "week")
        .endOf("week")
        .format("YYYY-MM-DD");
    } else {
      this.TimeFilter.Start = moment(this.TimeFilter.Start)
        .add(1, "week")
        .startOf("week")
        .format("YYYY-MM-DD");
      this.TimeFilter.End = moment(this.TimeFilter.End)
        .add(1, "week")
        .endOf("week")
        .format("YYYY-MM-DD");
    }
    this.FilterChange.emit(this.TimeFilter);
  }

  getCurrentSpan() {
    return (
      moment(this.TimeFilter.Start).format("DD.MM.") +
      "-" +
      moment(this.TimeFilter.End).format("DD.MM.YY")
    );
  }

  changeMonth(val: string) {
    if (val === "previous") {
      this.TimeFilter.Start = moment(this.TimeFilter.Start)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.TimeFilter.End = moment(this.TimeFilter.End)
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      this.TimeFilter.Start = moment(this.TimeFilter.Start)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.TimeFilter.End = moment(this.TimeFilter.End)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    this.FilterChange.emit(this.TimeFilter);
  }

  getCurrentMonth() {
    return moment(this.TimeFilter.Start).format("MMMM YYYY");
  }

  onDateSelection(date: NgbDate) {
    if (!this.StartDay && !this.EndDay) {
      this.StartDay = date;
    } else if (this.StartDay && !this.EndDay && date.after(this.StartDay)) {
      this.EndDay = date;
    } else {
      this.EndDay = null;
      this.StartDay = date;
    }
    this.TimeFilter.Start = this.timeParseService.decodeNgbDate(this.StartDay);
    this.TimeFilter.End = this.timeParseService.decodeNgbDate(
      this.EndDay || this.StartDay,
    );
    this.FilterChange.emit(this.TimeFilter);
  }

  isHovered(date: NgbDate) {
    return (
      this.StartDay &&
      !this.EndDay &&
      this.hoveredDate &&
      date.after(this.StartDay) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.StartDay) && date.before(this.EndDay);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.StartDay) ||
      date.equals(this.EndDay) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  setInput(Start: string, ngbDate: NgbDate) {
    console.log(Start, ngbDate);
  }

  setToday() {
    this.StartDay = this.timeParseService.encodeNgbDate(
      moment().format("YYYY-MM-DD"),
    );
    this.EndDay = null;
    this.TimeFilter.Start = this.timeParseService.decodeNgbDate(this.StartDay);
    this.TimeFilter.End = this.timeParseService.decodeNgbDate(
      this.EndDay || this.StartDay,
    );
    this.FilterChange.emit(this.TimeFilter);
  }
}
