import { TranslateService } from "@ngx-translate/core";
import { ModalController } from "@ionic/angular";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { AcceptDeclineModalComponent } from "src/app/modals/accept-decline-modal/accept-decline-modal.component";
import { DraftType } from "../../enums";
import { ScheduleService } from "../../services/api/schedule.service";
import { TimeRange } from "../../interfaces";
import { SettingsService } from "src/app/services/api/settings.service";
import { catchError } from "rxjs";

@Component({
  selector: "app-schedule-options-button",
  templateUrl: "./schedule-options-button.component.html",
  styleUrls: ["./schedule-options-button.component.scss"]
})
export class ScheduleOptionsButtonComponent implements OnInit {
  @Input() Type: DraftType;
  @Input() set TimeRange(timeRange: { Start: any; End: any }) {
    if (!timeRange?.Start || !timeRange?.End) {
      this.timeRange = null;
    } else {
      this.timeRange = {
        Start: moment(timeRange.Start),
        End: moment(timeRange.End)
      };
    }
  }
  @Input() goLiveWording: string;
  @Input() goDraftWording: string;
  @Output() Update = new EventEmitter<string>();
  @Output() StartLoading = new EventEmitter<string>();
  @Output() Show = new EventEmitter<string>();
  @Output() Hide = new EventEmitter<string>();
  HighlightChanges = false;
  ExternalApproval = false;
  showHighlights = false;

  timeRange: TimeRange;

  constructor(
    private scheduleService: ScheduleService,
    private modalController: ModalController,
    private translate: TranslateService,
    private settingsService: SettingsService
  ) {}

  async ngOnInit() {
    if (this.Type !== DraftType.MASTERLIST) {
      this.HighlightChanges = await this.settingsService.getBooleanValue("HighlightChanges");
      this.ExternalApproval = await this.settingsService.getBooleanValue("ExternalApproval");
    }
  }

  isWorkOrder() {
    return this.Type === DraftType.WORK_ORDER;
  }

  async goLive() {
    let Message = "";
    switch (this.Type) {
      case DraftType.SHIFT:
        Message = this.translate.instant("components.schedule_options_button.shiftsMsg");
        break;
      case DraftType.WORK_ORDER:
        Message = this.translate.instant("components.schedule_options_button.workOrdersMsg");
        break;
      case DraftType.MASTERLIST:
        Message = this.translate.instant("components.schedule_options_button.masterlistsMsg");
        break;
      default:
        "";
    }
    let OkText =
      this.Type === DraftType.MASTERLIST
        ? this.translate.instant("sync")
        : this.translate.instant("components.schedule_options_button.goLive");
    const modal = await this.modalController.create({
      componentProps: {
        Message,
        OkText
      },
      component: AcceptDeclineModalComponent,
      cssClass: "custom-ion-modal"
    });
    await modal.present();
    const response = await modal.onDidDismiss();

    if (response && response.data) {
      this.StartLoading.emit();
      await this.scheduleService.publishDrafts(this.Type, this.timeRange);
      this.Update.emit();
    }
  }
  async goAccept() {
    const modal = await this.modalController.create({
      componentProps: {
        Message: this.translate.instant("components.schedule_options_button.acceptWorkOrdersMsg"),
        OkText: this.translate.instant("components.schedule_options_button.goAccept")
      },
      component: AcceptDeclineModalComponent,
      cssClass: "custom-ion-modal"
    });
    await modal.present();
    const response = await modal.onDidDismiss();

    if (response && response.data) {
      this.StartLoading.emit();
      await this.scheduleService.acceptTimeSlots(this.timeRange);
      this.Update.emit();
    }
  }
  async goDraft() {
    let Message = "";
    switch (this.Type) {
      case DraftType.SHIFT:
        Message = this.translate.instant("components.schedule_options_button.shiftsMsgUndo");
        break;
      case DraftType.WORK_ORDER:
        Message = this.translate.instant("components.schedule_options_button.workOrdersMsgUndo");
        break;
      case DraftType.MASTERLIST:
        Message = this.translate.instant("components.schedule_options_button.masterlistsMsgUndo");
        break;
      default:
        "";
    }
    let OkText = this.translate.instant("components.schedule_options_button.goLiveUndo");
    const modal = await this.modalController.create({
      componentProps: {
        Message,
        OkText
      },
      component: AcceptDeclineModalComponent,
      cssClass: "custom-ion-modal"
    });
    await modal.present();
    const response = await modal.onDidDismiss();

    if (response && response.data) {
      this.StartLoading.emit();
      await this.scheduleService.unPublishDrafts(this.Type, this.timeRange);
      this.Update.emit();
    }
  }

  async goChanges() {
    const modal = await this.modalController.create({
      componentProps: {
        Message:
          this.Type === DraftType.SHIFT
            ? this.translate.instant("components.schedule_options_button.changeShiftsMsg")
            : this.translate.instant("components.schedule_options_button.changeWorkOrdersMsg"),
        OkText: this.translate.instant("components.schedule_options_button.goChange")
      },
      component: AcceptDeclineModalComponent,
      cssClass: "custom-ion-modal"
    });
    await modal.present();
    const response = await modal.onDidDismiss();

    if (response && response.data) {
      this.StartLoading.emit();
      await this.scheduleService.resetChanges(this.Type, this.timeRange);
      this.showHighlights = false;
      this.Hide.emit();
      this.Update.emit();
    }
  }

  async show() {
    this.showHighlights = true;
    this.Show.emit();
  }

  async hide() {
    this.showHighlights = false;
    this.Hide.emit();
  }
}
