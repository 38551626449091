import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Device } from "@capacitor/device";
import { SplashScreen } from "@capacitor/splash-screen";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "./services/storage/storage.service";
import { AuthService } from "./services/api/auth.service";
import { DisplaySettingService } from "./services/core/display-setting.service";
import { Router, NavigationEnd } from "@angular/router";
import { UrlStorageService } from "./services/storage/url-storage.service";
import { NavigationService } from "./services/core/navigation.service";
import { filter } from "rxjs/operators";
import { AppVersionService } from "./services/api/app-version.service";
import { PlatformStorageService } from "./services/storage/platform-storage.service";
import { VersionService } from "./services/core/version.service";
import * as moment from "moment";
import { PushService } from "./services/core/push.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  showSettings = false;
  showConfiguration = false;
  menuAlwaysOpen = true;
  isTablet = false;
  availableLanguages = ['de', 'en', 'pl', 'sq', 'fr', 'it', 'sp', 'ja'];
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private storageService: StorageService,
    private displaySettingService: DisplaySettingService,
    private authService: AuthService,
    private storeUrlService: UrlStorageService,
    private router: Router,
    //NavigationService needs to be loaded all the time in order to work
    private navigationService: NavigationService,
    private appVersionService: AppVersionService,
    private platformStorage: PlatformStorageService,
    private versionService: VersionService,
    private pushService: PushService
  ) {
    this.initializeApp().catch((e) => console.log("Error while initialize", e));
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) =>
        this.storeUrlService.setUrl((event as NavigationEnd).url),
      );
  }

  async initializeApp() {
    this.translate.setDefaultLang("de");

    await this.authService.initAccount();
    await this.authService.initUser();
    this.navigationService.init();
    await this.initLanguage();
    await this.initPlatform();
  }

  async initLanguage() {
    const LangCode = localStorage.getItem('chosenLanguage') || (await Device.getLanguageCode()).value;
    if (this.availableLanguages.indexOf(LangCode) !== -1) {
      this.translate.use(LangCode)
      moment.locale(LangCode);
    } else {
      this.translate.use('de')
      moment.locale('de');
    }
  }

  async initPlatform() {
    await this.platform.ready();
    const isDesktop = this.platform.is("desktop");
    this.isTablet = false;//this.platform.is("tablet") && !isDesktop;
    this.platformStorage.setIsDesktop(isDesktop);
    this.platformStorage.setIsTabled(this.isTablet);
    this.menuAlwaysOpen = this.isTablet
      ? false
      : this.displaySettingService.getMenuAlwaysOpen();
    this.appVersionService.checkVersion();
    await this.versionService.checkVersion();

    if (this.platform.is("mobileweb") || isDesktop) {
      this.checkChromeBrowser();
    } else {
      await StatusBar.setStyle({ style: Style.Light });
      SplashScreen.hide();
    }
  }

  toggleMenuMode() {
    if (!this.isTablet) {
      this.menuAlwaysOpen = !this.menuAlwaysOpen;
      this.displaySettingService.setMenuAlwaysOpen(this.menuAlwaysOpen);
    }
  }

  checkChromeBrowser() {
    const agent = window.navigator.userAgent.toLowerCase();
    this.storageService.setIsChrome(
      agent.indexOf("chrome") > -1 && !!(window as any).chrome,
    );
  }

  isPaymentNoticeAllowed(): boolean {
    const blockedUrls: string[] = [
      "/selection",
      "/setup",
      "/setup/welcome",
      "/setup/profile",
      "/setup/invite",
      "/setup/finish",
      "/setup/employment",
      "/setup/company",
      "/setup/absence",
      "signup",
    ];
    return blockedUrls.indexOf(this.router.url) === -1;
  }
}
