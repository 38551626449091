import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api/api.service";

@Component({
  selector: 'app-dashboard-deadline',
  templateUrl: './deadline.component.html',
  styleUrls: ['./deadline.component.scss'],
})
export class DashboardDeadlineComponent implements OnInit {
  @Input() set GridWidth(value: number) {
    this.gridWidth = value;
    // this.folded = value == 1
  }
  @Input() set GridHeight(value: number) {
    this.gridHeight = value;
  }

  @Input() set IsLoading(value: boolean) {
    this.isLoading = value;
    if (value) {
      this.init();
    }
  }
  isLoading = false;
  gridWidth = 2;
  gridHeight = 2;

  Deadlines: { [key: string]: any } = {};

  constructor(
    private api: ApiService,
  ) { }

  async ngOnInit() {
    this.init();
  }


  async init() {
    this.Deadlines = (await this.api.get("deadlines/14")) as {
      [key: string]: any;
    };
  }

  getDeadlineLength(Deadlines) {
    return Object.keys(Deadlines).length;
  }

  setDeadlineColor(isBeforeToday) {
    if (isBeforeToday) {
      return "bg-status-red";
    } else {
      return "bg-status-yellow";
    }
  }

}
