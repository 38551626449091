import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/api/auth.service";
import { ViewChild } from "@angular/core";
import { IonModal } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

@Component({
  selector: "app-payment-head",
  templateUrl: "./payment-head.component.html",
  styleUrls: ["./payment-head.component.scss"],
})
export class PaymentHeadComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  isModalOpen = false;
  NotificationOpen = false;
  closeModal = false;
  showText = true;

  PaymentData: any = {
    hasContract: false,
    expirationDate: "",
  };

  Countdown: number;

  CountdownMessage: string;
  Notification: string;
  MeetingLink: string = "https://meetfox.com/de/e/youco-gmbh";
  PricingLink: string = "https://teamcraft.youco-it.com/preise/";

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
  ) {}

  async ngOnInit() {
    document.documentElement.style.setProperty("--bannerWidth", "100%");
    document.documentElement.style.setProperty("--bannerLeft", "0");
    document.documentElement.style.setProperty(
      "--bannerBackgroundColor",
      "#ffc107",
    );
    document.documentElement.style.setProperty(
      "--bannerButtonColor",
      "#136a8a",
    );
    document.documentElement.style.setProperty(
      "--bannerButtonTextColor",
      "white",
    );
    const user = await this.authService.getUser();
    this.PaymentData.hasContract = user?.Company.Contract;
    this.PaymentData.expirationDate = moment(
      user?.Company.CreatedAt,
      "YYYY-MM-DD",
    )
      .add(14, "days")
      .format();
    this.Countdown = moment
      .duration(
        moment(this.PaymentData.expirationDate, "YYYY-MM-DD").diff(moment()),
      )
      .asDays();
    if (this.Countdown > 1) {
      this.CountdownMessage = this.translate.instant(
        "components.trial.days_until",
        {
          Countdown: this.Countdown | 0,
        },
      );
    } else {
      this.CountdownMessage = this.translate.instant(
        "components.trial.day_until",
      );
    }

    setTimeout(() => {
      if (this.PaymentData.hasContract === false && this.Countdown > 0) {
        this.NotificationOpen = true;
      }
      if (this.PaymentData.hasContract === false && this.Countdown <= 0) {
        this.openFullScreenModal();
        setTimeout(() => {
          this.closeModal = true;
        }, 1000 * 5);
      }
    }, 1000 * 3);
  }

  ClosePaymentNotice() {
    document.documentElement.style.setProperty("--bannerWidth", "auto");
    document.documentElement.style.setProperty("--bannerLeft", "36vw");
    document.documentElement.style.setProperty(
      "--bannerBackgroundColor",
      "rgba(0,0,0,0)",
    );
    document.documentElement.style.setProperty(
      "--bannerButtonColor",
      "#ffc107",
    );
    document.documentElement.style.setProperty(
      "--bannerButtonTextColor",
      "black",
    );
    this.showText = false;
  }

  openFullScreenModal() {
    this.isModalOpen = true;
  }

  closeFullScreenModal() {
    window.open("https://meetfox.com/de/e/youco-gmbh", "_blank");
    this.modal.dismiss();
  }
}
