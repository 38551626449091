import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { User } from "../../interfaces";

@Component({
  selector: "app-employee-dropdown-badge",
  templateUrl: "./employee-dropdown-badge.component.html",
  styleUrls: ["./employee-dropdown-badge.component.scss"],
})
export class EmployeeDropdownBadgeComponent implements OnInit {
  @Input() Employee: User;
  @Input() Dropdown: boolean = true;
  @Output() Clear = new EventEmitter<User>();
  constructor() {}

  ngOnInit() {}

  emitClear() {
    return this.Clear.emit(this.Employee);
  }
}
