import { QualificationFilterService } from './../../services/filter/qualification-filter.service';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Qualification } from "../../interfaces";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-qualification-filter",
  templateUrl: "./qualification-filter.component.html",
  styleUrls: ["./qualification-filter.component.scss"],
})
export class QualificationFilterComponent implements OnInit {
  @Input() set Qualifications(qualifications: Qualification[]) {
    this.qualifications = qualifications;
    this.selectedQualificationIds = this.qualificationFilterService.getFilter();
  }

  @Output() Update = new EventEmitter<string[]>();

  qualifications: Qualification[];
  selectedQualificationIds: string[];

  subject: Subscription

  constructor(private qualificationFilterService: QualificationFilterService) {}

  ngOnInit() {
    this.subject = this.qualificationFilterService.Filter.subscribe((filter)=> {
      this.selectedQualificationIds = this.qualificationFilterService.getFilter();
    })
  }

  ngOnDestroy(): void {
    if(this.subject) {
      this.subject.unsubscribe();
    }
  }

  updateFilter(ids: string[]) {
    this.qualificationFilterService.setFilter(ids);
    this.Update.emit(this.selectedQualificationIds);
  }
}
