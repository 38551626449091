import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { ModalController } from "@ionic/angular";
import { WorkOrderImportService } from "../../services/import/work-order-import.service";
import { Subscription } from "rxjs";
import { WorkOrderService } from "../../services/api/work-order.service";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { InventoryService } from "../../services/api/inventory.service";
import { ArrayService } from "../../services/core/array.service";
import { LeasingAgreement, LeasingImportResult, User, WorkOrder } from "../../interfaces";
import { LeasingImportService } from "src/app/services/import/leasing-import.service";

@Component({
  selector: "app-leasing-import",
  templateUrl: "./leasing-import.component.html",
  styleUrls: ["./leasing-import.component.scss"]
})
export class LeasingImportComponent implements OnInit {
  @ViewChild("FileSelect", { static: true }) FileSelect: ElementRef;
  @Input() Name: string;
  @Input() set Service(service: string) {
    this.leasingImportService.setActiveService(service);
  }

  @Output() Close = new EventEmitter<boolean>();

  selectionConfig: {
    all: boolean;
    subset: boolean;
    excludeWorkOrderWithoutType: boolean; //if true, workorder without matching types will be imported without tpye, if false they will be skipped
    time: boolean; // if true time filter is evaluated
    sheet: boolean; // if true sheet filter is evaluated
    sheets: {
      // which sheets are selected for import and which rows of that sheet, only used when sheet=true
      [key: string]: {
        selected: boolean;
        from: number;
        to: number;
        range: string;
      };
    };
    times: {
      // only WorkOrders in time range are imported, only used when time=true
      from: Date;
      to: Date;
      range: { from: Date; to: Date };
    };
  };

  imports: LeasingImportResult = { sheets: [], rows: {}, values: {} };
  selectedImports: { sheet: string; row: string; leasing: LeasingAgreement }[] = [];

  users: { [CustomId: string]: User };
  contracts: { [ContractId: string]: LeasingAgreement };

  synchronizing = false;
  loading = false;

  subscription: Subscription;

  dragAndDropHover = false;
  hasFile = false;
  hasLeasingAgreements = false;
  fileName: string;
  previewSettings: { Icon: any; Cols: any } = { Icon: {}, Cols: {} };

  constructor(
    private api: ApiService,
    private modalController: ModalController,
    private leasingImportService: LeasingImportService,
    private arrayService: ArrayService
  ) {}

  async ngOnInit() {
    this.synchronizing = false;
    this.previewSettings = await this.leasingImportService.getPreviewSettings();
    this.users = this.arrayService.toMap(await this.api.get("employees"), "CustomId");
    this.contracts = this.arrayService.toMap(JSON.parse(JSON.stringify(await this.api.get("leasing-agreements"))).map(contract => {
      contract.ContractId = contract.Settings.ContractId || null;
      return contract.ContractId ? contract : {};
    }), "ContractId");
    this.subscription = this.leasingImportService.Parsed.subscribe(async parsedElements => this.initImports(parsedElements));
    this.hasFile = false;
    this.dragAndDropHover = false;
    this.hasLeasingAgreements = false;
    this.selectedImports = [];
    this.loading = false;
  }

  async initImports(data: LeasingImportResult) {
    this.loading = false;
    this.hasLeasingAgreements = false;
    this.imports = data;
    const selectAllDefault = await this.leasingImportService.getSelectAllDefault();
    const selected = {
      all: selectAllDefault,
      excludeWorkOrderWithoutType: false,
      subset: false,
      time: false,
      sheet: false,
      sheets: {},
      times: { from: null, to: null, range: { from: null, to: null } }
    };
    let timeFrom: moment.Moment;
    let timeTo: moment.Moment;
    for (const key in this.imports.values) {
      for (const leasing of this.imports.values[key]) {
        if (!leasing.Settings.StartDate) {
          continue;
        }
        const date = moment(leasing.Settings.StartDate);
        if (!timeFrom || date.isBefore(timeFrom)) {
          timeFrom = date;
        }
        if (!timeTo || date.isAfter(timeFrom)) {
          timeTo = date;
        }
      }
    }
    selected.times.from = timeFrom?.toDate() || new Date();
    selected.times.to = timeTo?.toDate() || new Date();
    selected.times.range.from = selected.times.from;
    selected.times.range.to = selected.times.to;
    const selectedSheets = {};
    for (const sheet of this.imports.sheets) {
      const sheetItem: any = {
        selected: true,
        from: Number(this.imports.rows[sheet][0]),
        to: Number(this.imports.rows[sheet][this.imports.rows[sheet].length - 1])
      };
      sheetItem.range = sheetItem.from + " - " + sheetItem.to;
      selectedSheets[sheet] = sheetItem;
    }
    selected.sheets = selectedSheets;
    this.selectionConfig = selected;
    this.hasLeasingAgreements = this.imports.sheets.length > 0;
    if (selectAllDefault) {
      this.selectAll();
    } else {
      this.selectNothing();
    }
  }

  selectSubset() {
    this.selectionConfig.all = false;
    this.selectionConfig.subset = true;
    this.filter();
  }

  setFromTime(newDate: any) {
    this.selectionConfig.times.from = moment(newDate).startOf("d").toDate();
    this.filter();
  }

  setToTime(newDate: any) {
    this.selectionConfig.times.to = moment(newDate).endOf("d").toDate();
    this.filter();
  }

  filter() {
    const selectedImports = [];
    for (const sheet of this.imports.sheets) {
      for (const row of this.imports.rows[sheet]) {
        const key = sheet + "-" + row;
        for (const leasing of this.imports.values[key]) {
          if (!leasing) {
            continue;
          }
          if (this.isSelectedBySheetFilter(sheet, Number(row)) && this.isSelectedByTimeFilter(leasing)) {
            selectedImports.push({ sheet, row, leasing });
          }
        }
      }
    }
    this.selectedImports = selectedImports;
  }

  isSelectedBySheetFilter(sheetName: string, row: number): boolean {
    const sheetSelection = this.selectionConfig.sheets[sheetName];
    return (
      !this.selectionConfig.subset ||
      !this.selectionConfig.sheet ||
      (sheetSelection.selected && row >= sheetSelection.from && row <= sheetSelection.to)
    );
  }

  isSelectedByTimeFilter(leasing: LeasingAgreement) {
    if (!this.selectionConfig.time) {
      return true;
    } else if (!leasing || !leasing.Settings.StartDate) {
      return false;
    }
    const from: moment.Moment = moment(this.selectionConfig.times.from);
    const to: moment.Moment = moment(this.selectionConfig.times.to);
    const startTime = moment(leasing.Settings.StartDate);
    return !this.selectionConfig.subset || (startTime.isSameOrAfter(from) && startTime.isSameOrBefore(to));
  }

  selectNothing() {
    for (const key in this.selectionConfig.sheets) {
      this.selectionConfig.sheets[key].selected = false;
    }
    this.selectionConfig.sheet = true;
    this.selectionConfig.time = false;
    this.selectionConfig.subset = true;
    this.selectedImports = [];
  }

  selectAll() {
    this.selectionConfig.all = true;
    this.selectionConfig.subset = false;
    this.selectionConfig.sheet = false;
    this.selectionConfig.time = false;
    const selectedImports = [];
    for (const sheet of this.imports.sheets) {
      for (const row of this.imports.rows[sheet]) {
        const key = sheet + "-" + row;
        for (const leasing of this.imports.values[key]) {
          if (!leasing) {
            continue;
          }
          selectedImports.push({ sheet, row, leasing });
        }
      }
    }
    this.selectedImports = selectedImports;
  }

  async closeModal(save: boolean = true) {
    if (save) {
      await this.save();
    } else {
      this.Close.emit();
      await this.modalController.dismiss();
    }
  }

  openFileSelection() {
    const e: HTMLElement = this.FileSelect.nativeElement;
    e.click();
  }

  async filesDropped(files: File[]) {
    return this.parseFile({ target: { files } });
  }

  async parseFile(event: any) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      this.fileName = event.target.files[0].name;
      this.hasFile = true;
    }
    this.loading = true;
    await this.leasingImportService.import(event);
  }

  async save() {
    this.synchronizing = true;
    const leasings = [];
    for (const item of this.selectedImports) {
      const leasing = item.leasing;
      if (!this.userExists(leasing.User) || !this.contractExists(leasing.Settings.ContractId)) {
        continue;
      } else if (this.users[leasing.User.CustomId].Id != this.contracts[leasing.Settings.ContractId].LessorUserId) {
        continue;
      }
      
      const copy = JSON.parse(JSON.stringify(this.contracts[leasing.Settings.ContractId]));
      copy.Settings.StartDate = moment(leasing.Settings.StartDate).format('YYYY-MM-DD');
      copy.Settings.EndDate = moment(leasing.Settings.EndDate).format('YYYY-MM-DD');
      copy.Settings.Title = leasing.Settings.Title;
      copy.Settings.HiringManager = leasing.Settings.HiringManager;
      leasings.push(copy);
    }
    await this.importAll(leasings);
    this.synchronizing = false;
    this.Close.emit();
    return this.modalController.dismiss("update");
  }

  userExists(user: User): boolean {
    return user && user.CustomId && this.users[user.CustomId] != null;
  }
  getUserName(customId: string): string {
    return this.users && this.users[customId] ? this.users[customId].LastName + ", " + this.users[customId].FirstName : "---";
  }
  contractExists(contractId: string): boolean {
    return contractId && this.contracts[contractId] != null;
  }

  async importAll(leasings: LeasingAgreement[]): Promise<boolean> {
    return this.api.post("leasing-agreements/import/", { LeasingAgreements: leasings });
  }
}
