import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "app-timepicker",
  templateUrl: "./timepicker.component.html",
  styleUrls: ["./timepicker.component.scss"],
})
export class TimepickerComponent {
  @Input() Disabled = false;
  @Input() set Time(timeObject: Date) {
    if (timeObject) {
      if (typeof timeObject === "string") {
        timeObject = new Date(timeObject);
      }
      this.fullDate = timeObject;
      this.time = {
        hour: timeObject.getHours(),
        minute: timeObject.getMinutes(),
        second: this.time.second,
      };
    }
  }

  @Input() set TimeString(timeObject: string) {
    if (timeObject) {
      this.fullDate = new Date();
      this.time.hour = moment(timeObject).hours();
      this.time.minute = moment(timeObject).minutes();
      this.fullDate.setHours(this.time.hour, this.time.minute);
    }
  }

  @Output() TimeChange = new EventEmitter<Date>();
  @Output() TimeStringChange = new EventEmitter<string>();

  fullDate: Date = new Date();
  time: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };

  constructor() {}

  get Time(): Date {
    this.fullDate.setHours(this.time.hour, this.time.minute);
    return this.fullDate;
  }

  get TimeString(): string {
    this.fullDate.setHours(this.time.hour, this.time.minute);
    return moment(this.time).format("HH:mm");
  }

  setHours(hour) {
    this.time.hour = hour;
    this.saveChanges();
  }

  setMinutes(minute) {
    this.time.minute = minute;
    this.saveChanges();
  }

  saveChanges() {
    const newDate = new Date(this.fullDate);
    newDate.setHours(this.time.hour, this.time.minute, 0, 0);
    this.fullDate = newDate;
    this.TimeChange.emit(this.fullDate);
    this.TimeStringChange.emit(moment(this.time).format("HH:mm"));
  }

  changeDate(event: any) {
    if (this.time && event) {
      this.time.hour = event.hour;
      this.time.minute = event.minute;
      this.saveChanges();
    }
  }
}
