import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-order-table-head",
  templateUrl: "./order-table-head.component.html",
  styleUrls: ["./order-table-head.component.scss"],
})
export class OrderTableHeadComponent implements OnInit {
  @Input() Name: string;
  @Input() DisplayName: string;

  @Input()
  set Order(Order) {
    if (Order) {
      this.OrderObject = Order;
      this.checkOrder();
    }
  }

  @Output() OrderChange: EventEmitter<any> = new EventEmitter<any>();
  OrderObject: any = {};
  OrderNumber: any;
  OrderedAs: string;

  constructor() {}

  ngOnInit(): void {
    this.DisplayName = this.DisplayName || this.Name;
  }

  changeOrder() {
    if (this.Name) {
      if (this.OrderObject[this.Name]) {
        if (this.OrderObject[this.Name] === "ASC") {
          this.OrderObject[this.Name] = "DESC";
        } else {
          delete this.OrderObject[this.Name];
        }
      } else {
        this.OrderObject[this.Name] = "ASC";
      }
    }
    this.OrderChange.emit(this.OrderObject);
    this.checkOrder();
  }

  getNumber() {
    let orderNumber = 1;
    if (this.Name && Object.keys(this.OrderObject).length < 2) {
      return "";
    } else {
      for (const key in this.OrderObject) {
        if (key === this.Name) {
          return orderNumber;
        } else {
          orderNumber++;
        }
      }
      return "";
    }
  }

  checkOrder() {
    if (this.Name) {
      this.OrderedAs = this.OrderObject[this.Name] || "";
      this.OrderNumber = this.getNumber();
    }
  }
}
