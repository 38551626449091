import { Injectable } from "@angular/core";
import { Sheet, WorkBook, utils } from "xlsx";
import { AbstractCustomMasterlistImportService } from "./abstract-custom-masterlist-import.service";
import { ToastService } from "../core/toast.service";
import {
  Masterlist,
  MasterlistImportResult,
  MasterlistType,
} from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class SkyAutMasterlistImportService extends AbstractCustomMasterlistImportService {
  constructor(toastService: ToastService) {
    super(toastService);
  }

  getKey(): string {
    return "SkyAutMasterlistImportService";
  }

  private getTypeName(
    sheet: Sheet,
    row: number,
    nameColumn: string,
    typeColumn: string,
    typeNameColumn: string,
  ) {
    const name = this.contentOrNull(sheet, nameColumn, row) as string;
    const type = this.contentOrNull(sheet, typeColumn, row) as string;
    const typeName = this.contentOrNull(sheet, typeNameColumn, row) as string;
    return name && type && typeName
      ? (type + " --- " + name + " " + typeName).trim()
      : "";
  }
  private getName(
    sheet: Sheet,
    row: number,
    nameColumn: string,
    secondNameColumn: string,
  ) {
    const name = (
      (this.contentOrNull(sheet, nameColumn, row) as string) || ""
    ).trim();
    const secondName = (
      (this.contentOrNull(sheet, secondNameColumn, row) as string) || ""
    ).trim();
    let result = name;
    if (secondName && secondName.length > 0) {
      result = result.trim() + " vs. " + secondName.trim();
    }
    return result;
  }

  public async createElements(
    workBook: WorkBook,
  ): Promise<MasterlistImportResult> {
    if (!workBook || workBook.SheetNames.length === 0) {
      return { sheets: [], rows: {}, values: {} };
    }

    const keys: string[] = [];
    const values: { [key: string]: Masterlist[] } = {};
    const sheetName = workBook.SheetNames[0];
    const sheet = workBook.Sheets[sheetName];
    const startRow = 3;
    const endRow = this.getRows(sheet);
    const typeColumn = "C";
    const dateColumn = "D";
    const nameColumn = "E";
    const lapColumn = "P";
    const secondNameColumn = "F";
    const timeStartColumn = "H";
    const timeEndColumn = "I";
    const typeNameColumn = "T";
    const externalIdColumn = "A";
    for (let i = startRow; i <= endRow; i++) {
      const Name = this.getName(sheet, i, nameColumn, secondNameColumn);
      const typeName = this.getTypeName(
        sheet,
        i,
        nameColumn,
        typeColumn,
        typeNameColumn,
      );
      const ExternalId = this.getExternalId(sheet, externalIdColumn, i);
      const { StartTime, EndTime } = this.createTimesFromDateAndTimeframe(
        sheet,
        i,
        dateColumn,
        timeStartColumn,
        timeEndColumn,
      );
      if (Name && StartTime && EndTime) {
        const masterlist: Masterlist = {
          StartTime,
          EndTime,
          Name,
          ExternalId,
          SpecialFields: {
            Runde: this.stringContent(sheet, lapColumn, i).trim(),
          },
          Type: typeName
        };
        keys.push(String(i));
        values[sheetName + "-" + i] = [masterlist];
      }
    }
    const hasMasterlists = Object.keys(values).length > 0;
    return {
      sheets: hasMasterlists ? [sheetName] : [],
      rows: hasMasterlists ? { [sheetName]: keys } : {},
      values,
    };
  }

  public getPreviewSettings(): { Icon: any; Cols: any } {
    return {
      Icon: {
        Style: { width: "5%" },
        Show: true,
        Icons: { MissingId: true, MissingType: true, WillBeDeleted: false },
      },
      Cols: {
        Row: {
          Style: { width: "5%" },
          Show: true,
        },
        Date: {
          Style: { width: "10%" },
          Show: true,
        },
        Start: {
          Style: { width: "5%" },
          Show: true,
        },
        End: {
          Style: { width: "5%" },
          Show: true,
        },
        Name: {
          Style: { width: "25%" },
          Show: true,
        },
        Type: {
          Style: { width: "15%" },
          Show: true,
        },
        SpecialFields: {
          Style: { width: "10%" },
          Show: true,
        },
      },
    };
  }
}
