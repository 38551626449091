import { Injectable } from "@angular/core";
import { StorageService } from "../storage/storage.service";
import { Observable, Subject } from "rxjs";
import { DisplayConfig, ScheduleDisplayConfig, ScheduleGridDisplayConfig, UserDisplayConfig } from "../../interfaces";

@Injectable({
  providedIn: "root"
})
export class DisplaySettingService {
  public USER_FORMAT: { [key: string]: string } = { fl: "fl", lf: "lf" };
  public USER_CUSTOM_ORDER: { [key: string]: boolean } = {
    true: true,
    false: false
  };
  public SCHEDULE_CUSTOM_COLOR: { [key: string]: boolean } = {
    true: true,
    false: false
  };
  public SCHEDULE_SHOW_WARNING: { [key: string]: boolean } = {
    true: true,
    false: false
  };
  public SCHEDULE_SHOW_CONFLICT: { [key: string]: boolean } = {
    true: true,
    false: false
  };
  public USER_SORTING: { [key: string]: string } = { asc: "asc", desc: "desc" };
  public SCHEDULE_LETTER_COUNT: { [key: string]: number } = { "2": 2, "4": 4 };
  public SCHEDULE_GRID: { [key: string]: ScheduleGridDisplayConfig } = {
    s: {
      name: "s",
      showUserAvatar: false,
      scheduleGridRowHeight: "25px",
      showHours: false,
      showOverTime: false,
      showEmploymentType: false
    },
    m: {
      name: "m",
      showUserAvatar: true,
      scheduleGridRowHeight: "45px",
      showHours: true,
      showOverTime: true,
      showEmploymentType: false
    },
    l: {
      name: "l",
      showUserAvatar: true,
      scheduleGridRowHeight: "60px",
      showHours: true,
      showOverTime: true,
      showEmploymentType: true
    }
  };

  public DEFAULT_CONFIG = {
    schedule: {
      grid: this.SCHEDULE_GRID.m,
      letterCount: 2,
      customColor: this.SCHEDULE_CUSTOM_COLOR["false"],
      showWarning: this.SCHEDULE_SHOW_WARNING["true"],
      showConflict: this.SCHEDULE_SHOW_CONFLICT["true"]
    },
    user: {
      format: this.USER_FORMAT.fl,
      sorting: this.USER_SORTING.asc,
      customOrder: this.USER_CUSTOM_ORDER["false"]
    },
    menu: {
      alwaysOpen: true
    }
  };

  private userUpdate: Subject<UserDisplayConfig> = new Subject<UserDisplayConfig>();
  private userSortUpdate: Subject<void> = new Subject<void>();
  private scheduleUpdate: Subject<ScheduleDisplayConfig> = new Subject<ScheduleDisplayConfig>();
  private scheduleSizeUpdate: Subject<string> = new Subject<string>();
  private configUpdate: Subject<DisplayConfig> = new Subject<DisplayConfig>();

  constructor(private storageService: StorageService) {}

  public get User(): Observable<UserDisplayConfig> {
    return this.userUpdate.asObservable();
  }

  public get UserSort(): Observable<void> {
    return this.userSortUpdate.asObservable();
  }

  public get Schedule(): Observable<ScheduleDisplayConfig> {
    return this.scheduleUpdate.asObservable();
  }

  public get ScheduleSize(): Observable<string> {
    return this.scheduleSizeUpdate.asObservable();
  }

  public get Config(): Observable<DisplayConfig> {
    return this.configUpdate.asObservable();
  }

  private saveSetting(config: DisplayConfig) {
    const copy = JSON.parse(JSON.stringify(config || {}));
    copy.schedule.grid = copy.schedule.grid.name;
    this.storageService.set("display-config", JSON.stringify(copy));
    this.configUpdate.next(this.getSetting());
  }

  getSetting(): DisplayConfig {
    const value = this.storageService.get("display-config");
    const setting = value ? JSON.parse(value) || {} : {};
    const defaultSetting = JSON.parse(JSON.stringify(this.DEFAULT_CONFIG));
    const scheduleGridSetting = setting.schedule?.grid || defaultSetting.schedule.grid.name;
    const scheduleCustomColorSetting = setting.schedule?.customColor || defaultSetting.schedule.customColor;
    const scheduleShowWarningSetting = setting.schedule?.showWarning == undefined ? defaultSetting.schedule.showWarning : setting.schedule?.showWarning;
    const scheduleShowConflictSetting = setting.schedule?.showConflict == undefined ? defaultSetting.schedule.showConflict : setting.schedule?.showConflict;
    setting.schedule = setting.schedule || defaultSetting.schedule;
    setting.schedule.grid = this.SCHEDULE_GRID[scheduleGridSetting] || defaultSetting.schedule.grid;
    setting.schedule.customColor = this.SCHEDULE_CUSTOM_COLOR[scheduleCustomColorSetting] || defaultSetting.schedule.customColor;
    setting.schedule.showWarning = this.SCHEDULE_SHOW_WARNING[scheduleShowWarningSetting] == undefined ? defaultSetting.schedule.showWarning : this.SCHEDULE_SHOW_WARNING[scheduleShowWarningSetting];
    setting.schedule.showConflict = this.SCHEDULE_SHOW_CONFLICT[scheduleShowConflictSetting] == undefined ? defaultSetting.schedule.showConflict : this.SCHEDULE_SHOW_CONFLICT[scheduleShowConflictSetting];
    setting.user = setting.user || defaultSetting.user;
    setting.menu = setting.menu || defaultSetting.menu;
    return setting;
  }

  getScheduleSetting(): ScheduleDisplayConfig {
    const setting = this.getSetting();
    return setting.schedule;
  }

  getScheduleGridSize(): string {
    const setting = this.getSetting();
    return setting.schedule.grid.name;
  }

  getUserSetting(): UserDisplayConfig {
    const setting = this.getSetting();
    return setting.user;
  }

  setScheduleGridConfig(size: string) {
    const gridSetting: ScheduleGridDisplayConfig =
      this.SCHEDULE_GRID[size] || JSON.parse(JSON.stringify(this.DEFAULT_CONFIG.schedule.grid));
    const setting = this.getSetting();
    setting.schedule.grid = gridSetting;
    this.saveSetting(setting);
    this.scheduleUpdate.next(this.getScheduleSetting());
    this.scheduleSizeUpdate.next(gridSetting.name);
  }

  setScheduleLetterCount(count: number | string) {
    const setting = this.getSetting();
    setting.schedule.letterCount = Number(count);
    this.saveSetting(setting);
    this.scheduleUpdate.next(this.getScheduleSetting());
  }


  setUserFormat(format: string) {
    const userFormat = this.USER_FORMAT[format] || this.DEFAULT_CONFIG.user.format;
    const setting = this.getSetting();
    setting.user.format = userFormat;
    this.saveSetting(setting);
    this.userUpdate.next(this.getUserSetting());
  }

  setUserCustomOrder(value: boolean) {
    const userCustomOrder = this.USER_CUSTOM_ORDER[value.toString()] || this.DEFAULT_CONFIG.user.customOrder;
    const setting = this.getSetting();
    setting.user.customOrder = userCustomOrder;
    this.saveSetting(setting);
    this.userUpdate.next(this.getUserSetting());
    this.userSortUpdate.next();
  }
  setScheduleCustomColor(value: boolean) {
    const scheduleCustomColor = this.SCHEDULE_CUSTOM_COLOR[value.toString()] || this.DEFAULT_CONFIG.schedule.customColor;
    const setting = this.getSetting();
    setting.schedule.customColor = scheduleCustomColor;
    this.saveSetting(setting);
    this.scheduleUpdate.next(this.getScheduleSetting());
  }
  setScheduleShowWarning(value: boolean) {
    const scheduleShowWarning = this.SCHEDULE_SHOW_WARNING[value.toString()];
    const setting = this.getSetting();
    setting.schedule.showWarning = scheduleShowWarning;
    this.saveSetting(setting);
    this.scheduleUpdate.next(this.getScheduleSetting());
  }
  setScheduleShowConflict(value: boolean) {
    const scheduleShowConflict = this.SCHEDULE_SHOW_CONFLICT[value.toString()];
    const setting = this.getSetting();
    setting.schedule.showConflict = scheduleShowConflict;
    this.saveSetting(setting);
    this.scheduleUpdate.next(this.getScheduleSetting());
  }

  setUserSorting(sorting: string) {
    const userSorting = this.USER_SORTING[sorting] || this.DEFAULT_CONFIG.user.sorting;
    const setting = this.getSetting();
    setting.user.sorting = userSorting;
    this.saveSetting(setting);
    this.userUpdate.next(this.getUserSetting());
    this.userSortUpdate.next();
  }

  getMenuAlwaysOpen(): boolean {
    const setting = this.getSetting();
    return Boolean(setting.menu.alwaysOpen);
  }

  setMenuAlwaysOpen(value: boolean) {
    const setting = this.getSetting();
    setting.menu.alwaysOpen = value;
    this.saveSetting(setting);
  }
}
