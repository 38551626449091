import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from "@angular/core";
import { ApiService } from "../../../services/api/api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "../../../services/core/toast.service";
import { StorageService } from "../../../services/storage/storage.service";
import { DisplaySettingService } from "../../../services/core/display-setting.service";
import { Subscription } from "rxjs";
import { SettingsService } from "../../../services/api/settings.service";
import { SortService } from "../../../services/core/sort.service";
import { ArrayService } from "../../../services/core/array.service";
import { Field, SearchFunction, SpecialField } from "../../../interfaces";
import { TypeGroupService } from "src/app/services/api/type-group.service";

@Component({
  selector: "app-default-filter",
  templateUrl: "./default-filter.component.html",
  styleUrls: ["./default-filter.component.scss"]
})
export class DefaultFilterComponent implements OnInit, OnDestroy {
  @Input()
  ShowFilter: boolean;

  @Output()
  ShowFilterChange: EventEmitter<boolean> = new EventEmitter(true);

  @Input()
  FilterData: any;

  @Input()
  SpecialFieldsComponent: string;

  @Input()
  set Component(value: string) {
    this.ComponentName = value;
    this.FilterData = {};
    this.filterInit();
  }

  @Output()
  FilterDataChange: EventEmitter<any> = new EventEmitter<any>(true);

  @Output()
  doFilter: EventEmitter<any> = new EventEmitter<any>(true);

  ComponentName = "";
  SpecialItems: SpecialField[] = []; //this.api.Cache('special-fields/component/' + this.ComponentName, []);
  NormalItems: Field[] = []; //this.api.Cache('special-fields/model/' + this.ComponentName, []);
  NestedItems: Field[] = []; //Qualifications from User eg
  filteredResults = false;
  FilterOptions: any;
  NewFilterScenarioName: string;
  FilterScenarios: any;
  HideFilterItems: {} = {};
  private SearchFilterNumber = 0;
  settingSubscription: Subscription;
  userSearchFunction: SearchFunction = this.arrayService.createSearchFunction("FirstName", "LastName");
  typeSearchFct = this.groupService.getTypeSearchFunction();

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private storageService: StorageService,
    private settingService: DisplaySettingService,
    private sortService: SortService,
    private arrayService: ArrayService,
    private groupService: TypeGroupService
  ) {
    this.ShowFilter = true;
  }

  async ngOnInit() {
    if (this.ComponentName) {
      await this.filterInit();
    }
    this.settingSubscription = this.settingService.UserSort.subscribe(() => {
      for (const item of this.NormalItems) {
        if (item.DynamicOptions) {
          if (item.FieldType === "UserSelect") {
            const options = item.Options && item.Options.length > 0 ? [...item.Options] : [];
            const setting = this.settingService.getUserSetting();
            this.sortService.sortByComparators(
              options,
              ...(setting.sorting == "desc"
                ? setting.customOrder
                  ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
                  : this.sortService.USER_COMPARATORS_DESC
                : setting.customOrder
                ? this.sortService.USER_COMPARATORS_CUSTOM
                : this.sortService.USER_COMPARATORS)
            );
            item.Options = options;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.settingSubscription) {
      this.settingSubscription.unsubscribe();
    }
  }

  onEnter() {
    this.doFilterFunction();
  }

  async filterInit() {
    this.FilterData = this.FilterData || {};
    this.SpecialItems = await this.api.get("special-fields/component/" + this.SpecialFieldsComponent || this.ComponentName);
    this.NormalItems = await this.api.get("special-fields/model/" + this.ComponentName);
    this.NestedItems = await this.api.get("special-fields/nested/" + this.ComponentName);
    for (const item of this.NormalItems) {
      if (item.DynamicOptions) {
        const options = await this.api.get(item.DynamicOptions);
        if (item.FieldType === "UserSelect") {
          item.Options = options.map(option => {
            return {
              FirstName: option.FirstName,
              LastName: option.LastName,
              Picture: option.Picture,
              Id: option.Id,
              CustomOrder: option.CustomOrder
            };
          });
          const setting = this.settingService.getUserSetting();
          this.sortService.sortByComparators(
            item.Options,
            ...(setting.sorting == "desc"
              ? setting.customOrder
                ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
                : this.sortService.USER_COMPARATORS_DESC
              : setting.customOrder
              ? this.sortService.USER_COMPARATORS_CUSTOM
              : this.sortService.USER_COMPARATORS)
          );
        } else {
          if (item.DynamicOptions == "work-order-types") {
            item.Options = options.map(option => {
              return { Name: option.Name, Id: option.Id, Group: option.WorkOrderTypeGroup.Name };
            });
          } else if (item.DynamicOptions == "shift-types") {
            item.Options = options.map(option => {
              return { Name: option.Name, Id: option.Id, Group: option.ShiftTypeGroup.Name };
            });
          } else {
            item.Options = options.map(option => {
              return { Name: option.Name, Id: option.Id };
            });
          }
        }
      }
    }
    this.loadFilterScenarios();
    this.loadHideFilterItems();
  }

  doFilterFunction() {
    this.FilterDataChange.emit(this.FilterData);
    // this.ShowFilterChange.emit(false);
    this.doFilter.emit(this.FilterData);
    this.SearchFilterNumber = Object.keys(this.FilterData).length;
  }

  resetFilter() {
    for (const item of Object.keys(this.FilterData)) {
      delete this.FilterData[item];
    }
    this.ShowFilterChange.emit(false);
    this.doFilter.emit(this.FilterData);
    this.SearchFilterNumber = Object.keys(this.FilterData).length;
  }

  removeFilterOption(item) {
    delete this.FilterData[item];
    this.doFilter.emit(this.FilterData);
    this.SearchFilterNumber = Object.keys(this.FilterData).length;
  }

  hasFilterData(): boolean {
    return Object.keys(this.FilterData).length > 0;
  }

  public NumOfItemsInSearch(): number {
    return this.SearchFilterNumber;
  }

  openFilterOptionsModal(Modal) {
    this.modalService.open(Modal, {
      windowClass: "modal-animated",
      centered: true
    });
  }

  loadFilterScenarios() {
    this.FilterScenarios = this.storageService.getComponentFilter(this.ComponentName);
  }

  async addFilterScenario(FilterScenario) {
    this.loadFilterScenarios();
    const newScenario = JSON.parse(JSON.stringify(FilterScenario));
    newScenario.ScenarioTitle = this.NewFilterScenarioName;
    this.FilterScenarios.push(newScenario);
    this.saveFilterScenarios();
    await this.toastService.presentSuccess("Das Filterszenario wurde erfolgreich hinzugefügt!");
    this.NewFilterScenarioName = null;
  }

  saveFilterScenarios() {
    // TODO ScenarioName und FilterData in API speichern
    this.storageService.setComponentFilter(this.ComponentName, this.FilterScenarios);
  }

  private loadHideFilterItems() {
    this.HideFilterItems = this.storageService.getComponentHideFilter(this.ComponentName);
  }

  setHideFilterItems(modal) {
    this.storageService.setComponentHideFilter(this.ComponentName, this.HideFilterItems);
    modal.close("save click");
  }
}
