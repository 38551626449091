import { Component, EventEmitter, Input, Output } from "@angular/core";
import { WorkTimeService } from "../../services/core/work-time.service";
import { Router } from "@angular/router";
import { SortService } from "../../services/core/sort.service";
import { DatePipe } from "@angular/common";
import { Comparator, WorkTime, User } from "src/app/interfaces";
import { TranslateEnumPipe } from "../../pipes/translate-enum.pipe";
import { TranslateService } from "@ngx-translate/core";
import { RemarkService } from "src/app/services/api/remark.service";

@Component({
  selector: "app-work-time-table",
  templateUrl: "./work-time-table.component.html",
  styleUrls: ["./work-time-table.component.scss"]
})
export class WorkTimeTableComponent {
  @Input() set WorkTimes(workTimes: any[]) {
    const newWorkTimes = workTimes && workTimes.length > 0 ? [...workTimes] : [];
    this.orderBy(newWorkTimes);
    this.workTimes = newWorkTimes;
    this.countRemarks(newWorkTimes);
  }
  @Input() Times: any[];
  @Input() User: User = {};
  @Input() FilterElement;
  @Output() resetFilter = new EventEmitter<void>();
  @Input() LocationTracking = false;
  @Input() DenyTimeTracking = false;
  @Input() loading = true;

  workTimes: any[];

  comparators: Comparator[] = [
    {
      field: "employee",
      raw: true,
      order: -1,
      compareFct: (a: WorkTime, b: WorkTime) => {
        const aName = ((a?.User?.LastName || "") + " " + (a?.User?.FirstName || "")).trim().toLowerCase();
        const bName = ((b?.User?.LastName || "") + " " + (b?.User?.FirstName || "")).trim().toLowerCase();
        return aName.localeCompare(bName);
      }
    },
    {
      field: "type",
      raw: true,
      order: -1,
      compareFct: (a: WorkTime, b: WorkTime) => {
        const aName = (this.getItemType(a) || "").trim().toLowerCase();
        const bName = (this.getItemType(b) || "").trim().toLowerCase();
        return aName.localeCompare(bName);
      }
    },
    {
      field: "date",
      raw: true,
      order: 0,
      compareFct: (a: WorkTime, b: WorkTime) => {
        const aName = this.datePipe.transform(this.getItemDate(a), "YYYY-MM-dd");
        const bName = this.datePipe.transform(this.getItemDate(b), "YYYY-MM-dd");
        return aName.localeCompare(bName);
      }
    },
    {
      field: "startTime",
      raw: true,
      order: -1,
      compareFct: (a: WorkTime, b: WorkTime) => {
        const aName = this.getItemStartTime(a);
        const bName = this.getItemStartTime(b);
        return aName.localeCompare(bName);
      }
    },
    {
      field: "endTime",
      raw: true,
      order: -1,
      compareFct: (a: WorkTime, b: WorkTime) => {
        const aName = this.getItemEndTime(a);
        const bName = this.getItemEndTime(b);
        return aName.localeCompare(bName);
      }
    },
    {
      field: "BreakTime",
      raw: true,
      order: -1
    }
  ];

  RemarkCounts = {};

  constructor(
    private workTimeService: WorkTimeService,
    private router: Router,
    private sortService: SortService,
    private datePipe: DatePipe,
    private enumTranslate: TranslateEnumPipe,
    private translate: TranslateService,
    private remarkService: RemarkService
  ) { }

  getShowTimeDiff(item: any): boolean {
    return !this.DenyTimeTracking && (item.ShiftId || item.WorkOrderId);
  }

  getStartDate(item: WorkTime) {
    return this.workTimeService.getStartDate(item);
  }

  getStartTimeDifference(item: WorkTime) {
    return this.workTimeService.getStartTimeDifference(item);
  }

  getEndTimeDifference(item: WorkTime) {
    return this.workTimeService.getEndTimeDifference(item);
  }

  getNominalWorkTime(item: any) {
    return item.TargetMinutes || this.workTimeService.getPlannedWorkTime(item) || 0;
  }

  getActualWorkTime(item: any) {
    return item.WorkingMinutes !== null && item.WorkingMinutes !== undefined
      ? item.WorkingMinutes
      : this.workTimeService.getActualWorkTime(item) || 0;
  }

  onClick() {
    this.resetFilter.emit();
  }

  getItemDate(item: any): Date {
    return item.Date || this.getStartDate(item);
  }

  getItemStartTime(item: any): string {
    return (item.Shift || item.WorkOrder) && item.StartTime ? this.datePipe.transform(item.StartTime, "HH:mm") : "-";
  }

  getItemEndTime(item: any): string {
    return (item.Shift || item.WorkOrder) && item.EndTime ? this.datePipe.transform(item.EndTime, "HH:mm") : "-";
  }

  getItemType(item: any): string {
    if (!item) {
      return null;
    }
    let type: string;
    let target: { Name?: string };
    if (item.Shift) {
      type = this.enumTranslate.transform(this.workTimeService.getItemType(item), "TimeslotType");
      target = item.Shift;
    } else if (item.WorkOrder) {
      type = this.enumTranslate.transform(this.workTimeService.getItemType(item), "TimeslotType");
      target = item.WorkOrder;
    } else if (item.AbsenceType) {
      type = "Abwesenheit";
      target = item.AbsenceType;
    } else {
      type = "Feiertag";
      target = item;
    }
    return type + this.getName(target);
  }

  getName(item: { Name?: string }): string {
    return item && item.Name && item.Name.length > 0 ? " (" + item.Name + ")" : "";
  }

  async click(time: any) {
    if (time.Id) {
      let navigation;
      if (time.Shift || time.WorkOrder) {
        if (this.DenyTimeTracking) {
          navigation = ["/time-slots", time.Id];
        } else if (time.Multiple) {
          navigation = ["/work-times/time-slot", time.TimeSlotId];
        } else {
          navigation = ["/work-times", time.Id];
        }
      } else if (time.AbsenceType) {
        navigation = ["/absences", time.Id];
      } else {
        navigation = ["/calendar", "list"];
      }
      return this.router.navigate(navigation);
    }
  }

  async clickType(time: any) {
    let navigation;
    if (time.Shift) {
      navigation = ["/shifts", time.ShiftId];
    } else if (time.WorkOrder) {
      navigation = ["/work-orders", time.WorkOrderId];
    } else {
      navigation = ["/absences", time.Id];
    }
    return this.router.navigate(navigation);
  }

  orderBy(workTimes: WorkTime[] = this.workTimes) {
    this.sortService.sortByComparators(workTimes, ...this.comparators);
  }

  getLocationTracking(workTime: WorkTime, field: string, name: string) {
    if (!workTime?.LocationTracking || !workTime.LocationTracking[field]) {
      return "";
    }
    return name + ": " + this.translate.instant(workTime.LocationTracking[field]);
  }

  async countRemarks(workTimes: WorkTime[] = this.workTimes) {
    const RemarkCounts = {}
    try {
      this.loading = true
      for (let workTime of workTimes) {
        if (workTime.TimeSlotId) {
          RemarkCounts[workTime.Id] = await this.remarkService.getCountByItemId(workTime.TimeSlotId);;
        }
      }
      this.RemarkCounts = RemarkCounts;
      this.loading = false
    } catch (error) {
      console.log(error);
    }
  }
}
