import { Component, OnInit } from "@angular/core";
import { ScheduleStorageService } from "../../../services/storage/schedule-storage.service";
import { ScheduleStateService } from "../../../services/core/schedule-state.service";
import { SettingsService } from "src/app/services/api/settings.service";

@Component({
  selector: "app-schedule-shift-view-toggle",
  templateUrl: "./schedule-shift-view-toggle.component.html",
  styleUrls: ["./schedule-shift-view-toggle.component.scss"],
})
export class ScheduleShiftViewToggleComponent implements OnInit {
  public LIST: string = this.scheduleStateService.VIEWS.SHIFT_LIST;
  public WEEK: string = this.scheduleStateService.VIEWS.SHIFT_WEEK;
  public MONTH: string = this.scheduleStateService.VIEWS.SHIFT_MONTH;
  public SHIFT_PERSONLIST: string = this.scheduleStateService.VIEWS.SHIFT_PERSONLIST;

  private ShowShiftPersonList: boolean
  constructor(private scheduleStateService: ScheduleStateService,
    private settingsService: SettingsService
  ) {
    this.settingsService.getBooleanValue('ShowShiftPersonList').then(BooleanValue => this.ShowShiftPersonList = BooleanValue)
  }

  async ngOnInit() {
    this.ShowShiftPersonList = await this.settingsService.getBooleanValue('ShowShiftPersonList')
  }

  async navigate(url: string) {
    return this.scheduleStateService.goToShiftView(url);
  }
}
