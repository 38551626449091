import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AbstractDetailXlsxExportService } from "../../../services/export/abstract-detail-xlsx-export.service";
import { SettingsService } from "../../../services/api/settings.service";
import { SocketService } from "src/app/services/socket/socket.service";
import { NavigationService } from "src/app/services/core/navigation.service";
import { Subscription } from "rxjs";
import { Shift, Team, TimeSlot, WorkOrder } from "src/app/interfaces";

@Component({
  selector: "app-crud-bar",
  templateUrl: "./crud-bar.component.html",
  styleUrls: ["./crud-bar.component.scss"],
})
export class CrudBarComponent implements OnInit, OnDestroy {
  @Input() set Disabled(value: any) {
    this.setValues(this.disabled, value);
  }
  @Input() set Show(value: any) {
    this.setValues(this.show, value);
  }
  @Input() set ExportAs(value: any) {
    this.setValues(this.exportAs, value);
  }
  @Input() DeleteMessage: string;
  @Input() ItemId: string;
  @Input() RemarkItemId: string;
  @Input() ItemComponent: string;
  @Input() Item: Shift | WorkOrder;
  @Input() ItemName: string;
  @Input() ExportName: string;
  @Input() ExportData: any;
  @Input() ExportHtmlElementId: string; //TODO not in use, remove
  @Input() ExportService: { xlsx: AbstractDetailXlsxExportService<any> };
  @Input() MailRecipientIds: string[] = [];
  @Input() MailTeamIds: Team[] = [];
  @Input() MailSubject: string = '';
  @Input() MailWorkOrder: WorkOrder = {};
  @Input() MailTimeSlots: TimeSlot[] = [];
  @Input() CustomGoBack: boolean = false;
  @Output() Save: EventEmitter<any> = new EventEmitter(true);
  @Output() Delete: EventEmitter<any> = new EventEmitter(true);
  @Output() GoBack: EventEmitter<any> = new EventEmitter(true);
  saveActive = false;
  handleSave = this.createKeyListener();
  disabled = this.createBarComponentsObject(false);
  show = this.createBarComponentsObject(true);
  exportAs = {
    pdf: false,
    xlsx: true,
    json: true,
  };
  isAllowedToMail = false;
  blockSaveSubscription: Subscription;

  showMasterListLogs: boolean = false

  constructor(
    private modalService: NgbModal,
    private settingsService: SettingsService,
    private socketService: SocketService,
    private navigation: NavigationService,
  ) { }

  async ngOnInit() {
    this.isAllowedToMail =
      await this.settingsService.getBooleanValue("MailingList");
    // document.getElementById('body').addEventListener('keydown', this.handleSave);
    this.disabled.socketSave = this.socketService.getBlocked();
    this.blockSaveSubscription = this.socketService.BlockSave.subscribe(
      (isBlocked) => (this.disabled.socketSave = isBlocked),
    );
    
    // Zeigt die Logs für die Masterlist an 
    const masterlistId = await this.settingsService.getStringValue('MasterlistId')
    if (masterlistId) {
      this.showMasterListLogs = true;
    }
  }

  ngOnDestroy(): void {
    // document.getElementById('body').removeEventListener('keydown', this.handleSave, true);
    if (this.blockSaveSubscription) {
      this.blockSaveSubscription.unsubscribe();
    }
  }

  createBarComponentsObject(value: boolean) {
    return {
      save: value,
      socketSave: value,
      delete: value,
      logs: value,
      remarks: value,
      export: value,
      mail: value,
    };
  }

  setValues(element: any, replacements: any) {
    for (const key of Object.keys(replacements)) {
      element[key] = replacements[key];
    }
  }

  openModal(Modal) {
    this.modalService.open(Modal, {
      windowClass: "modal-animated",
      centered: true,
    });
  }

  createKeyListener() {
    return (event) => {
      let goBack = false;
      if (event.shiftKey) {
        goBack = true;
      }
      if (event.ctrlKey && event.key === "Enter") {
        event.preventDefault();
        this.Save.emit(goBack);
      }
    };
  }

  async save(goBack: boolean = false) {
    if (!this.saveActive) {
      this.saveActive = true;
      await this.Save.emit(goBack);
      this.saveActive = false;
    }
  }

  async closeModal(modal, acceptDelete: boolean) {
    if (acceptDelete) {
      await this.Delete.emit(true);
    }
    modal.close();
  }

  back() {
    if (this.CustomGoBack) {
      return this.GoBack.emit()
    }
    this.navigation.back();
  }

  async onXlsxExport() {
    if (this.ExportService.xlsx) {
      this.ExportService.xlsx.export(this.ExportData);
    }
  }
}
