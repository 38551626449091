import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { SettingsService } from "./settings.service";
import * as moment from "moment";
import { MaterialTypeFilterService } from "../filter/material-type-filter.service";
import {
  Inventory,
  InventoryHistory,
  InventorySchedule,
  Remark,
  Team,
} from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  constructor(
    private api: ApiService,
    private settingsService: SettingsService,
    private filterService: MaterialTypeFilterService,
    private translate: TranslateService,
  ) { }

  show(): Promise<boolean> {
    return this.settingsService.getBooleanValue("UseMaterials");
  }

  async getActiveTeam(
    inventory: Inventory,
    date: string | Date | moment.Moment,
  ): Promise<Team> {
    const dateString = moment(date).format("YYYY-MM-DD");
    return this.api.get("inventory/team/" + inventory.Id + "/" + dateString);
  }

  async getSchedule(
    startTime: Date | string,
    endTime: Date | string,
  ): Promise<InventorySchedule[]> {
    return this.api.get(
      "inventory/schedule/" + startTime + "/" + endTime,
      this.filterService.getParams(),
    );
  }

  async getScheduleRemarks(
    startTime: Date | string,
    endTime: Date | string,
  ): Promise<{ [inventoryId: string]: { [date: string]: Remark[] } }> {
    return this.api.get(
      "inventory/schedule/remarks/" + startTime + "/" + endTime,
      this.filterService.getParams(),
    );
  }

  async getAllTypes(): Promise<string[]> {
    return this.api.get("inventory/types");
  }

  async getAll(filter?: any): Promise<Inventory[]> {
    const show = await this.show();
    if (!show) {
      return [];
    }
    filter = filter || {};
    const params = {
      Filter: JSON.stringify(filter),
    };
    return this.api.get("inventory", params);
  }

  async get(id: string): Promise<Inventory> {
    return this.api.get(`inventory/${id}`);
  }

  async getTrackerInformations(id: string): Promise<any> {
    return this.api.get(`inventory/${id}/tracker-infos`);
  }

  async getLocationHistory(id: string): Promise<any> {
    return this.api.get(`inventory/${id}/location-history`);
  }

  async save(inventory: Inventory): Promise<Inventory> {
    return this.api.post(
      "inventory" + (inventory.Id ? "/" + inventory.Id : ""),
      inventory,
      this.translate.instant("services.api.inventory.saveInventoriesSuccess"),
    );
  }

  async destroy(inventory: Inventory): Promise<Inventory> {
    if (!inventory?.Id) {
      return inventory;
    }
    return this.api.delete(`inventory/${inventory.Id}`);
  }

  async saveHistory(
    inventoryHistory: InventoryHistory,
  ): Promise<InventoryHistory> {
    return this.api.post(
      "inventory-history" +
      (inventoryHistory.Id ? "/" + inventoryHistory.Id : ""),
      inventoryHistory,
      this.translate.instant('die_zuordnung_wurde_erfolgreich_gespeichert'),
    );
  }

  async destroyHistory(
    inventoryHistory: InventoryHistory,
  ): Promise<InventoryHistory> {
    return this.api.delete("inventory-history/" + inventoryHistory.Id);
  }
}
