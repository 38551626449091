import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CollapseService {
  private updateSubject: Subject<{ Item: any; Open: boolean }> = new Subject<{
    Item: any;
    Open: boolean;
  }>();

  constructor() {}

  public get Update(): Observable<{ Item: any; Open: boolean }> {
    return this.updateSubject.asObservable();
  }

  emit(item: any, open: boolean) {
    this.updateSubject.next({ Item: item, Open: open });
  }
}
