import { Injectable } from "@angular/core";
import { AbstractCustomWorkOrderImportService } from "./abstract-custom-work-order-import.service";
import { CellObject, WorkBook, WorkSheet } from "xlsx";
import { ToastService } from "../core/toast.service";
import { WorkOrder, WorkOrderImportResult } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class SkyDeWorkOrderImportService extends AbstractCustomWorkOrderImportService {
  constructor(toastService: ToastService) {
    super(toastService);
  }

  writeChangeLogFile(): boolean {
    return true;
  }

  getKey(): string {
    return "SkyDeWorkOrderImportService";
  }

  getSelectAllDefault(): boolean {
    return false;
  }

  private contentMatches(cell: CellObject, lookup: string): boolean {
    if (!cell) {
      return false;
    }
    const content = ((cell.v || "") as string).trim().toLowerCase();
    return content === lookup.toLowerCase();
  }

  private sheetIsValid(sheet: WorkSheet, row: number = 1, indexColumn: string = "A", indexName: string = "Index", statusColumn: string = "B", statusName: string = "Status",) {
    if (!sheet) {
      return false;
    }
    const indexCell = sheet[indexColumn + row];
    const statusCell = sheet[statusColumn + row];
    return (
      this.contentMatches(indexCell, indexName) &&
      this.contentMatches(statusCell, statusName)
    );
  }

  private getInventoryValue(value: string): string[] {
    if (!value) {
      return [];
    }

    const splitValue = (value || "").split(/[,;/]+/);
    const names = [];
    for (const singleValue of splitValue) {
      const trimmed = singleValue.trim().replace(/\s+/g, "");
      if (trimmed.length > 0) {
        names.push(trimmed);
      }
    }
    return names.length > 0 ? names : null;
  }

  private isUHD(value: string): boolean {
    if (!value || value.length === 0) {
      return false;
    }
    const uhdMap = {
      uhd: true,
      "uhd hdr": true,
      "hd hdr": true,
      ja: true,
    };
    return !!uhdMap[value];
  }

  private deleteUhd(value: string): boolean {
    return value === "-" || value === "nein";
  }

  private getDeletedAt(status: string): Date {
    return status === "canceled" || status === "postponed" ? new Date() : null;
  }

  public async createElementsFromSheet(sheet: WorkSheet,): Promise<{ rows: string[]; values: { [row: string]: WorkOrder[] } }> {
    if (!this.sheetIsValid(sheet)) {
      return { rows: [], values: {} };
    }

    const startRow = 3;
    const endRow = this.getRows(sheet);

    const externalIdColumn = "A";
    const statusColumn = "B";
    const dateColumn = "D";
    const sportColumn = "C";
    const homeColumn = "E";
    const guestColumn = "F";
    const timeStartColumn = "H";
    const timeEndColumn = "I";
    const playoutColumn = "M";
    const roomColumn = "J";
    const uhdColumn = "L";
    const topEventColumn = "N";

    const keys: string[] = [];
    const values: { [key: string]: WorkOrder[] } = {};

    for (let i = startRow; i <= endRow; i++) {
      const playout = this.stringContent(sheet, playoutColumn, i)
        .toLowerCase()
        .trim();

      const udhOnly = playout === "uhd";
      const doImport = playout === "sky" || playout === "mti" || playout === "ja" || udhOnly;
      if (!doImport) {
        continue;
      }
      const sport = this.stringContent(sheet, sportColumn, i, true).trim();
      const home = this.stringContent(sheet, homeColumn, i, true).trim();
      const guest = this.stringContent(sheet, guestColumn, i, true).trim();
      const status = this.stringContent(sheet, statusColumn, i)
        .toLowerCase()
        .trim();
      const room = this.stringContent(sheet, roomColumn, i).trim();
      const uhd = this.stringContent(sheet, uhdColumn, i).toLowerCase().trim();
      const topEvent = this.stringContent(sheet, topEventColumn, i)
        .toLowerCase()
        .trim();

      const name = sport + " " + home + (guest ? " / " + guest : "");
      const externalId = this.getExternalId(sheet, externalIdColumn, i);
      const isUhd = this.isUHD(uhd);
      const isTopEvent = topEvent === "sky" || topEvent === "mti" || topEvent === "ja";
      const deletedAt = this.getDeletedAt(status);
      const deleteUhd = !isUhd && this.deleteUhd(uhd);
      const inventoryNames = this.getInventoryValue(room);
      const uhdSpecialField = { UHD: true };
      const topEventSpecialField = { "Top Event": true };
      const deleteTopEvent = !isTopEvent && (topEvent === "-" || topEvent === "nein");
      const { StartTime, EndTime } = this.createTimesFromDateAndTimeframe(sheet, i, dateColumn, timeStartColumn, timeEndColumn, 0);

      if (!name && !(deleteUhd && externalId) && !(deleteTopEvent && externalId)) {
        continue;
      }

      const workOrders = [];
      const workOrder: WorkOrder = {
        StartTime,
        EndTime,
        Name: name,
        ExternalId: externalId,
        DeletedAt: deletedAt,
      };
      if (inventoryNames) {
        workOrder.Inventories = inventoryNames.map(name => {
          return { Name: name };
        });
      }
      workOrder.WorkOrderType = {
        Name: sport,
      };

      if (deleteUhd && externalId) {
        const clone = JSON.parse(JSON.stringify(workOrder));
        clone.Name = "UHD " + clone.Name;
        clone.ExternalId = clone.ExternalId ? clone.ExternalId + "_UHD" : null;
        clone.DeletedAt = new Date();
        workOrders.push(clone);
      }
      if (deleteTopEvent && externalId) {
        const clone = JSON.parse(JSON.stringify(workOrder));
        clone.Name = "Top Event " + clone.Name;
        clone.ExternalId = clone.ExternalId ? clone.ExternalId + "_TOP" : null;
        clone.DeletedAt = new Date();
        workOrders.push(clone);
      }
      if (name) {
        if (!udhOnly) {
          workOrders.push(workOrder);
        }
        if (isUhd) {
          const clone = JSON.parse(JSON.stringify(workOrder));
          clone.Name = "UHD " + clone.Name;
          clone.ExternalId = clone.ExternalId
            ? clone.ExternalId + "_UHD"
            : null;
          clone.SpecialFields = Object.assign({}, uhdSpecialField);
          clone.Inventories = [{ Name: "UHD" }];
          workOrders.push(clone);
        }
        if (isTopEvent) {
          const clone = JSON.parse(JSON.stringify(workOrder));
          clone.Name = "Top Event " + clone.Name;
          clone.ExternalId = clone.ExternalId
            ? clone.ExternalId + "_TOP"
            : null;
          clone.SpecialFields = Object.assign({}, topEventSpecialField);
          workOrders.push(clone);
        }
      }

      if (workOrders.length > 0) {
        values[i] = workOrders;
        keys.push(String(i));
      }
    }
    return { rows: keys, values };
  }

  public async createElements(workBook: WorkBook): Promise<WorkOrderImportResult> {
    const result = { sheets: [], rows: {}, values: {} };
    if (!workBook || workBook.SheetNames.length === 0) {
      return result;
    }
    for (const sheetName of workBook.SheetNames) {
      const sheet = await this.createElementsFromSheet(workBook.Sheets[sheetName]);
      if (sheet.rows.length > 0) {
        result.sheets.push(sheetName);
        result.rows[sheetName] = sheet.rows;
        for (const key in sheet.values) {
          result.values[sheetName + "-" + key] = sheet.values[key];
        }
      }
    }
    return result;
  }

  public getPreviewSettings(): { Icon: any; Cols: any } {
    return {
      Icon: {
        Style: { width: "5%" },
        Show: true,
        Icons: {
          MissingId: true,
          MissingType: true,
          WillBeDeleted: true,
          MissingInventory: true,
        },
      },
      Cols: {
        Sheet: {
          Style: { width: "7%" },
          Show: true,
        },
        Row: {
          Style: { width: "5%" },
          Show: true,
        },
        Date: {
          Style: { width: "10%" },
          Show: true,
        },
        Start: {
          Style: { width: "5%" },
          Show: true,
        },
        End: {
          Style: { width: "5%" },
          Show: true,
        },
        Name: {
          Style: { width: "28%" },
          Show: true,
        },
        Type: {
          Style: { width: "10%" },
          Show: true,
        },
        Inventory: {
          Style: { width: "10%" },
          Show: true,
        },
        SpecialFields: {
          Style: { width: "15%" },
          Show: true,
        },
      },
    };
  }
}
