import { Component, Inject, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AuthService } from "../../services/api/auth.service";
import { ApiService } from "../../services/api/api.service";
import { CompanyDesignService } from "../../services/core/company-design.service";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StorageService } from "../../services/storage/storage.service";
import { ModalController } from "@ionic/angular";
import { DisplaySettingsModalComponent } from "../../modals/display-settings-modal/display-settings-modal.component";
import { QueueItem, Company } from "../../interfaces";
import { UrlStorageService } from "../../services/storage/url-storage.service";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
  providers: [NgbDropdownConfig],
})
export class UserInfoComponent implements OnInit {
  @Input() ShowFullScreen: boolean = false;
  @Input() RouterLink: string = null;

  Company: Company = {};
  public queue: QueueItem[];
  isFullscreen = false;
  AccountUrl = "";
  elem;

  constructor(
    public titleService: Title,
    public authService: AuthService,
    public api: ApiService,
    public companyDesign: CompanyDesignService,
    private router: Router,
    private modalService: NgbModal,
    private modalController: ModalController,
    config: NgbDropdownConfig,
    @Inject(DOCUMENT) private document: any,
    private storageService: StorageService,
    private urlStorageService: UrlStorageService,
  ) {
    config.placement = "bottom-right";
    config.container = "body";
  }

  async ngOnInit() {
    this.Company = await this.api.get("company");
    this.AccountUrl = this.authService.getAccountUrl();
    this.elem = document.documentElement;
  }

  logout() {
    this.storageService.removeCompanyId();
    this.storageService.removeApiKey();
    this.urlStorageService.clear();
    //location.href = this.AccountUrl + 'logout';
    location.reload();
  }

  toggleFullScreen() {
    if (this.isFullscreen) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  async openDisplaySettings() {
    const modal = await this.modalController.create({
      component: DisplaySettingsModalComponent,
    });
    await modal.present();
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
      this.isFullscreen = true;
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
      this.isFullscreen = true;
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
      this.isFullscreen = true;
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
      this.isFullscreen = true;
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
      this.isFullscreen = false;
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
      this.isFullscreen = false;
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
      this.isFullscreen = false;
    }
  }

  goToSetup() {
    this.storageService.removeCompanyId();
    this.router.navigate(["setup/welcome"]);
  }

  openShortCutModal(content) {
    this.modalService.open(content);
  }

  async goTo(routerLink) {
    if(routerLink) {
      await this.router.navigateByUrl(routerLink);
    }
  }
}
