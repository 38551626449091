import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Permission } from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  constructor(private api: ApiService, private translate: TranslateService) { }

  async getAll(): Promise<Permission[]> {
    return this.api.get("permissions");
  }

  async get(name: string): Promise<Permission> {
    return this.api.get("permissions/" + name);
  }

  async getAvailableMenuPermissions(): Promise<any> {
    return this.api.get("permissions/availableMenuPermissions");
  }

  async getAvailableDatabasePermissions(): Promise<any> {
    return this.api.get("permissions/availablePermissions");
  }

  async getMenuPermission(name: string): Promise<Permission> {
    return this.api.get("permissions/menuPermissions/" + name);
  }

  async create(permission: Permission): Promise<Permission> {
    return this.api.post(
      "permissions",
      permission,
      this.translate.instant('die_rolle_wurden_erfolgreich_gespeichert'),
    );
  }

  async updateDatabasePermission(permission: Permission): Promise<Permission> {
    return this.api.post("permissions/" + permission.RoleName, permission);
  }

  async updateMenuPermission(
    roleName: string,
    menuPermission: any,
  ): Promise<Permission> {
    return this.api.post(
      "permissions/menuPermissions/" + roleName,
      menuPermission,
      this.translate.instant('das_zugriffsrecht_wurde_erfolgreich_gespeichert'),
    );
  }

  async getAllRoles(): Promise<Permission[]> {
    return this.api.get("company/roles/");
  }

  async destroy(permission: Permission): Promise<Permission> {
    return this.api.delete(
      "permissions/" + permission.RoleName,
      this.translate.instant('die_rolle_wurden_erfolgreich_gelscht'),
    );
  }
}
