import { ApiService } from "src/app/services/api/api.service";
import { Injectable } from "@angular/core";
import { PlatformStorageService } from "../storage/platform-storage.service";
import { VersionModalComponent } from "src/app/modals/version-modal/version-modal.component";
import { ModalController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class VersionService {
  constructor(
    private apiService: ApiService,
    private modalController: ModalController,
    private platformStorage: PlatformStorageService,
  ) { }

  async checkVersion() {
    if (!this.platformStorage.getIsTabled()) {
      let localVersion = this.platformStorage.getVersion();
      let apiVersion = await this.apiService.get("manager-version");
      if (apiVersion !== localVersion) {

        const modal = await this.modalController.create({
          componentProps: {},
          component: VersionModalComponent,
          cssClass: "holiday-import-modal"
        });
        await modal.present();
        await modal.onWillDismiss();
        await this.reNewVersion(apiVersion)
      }
    }
  }

  async reNewVersion(newVersion: string) {
    this.platformStorage.setVersion(newVersion);
    location.replace("/");
  }
}
