import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit {
  @Input() PDF: any;

  pages = 1;
  page = 1;

  constructor() {}

  ngOnInit() {}

  afterLoadComplete(event: any) {
    this.page = 1;
    this.pages = event.numPages;
  }

  togglePage(add: number) {
    let next = (this.page += add);
    if (next < 1) {
      next = 1;
    } else if (next > this.pages) {
      next = this.pages;
    }
    this.page = next;
  }
}
