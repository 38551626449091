import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { StorageService } from "../storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class MaterialTypeFilterService {
  subject = new Subject<void>();

  constructor(private storageService: StorageService) {}

  get Filter(): Observable<void> {
    return this.subject.asObservable();
  }

  setFilter(teamId: string) {
    const previousId = this.getFilter();
    if (teamId !== previousId) {
      this.storageService.setMaterialTypeFilter(teamId);
      this.subject.next();
    }
  }

  getFilter(): string {
    return this.storageService.getMaterialTypeFilter();
  }

  getParams() {
    const name = this.getFilter();
    if (name) {
      return { Type: name };
    }
    return {};
  }
}
