import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment/moment";
import { HasTimes } from "../../interfaces";

@Component({
  selector: "app-start-end-time-picker",
  templateUrl: "./start-end-time-picker.component.html",
  styleUrls: ["./start-end-time-picker.component.scss"],
})
export class StartEndTimePickerComponent implements OnInit {
  @Input() set Item(item: HasTimes) {
    this.item = item;
    this.setSelectedDate();
  }

  @Output() TimeChange = new EventEmitter<HasTimes>();

  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedStartTime: Date;
  selectedEndTime: Date;
  item: HasTimes;
  multiDay: boolean = false;

  constructor() {}

  ngOnInit() {}

  changeDate(newDate: Date) {
    this.selectedStartDate = newDate
      ? moment(newDate).startOf("day").toDate()
      : null;
    this.updateItem();
  }
  changeEndDate(newDate: Date) {
    this.selectedEndDate = newDate
      ? moment(newDate).startOf("day").toDate()
      : null;
    this.updateItem();
  }

  setSelectedDate() {
    this.selectedStartDate = moment(this.item?.StartTime)
      .startOf("d")
      .toDate();
    this.selectedEndDate = moment(this.item?.EndTime)
      .startOf("d")
      .toDate();
    this.selectedStartTime = new Date(this.item?.StartTime);
    this.selectedEndTime = new Date(this.item?.EndTime);
    if (
      moment(this.item?.EndTime).diff(moment(this.item?.StartTime)) > 86400000
    ) {
      this.multiDay = true;
    }
  }

  updateItem() {
    const element: HasTimes = { StartTime: null, EndTime: null };
    if (!this.selectedStartDate) {
      this.selectedEndDate = null;
    } else {
      const start = moment().set({
        hours: this.selectedStartTime.getHours(),
        minutes: this.selectedStartTime.getMinutes(),
      });
      const end = moment().set({
        hours: this.selectedEndTime.getHours(),
        minutes: this.selectedEndTime.getMinutes(),
      });
      if (!this.multiDay) {
        if (start.isSameOrAfter(end)) {
          this.selectedEndDate = moment(this.selectedStartDate)
            .add(1, "d")
            .toDate();
        } else {
          this.selectedEndDate = moment(this.selectedStartDate).toDate();
        }
      }
      element.StartTime = moment(this.selectedStartDate)
        .set({ hour: start.hour(), minutes: start.minutes() })
        .toDate();
      element.EndTime = moment(this.selectedEndDate)
        .set({ hour: end.hour(), minutes: end.minutes() })
        .toDate();
    }
    this.TimeChange.emit(element);
  }
}
