import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CompanyDesignService } from "../../services/core/company-design.service";
import {
  FormChildType,
  FormElementPosition,
  FormFieldType,
  FormTableColumnType,
  FormType,
  FormValueListType,
} from "../../enums";
import {
  FormContainer,
  FormElement,
  FormGroup,
  FormTable,
  FormTableColumn,
  InteractionForm,
} from "../../interfaces";

@Component({
  selector: "app-page-preview",
  templateUrl: "./page-preview.component.html",
  styleUrls: ["./page-preview.component.scss", "./style.css"],
})
export class PagePreviewComponent implements AfterViewInit, OnInit {
  @ViewChild("FormPane", { static: true }) FormPane: ElementRef;
  @ViewChild("FormWrapper", { static: true }) FormWrapper: ElementRef;

  @Input() set Form(interactionForm: InteractionForm) {
    this.interactionForm = interactionForm;
    this.resize(null);
  }

  @Output() ZoomChange = new EventEmitter<boolean>();

  FormChildTypes = FormChildType;
  FormTableColumnTypes = FormTableColumnType;
  FormFieldTypes = FormFieldType;
  FormTypes = FormType;

  interactionForm: InteractionForm;
  paneHeight: number;
  paneWidth: number;
  scale: number;
  defaultBackground: string =
    "https://teamcraft.s3-eu-central-1.ionoscloud.com/95cf3c1c-6d21-41da-9196-573566e881eb";
  zoom = false;
  today: Date;

  constructor(public companyDesign: CompanyDesignService) {}

  ngOnInit() {
    this.today = new Date();
  }

  ngAfterViewInit(): void {
    this.zoom = false;
    this.resize(null);
  }

  resize(event: Event) {
    this.paneHeight = this.FormPane.nativeElement.clientHeight;
    this.paneWidth = this.FormPane.nativeElement.clientWidth;
    this.scale = Math.min(
      this.FormWrapper.nativeElement.clientWidth / this.paneWidth,
      this.FormWrapper.nativeElement.clientHeight / this.paneHeight,
    );
    if (this.paneWidth === 0) {
      setTimeout(() => {
        this.resize(null);
      }, 300);
    }
  }

  getHeaderStyle() {
    const style: any = {};
    if (this.interactionForm.TitleSettings.Height) {
      const height = this.interactionForm.TitleSettings.Height + "cm";
      style.height = height;
      style.maxHeight = height;
    }

    if (this.interactionForm.TitleSettings.BackgroundColor) {
      style.backgroundColor =
        this.interactionForm.TitleSettings.BackgroundColor;
    }
    return style;
  }

  getTitleStyle() {
    const style: any = {
      textAlign:
        this.interactionForm.TitleSettings.Position || FormElementPosition.LEFT,
    };
    if (this.interactionForm.TitleSettings.Color) {
      style.color = this.interactionForm.TitleSettings.Color;
    }
    return style;
  }

  getFormPaneStyle() {
    return {
      transform: "translate(-50%, -50%) scale(" + this.scale + ")",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${
        this.interactionForm.PageSettings.BackgroundImage ||
        this.defaultBackground
      })`,
      padding:
        this.interactionForm.PageSettings.MarginTop +
        "mm " +
        this.interactionForm.PageSettings.MarginRight +
        "mm " +
        this.interactionForm.PageSettings.MarginBottom +
        "mm " +
        this.interactionForm.PageSettings.MarginLeft +
        "mm ",
    };
  }

  getContainerStyle() {
    return {
      height: "100%",
    };
  }

  getTableContainerStyle(table: FormTable) {
    const style: any = {};
    if (table.Settings.Width) {
      style.width = table.Settings.Width + "cm";
    }
    if (table.Settings.ShowBorder) {
      style.border = "1px solid #000";
    }
    if (table.Settings.MarginLeft) {
      style.marginLeft = table.Settings.MarginLeft + "pt";
    }
    if (table.Settings.MarginTop) {
      style.marginTop = table.Settings.MarginTop + "pt";
    }
    return style;
  }

  getGroupStyle(group: FormGroup) {
    const style: any = {
      height: "unset",
    };
    if (group.Settings.Width) {
      style.width = group.Settings.Width + "cm";
    }
    if (group.Settings.ShowBorder) {
      style.border = "1px solid #000";
    }
    if (group.Settings.MarginLeft) {
      style.marginLeft = group.Settings.MarginLeft + "pt";
    }
    if (group.Settings.MarginTop) {
      style.marginTop = group.Settings.MarginTop + "pt";
    }
    return style;
  }

  getChildHeaderStyle(child: FormGroup | FormTable) {
    const style: any = {
      textAlign: child.Settings.TitlePosition || FormElementPosition.LEFT,
    };
    if (child.Settings.BackgroundColor) {
      style.backgroundColor = child.Settings.BackgroundColor;
    }
    if (child.Settings.Color) {
      style.color = child.Settings.Color;
    }
    return style;
  }

  getFormContainerStyle(container: FormContainer) {
    const style: any = {
      borderColor: container.Settings.ShowBorder ? null : "transparent",
    };
    if (
      container.Settings.Height &&
      this.interactionForm.Type === FormType.STATIC
    ) {
      style.height = container.Settings.Height + "cm";
    }
    if (container.Settings.MarginTop) {
      style.marginTop = container.Settings.MarginTop + "pt";
    }
    return style;
  }

  getFormElementStyle(formElement: FormElement) {
    return {
      marginTop: formElement.Settings.MarginTop + "pt",
      marginLeft: formElement.Settings.MarginLeft + "pt",
    };
  }

  getImageWrapperStyle(formElement: FormElement) {
    const style: any = {};
    if (formElement.Settings.MaxWidth) {
      style.maxWidth = formElement.Settings.MaxWidth + "pt";
    }
    return style;
  }

  getImageStyle(formElement: FormElement) {
    const style: any = {};
    if (formElement.Settings.MaxHeight) {
      style.maxHeight = formElement.Settings.MaxHeight + "pt";
    }
    return style;
  }

  getTotalColumns(table: FormTable): number {
    let number = 0;
    for (let column of table.FormTableColumns) {
      if (this.isValueListColumn(column)) {
        number += (column.Settings.ValueList || []).length;
      } else if (this.isFieldListColumn(column)) {
        number += (column.Settings.FieldList || []).length;
      } else {
        number++;
      }
    }
    return number;
  }

  toggleZoom() {
    this.zoom = !this.zoom;
    this.ZoomChange.emit(this.zoom);
    setTimeout(() => this.resize(null), 200);
  }

  getExampleTextForSingleSelect(formElement: FormElement) {
    return formElement.Settings.ValueList &&
      formElement.Settings.ValueList.length > 0
      ? formElement.Settings.ValueList[0]
      : formElement.Placeholder || "--";
  }

  getExampleTextForMultiSelect(formElement: FormElement) {
    const text = (formElement.Settings.ValueList || []).join(", ");
    return text.length > 0 ? text : formElement.Placeholder || "--";
  }

  getFormElementCanBeRequired(formElement: FormElement) {
    return (
      formElement.FieldType &&
      formElement.FieldType != FormFieldType.DIVIDER &&
      formElement.FieldType != FormFieldType.TEXT_STATIC &&
      formElement.FieldType != FormFieldType.DATE_STATIC
    );
  }

  getFormElementIsRequiredText(formElement: FormElement): string {
    if (
      this.getFormElementCanBeRequired(formElement) &&
      (formElement.Required || formElement.Settings.ConditionActive)
    ) {
      return "*";
    }
    return null;
  }

  getColumnCanBeRequired(column: FormTableColumn) {
    return (
      column.Type &&
      column.Type != FormTableColumnType.TEXT_STATIC &&
      column.Type != FormTableColumnType.TIMESLOT
    );
  }

  getStaticColumnRequiredText(column: FormTableColumn): string {
    if (this.getColumnCanBeRequired(column) && column.Settings?.Required) {
      return "*";
    }
    return null;
  }

  isValueListColumn(column: FormTableColumn) {
    return (
      (column.Type === FormTableColumnType.SINGLE_SELECTION ||
        column.Type === FormTableColumnType.MULTI_SELECTION) &&
      column.Settings?.ValueListType === FormValueListType.COLUMNS
    );
  }

  isFieldListColumn(column: FormTableColumn) {
    return column.Type === FormTableColumnType.TIMESLOT;
  }

  isDynamicColumn(column: FormTableColumn) {
    return this.isValueListColumn(column) || this.isFieldListColumn(column);
  }
}
