import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { CollapseService } from "../../services/core/collapse.service";
import { CollapseList } from "../../interfaces";

@Component({
  selector: "app-collapse-all-button",
  templateUrl: "./collapse-all-button.component.html",
  styleUrls: ["./collapse-all-button.component.scss"],
})
export class CollapseAllButtonComponent implements OnInit, OnDestroy {
  @Input() Collapse: CollapseList;
  @Input() set Items(items: any[]) {
    this.items = items;
    this.updateIsOpen();
  }
  @Input() Identifier = "Id";
  @Output() CollapseChange = new EventEmitter<CollapseList>();
  isOpen: boolean;
  collapseSubscription: Subscription;
  items: any[];

  constructor(private collapseService: CollapseService) {}

  ngOnInit() {
    this.collapseSubscription = this.collapseService.Update.subscribe(
      (update) => {
        this.Collapse[this.getItemIdentifier(update.Item)] = update.Open;
        this.CollapseChange.emit(this.Collapse);
        this.updateIsOpen();
      },
    );
  }

  ngOnDestroy() {
    if (this.collapseSubscription) {
      this.collapseSubscription.unsubscribe();
    }
  }

  toggle() {
    if (!this.items) {
      return;
    }
    this.isOpen = !this.isOpen;
    for (const item of this.items || []) {
      this.Collapse[this.getItemIdentifier(item)] = this.isOpen;
    }
    this.CollapseChange.emit(this.Collapse);
  }

  getItemIdentifier(item: any) {
    return this.Identifier ? item[this.Identifier] : item;
  }

  updateIsOpen() {
    this.isOpen = this.getAllOpen();
  }

  getAllOpen(): boolean {
    for (const item of this.items || []) {
      const state = (this.Collapse || {})[this.getItemIdentifier(item)];
      if (!state) {
        return false;
      }
    }
    return true;
  }
}
