import { DisplayConfig } from './../../../interfaces';
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DisplaySettingService } from "src/app/services/core/display-setting.service";

@Component({
  selector: "app-schedule-colors",
  templateUrl: "./schedule-colors.component.html",
  styleUrls: ["./schedule-colors.component.scss"],
})
export class ScheduleColorsComponent implements OnInit {
  @Input() HideRestTime = false;
  @Input() HideAbsence = false;
  @Input() HideLeasing = false;
  @Input() HideCustomColor = false;
  @Input() HideColorHints = false;
  @Input() DisplayConfig: DisplayConfig;
  constructor(private displaySettingService: DisplaySettingService) {}

  ngOnInit() {
  }

  toggleCustomColor() {
    this.displaySettingService.setScheduleCustomColor(!this.DisplayConfig.schedule.customColor || false)
  }
  toggleWarning() {
    this.displaySettingService.setScheduleShowWarning(!this.DisplayConfig.schedule.showWarning || false)
  }
  toggleConflict() {
    this.displaySettingService.setScheduleShowConflict(!this.DisplayConfig.schedule.showConflict || false)
  }
}
