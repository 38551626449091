import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ScheduleStateService } from "../../../services/core/schedule-state.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-schedule-navigate-to-today",
  templateUrl: "./schedule-navigate-to-today.component.html",
  styleUrls: ["./schedule-navigate-to-today.component.scss"],
})
export class ScheduleNavigateToTodayComponent implements OnInit {
  @Input() Emit: boolean = false;
  @Output() Click = new EventEmitter<void>();

  constructor(
    private scheduleStateService: ScheduleStateService,
    private router: Router,
  ) {}

  ngOnInit() {}

  async navigate() {
    if (this.Emit) {
      this.Click.emit();
    } else {
      return this.scheduleStateService.goToToday(this.router.url);
    }
  }
}
