import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TimeMode } from "../../../enums";

@Component({
  selector: "app-default-filter-time-switch",
  templateUrl: "./default-filter-time-switch.component.html",
  styleUrls: ["./default-filter-time-switch.component.scss"],
})
export class DefaultFilterTimeSwitchComponent {
  @Input() ActiveModes;
  @Input() HideNone = false;
  @Input()
  set Mode(Mode: TimeMode) {
    this.TimeFilterMode = Mode;
  }

  TimeModes = TimeMode;

  @Output() ModeChange: EventEmitter<TimeMode> = new EventEmitter<TimeMode>();
  TimeFilterMode: TimeMode;

  constructor() {}

  setTimeFilterMode(Mode: TimeMode) {
    this.ModeChange.emit(Mode);
  }
}
