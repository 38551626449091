import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { ApiService } from "../../services/api/api.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "dynamic-default",
  templateUrl: "./dynamic-default.component.html",
  styleUrls: ["./dynamic-default.component.scss"],
})
export class DynamicDefaultComponent implements OnInit {
  @Input() selector: string;
  @Input() description: string;
  @Input() property: any;
  @Output() propertyChange = new EventEmitter<string>();

  defaultProp: any = "";

  constructor(private api: ApiService) {}

  async setDefault() {
    if (this.selector) {
      const type = typeof this.property;
      await this.api.post("defaults/" + encodeURIComponent(this.selector), {
        Value:
          type === "object" ? JSON.stringify(this.property) : this.property,
        Type: type,
        Description: this.description,
      });
    }
  }

  isNotEmpty() {
    return (
      !this.property ||
      (Array.isArray(this.property) && this.property.length === 0) ||
      (typeof this.property === "object" &&
        Object.keys(this.property).length === 0)
    );
  }

  async ngOnInit() {
    if (
      this.selector &&
      ((Array.isArray(this.property) && this.property.length === 0) ||
        !this.property)
    ) {
      const uri = encodeURIComponent(this.selector);
      const defaultProp = await this.api.get("defaults/" + uri);
      if (defaultProp) {
        if (defaultProp.Type === "object") {
          defaultProp.Value = JSON.parse(defaultProp.Value);
        }
        this.propertyChange.emit(defaultProp.Value);
      }
      this.defaultProp = defaultProp;
    }
  }

  async removeDefault() {
    if (this.selector) {
      this.defaultProp = await this.api.post(
        "defaults/" + encodeURIComponent(this.selector),
        {
          Value: "",
          Type: "",
          Description: this.description,
        },
      );
    }
  }
}
