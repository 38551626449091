import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ArrayService } from "../../services/core/array.service";
import { SortService } from "../../services/core/sort.service";
import { Comparator } from "../../interfaces";

@Component({
  selector: "app-live-order-table-head",
  templateUrl: "./live-order-table-head.component.html",
  styleUrls: ["./live-order-table-head.component.scss"],
})
export class LiveOrderTableHeadComponent implements OnInit {
  @Input() Order: Comparator[];
  @Input() Comparator: Comparator;
  @Input() Name: string;
  @Output() OrderChange: EventEmitter<Comparator[]> = new EventEmitter<
    Comparator[]
  >();

  constructor(
    private sortService: SortService,
    private arrayService: ArrayService,
  ) {}

  ngOnInit() {}

  changeOrder() {
    this.Comparator.desc =
      this.Comparator.desc == null ? false : this.Comparator.desc;
    if (this.Comparator.order === -1) {
      this.Comparator.desc = false;
      this.Comparator.order = this.getMaxOrderNumber();
    } else if (this.Comparator.desc === false) {
      this.Comparator.desc = true;
    } else {
      this.Comparator.desc = false;
      this.Comparator.order = -1;
    }
    this.updateOrderNumbers();
    this.OrderChange.emit(this.Order);
  }

  updateOrderNumbers() {
    const sortedComparators = this.sortService.sortAndFilterComparators(
      this.Order,
    );
    for (let i = 0; i < sortedComparators.length; i++) {
      sortedComparators[i].order = i;
    }
    const comparatorsMap = this.arrayService.toMap(sortedComparators, "field");
    for (const order of this.Order) {
      if (comparatorsMap[order.field]) {
        order.order =
          sortedComparators.length > 0
            ? comparatorsMap[order.field].order
            : null;
      } else {
        order.order = -1;
      }
    }
  }

  getMaxOrderNumber(): number {
    const numbers = (this.Order || []).map((o) => o.order || 0);
    return Math.max(...numbers, 0) + 1;
  }
}
