import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment'
import { AuthService } from 'src/app/services/api/auth.service';
import { SettingsService } from 'src/app/services/api/settings.service';
@Component({
  selector: 'app-dashboard-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class DashboardLogsComponent implements OnInit {
  @Input() set GridWidth(value: number) {
    this.gridWidth = value;
  }
  @Input() set GridHeight(value: number) {
    this.gridHeight = value;
  }

  @Input() set IsLoading(value: boolean) {
    this.isLoading = value;
    if (value) {
      this.init();
    }
  }
  isLoading = false;
  gridWidth = 2;
  gridHeight = 2;
  Logs: any = [];
  detailLogging = false

  constructor(private http: HttpClient, private settings: SettingsService, private authService: AuthService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.detailLogging = await this.settings.getBooleanValue('DetailLogging')
    if (this.detailLogging) {
      this.Logs = await this.http.get(this.authService.loggingApi + 'logs').toPromise()
    }

  }


  setColor(Changes) {
    //TODO deleted missing
    if (Changes?.length > 0) {
      return "bg-status-yellow";
    } else {
      return "bg-status-green";
    }
  }

}
