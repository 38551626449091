import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api/api.service";
import { ArrayService } from "../../services/core/array.service";
import { SortService } from "../../services/core/sort.service";
import { DisplaySettingService } from "../../services/core/display-setting.service";
import { Subscription } from "rxjs";
import {
  SearchFunction,
  Team,
  User,
  Qualification,
  ShiftType,
} from "../../interfaces";
import { TypeGroupService } from "src/app/services/api/type-group.service";
import { TypeGroupType } from "../../enums";
import { SettingsService } from "src/app/services/api/settings.service";

@Component({
  selector: "app-team-detail-content",
  templateUrl: "./team-detail-content.component.html",
  styleUrls: ["./team-detail-content.component.scss"],
})
export class TeamDetailContentComponent implements OnInit, OnDestroy {
  @Input() set Id(teamId: string) {
    const previousId = this.teamId;
    this.teamId = teamId;
    if (teamId === "create") {
      this.resetValues();
    } else if (this.isInitialized && previousId != teamId) {
      this.loadTeam();
    }
  }

  @Input() SplitViewMode;
  @Output() Reload = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() CloseView = new EventEmitter<void>();

  teamId: string;
  team: Team;
  isLoading: boolean = false;
  qualificationSums: {
    [Id: string]: { Qualification: Qualification; Sum: number };
  } = {};
  userMap: { [Id: string]: User } = {};
  selectedMembers: string[] = [];
  selectedManagers: string[] = [];
  addressIsDifferent = false;
  users: User[] = this.api.Cache("employees", []);
  userSearchFunction: SearchFunction = this.arrayService.createSearchFunction(
    "FirstName",
    "LastName",
  );
  settingSubscription: Subscription;
  isInitialized = false;
  typeSearchFct = this.groupService.getTypeSearchFunction();
  shiftTypes: ShiftType[];
  workOrderTypes: ShiftType[];
  DenyTimeTracking = false;
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private arrayService: ArrayService,
    private sortService: SortService,
    private settingService: DisplaySettingService,
    private settingsService: SettingsService,
    private groupService: TypeGroupService,
  ) {}

  async ngOnInit() {
    this.isInitialized = true;
    this.isLoading = true;
    this.resetValues();
    this.users = await this.api.get("employees");
    this.shiftTypes = await this.groupService.getAllTypes(TypeGroupType.SHIFT);
    this.workOrderTypes = await this.groupService.getAllTypes(
      TypeGroupType.WORK_ORDER,
    );
    let setting = this.settingService.getUserSetting();
    this.DenyTimeTracking =
      await this.settingsService.getBooleanValue("DenyTimeTracking");
    this.sortService.sortByComparators(
      this.users,
      ...(setting.sorting == "desc"
        ? setting.customOrder
          ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
          : this.sortService.USER_COMPARATORS_DESC
        : setting.customOrder
        ? this.sortService.USER_COMPARATORS_CUSTOM
        : this.sortService.USER_COMPARATORS),
    );
    this.userMap = this.arrayService.toMap(this.users);
    this.settingSubscription = this.settingService.UserSort.subscribe(() => {
      setting = this.settingService.getUserSetting();
      this.sortService.sortByComparators(
        this.users,
        ...(setting.sorting == "desc"
          ? setting.customOrder
            ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
            : this.sortService.USER_COMPARATORS_DESC
          : setting.customOrder
          ? this.sortService.USER_COMPARATORS_CUSTOM
          : this.sortService.USER_COMPARATORS),
      );
      if (this.team) {
        this.sortService.sortByComparators(
          this.team.TeamMembers,
          ...(setting.sorting == "desc"
            ? setting.customOrder
              ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
              : this.sortService.USER_COMPARATORS_DESC
            : setting.customOrder
            ? this.sortService.USER_COMPARATORS_CUSTOM
            : this.sortService.USER_COMPARATORS),
        );
        this.sortService.sortByComparators(
          this.team.TeamManagers,
          ...(setting.sorting == "desc"
            ? setting.customOrder
              ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
              : this.sortService.USER_COMPARATORS_DESC
            : setting.customOrder
            ? this.sortService.USER_COMPARATORS_CUSTOM
            : this.sortService.USER_COMPARATORS),
        );
        this.selectedMembers = (this.team?.TeamMembers || []).map((u) => u.Id);
        this.selectedManagers = (this.team?.TeamManagers || []).map(
          (u) => u.Id,
        );
      }
    });
    if (this.teamId !== "create") {
      await this.loadTeam();
    }
    this.isLoading = false;
    this.isInitialized = true;
  }

  ngOnDestroy() {
    if (this.settingSubscription) {
      this.settingSubscription.unsubscribe();
    }
  }

  async loadTeam() {
    const team = await this.api.get("teams/" + this.teamId);
    team.SpecialFields = this.team.SpecialFields || {};
    if (team.Zip || team.Street || team.City) {
      this.addressIsDifferent = true;
    }
    const setting = this.settingService.getUserSetting();
    if (team) {
      this.sortService.sortByComparators(
        this.team.TeamMembers,
        ...(setting.sorting == "desc"
          ? setting.customOrder
            ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
            : this.sortService.USER_COMPARATORS_DESC
          : setting.customOrder
          ? this.sortService.USER_COMPARATORS_CUSTOM
          : this.sortService.USER_COMPARATORS),
      );
      this.sortService.sortByComparators(
        this.team.TeamManagers,
        ...(setting.sorting == "desc"
          ? setting.customOrder
            ? this.sortService.USER_COMPARATORS_CUSTOM_DESC
            : this.sortService.USER_COMPARATORS_DESC
          : setting.customOrder
          ? this.sortService.USER_COMPARATORS_CUSTOM
          : this.sortService.USER_COMPARATORS),
      );
    }
    this.selectedMembers = (team?.TeamMembers || []).map((u) => u.Id);
    this.selectedManagers = (team?.TeamManagers || []).map((u) => u.Id);
    this.team = team;
    this.updateQualificationSums();
  }

  updateQualificationSums() {
    const qualificationSums = {};
    for (const user of this.team?.TeamMembers || []) {
      for (const qualification of user.Qualifications) {
        if (!qualificationSums[qualification.Id]) {
          qualificationSums[qualification.Id] = {
            Qualification: qualification,
            Sum: 0,
          };
        }
        qualificationSums[qualification.Id].Sum += 1;
      }
    }
    this.qualificationSums = qualificationSums;
  }

  async save(goBack: boolean = false) {
    if (!this.addressIsDifferent) {
      this.team.City = "";
      this.team.Street = "";
      this.team.Zip = "";
    }
    const newTeam = await this.api.post(
      "teams" + (this.team.Id ? "/" + this.team.Id : ""),
      this.team,
      "Die Teamdaten wurden erfolgreich gespeichert",
    );
    this.Reload.emit();
    if (goBack && !this.SplitViewMode) {
      return this.goBack();
    }
    if (this.teamId != newTeam.Id) {
      await this.router.navigate(["/teams/", newTeam.Id]);
    } else {
      await this.loadTeam();
    }
  }

  async delete() {
    await this.api.delete("teams/" + this.team.Id);
    this.Remove.emit();
    return this.goBack();
  }

  async goBack() {
    if (this.SplitViewMode) {
      return;
    }
    return this.router.navigate(["/teams"]);
  }

  resetValues() {
    this.team = {
      Name: "",
      TeamMembers: [],
      TeamManagers: [],
      SpecialFields: {},
    };
    this.addressIsDifferent = false;
    this.selectedMembers = [];
    this.selectedManagers = [];
  }

  update(
    idField: string = "selectedMembers",
    teamField: string = "TeamMembers",
  ) {
    const users = [];
    for (const id of this[idField]) {
      const user = this.userMap[id];
      if (user) {
        users.push(user);
      }
    }
    this.team[teamField] = users;
  }

  async closeSplitView() {
    await this.CloseView.emit();
  }
}
