import { EventEmitter, Output } from "@angular/core";
import { Component, OnInit, Input } from "@angular/core";
import { UserSchedule, UserScheduleDayItem } from "src/app/interfaces";
import { ScheduleMonthSidebarService } from "src/app/services/core/schedule-month-sidebar.service";
import * as Color from 'color'

@Component({
  selector: "app-schedule-day",
  templateUrl: "./schedule-day.component.html",
  styleUrls: ["./schedule-day.component.scss"]
})
export class ScheduleDayComponent implements OnInit {
  @Input() userSchedule: UserSchedule;
  @Input() shiftPlan: boolean = false;
  @Input() workOrderPlan: boolean = false;
  @Input() dayNumber: number;
  @Input() isUpdating: boolean = false;
  @Input() massDeletion: boolean = false;
  @Input() highlights: boolean = false;
  @Input() sidebarContainer;
  @Input() displayConfig;
  @Input() popItems;
  @Input() popContent;

  @Output() StartClick = new EventEmitter<any>();
  @Output() HandleHover = new EventEmitter<any>();
  @Output() EndClick = new EventEmitter<any>();
  @Output() SelectTimeSlot = new EventEmitter<any>();
  @Output() SelectPopItem = new EventEmitter<any>();
  @Output() UnmarkForDeletion = new EventEmitter<any>();

  constructor(private sidebarService: ScheduleMonthSidebarService) {}

  ngOnInit() {
  }

  startClick(event: MouseEvent, employee) {
    this.StartClick.emit({ employee });
  }
  endClick(event: MouseEvent, employee = null, day = null) {
    this.EndClick.emit({ employee, day });
  }

  handleHover(event: MouseEvent, employee, day) {
    this.HandleHover.emit({ employee, day });
  }

  unmarkForDeletion(day: UserScheduleDayItem, index?) {
    this.UnmarkForDeletion.emit({ day, index });
  }

  selectTimeSlot(employee: UserSchedule, day: UserScheduleDayItem) {
    this.SelectTimeSlot.emit({ employee, day });
  }

  selectPopItem(day: UserScheduleDayItem) {
    this.SelectPopItem.emit({ day });
  }

  getStyleTimeslot(employee: UserSchedule, dayItem: UserScheduleDayItem) {
    return this.sidebarService.getStyleTimeslot(this.sidebarContainer, employee, dayItem);
  }

  getBgStatusClass(day: UserScheduleDayItem, absence?: boolean) {
    let cssClass;

    if (day.hasConflict && this.displayConfig?.schedule?.showConflict) {
      cssClass = "bg-status-red";
    } else if (day.hasWarning && this.displayConfig?.schedule?.showWarning) {
      cssClass = "bg-status-yellow";
    } else if (absence) {
      cssClass = "bg-status-grey";
    } else if (day.isLeasing) {
      cssClass = "bg-status-green";
    } else {
      cssClass = "bg-status-green";
    }

    if(this.displayConfig?.schedule?.customColor) {
      cssClass = "bg-status-custom"
    }

    if (this.highlights) {
      if (day.hasChanges) {
        cssClass = "bg-status-highlight";
      } else {
        cssClass = "bg-status-no-highlight";
      }
    }

    return cssClass;
  }

  getStyleColorBadge(userSchedule: UserSchedule, dayNumber: number): any {
    return {
      borderTopColor: this.getDayColor(userSchedule, dayNumber) || "transparent"
    };
  }
  getStyleColor(userSchedule: UserSchedule, dayNumber: number) {
    let dayColor = this.getDayColor(userSchedule, dayNumber)
    if(this.displayConfig?.schedule?.customColor && dayColor) {
      let colorObj = Color(''+dayColor)
      let colorText
      let colorDraftLight
      if(colorObj.isDark()) {
        colorText = '#FAF9F6'
        colorDraftLight = '#FAF9F6'
      } else {
        colorText = '#212529'
        colorDraftLight = '#FAF9F6'
      }

      return {"--color-custom": dayColor, '--color-custom-light': colorObj.lighten(0.1), '--color-custom-draft': colorObj, '--color-custom-draft-second': colorDraftLight, '--color-custom-text': colorText}
    }
    return {}
  }
  getDayColor(userSchedule: UserSchedule, dayNumber: number): string {
    if (userSchedule?.Days[dayNumber]?.hasAbsence) {
      return userSchedule?.Days[dayNumber]?.dayEvents[0]?.AbsenceType?.Color;
    } else if(userSchedule?.Days[dayNumber]?.hasShift) {
      return userSchedule?.Days[dayNumber]?.dayEvents[0]?.Shift?.ShiftType?.Color;
    } else if(userSchedule?.Days[dayNumber]?.hasWorkOrder) {
      return userSchedule?.Days[dayNumber]?.dayEvents[0]?.WorkOrder?.WorkOrderType?.Color;
    } else {
      return null;
    }
  }

  isSelectedTimeSlot(userId: string, date: moment.Moment): boolean {
    return this.sidebarService.isSelectedTimeSlot(this.sidebarContainer, userId, date);
  }
}
