import { SkyDeProgramPlanImportService } from './sky-de-program-plan-import.service';
import { Injectable } from "@angular/core";
import { WorkBook } from "xlsx";
import { AbstractImportService } from "./abstract-import.service";
import { SkyAutWorkOrderImportService } from "./sky-aut-work-order-import.service";
import { AbstractCustomWorkOrderImportService } from "./abstract-custom-work-order-import.service";
import { SettingsService } from "../api/settings.service";
import { ToastService } from "../core/toast.service";
import { SkyDeWorkOrderImportService } from "./sky-de-work-order-import.service";
import { SkyDeLiveSportWorkOrderImportService } from "./sky-de-live-sport-work-order-import.service";
import { WorkOrder, WorkOrderImportResult } from "../../interfaces";
import { SkyDeLiveSportMasterListWorkOrderImportService } from './sky-de-live-sport-masterlist-work-order-import.service';

@Injectable({
  providedIn: "root",
})
export class WorkOrderImportService extends AbstractImportService<WorkOrder[]> {
  services: {
    [key: string]: AbstractCustomWorkOrderImportService;
  } = {};
  activeService: AbstractCustomWorkOrderImportService = null;

  constructor(
    private skyAutImportService: SkyAutWorkOrderImportService,
    private skyDeProgramPlanImportService: SkyDeProgramPlanImportService,
    private skyDeImportService: SkyDeWorkOrderImportService,
    private skyDeLiveSportImportService: SkyDeLiveSportWorkOrderImportService,
    private skyDeLiveSportMasterListImportService: SkyDeLiveSportMasterListWorkOrderImportService,
    private settingService: SettingsService,
    toastService: ToastService,
  ) {
    super(toastService);
    const serviceArray: AbstractCustomWorkOrderImportService[] = [
      skyAutImportService,
      skyDeImportService,
      skyDeLiveSportImportService,
      skyDeProgramPlanImportService,
      skyDeLiveSportMasterListImportService
    ];
    for (const service of serviceArray) {
      this.services[service.getKey()] = service;
    }
  }

  getKeys(): string[] {
    return Object.keys(this.services);
  }
  async setActiveService(service) {
    this.activeService = await this.getService(service);
  }

  async getService(Service?): Promise<AbstractCustomWorkOrderImportService> {
    let serviceBySetting;
    if(Service) {
      serviceBySetting = Service
    } else {
      serviceBySetting = await this.settingService.getStringValue(
        "WorkOrderImportService",
      );
    }
    return serviceBySetting ? this.services[serviceBySetting] : null;
  }

  public async getPreviewSettings(): Promise<{ Icon: any; Cols: any }> {
    if (this.activeService == null) {
      return { Icon: {}, Cols: {} };
    }
    return this.activeService.getPreviewSettings();
  }

  public async createElements(
    workBook: WorkBook,
  ): Promise<WorkOrderImportResult> {
    if (this.activeService == null) {
      await this.toastService.presentError(
        "Keine Importkonfiguration für das Unternehmen gefunden",
      );
      return { sheets: [], rows: {}, values: {} };
    }
    return this.activeService.createElements(workBook);
  }

  public async getSelectAllDefault(): Promise<boolean> {
    if (!this.activeService) {
      return true;
    }
    return this.activeService.getSelectAllDefault();
  }

  public async writeChangeLogFile(): Promise<boolean> {
    if (!this.activeService) {
      return false;
    }
    return this.activeService.writeChangeLogFile();
  }
}
