import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserSchedule, DisplayConfig } from "../../interfaces";

@Component({
  selector: "app-employee-badge",
  templateUrl: "./employee-badge.component.html",
  styleUrls: ["./employee-badge.component.scss"],
})
export class EmployeeBadgeComponent implements OnInit {
  @Input() User: UserSchedule;
  @Input() DisplayConfig: DisplayConfig;

  constructor(private router: Router) {}

  ngOnInit() {}

  async openUser() {
    return this.router.navigate(["/employees", this.User.Id]);
  }

  async openRemarks() {
    return this.router.navigate(["/remarks", "detail", "User", this.User.Id]);
  }
}
