import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { TypeGroupType } from "../../enums";
import { ShiftTypeService } from "./shift-type.service";
import { WorkOrderTypeService } from "./work-order-type.service";
import {
  SearchFunction,
  ShiftType,
  TypeGroup,
  WorkOrderType,
} from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class TypeGroupService {
  constructor(
    private api: ApiService,
    private shiftTypeService: ShiftTypeService,
    private workOrderTypeService: WorkOrderTypeService,
    private translate: TranslateService
  ) { }

  create(): TypeGroup<any> {
    return { Name: null, Default: false, Types: [] };
  }

  getTypeSearchFunction(): SearchFunction {
    return (term: string, item: any) => {
      const termLowercase = term.toLowerCase();
      const name = String(item.Name || "").toLowerCase();
      const group = String(item.Group || "").toLowerCase();
      return (
        name.indexOf(termLowercase) >= 0 || group.indexOf(termLowercase) >= 0
      );
    };
  }

  async getAllTypes(
    type: TypeGroupType,
    filter: any = {},
    useCache: boolean = false,
  ): Promise<(WorkOrderType | ShiftType)[]> {
    const items: (WorkOrderType | ShiftType)[] =
      type === TypeGroupType.WORK_ORDER
        ? await this.workOrderTypeService.getAll(filter, useCache)
        : await this.shiftTypeService.getAll(filter, useCache);
    for (const item of items) {
      item.Group =
        (item as any).WorkOrderTypeGroup?.Name ||
        (item as any).ShiftTypeGroup?.Name;
    }
    return items;
  }

  async getAll<Type, Group extends TypeGroup<Type>>(
    type: TypeGroupType,
  ): Promise<Group[]> {
    return this.api.get(`${this.getRoute(type)}`);
  }

  async get<Type, Group extends TypeGroup<Type>>(
    type: TypeGroupType,
    id: string,
  ): Promise<Group> {
    return this.api.get(`${this.getRoute(type)}/${id}`);
  }

  async save<Type, Group extends TypeGroup<Type>>(
    type: TypeGroupType,
    group: Group,
  ): Promise<Group> {
    const message = this.translate.instant('die_vorlagengruppe_wurde_erfolgreich_gespeichert');
    return this.api.post(
      `${this.getRoute(type)}${group.Id ? "/" + group.Id : ""}`,
      group,
      message,
    );
  }

  async delete<Type, Group extends TypeGroup<Type>>(
    type: TypeGroupType,
    group: Group,
  ): Promise<Group> {
    return this.api.delete(`${this.getRoute(type)}/${group.Id}`);
  }

  getRoute(type: TypeGroupType) {
    return type === TypeGroupType.WORK_ORDER
      ? "work-order-type-groups"
      : "shift-type-groups";
  }
}
