import { ColorPickerStorageService } from "../../services/storage/color-picker-storage.service";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent implements OnInit, OnDestroy {
  @Input() set Color(color: string) {
    const previousColor = this.color;
    this.color = color;
    if (previousColor !== this.color) {
      this.ColorChange.emit(this.color);
    }
  }
  get Color(): string {
    return this.color;
  }
  @Input() Label: string = "Farbe";
  @Input() Default: string = null;

  @Output() ColorChange: EventEmitter<string> = new EventEmitter<string>();

  color: string;

  Preset = [];
  private presetObservable;

  constructor(private colorPickerStorageService: ColorPickerStorageService) {}

  ngOnInit() {
    this.Preset = this.colorPickerStorageService.getPreset();
    this.presetObservable =
      this.colorPickerStorageService.PresetObservable.subscribe((set) => {
        this.Preset = set;
      });
  }

  ngOnDestroy(): void {
    if (this.presetObservable) {
      this.presetObservable.unsubscribe();
    }
  }

  resetColor() {
    this.color = this.Default;
    this.ColorChange.emit(this.color);
  }
}
